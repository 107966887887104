import { render, staticRenderFns } from "./month_list.vue?vue&type=template&id=897f90fa&scoped=true&"
import script from "./month_list.vue?vue&type=script&lang=js&"
export * from "./month_list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "897f90fa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('897f90fa')) {
      api.createRecord('897f90fa', component.options)
    } else {
      api.reload('897f90fa', component.options)
    }
    module.hot.accept("./month_list.vue?vue&type=template&id=897f90fa&scoped=true&", function () {
      api.rerender('897f90fa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/myEarnings/month_list.vue"
export default component.exports