"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

var _vueDanmaku = _interopRequireDefault(require("vue-danmaku"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AllSettings',
  components: {
    vueDanmaku: _vueDanmaku.default
  },
  props: {},
  data: function data() {
    return {
      receiveShow: false,
      detail: undefined,
      danmus: [],
      amount: '',
      popupShow: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityLKDetail();
  },
  methods: {
    imageAction: function imageAction() {
      this.fetchActivityLKReceive();
    },
    fetchActivityLKReceive: function fetchActivityLKReceive() {
      var _this = this;

      _core.mallInteractor.fetchActivityLKReceive().then(function (data) {
        _this.receiveShow = true;
        _this.amount = data.amount;
      });
    },
    receiveShowDismiss: function receiveShowDismiss() {
      this.receiveShow = false;
      this.fetchActivityLKDetail();
    },
    fetchActivityLKDetail: function fetchActivityLKDetail() {
      var _this2 = this;

      _core.mallInteractor.fetchActivityLKDetail().then(function (data) {
        _this2.detail = data;
        _this2.danmus = data.barrage_list;
      });
    }
  }
};
exports.default = _default;