var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.detail
        ? _c("div", { staticStyle: { "background-color": "white" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  al: "center"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { display: "flex", padding: "10px" } },
                  [
                    _c("van-image", {
                      staticClass: "avatar-img",
                      attrs: {
                        width: "48px",
                        height: "48px",
                        src: _vm.detail.doctor_url
                      }
                    }),
                    _c("div", { staticClass: "header-title" }, [
                      _vm._v(_vm._s(_vm.detail.doctor_name))
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "padding-right": "20px"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.jumpToCommunityMessages($event)
                      }
                    }
                  },
                  [
                    _c(
                      "van-badge",
                      { attrs: { dot: _vm.detail.news_notice > 0 } },
                      [
                        _c("van-icon", {
                          attrs: {
                            name: "envelop-o",
                            size: "25",
                            color: "#ff9000"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-around",
                  "padding-bottom": "10px"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "center" },
                    on: { click: _vm.jumpToMyFollowList }
                  },
                  [
                    _c("div", { staticClass: "header-bottom-title" }, [
                      _vm._v(_vm._s(_vm.detail.followers_count))
                    ]),
                    _c("div", { staticClass: "header-bottom-des" }, [
                      _vm._v("关注")
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "center" },
                    on: { click: _vm.jumpToMyFansList }
                  },
                  [
                    _c("div", { staticClass: "header-bottom-title" }, [
                      _vm._v(_vm._s(_vm.detail.followings_count))
                    ]),
                    _c("div", { staticClass: "header-bottom-des" }, [
                      _vm._v("粉丝")
                    ])
                  ]
                ),
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _c("div", { staticClass: "header-bottom-title" }, [
                    _vm._v(_vm._s(_vm.detail.vote_count))
                  ]),
                  _c("div", { staticClass: "header-bottom-des" }, [
                    _vm._v("获赞数")
                  ])
                ]),
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _c("div", { staticClass: "header-bottom-title" }, [
                    _vm._v(_vm._s(_vm.detail.click_count))
                  ]),
                  _c("div", { staticClass: "header-bottom-des" }, [
                    _vm._v("被阅读量")
                  ])
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm.actionSheetItem
        ? _c("van-action-sheet", {
            attrs: { actions: _vm.getActions() },
            on: { select: _vm.actionSheetOnSelect },
            model: {
              value: _vm.actionSheetShow,
              callback: function($$v) {
                _vm.actionSheetShow = $$v
              },
              expression: "actionSheetShow"
            }
          })
        : _vm._e(),
      _c(
        "van-tabs",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { color: "#ff9000", "title-active-color": "#ff9000" },
          on: { click: _vm.tabsOnClick },
          model: {
            value: _vm.tabsActive,
            callback: function($$v) {
              _vm.tabsActive = $$v
            },
            expression: "tabsActive"
          }
        },
        _vm._l(_vm.tabs, function(tabItem) {
          return _c(
            "van-tab",
            {
              attrs: {
                title: tabItem.title,
                "title-style": "font-size: 16px;",
                name: tabItem.name
              }
            },
            [
              _c(
                "van-pull-refresh",
                {
                  on: { refresh: _vm.onRefresh },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                [
                  _c(
                    "van-list",
                    {
                      attrs: {
                        finished: _vm.finished,
                        "finished-text": "没有更多了",
                        offset: "150"
                      },
                      on: { load: _vm.onLoad },
                      model: {
                        value: _vm.loading,
                        callback: function($$v) {
                          _vm.loading = $$v
                        },
                        expression: "loading"
                      }
                    },
                    _vm._l(_vm.list, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "cell",
                          on: {
                            click: function($event) {
                              return _vm.clickCell(item)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                "align-items": "center"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("van-image", {
                                    staticClass: "avatar-img",
                                    attrs: {
                                      width: "40px",
                                      height: "40px",
                                      src: item.doctor_url
                                    }
                                  }),
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c("div", { staticClass: "title" }, [
                                          _vm._v(_vm._s(item.doctor_name))
                                        ]),
                                        item.status == 1 || item.status == 3
                                          ? _c(
                                              "van-tag",
                                              {
                                                staticStyle: {
                                                  "margin-left": "6px"
                                                },
                                                attrs: { type: "danger" }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("statusFilter")(
                                                      item.status
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "row",
                                          "padding-left": "5px"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              color: "#A4A4A4",
                                              "line-height": "22px"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.diff_for_humans))]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              color: "#A4A4A4",
                                              "line-height": "22px",
                                              "padding-left": "5px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.click_count) + "阅读"
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm.tabsActive == 0
                                ? _c("van-icon", {
                                    attrs: {
                                      size: "18",
                                      name: require("../../assets/community/three-point_1.png")
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.infoIconAction(item)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("div", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(item.title))
                          ]),
                          _c("div", { staticClass: "card-des" }, [
                            _vm._v(_vm._s(item.strip_tag_content))
                          ]),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(item.img_info, function(img) {
                              return _c(
                                "div",
                                { staticStyle: { padding: "4px 4px 8px 0" } },
                                [
                                  _c("van-image", {
                                    staticClass: "avatar-img",
                                    attrs: {
                                      width: "60px",
                                      height: "60px",
                                      src: img
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(item.post_tags, function(itemTag) {
                              return _c(
                                "div",
                                { staticStyle: { padding: "10px 4px 8px 0" } },
                                [
                                  _c(
                                    "van-tag",
                                    {
                                      attrs: {
                                        color: "#E9FFE7",
                                        "text-color": "#41DC33"
                                      }
                                    },
                                    [_vm._v(_vm._s(itemTag.name))]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "6px 20px",
                                display: "flex",
                                "flex-direction": "row",
                                "justify-content": "space-between"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.fetchToggleVote(item)
                                    }
                                  }
                                },
                                [
                                  _c("van-icon", {
                                    attrs: {
                                      name: require("../../assets/community/click_" +
                                        (item.is_voted ? 1 : 0) +
                                        ".png"),
                                      size: "20"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#707270",
                                        padding: "0 6px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.vote_count))]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("van-icon", {
                                    attrs: {
                                      name: require("../../assets/community/f_" +
                                        (item.is_favorited ? 1 : 0) +
                                        ".png"),
                                      size: "20"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#707270",
                                        padding: "0 6px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.favorite_count))]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("van-icon", {
                                    attrs: {
                                      name: require("../../assets/community/reply.png"),
                                      size: "20"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#707270",
                                        padding: "0 6px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.reply_count))]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c("van-divider", {
                            staticStyle: { margin: "5px 0 0 0" }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }