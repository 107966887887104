"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.routes = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/defineProperty"));

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

var _components = _interopRequireDefault(require("./modules/components"));

var _ref;

_vue.default.use(_vueRouter.default);

var routes = [{
  path: '',
  component: _layout.default,
  redirect: '/home',
  children: [{
    path: 'home',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/home/index'));
      });
    },
    name: 'Home',
    meta: {
      title: '首页'
    }
  }, {
    path: 'login',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/login/index'));
      });
    },
    name: 'Login',
    meta: {
      title: '登录'
    }
  }, {
    path: 'myEarnings',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/myEarnings/index'));
      });
    },
    name: 'MyEarnings',
    meta: {
      title: '我的收益'
    }
  }, {
    path: '/income_detail_list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/myEarnings/month_list'));
      });
    },
    name: 'month_list',
    meta: {
      title: '我的收益'
    }
  }, {
    path: 'allSettings',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/allSettings/index'));
      });
    },
    name: 'AllSettings',
    meta: {
      title: '服务设置'
    }
  }, {
    path: 'monthlySeted',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/monthlySeted/index'));
      });
    },
    name: 'MonthlySeted',
    meta: {
      title: '月结提现'
    }
  }, {
    path: 'monthlyMonthList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/monthlyMonthList/index'));
      });
    },
    name: 'MonthlyMonthList',
    meta: {
      title: '对账记录'
    }
  }, {
    path: 'monthlyMonthItem',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/monthlyMonthItem/index'));
      });
    },
    name: 'MonthlyMonthItem',
    meta: {
      title: '对账信息'
    }
  }, {
    path: 'doctorCashAppList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/doctorCashAppList/index'));
      });
    },
    name: 'DoctorCashAppList',
    meta: {
      title: '兑换记录'
    }
  }, {
    path: 'inviteDoctor',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/inviteDoctor/index'));
      });
    },
    name: 'InviteDoctor',
    meta: {
      title: '诚邀您加入喜郎中',
      hideNav: true
    }
  }]
}, {
  path: '',
  component: _layout.default,
  redirect: '/community',
  children: [{
    path: 'community',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/index'));
      });
    },
    name: 'Community',
    meta: {
      title: '社区首页',
      keepAlive: true
    }
  }]
}, {
  path: '',
  component: _layout.default,
  redirect: '/community',
  children: [{
    path: 'postDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/detail'));
      });
    },
    name: 'PostDetail',
    meta: {
      title: '文章详情',
      keepAlive: false
    }
  }, {
    path: 'editPost',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/editPost'));
      });
    },
    name: 'EditPost',
    meta: {
      title: '编辑文章',
      keepAlive: false
    }
  }, {
    path: 'mySpace',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/my'));
      });
    },
    name: 'MySpace',
    meta: {
      title: '个人空间',
      keepAlive: false
    }
  }, {
    path: 'communityMessages',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/messages/messages'));
      });
    },
    name: 'CommunityMessages',
    meta: {
      title: '消息',
      keepAlive: false
    }
  }, {
    path: 'communityFollowList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/messages/followList'));
      });
    },
    name: 'CommunityFollowList',
    meta: {
      title: '关注',
      keepAlive: false
    }
  }, {
    path: 'communityVoteList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/messages/voteList'));
      });
    },
    name: 'CommunityVoteList',
    meta: {
      title: '点赞',
      keepAlive: false
    }
  }, {
    path: 'communityCommentList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/messages/commentList'));
      });
    },
    name: 'CommunityCommentList',
    meta: {
      title: '评论',
      keepAlive: false
    }
  }, {
    path: 'communitySysList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/messages/sysList'));
      });
    },
    name: 'CommunitySysList',
    meta: {
      title: '系统',
      keepAlive: false
    }
  }, {
    path: 'communityMyFollowList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/myFollowList'));
      });
    },
    name: 'CommunityMyFollowList',
    meta: {
      title: '关注列表',
      keepAlive: false
    }
  }, {
    path: 'communityMyFansList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/myFansList'));
      });
    },
    name: 'CommunityMyFansList',
    meta: {
      title: '粉丝列表',
      keepAlive: false
    }
  }, {
    path: 'communityDoctorInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/doctorInfo'));
      });
    },
    name: 'CommunityDoctorInfo',
    meta: {
      title: '',
      keepAlive: false
    }
  }, {
    path: 'communitySearch',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/communitySearch'));
      });
    },
    name: 'CommunitySearch',
    meta: {
      title: '搜索',
      keepAlive: false
    }
  }, {
    path: 'communityComplaint',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/community/complaint'));
      });
    },
    name: 'CommunityComplaint',
    meta: {
      title: '投诉',
      keepAlive: false
    }
  }]
}, {
  path: '',
  component: _layout.default,
  redirect: '/mall',
  children: [{
    path: 'mall',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/index'));
      });
    },
    name: 'Mall',
    meta: {
      title: '商城首页',
      keepAlive: false
    }
  }, {
    path: 'mallList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/mallList'));
      });
    },
    name: 'mallList',
    meta: {
      title: '商城列表',
      keepAlive: false
    }
  }, (_ref = {
    path: 'mallItemDetail/:id',
    name: 'MallItemDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/mallItemDetail'));
      });
    }
  }, (0, _defineProperty2.default)(_ref, "name", 'mallList'), (0, _defineProperty2.default)(_ref, "meta", {
    title: '商品详情',
    keepAlive: false
  }), _ref), {
    path: '/mallItemEdit',
    name: 'MallItemEdit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/mallItemEdit'));
      });
    },
    meta: {
      title: '支付订单',
      keepAlive: false
    }
  }, {
    path: '/mallAddressEdit',
    name: 'MallAddressEdit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/mallAddressEdit'));
      });
    },
    meta: {
      title: '编辑地址'
    }
  }, {
    path: '/mallItemBuySuccess',
    name: 'MallItemBuySuccess',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/mallItemBuySuccess'));
      });
    },
    meta: {
      title: '兑换成功'
    }
  }, {
    path: '/mallOrders',
    name: 'MallOrders',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/mallOrders'));
      });
    },
    meta: {
      title: '兑换记录'
    }
  }, {
    path: '/mallExpress',
    name: 'MallExpress',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/mallExpress'));
      });
    },
    meta: {
      title: '物流详情'
    }
  }, {
    path: '/mallJDCardList',
    name: 'MallJDCardList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/mallJDCardList'));
      });
    },
    meta: {
      title: '京东卡专区'
    }
  }, {
    path: '/mallMyCard',
    name: 'MallMyCard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/mallMyCard'));
      });
    },
    meta: {
      title: '我的卡密'
    }
  }, {
    path: '/mallMyCardDetail',
    name: 'MallMyCardDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/mallMyCardDetail'));
      });
    },
    meta: {
      title: '卡密详情'
    }
  }, {
    path: '/mallActivitiesList',
    name: 'MallActivitiesList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/mallActivitiesList'));
      });
    },
    meta: {
      title: '活动列表'
    }
  }, {
    path: '/mallActivitiesItemList',
    name: 'MallActivitiesItemList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/mall/mallActivitiesItemList'));
      });
    },
    meta: {
      title: '活动专区'
    }
  }, {
    path: '/return202206',
    name: 'Return202206',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/return202206'));
      });
    },
    meta: {
      title: '回归活动'
    }
  }, {
    path: '/gbactivity202206',
    name: 'GBactivity202206',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/gbactivity202206'));
      });
    },
    meta: {
      title: '国标处方补贴活动'
    }
  }, {
    path: '/share202207',
    name: 'share202207',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/share202207'));
      });
    },
    meta: {
      title: '协定方补贴活动'
    }
  }, {
    path: '/red202207',
    name: 'red202207',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/red202207'));
      });
    },
    meta: {
      title: '抢红包活动'
    }
  }, {
    path: '/inviteDoctorActivity',
    name: 'InviteDoctorActivity',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/inviteDoctor'));
      });
    },
    meta: {
      title: '邀请活动'
    }
  }, {
    path: '/inviteDoctorActivityDetail',
    name: 'InviteDoctorActivityDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/inviteDoctorDetail'));
      });
    },
    meta: {
      title: '邀请活动详情'
    }
  }, {
    path: '/target202209',
    name: 'Target202209',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/target202209'));
      });
    },
    meta: {
      title: '专属目标奖活动'
    }
  }, {
    path: '/doctorStationed202210',
    name: 'DoctorStationed202210',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/doctorStationed202210'));
      });
    },
    meta: {
      title: '医师处方补贴活动'
    }
  }, {
    path: '/national202210',
    name: 'National202210',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/national202210'));
      });
    },
    meta: {
      title: '国庆感恩活动'
    }
  }, {
    path: '/november202211',
    name: 'November202211',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/november202211'));
      });
    },
    meta: {
      title: '双11活动'
    }
  }, {
    path: '/activityT0',
    name: 'ActivityT0',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/activityT0'));
      });
    },
    meta: {
      title: ''
    }
  }, {
    path: '/yearReport2022',
    name: 'YearReport2022',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/yearReport2022'));
      });
    },
    meta: {
      title: '2022年报',
      hideNav: true
    }
  }, {
    path: '/yearReport2022Share',
    name: 'YearReport2022Share',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/yearReport2022Share'));
      });
    },
    meta: {
      title: '2022年报',
      hideNav: true
    }
  }, {
    path: '/january202301',
    name: 'January202301',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/january202301'));
      });
    },
    meta: {
      title: '金兔送福袋',
      hideNav: true
    }
  }, {
    path: '/return202307',
    name: 'Return202307',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/return202307'));
      });
    },
    meta: {
      title: '回归活动'
    }
  }, {
    path: '/mofang',
    name: 'Mofang',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/mofang'));
      });
    },
    meta: {
      title: '喜分魔方'
    }
  }, {
    path: '/activityType2202310',
    name: 'ActivityType2202310',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/activityType2202310'));
      });
    },
    meta: {
      title: '膏方节'
    }
  }, {
    path: '/activityType2RecipeList',
    name: 'ActivityType2RecipeList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/activityType2RecipeList'));
      });
    },
    meta: {
      title: '设置推荐膏方'
    }
  }, {
    path: '/activityType2AppRecipeList',
    name: 'activityType2AppRecipeList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/activityType2AppRecipeList'));
      });
    },
    meta: {
      title: '生成专属膏方'
    }
  }, {
    path: '/inviteDoctor202311',
    name: 'inviteDoctor202311',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/inviteDoctor202311'));
      });
    },
    meta: {
      title: '邀请同行'
    }
  }, {
    path: '/inviteDoctor202311Detail',
    name: 'inviteDoctor202311Detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/inviteDoctor202311Detail'));
      });
    },
    meta: {
      title: '奖励明细'
    }
  }, {
    path: '/questionnaireMain',
    name: 'questionnaireMain',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/questionnaireMain'));
      });
    },
    meta: {
      title: '调查问卷'
    }
  }, {
    path: '/questionnaireResult',
    name: 'questionnaireResult',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/questionnaireResult'));
      });
    },
    meta: {
      title: '调查问卷'
    }
  }, {
    path: '/fourActivity',
    name: 'fourActivity',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/fourActivity'));
      });
    },
    meta: {
      title: '四重礼'
    }
  }, {
    path: '/red202401',
    name: 'red202401',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/red202401'));
      });
    },
    meta: {
      title: '红包大派送'
    }
  }, {
    path: '/yearReport2023',
    name: 'YearReport2023',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/yearReport2023'));
      });
    },
    meta: {
      title: '2023年报',
      hideNav: true
    }
  }, {
    path: '/yearReport2023Share',
    name: 'YearReport2023Share',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/yearReport2023Share'));
      });
    },
    meta: {
      title: '2023年报',
      hideNav: true
    }
  }, {
    path: '/inviteDoctor202402',
    name: 'InviteDoctor202402',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/inviteDoctor202402'));
      });
    },
    meta: {
      title: '2024邀请同行',
      hideNav: true
    }
  }, {
    path: '/invite202405',
    name: 'Invite202405',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/invite202405'));
      });
    },
    meta: {
      title: '2024邀请同行',
      hideNav: true
    }
  }, {
    path: '/configActivity0',
    name: 'ConfigActivity0',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/configActivity0'));
      });
    },
    meta: {
      title: '',
      hideNav: true
    }
  }, {
    path: '/configActivity1',
    name: 'ConfigActivity1',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/configActivity1'));
      });
    },
    meta: {
      title: '',
      hideNav: true
    }
  }, {
    path: '/configActivity2',
    name: 'ConfigActivity2',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/configActivity2'));
      });
    },
    meta: {
      title: '',
      hideNav: true
    }
  }, {
    path: '/configActivity3',
    name: 'ConfigActivity3',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/configActivity3'));
      });
    },
    meta: {
      title: '',
      hideNav: true
    }
  }, {
    path: '/configActivityRewardList',
    name: 'ConfigActivityRewardList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/configActivityRewardList'));
      });
    },
    meta: {
      title: '中奖记录',
      hideNav: false
    }
  }, {
    path: '/appAllSlideList',
    name: 'AppAllSlideList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/activity/appAllSlideList'));
      });
    },
    meta: {
      title: '线上活动',
      hideNav: false
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/pages/login/index'));
    });
  },
  hidden: true
}, {
  path: '/redirect',
  component: _layout.default,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/redirect/index'));
      });
    }
  }]
}, _components.default];
exports.routes = routes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: routes
  });
};

var router = createRouter();

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

var _default = router;
exports.default = _default;