var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#F5F5F5" }
    },
    [
      _vm.detail
        ? _c(
            "div",
            {
              staticStyle: {
                position: "relative",
                display: "flex",
                "flex-direction": "column"
              }
            },
            [
              _c("van-image", {
                staticStyle: { width: "100%", "z-index": "0" },
                attrs: {
                  fit: "fill",
                  src: require("../../assets/activity/activityTrainingSign/banner.png")
                }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "140px",
                    display: "flex",
                    "justify-content": "space-evenly",
                    width: "100%",
                    color: "#FFFFFF",
                    "font-weight": "bold",
                    "font-size": "20px"
                  }
                },
                [
                  _c("div", [
                    _vm._v(
                      _vm._s("已累计签到：" + _vm.detail.user_quantity + "次")
                    )
                  ]),
                  _c("div", { staticStyle: { opacity: "0.6" } }, [
                    _vm._v(
                      _vm._s(
                        "未签到还剩：" +
                          (_vm.detail.total - _vm.detail.user_quantity) +
                          "次"
                      )
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "background-color": "#FFFFFF",
                    margin: "-10% 2% 0 2%",
                    "border-radius": "10px",
                    overflow: "hidden"
                  }
                },
                [
                  _c(
                    "van-grid",
                    {
                      staticStyle: {
                        width: "100%",
                        "border-radius": "10px",
                        overflow: "hidden",
                        "background-color": "#FFFFFF"
                      },
                      attrs: { "column-num": 4, border: false }
                    },
                    _vm._l(_vm.detail.items, function(item, index) {
                      return _c(
                        "van-grid-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.gridOnClick(item)
                            }
                          }
                        },
                        [
                          _c("div", { staticStyle: { margin: "10px 0" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "coupon-card",
                                style: _vm._f("statusFilter")(item.status)
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "coupon-header",
                                    style: _vm._f("statusHeaderFilter")(
                                      item.status
                                    )
                                  },
                                  [_vm._v(_vm._s("第" + (index + 1) + "次"))]
                                ),
                                _c("van-image", {
                                  staticStyle: {
                                    width: "50%",
                                    padding: "5px 0"
                                  },
                                  attrs: {
                                    fit: "fill",
                                    src: require("../../assets/activity/activityTrainingSign/s_" +
                                      item.status +
                                      ".png")
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "card-footer",
                                    style: _vm._f("statusTitleColorFilter")(
                                      item.status
                                    )
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("statusTitleFilter")(item.status)
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "card-price" }, [
                              _vm._v(_vm._s(item.name))
                            ])
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.userInfo
        ? _c(
            "van-dialog",
            {
              attrs: {
                title: "请填写签到信息",
                "show-cancel-button": "",
                "before-close": _vm.handleBeforeClose
              },
              model: {
                value: _vm.dialogShow,
                callback: function($$v) {
                  _vm.dialogShow = $$v
                },
                expression: "dialogShow"
              }
            },
            [
              _c("van-field", {
                attrs: { placeholder: "请输入手机号", readonly: true },
                model: {
                  value: _vm.userInfo.name,
                  callback: function($$v) {
                    _vm.$set(_vm.userInfo, "name", $$v)
                  },
                  expression: "userInfo.name"
                }
              }),
              _c("van-field", {
                attrs: { placeholder: "请输入手机号", readonly: true },
                model: {
                  value: _vm.userInfo.mobile,
                  callback: function($$v) {
                    _vm.$set(_vm.userInfo, "mobile", $$v)
                  },
                  expression: "userInfo.mobile"
                }
              }),
              _c("van-field", {
                attrs: {
                  type: "digit",
                  center: "",
                  clearable: "",
                  maxlength: "4",
                  placeholder: "请输入短信验证码"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button",
                      fn: function() {
                        return [
                          _c(
                            "van-button",
                            {
                              attrs: {
                                size: "small",
                                type: "primary",
                                color: "#ff9000",
                                round: "",
                                plain: "",
                                disabled: !_vm.canClick
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.sendCodeAction($event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.content))]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  473251439
                ),
                model: {
                  value: _vm.sms,
                  callback: function($$v) {
                    _vm.sms = $$v
                  },
                  expression: "sms"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "10%"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                "margin-top": "20px",
                "text-align": "center"
              }
            },
            [
              _c("van-image", {
                staticStyle: { width: "50%", "z-index": "0" },
                attrs: {
                  fit: "fill",
                  src: require("../../assets/activity/activityTrainingSign/card_title.png")
                }
              })
            ],
            1
          ),
          _vm._m(0)
        ]
      ),
      _c("div", { staticStyle: { height: "60px" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "70px 23px 20px 23px",
          color: "#727272",
          "line-height": "180%"
        }
      },
      [
        _vm._v(" 1、参加培训的每位学员须每次签到,4个学时签到一次。"),
        _c("br"),
        _vm._v(" 2、签到最早时间不能早于当天开课时间。"),
        _c("br"),
        _vm._v(" 3、签到最晚时间不能晚于当天22点。"),
        _c("br"),
        _vm._v(" 4、签到结果是作为培训学员是否参加培训的重要依据。"),
        _c("br"),
        _vm._v(" 5、对签到结果有异议可联系平台客服。"),
        _c("br"),
        _vm._v(" 6、最终解释权归郎中学院培训所有。 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }