//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor, doctorInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
import store from '@/store';
export default {
  name: 'activityType2202310',
  props: {},
  data: function data() {
    return {
      userInfo: undefined,
      data: undefined,
      list: []
    };
  },
  created: function created() {
    this.fetchDoctorInfo();
  },
  mounted: function mounted() {
    this.fetchActivityGF202309();
  },
  methods: {
    pushToList: function pushToList() {
      this.$router.push({
        path: '/activityType2RecipeList'
      });
    },
    pushToMallItem: function pushToMallItem() {
      this.$router.push({
        path: '/mallItemDetail/691'
      });
    },
    pushToMall: function pushToMall() {
      var _this = this;

      mallInteractor.fetchActivityGF202309Receive().then(function (data) {
        _this.$router.push({
          path: "/mallActivitiesItemList?id=".concat(data.activity_id)
        });
      });
    },
    pushToPatientUrl: function pushToPatientUrl() {
      mallInteractor.fetchActivityCommonGetLockStr({
        object_id: this.userInfo.id
      }).then(function (data) {
        window.location.href = process.env.VUE_APP_PATIENT_URL + '/pages/activity/activityType2200309?doctor=' + data.lock_str;
      });
    },
    fetchActivityGF202309: function fetchActivityGF202309() {
      var _this2 = this;

      mallInteractor.fetchActivityGF202309().then(function (data) {
        _this2.data = data; // this.list = data.list
      });
    },
    fetchDoctorInfo: function fetchDoctorInfo() {
      var _this3 = this;

      doctorInteractor.fetchDoctorInfo().then(function (data) {
        _this3.userInfo = data.doctor;
      });
    }
  }
};