"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Loading = _interopRequireDefault(require("@/components/Loading"));

//
//
//
//
//
var _default = {
  name: 'LoadingDemo',
  components: {
    Loading: _Loading.default
  }
};
exports.default = _default;