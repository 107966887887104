import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
export default {
  created: function created() {
    var _this$$route = this.$route,
        path = _this$$route.params.path,
        query = _this$$route.query;
    this.$router.replace({
      path: '/' + path,
      query: query
    });
  },
  render: function render(h) {
    return h(); // avoid warning message
  }
};