"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DoctorConfigList = exports.DoctorConfigSave = exports.DoctorNewIncomeDetail = exports.DoctorNewIncomes = exports.InviteUrl = exports.PrescriptionsConfigSave = exports.PrescriptionsConfigForm = exports.DoctorShareReport = exports.DoctorReportURL = exports.DoctorReport = exports.DoctorCashAppList = exports.DoctorSettlementItem = exports.DoctorSettlementBill = exports.DoctorBasics = exports.DoctorIncomeType = exports.DoctorIncomeList = exports.DoctorInfo = void 0;
var DoctorInfo = '/api/doctor_info';
exports.DoctorInfo = DoctorInfo;
var DoctorIncomeList = '/api/income_app_list_new';
exports.DoctorIncomeList = DoctorIncomeList;
var DoctorIncomeType = '/api/income_type';
exports.DoctorIncomeType = DoctorIncomeType;
var DoctorBasics = '/api/doctor_basics';
exports.DoctorBasics = DoctorBasics;
var DoctorSettlementBill = '/api/settlement_bill';
exports.DoctorSettlementBill = DoctorSettlementBill;
var DoctorSettlementItem = '/api/settlement_item';
exports.DoctorSettlementItem = DoctorSettlementItem;
var DoctorCashAppList = '/api/cash_app_list';
exports.DoctorCashAppList = DoctorCashAppList;
var DoctorReport = '/api/doctor_report';
exports.DoctorReport = DoctorReport;
var DoctorReportURL = '/api/doctor_report_url';
exports.DoctorReportURL = DoctorReportURL;
var DoctorShareReport = '/api/doctor_share_report';
exports.DoctorShareReport = DoctorShareReport;
var PrescriptionsConfigForm = '/api/prescriptions_config_form'; //医生配置诊金

exports.PrescriptionsConfigForm = PrescriptionsConfigForm;
var PrescriptionsConfigSave = '/api/prescriptions_config_save'; //医生配置诊金保存

exports.PrescriptionsConfigSave = PrescriptionsConfigSave;
var InviteUrl = '/api/invite_url'; //解密邀请同行

exports.InviteUrl = InviteUrl;
var DoctorNewIncomes = '/api/v3/new_incomes';
exports.DoctorNewIncomes = DoctorNewIncomes;
var DoctorNewIncomeDetail = '/api/v3/new_incomes_detail';
exports.DoctorNewIncomeDetail = DoctorNewIncomeDetail;
var DoctorConfigSave = '/api/config_save';
exports.DoctorConfigSave = DoctorConfigSave;
var DoctorConfigList = '/api/config_list';
exports.DoctorConfigList = DoctorConfigList;