var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isHideBar
    ? _c("van-nav-bar", {
        attrs: {
          title: _vm.pageTitle,
          "left-arrow": !(
            _vm.pageTitle == "首页" ||
            _vm.pageTitle == "医生" ||
            _vm.pageTitle == "我的团队" ||
            _vm.pageTitle == "我的"
          ),
          "right-text": _vm.pageTitle === "首页" ? "" : ""
        },
        on: { "click-left": _vm.onClickLeft, "click-right": _vm.onClickRight }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }