import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.function.name";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'configActivity0',
  props: {},
  data: function data() {
    return {
      code: undefined,
      detail: undefined,
      show: false,
      lotteryStart: 0,
      lotteryClass: 'ssss',
      // 覆盖组件内部样式
      prizeNo: 1,
      prizeNum: 0,
      isRolling: false,
      remarks: undefined,
      prizeList: [],
      // 奖品列表
      luckShow: false,
      luckList: [],
      // 中奖列表
      result_item: undefined,
      // 中奖结果
      itemList: [],
      buttonPopupShow: false,
      buttonPopupShowType: 0
    };
  },
  created: function created() {},
  computed: {
    /**
         * 1、把后台返回数组，添加rotate属性（奖品在圆内偏移角度（逆时针所以是负数））组成新数组
         * 2、偏移角度算法
         */
    prizeListCom: function prizeListCom() {
      var newPrizeList = [];

      var _rotate = 180 / this.prizeNum; // 夹角一半


      var _pinch = 360 / this.prizeNum; // 夹角


      var _prizeList = this.prizeList;

      for (var item in _prizeList) {
        var obj = _prizeList[item];
        obj.rotate = 360 - _rotate - item * _pinch + 'deg';
        newPrizeList.push(obj);
      }

      return newPrizeList;
    }
  },
  mounted: function mounted() {
    var code = this.$route.query.code;

    if (code) {
      this.code = code;
      console.log(code);
    }

    this.fetchConfigActivityDetail();
    this.fetchActivityLogs();
  },
  methods: {
    buttonPopupShowAction: function buttonPopupShowAction(type) {
      this.buttonPopupShowType = type;
      this.buttonPopupShow = true;
    },
    fetchConfigActivityDetail: function fetchConfigActivityDetail() {
      var _this = this;

      mallInteractor.fetchConfigActivityDetail({
        code: this.code
      }).then(function (data) {
        _this.detail = data;
        var pl = data.reward_items;
        var tmp = [];
        pl.forEach(function (element) {
          var item = {
            id: element.id,
            icon: element.url,
            name: element.name
          };
          tmp.push(item);
        });
        _this.prizeNum = tmp.length;
        _this.prizeList = tmp;

        if (_this.detail.activity_status == 1) {
          Dialog.alert({
            title: '温馨提醒',
            message: '活动暂未开始'
          }).then(function () {// on close
          });
        }
      });
    },
    //点击抽奖按钮
    lotteryClick: function lotteryClick() {
      var _this2 = this;

      if (this.isRolling) {
        return;
      }

      this.result_item = undefined;
      this.isRolling = true;
      mallInteractor.fetchConfigActivityReceive({
        code: this.code
      }).then(function (data) {
        _this2.lotteryStart = 1;
        var luckGood_id = data.result_item.id;
        var obj;

        _this2.prizeList.forEach(function (element) {
          if (element.id === luckGood_id) {
            obj = element;
          }
        });

        _this2.result_item = data.result_item;
        _this2.prizeNo = _this2.prizeList.indexOf(obj) + 1;
      }).catch(function (e) {
        _this2.isRolling = false;
      });
    },
    lotteryDone: function lotteryDone(res) {
      this.isRolling = false;
      this.lotteryStart = 0;
      this.luckShow = true;
    },
    luckPopupClose: function luckPopupClose() {
      this.luckShow = false;
      this.result_item = undefined;
      this.fetchConfigActivityDetail();
    },
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    jumpToRewardList: function jumpToRewardList() {
      this.$router.push({
        path: '/configActivityRewardList',
        query: {
          code: this.code
        }
      });
    },
    fetchActivityConfigReceive: function fetchActivityConfigReceive() {
      var _this3 = this;

      if (this.detail.receive_button_status != 1) {
        return;
      }

      mallInteractor.fetchActivityConfigReceive({
        code: this.code
      }).then(function (data) {
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
        } else {
          Toast('领取成功');
          setTimeout(function () {
            _this3.fetchActivityConfigDetail();
          }, 1000);
        }
      });
    }
  }
};