//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'Invite202405',
  props: {},
  data: function data() {
    return {
      detail: undefined,
      active: 1
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityInvite202405();
    this.fetchActivityLogs();
  },
  methods: {
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    fetchActivityInvite202405: function fetchActivityInvite202405() {
      var _this = this;

      mallInteractor.fetchActivityInvite202405().then(function (data) {
        _this.detail = data;
      });
    }
  }
};