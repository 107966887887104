"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _store = _interopRequireDefault(require("@/store"));

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MallExpress',
  props: {},
  data: function data() {
    return {
      no: undefined,
      detail: undefined,
      steps: [],
      active: 0
    };
  },
  created: function created() {
    this.no = this.$route.query.no;
    this.logisticsTracking({
      no: this.no
    });
  },
  mounted: function mounted() {},
  methods: {
    logisticsTracking: function logisticsTracking(query) {
      var _this = this;

      _core.mallInteractor.fetchMallGetLogisticsTracking(query).then(function (data) {
        _this.detail = data;
        var steps = [];

        if (data.data) {
          data.data.forEach(function (dict) {
            var item = {
              context: dict.context,
              time: dict.time
            };
            steps.push(item);
          });
          _this.steps = steps;
        }
      });
    }
  }
};
exports.default = _default;