//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'AllSettings',
  props: {},
  data: function data() {
    return {
      gbDetail: undefined
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityGBDetail();
  },
  methods: {
    fetchActivityGBDetail: function fetchActivityGBDetail() {
      var _this = this;

      mallInteractor.fetchActivityGBDetail().then(function (data) {
        _this.gbDetail = data;
      });
    }
  }
};