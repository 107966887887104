//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'InviteDoctorDetail',
  props: {},
  data: function data() {
    return {
      did: undefined,
      detail: undefined
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var id = this.$route.query.id;
    this.did = id;
    this.fetchActivityInviteDetail();
  },
  methods: {
    fetchActivityInviteDetail: function fetchActivityInviteDetail() {
      var _this = this;

      mallInteractor.fetchActivityInviteDetail({
        doctor_id: this.did
      }).then(function (data) {
        _this.detail = data;
      });
    },
    fetchActivityInviteDetailCash: function fetchActivityInviteDetailCash() {
      var _this2 = this;

      mallInteractor.fetchActivityInviteDetailCash({
        doctor_id: this.did
      }).then(function (data) {
        Toast('提取成功');
        setTimeout(function () {
          _this2.fetchActivityInviteDetail();
        }, 1500);
      });
    }
  }
};