"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'yearReport2023',
  props: {},
  data: function data() {
    return {
      detail: undefined
    };
  },
  created: function created() {
    this.fetchActivityDoctorReport2023();
  },
  mounted: function mounted() {
    this.fetchActivityLogs();
  },
  methods: {
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      _core.mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    nextPage: function nextPage() {
      this.$refs.swipe.next();
    },
    onChange: function onChange(index) {
      document.getElementById('image-item-arrow').style.transform = "translateY(0px)"; // document.getElementById('image-item-arrow-2').style.transform = `translateY(0px)`;

      if (index == 1) {
        document.getElementById('image-item-arrow').style.transform = "translateY(-70px)";
        document.getElementById('tilte-item-1').style.transform = "translate( 0px,150px)";
        document.getElementById('image-item-1').style.transform = "translate( 300px,-400px)";
      } else {
        document.getElementById('tilte-item-1').style.transform = "translate( 0px,0px)";
        document.getElementById('image-item-1').style.transform = "translate(-300px, 0px)";
      }

      if (index == 2) {
        document.getElementById('image-item-arrow-2').style.transform = "translateY(-70px)";
        document.getElementById('tilte-item-2').style.transform = "translate( 0px,100px)";
        document.getElementById('image-item-2').style.transform = "translate( 300px,-400px)";
      } else {
        document.getElementById('tilte-item-2').style.transform = "translate( 0px,0px)";
        document.getElementById('image-item-2').style.transform = "translate(-300px, 0px)";
      }

      if (index == 3) {
        document.getElementById('image-item-arrow-3').style.transform = "translateY(-70px)";
        document.getElementById('tilte-item-3').style.transform = "translate( 0px,100px)";
        document.getElementById('image-item-3').style.transform = "translate( 300px,-400px)";
      } else {
        document.getElementById('tilte-item-3').style.transform = "translate( 0px,0px)";
        document.getElementById('image-item-3').style.transform = "translate(-300px, 0px)";
      }

      if (index == 4) {
        document.getElementById('image-item-arrow-4').style.transform = "translateY(-70px)";
        document.getElementById('tilte-item-4').style.transform = "translate( 0px,100px)";
        document.getElementById('image-item-4').style.transform = "translate( 300px,-500px)";
      } else {
        document.getElementById('tilte-item-4').style.transform = "translate( 0px,0px)";
        document.getElementById('image-item-4').style.transform = "translate(-300px, 0px)";
      }

      if (index == 5) {
        document.getElementById('image-item-arrow-5').style.transform = "translateY(-70px)";
        document.getElementById('tilte-item-5').style.transform = "translate( 0px,100px)";
        document.getElementById('image-item-5').style.transform = "translate( 300px,-450px)";
      } else {
        document.getElementById('tilte-item-5').style.transform = "translate( 0px,0px)";
        document.getElementById('image-item-5').style.transform = "translate(-300px, 0px)";
      }
    },
    fetchActivityDoctorReport2023: function fetchActivityDoctorReport2023() {
      var _this = this;

      _core.mallInteractor.fetchActivityDoctorReport2023().then(function (data) {
        _this.detail = data;
      });
    },
    jumpToShare: function jumpToShare() {
      var url = process.env.NODE_ENV === 'production' ? 'http://im.5ixlz.com/' : 'http://im_t.5ixlz.com/';
      var pararm = {
        url: url + 'yearReport2023Share',
        year: '2023'
      };

      _core.mallInteractor.fetchActivityDoctorReportURL(pararm).then(function (data) {
        // this.detail = data;
        var share_url = data.share_url;
        window.location.href = share_url;
      }); // this.$router.push({ path: '/yearReport2022Share'})

    }
  }
};
exports.default = _default;