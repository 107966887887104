var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { position: "relative", "padding-bottom": "42px" } },
      [
        _c("van-image", {
          staticStyle: { "z-index": "0" },
          attrs: { fit: "fill", src: require("../../assets/invite.jpg") }
        }),
        _vm.doctor
          ? _c(
              "div",
              {
                staticStyle: {
                  position: "absolute",
                  "z-index": "1",
                  top: "1%",
                  "text-align": "center",
                  width: "100%"
                }
              },
              [
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    "·您的好友 " +
                      _vm._s(_vm.doctor.name) +
                      " 诚邀您入驻喜郎中·"
                  )
                ]),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "10px", color: "#F7FF03" } },
                  [_vm._v("注册并认证成功开单即可获首单奖励金！")]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      height: "80px",
                      "margin-left": "20px",
                      "justify-content": "space-between"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("van-image", {
                          staticClass: "avatar-img",
                          attrs: {
                            round: "",
                            width: "50px",
                            height: "50px",
                            src: _vm.doctor.figure_file.url
                          }
                        }),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                margin: "0px 0px 6px 20px",
                                color: "white",
                                "text-align": "left",
                                "font-size": "18px",
                                "font-weight": "bold"
                              }
                            },
                            [_vm._v(_vm._s(_vm.doctor.name))]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                color: "white",
                                "font-size": "14px"
                              }
                            },
                            [_vm._v(_vm._s(_vm.doctor.professional_title))]
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-right": "10px",
                          color: "white",
                          "font-size": "14px"
                        }
                      },
                      [_vm._v("喜郎中合作医生")]
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "footer",
      {
        staticStyle: {
          width: "100%",
          position: "fixed",
          bottom: "0",
          left: "0",
          height: "42px",
          "background-color": "white"
        }
      },
      [
        _c(
          "van-button",
          {
            staticStyle: { width: "100%" },
            attrs: { color: "#ff9000" },
            on: { click: _vm.jumpToUrl }
          },
          [_vm._v("接受邀请并注册")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }