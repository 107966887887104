var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body", staticStyle: { width: "100%", height: "100vh" } },
    [
      _vm.detail
        ? _c(
            "van-swipe",
            {
              ref: "swipe",
              staticStyle: { height: "100vh" },
              attrs: { vertical: "", loop: false, "show-indicators": false },
              on: { change: _vm.onChange }
            },
            [
              _c("van-swipe-item", [
                _c(
                  "div",
                  { staticStyle: { position: "relative", height: "100vh" } },
                  [
                    _c("van-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100vh",
                        "z-index": "0"
                      },
                      attrs: {
                        fit: "cover",
                        src: require("../../assets/activity/yearReport2022/y_r_0_b.png")
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          "z-index": "2",
                          top: "15%",
                          "text-align": "center",
                          width: "100vw"
                        }
                      },
                      [
                        _c("van-image", {
                          staticStyle: { width: "80%", "z-index": "1" },
                          attrs: {
                            fit: "contian",
                            src: require("../../assets/activity/yearReport2022/y_r_0_h.png")
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          bottom: "7%",
                          width: "100vw",
                          "text-align": "center"
                        },
                        on: {
                          click: function($event) {
                            return _vm.nextPage()
                          }
                        }
                      },
                      [
                        _c("van-image", {
                          staticStyle: {
                            width: "80%",
                            height: "100px",
                            "z-index": "1"
                          },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2022/y_r_0_b_b.png")
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("van-swipe-item", [
                _c(
                  "div",
                  { staticStyle: { position: "relative", height: "100vh" } },
                  [
                    _c("van-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100vh",
                        "z-index": "0"
                      },
                      attrs: {
                        fit: "cover",
                        src: require("../../assets/activity/yearReport2022/y_r_1_b.png")
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          "z-index": "2",
                          top: "0",
                          "text-align": "center",
                          width: "100vw",
                          transition: "transform 2.4s ease 0s",
                          "line-height": "180%"
                        },
                        attrs: { id: "tilte-item-1" }
                      },
                      [
                        _c("div", { staticClass: "main-title" }, [
                          _vm._v("2022年"),
                          _c("br"),
                          _vm._v(" 是您来到喜郎中的第 "),
                          _c("span", { staticClass: "span-title" }, [
                            _vm._v(_vm._s(_vm.detail.use_year))
                          ]),
                          _vm._v(" 年"),
                          _c("br"),
                          _vm._v(" 一转眼"),
                          _c("br"),
                          _vm._v(" 我们已经一起度过了 "),
                          _c("span", { staticClass: "span-title" }, [
                            _vm._v(_vm._s(_vm.detail.use_day))
                          ]),
                          _vm._v(" 天"),
                          _c("br"),
                          _vm._v(" 感恩在茫茫人海中与您相遇")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          width: "100vw",
                          left: "-300px",
                          "text-align": "center",
                          transition: "transform 2.4s ease 0s"
                        },
                        attrs: { id: "image-item-1" }
                      },
                      [
                        _c("van-image", {
                          staticStyle: { width: "80%", "z-index": "1" },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2022/y_r_1_m.png")
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          width: "100vw",
                          "text-align": "center",
                          transition: "transform 2.4s ease 0s"
                        },
                        attrs: { id: "image-item-arrow" }
                      },
                      [
                        _c("van-image", {
                          staticClass: "image-item-arrow",
                          staticStyle: { width: "10%", "z-index": "1" },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2022/y_c_up.png")
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm.detail.extra_data.third_data.prescription_number > 0
                ? _c("van-swipe-item", [
                    _c(
                      "div",
                      {
                        staticStyle: { position: "relative", height: "100vh" }
                      },
                      [
                        _c("van-image", {
                          staticStyle: {
                            width: "100%",
                            height: "100vh",
                            "z-index": "0"
                          },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2022/y_r_2_b.png")
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              "z-index": "2",
                              top: "0",
                              "text-align": "center",
                              width: "90vw",
                              transition: "transform 2.4s ease 0s",
                              "line-height": "180%"
                            },
                            attrs: { id: "tilte-item-2" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "main-title",
                                staticStyle: { "text-align": "right" }
                              },
                              [
                                _vm._v(" 这一年里"),
                                _c("br"),
                                _vm._v(" 您一共为 "),
                                _c("span", { staticClass: "span-title" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.detail.extra_data.third_data
                                        .archive_number
                                    )
                                  )
                                ]),
                                _vm._v(" 位患者"),
                                _c("br"),
                                _vm._v(" 开具了 "),
                                _c("span", { staticClass: "span-title" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.detail.extra_data.third_data
                                        .prescription_number
                                    )
                                  )
                                ]),
                                _vm._v(" 个方案 "),
                                _c("br"),
                                _vm._v(" 帮助患者免除了"),
                                _c("br"),
                                _c("span", { staticClass: "span-title" }, [
                                  _vm._v(
                                    _vm._s(
                                      Math.ceil(
                                        _vm.detail.extra_data.third_data
                                          .prescription_number * 1.7
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" 小时的就医奔波"),
                                _c("br"),
                                _vm._v(" 爱心真挚连千里 医德崇高惠万家")
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              width: "100vw",
                              left: "-300px",
                              "text-align": "center",
                              transition: "transform 2.4s ease 0s"
                            },
                            attrs: { id: "image-item-2" }
                          },
                          [
                            _c("van-image", {
                              staticStyle: { width: "60%", "z-index": "1" },
                              attrs: {
                                fit: "cover",
                                src: require("../../assets/activity/yearReport2022/y_r_2_m.png")
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              width: "100vw",
                              "text-align": "center",
                              transition: "transform 2.4s ease 0s"
                            },
                            attrs: { id: "image-item-arrow-2" }
                          },
                          [
                            _c("van-image", {
                              staticClass: "image-item-arrow",
                              staticStyle: { width: "10%", "z-index": "1" },
                              attrs: {
                                fit: "cover",
                                src: require("../../assets/activity/yearReport2022/y_c_up.png")
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.detail.extra_data.third_data.prescription_number > 0
                ? _c("van-swipe-item", [
                    _c(
                      "div",
                      {
                        staticStyle: { position: "relative", height: "100vh" }
                      },
                      [
                        _c("van-image", {
                          staticStyle: {
                            width: "100%",
                            height: "100vh",
                            "z-index": "0"
                          },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2022/y_r_3_b.png")
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              "z-index": "2",
                              top: "0",
                              "text-align": "center",
                              width: "90vw",
                              transition: "transform 2.4s ease 0s",
                              "line-height": "180%"
                            },
                            attrs: { id: "tilte-item-3" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "main-title",
                                staticStyle: { "text-align": "left" }
                              },
                              [
                                _c("span", { staticClass: "span-title" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.detail.extra_data.fourth_data
                                        .prescription_time_str
                                    )
                                  )
                                ]),
                                _c("br"),
                                _vm._v(" 是您最常开方的时间"),
                                _c("br"),
                                _vm.detail.extra_data.fourth_data.date &&
                                _vm.detail.extra_data.fourth_data.hour_str &&
                                _vm.detail.extra_data.fourth_data.time_str
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.detail.extra_data.fourth_data
                                            .date +
                                            " " +
                                            _vm.detail.extra_data.fourth_data
                                              .time_str +
                                            " "
                                        ) + " "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "span-title" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.detail.extra_data.fourth_data
                                                .hour_str
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br")
                                    ])
                                  : _vm._e(),
                                _vm.detail.extra_data.fourth_data.extra_str
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.detail.extra_data.fourth_data
                                            .extra_str
                                        )
                                      ),
                                      _c("br")
                                    ])
                                  : _vm._e(),
                                _vm._v(" 感谢您时刻守卫患者的健康")
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              width: "100vw",
                              left: "-300px",
                              "text-align": "center",
                              transition: "transform 2.4s ease 0s"
                            },
                            attrs: { id: "image-item-3" }
                          },
                          [
                            _c("van-image", {
                              staticStyle: { width: "60%", "z-index": "1" },
                              attrs: {
                                fit: "cover",
                                src: require("../../assets/activity/yearReport2022/y_r_3_m.png")
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              width: "100vw",
                              "text-align": "center",
                              transition: "transform 2.4s ease 0s"
                            },
                            attrs: { id: "image-item-arrow-3" }
                          },
                          [
                            _c("van-image", {
                              staticClass: "image-item-arrow",
                              staticStyle: { width: "10%", "z-index": "1" },
                              attrs: {
                                fit: "cover",
                                src: require("../../assets/activity/yearReport2022/y_c_up.png")
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.detail.extra_data.third_data.prescription_number > 0
                ? _c("van-swipe-item", [
                    _c(
                      "div",
                      {
                        staticStyle: { position: "relative", height: "100vh" }
                      },
                      [
                        _c("van-image", {
                          staticStyle: {
                            width: "100%",
                            height: "100vh",
                            "z-index": "0"
                          },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2022/y_r_4_b.png")
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              "z-index": "2",
                              top: "0",
                              "text-align": "center",
                              width: "90vw",
                              transition: "transform 2.4s ease 0s",
                              "line-height": "180%"
                            },
                            attrs: { id: "tilte-item-4" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "main-title",
                                staticStyle: { "text-align": "right" }
                              },
                              [
                                _c("span", { staticClass: "span-title" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.detail.extra_data.fifth_data.max_day
                                    )
                                  )
                                ]),
                                _c("br"),
                                _vm._v(" 是您开方最多的一天"),
                                _c("br"),
                                _vm._v(" 开具了 "),
                                _c("span", { staticClass: "span-title" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.detail.extra_data.fifth_data.max_num
                                    )
                                  )
                                ]),
                                _vm._v(" 个方案 "),
                                _c("br"),
                                _vm._v(" 人命至重 有贵千金"),
                                _c("br"),
                                _vm._v(" 一方济之 德逾于此")
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              width: "100vw",
                              left: "-300px",
                              "text-align": "center",
                              transition: "transform 2.4s ease 0s"
                            },
                            attrs: { id: "image-item-4" }
                          },
                          [
                            _c("van-image", {
                              staticStyle: { width: "70%", "z-index": "1" },
                              attrs: {
                                fit: "cover",
                                src: require("../../assets/activity/yearReport2022/y_r_4_m.png")
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              width: "100vw",
                              "text-align": "center",
                              transition: "transform 2.4s ease 0s"
                            },
                            attrs: { id: "image-item-arrow-4" }
                          },
                          [
                            _c("van-image", {
                              staticClass: "image-item-arrow",
                              staticStyle: { width: "10%", "z-index": "1" },
                              attrs: {
                                fit: "cover",
                                src: require("../../assets/activity/yearReport2022/y_c_up.png")
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.detail.extra_data.third_data.prescription_number > 0
                ? _c("van-swipe-item", [
                    _c(
                      "div",
                      {
                        staticStyle: { position: "relative", height: "100vh" }
                      },
                      [
                        _c("van-image", {
                          staticStyle: {
                            width: "100%",
                            height: "100vh",
                            "z-index": "0"
                          },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2022/y_r_5_b.png")
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              "z-index": "2",
                              top: "0",
                              "text-align": "center",
                              width: "90vw",
                              transition: "transform 2.4s ease 0s",
                              "line-height": "180%"
                            },
                            attrs: { id: "tilte-item-5" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "main-title",
                                staticStyle: { "text-align": "left" }
                              },
                              [
                                _vm._v(" 这一年里"),
                                _c("br"),
                                _vm._v(" 您最 "),
                                _c("span", { staticClass: "span-title" }, [
                                  _vm._v("常用")
                                ]),
                                _vm._v(" 的药材是"),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.detail.extra_data.sixth_data
                                        .common_medicine
                                    )
                                ),
                                _c("br"),
                                _vm._v(" 知针知药，固是良医。"),
                                _c("br")
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              width: "100vw",
                              left: "-300px",
                              "text-align": "center",
                              transition: "transform 2.4s ease 0s"
                            },
                            attrs: { id: "image-item-5" }
                          },
                          [
                            _c("van-image", {
                              staticStyle: { width: "60%", "z-index": "1" },
                              attrs: {
                                fit: "cover",
                                src: require("../../assets/activity/yearReport2022/y_r_5_m.png")
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              width: "100vw",
                              "text-align": "center",
                              transition: "transform 2.4s ease 0s"
                            },
                            attrs: { id: "image-item-arrow-5" }
                          },
                          [
                            _c("van-image", {
                              staticClass: "image-item-arrow",
                              staticStyle: { width: "10%", "z-index": "1" },
                              attrs: {
                                fit: "cover",
                                src: require("../../assets/activity/yearReport2022/y_c_up.png")
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("van-swipe-item", [
                _c(
                  "div",
                  { staticStyle: { position: "relative", height: "100vh" } },
                  [
                    _c("van-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100vh",
                        "z-index": "0"
                      },
                      attrs: {
                        fit: "cover",
                        src: require("../../assets/activity/yearReport2022/y_r_6_b.png")
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          width: "100vw",
                          "text-align": "center",
                          top: "55%"
                        }
                      },
                      [
                        _c("van-image", {
                          staticStyle: {
                            position: "relative",
                            width: "80%",
                            "z-index": "1"
                          },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2022/y_r_6_m.png")
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "10%",
                              left: "10%",
                              "text-align": "center",
                              width: "80%",
                              "z-index": "2",
                              "font-size": "32pt",
                              "font-weight": "bold",
                              color: "#6A3820",
                              display: "flex",
                              "justify-content": "space-around"
                            }
                          },
                          _vm._l(
                            _vm.detail.extra_data.seventh_data.keyword_str,
                            function(w, index) {
                              return _c("div", { key: index }, [
                                _vm._v(_vm._s(w))
                              ])
                            }
                          ),
                          0
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          width: "100vw",
                          "text-align": "center",
                          top: "70%"
                        },
                        on: {
                          click: function($event) {
                            return _vm.jumpToShare()
                          }
                        }
                      },
                      [
                        _c("van-image", {
                          staticStyle: { width: "60%", "z-index": "1" },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2022/y_r_6_b_b.png")
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }