var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [!_vm.hideNav ? _c("navbar") : _vm._e(), _c("app-main")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }