var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "white" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/gbactivity202206/gb_a_banner.png")
        }
      }),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "50px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/gbactivity202206/gb_h_t_0.png")
                }
              })
            ],
            1
          ),
          _vm.gbDetail
            ? _c(
                "div",
                {
                  staticStyle: {
                    padding: "50px 23px 20px 23px",
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "flex-start",
                    width: "100%"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#434B50",
                        "font-size": "16px",
                        "font-weight": "500"
                      }
                    },
                    [
                      _vm._v("已开具国标品种金额："),
                      _c("span", { staticClass: "color-span" }, [
                        _vm._v(_vm._s(_vm.gbDetail.prescription_amount))
                      ]),
                      _vm._v("元")
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#434B50",
                        "font-size": "16px",
                        "font-weight": "500",
                        margin: "10px 0px"
                      }
                    },
                    [
                      _vm._v("已获补贴金额："),
                      _c("span", { staticClass: "color-span" }, [
                        _vm._v(_vm._s(_vm.gbDetail.activity_amount))
                      ]),
                      _vm._v("元")
                    ]
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/gbactivity202206/gb_h_t_1.png")
                }
              })
            ],
            1
          ),
          _vm._m(0)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/gbactivity202206/gb_h_t_2.png")
                }
              })
            ],
            1
          ),
          _vm._m(1)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/gbactivity202206/gb_h_t_3.png")
                }
              })
            ],
            1
          ),
          _vm._m(2)
        ]
      ),
      _c("div", { staticStyle: { height: "30px" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "50px 23px 20px 23px" } }, [
      _c(
        "div",
        {
          staticClass: "main-title",
          staticStyle: { color: "#38404E", "line-height": "180%" }
        },
        [
          _vm._v(
            " 活动期间医生为患者开具处方，处方中如含有国标品种药品可获得国标品种部分基础药费的额外10%喜分奖励（国标品种部分基础药费价格需在该处方全部药品基础药费价格的50%以下，超过50%则该笔处方不计入活动统计） "
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "50px 23px 20px 23px" } }, [
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
        [_vm._v("Q：补贴什么时候能到账？")]
      ),
      _c(
        "div",
        { staticClass: "sub-title", staticStyle: { color: "#38404E" } },
        [_vm._v("A：处方成功发货后系统自动发放补贴。")]
      ),
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
        [_vm._v("Q：补贴处方计入规则？")]
      ),
      _c(
        "div",
        { staticClass: "sub-title", staticStyle: { color: "#38404E" } },
        [_vm._v("A：含有国标药材处方且国标药材药费占比在50%以下。")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "50px 23px 20px 23px",
          color: "#38404E",
          "line-height": "180%"
        }
      },
      [
        _vm._v(
          " 1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(" 3、本次活动最终解释权归喜郎中所有。 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }