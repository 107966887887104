var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.detail
      ? _c(
          "div",
          [
            _c(
              "van-panel",
              { attrs: { title: "物流信息" } },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "快递公司",
                        value: _vm.detail.com,
                        border: false
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "快递单号",
                        value: _vm.detail.nu,
                        border: false
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "van-steps",
                  {
                    attrs: {
                      active: _vm.active,
                      direction: "vertical",
                      "active-color": "#F7941E"
                    }
                  },
                  _vm._l(_vm.steps, function(item, index) {
                    return _c("van-step", { key: index }, [
                      _c("h4", [_vm._v(_vm._s(item.context))]),
                      _c("p", [_vm._v(_vm._s(item.time))])
                    ])
                  }),
                  1
                )
              ],
              1
            ),
            _vm.steps.length == 0
              ? _c("div", { staticClass: "steps" }, [
                  _vm._v("暂无最新物流信息")
                ])
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }