var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text": "没有更多了",
                offset: "150"
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.list, function(item) {
              return _c(
                "div",
                {
                  key: item.doctor_id,
                  staticStyle: { "background-color": "white" },
                  on: {
                    click: function($event) {
                      return _vm.clickCell(item)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "cell" }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c("van-image", {
                          staticClass: "avatar-img",
                          attrs: {
                            width: "40px",
                            height: "40px",
                            src: item.doctor_url
                          }
                        }),
                        _c("div", [
                          _c("div", { staticClass: "title" }, [
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(_vm._s(item.doctor_name))]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("点赞了这个帖子")])
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#A4A4A4",
                                "line-height": "22px",
                                "padding-left": "5px"
                              }
                            },
                            [_vm._v(_vm._s(item.created_at))]
                          )
                        ])
                      ],
                      1
                    )
                  ]),
                  _c("div", {
                    staticStyle: {
                      "background-color": "#F9F9F8",
                      height: "10px"
                    }
                  })
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }