var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.detail
        ? _c("div", { staticStyle: { "background-color": "white" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                  padding: "10px 20px"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c("van-image", {
                      staticClass: "avatar-img",
                      attrs: {
                        width: "40px",
                        height: "40px",
                        src: _vm.detail.doctor_url
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "right",
                      width: "200px"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          padding: "0 15px"
                        }
                      },
                      [
                        _c("div", { staticClass: "header-bottom-title" }, [
                          _vm._v(_vm._s(_vm.detail.vote_count))
                        ]),
                        _c("div", { staticClass: "header-bottom-des" }, [
                          _vm._v("获赞")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          padding: "0 15px"
                        },
                        on: { click: _vm.jumpToMyFollowList }
                      },
                      [
                        _c("div", { staticClass: "header-bottom-title" }, [
                          _vm._v(_vm._s(_vm.detail.followers_count))
                        ]),
                        _c("div", { staticClass: "header-bottom-des" }, [
                          _vm._v("关注")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          padding: "0 15px"
                        },
                        on: { click: _vm.jumpToMyFansList }
                      },
                      [
                        _c("div", { staticClass: "header-bottom-title" }, [
                          _vm._v(_vm._s(_vm.detail.followings_count))
                        ]),
                        _c("div", { staticClass: "header-bottom-des" }, [
                          _vm._v("粉丝")
                        ])
                      ]
                    )
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  padding: "10px 20px 15px 20px"
                }
              },
              [
                _c("div", { staticClass: "header-title" }, [
                  _vm._v(_vm._s(_vm.detail.doctor_name))
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "padding-right": "10px"
                    }
                  },
                  [
                    _vm.detail.is_followed
                      ? _c(
                          "van-tag",
                          {
                            attrs: {
                              round: "",
                              size: "large",
                              color: "#F39800",
                              "text-color": "white"
                            },
                            on: {
                              click: function($event) {
                                return _vm.fetchToggleFollow(
                                  _vm.detail.is_followed
                                )
                              }
                            }
                          },
                          [_vm._v("已关注")]
                        )
                      : _c(
                          "van-tag",
                          {
                            attrs: {
                              plain: "",
                              round: "",
                              size: "large",
                              color: "#F39800",
                              "text-color": "#F39800"
                            },
                            on: {
                              click: function($event) {
                                return _vm.fetchToggleFollow(
                                  _vm.detail.is_followed
                                )
                              }
                            }
                          },
                          [_vm._v("+关注")]
                        )
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "16px",
            "font-weight": "bold",
            padding: "10px 20px 0 20px",
            "background-color": "white",
            "margin-top": "10px"
          }
        },
        [_vm._v(_vm._s("帖子(" + _vm.total + ")"))]
      ),
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text": "没有更多了",
                offset: "150"
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "cell",
                  on: {
                    click: function($event) {
                      return _vm.clickCell(item)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "padding-left": "0px"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#A4A4A4",
                                "line-height": "22px"
                              }
                            },
                            [_vm._v(_vm._s(item.diff_for_humans))]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#A4A4A4",
                                "line-height": "22px",
                                "padding-left": "5px"
                              }
                            },
                            [_vm._v(_vm._s(item.click_count) + "阅读")]
                          )
                        ]
                      )
                    ]
                  ),
                  _c("div", { staticClass: "card-title" }, [
                    _vm._v(_vm._s(item.title))
                  ]),
                  _c("div", { staticClass: "card-des" }, [
                    _vm._v(_vm._s(item.strip_tag_content))
                  ]),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(item.img_info, function(img) {
                      return _c(
                        "div",
                        { staticStyle: { padding: "4px 4px 8px 0" } },
                        [
                          _c("van-image", {
                            staticClass: "avatar-img",
                            attrs: { width: "60px", height: "60px", src: img }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(item.post_tags, function(itemTag) {
                      return _c(
                        "div",
                        { staticStyle: { padding: "10px 4px 8px 0" } },
                        [
                          _c(
                            "van-tag",
                            {
                              attrs: {
                                color: "#E9FFE7",
                                "text-color": "#41DC33"
                              }
                            },
                            [_vm._v(_vm._s(itemTag.name))]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "0 0px",
                        display: "flex",
                        "flex-direction": "row"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { "font-size": "14px", color: "#A4A4A4" }
                        },
                        [_vm._v(_vm._s(item.vote_count) + "点赞")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            color: "#A4A4A4",
                            padding: "0 6px"
                          }
                        },
                        [_vm._v(_vm._s(item.favorite_count) + "收藏")]
                      )
                    ]
                  ),
                  _c("van-divider", { staticStyle: { margin: "10px 0 0 0" } })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }