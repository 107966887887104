var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.detail
    ? _c(
        "div",
        {
          staticClass: "body",
          staticStyle: { position: "relative", height: "100vh" }
        },
        [
          _c(
            "div",
            { ref: "imageWrapper", attrs: { id: "imageWrapper" } },
            [
              _c("van-image", {
                staticStyle: { width: "100%", "z-index": "0" },
                attrs: {
                  fit: "fill",
                  src: require("../../assets/activity/yearReport2022/y_c_share_b.png")
                }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    width: "100%",
                    top: "11%",
                    "text-align": "center",
                    "margin-top": "0px",
                    "z-index": "10"
                  }
                },
                [
                  _c("van-image", {
                    staticStyle: {
                      width: "110px",
                      height: "110px",
                      "z-index": "1"
                    },
                    attrs: { round: "", fit: "fill", src: _vm.detail.figure }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "card",
                  staticStyle: {
                    position: "absolute",
                    "justify-content": "center",
                    top: "15%",
                    "z-index": "2"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#6A3820",
                        "font-size": "25px",
                        "text-align": "center",
                        "padding-top": "60px"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.detail.doctor_name) + "医师的年度关键词"
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        "text-align": "center",
                        top: "130px",
                        width: "90%"
                      }
                    },
                    [
                      _c("van-image", {
                        staticStyle: {
                          position: "relative",
                          width: "80%",
                          "z-index": "1"
                        },
                        attrs: {
                          fit: "cover",
                          src: require("../../assets/activity/yearReport2022/y_r_6_m.png")
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "0%",
                            left: "10%",
                            "text-align": "center",
                            width: "80%",
                            "z-index": "2",
                            "font-size": "32pt",
                            "font-weight": "bold",
                            color: "#6A3820",
                            display: "flex",
                            "justify-content": "space-around"
                          }
                        },
                        _vm._l(
                          _vm.detail.extra_data.seventh_data.keyword_str,
                          function(w, index) {
                            return _c("div", { key: index }, [
                              _vm._v(_vm._s(w))
                            ])
                          }
                        ),
                        0
                      )
                    ],
                    1
                  ),
                  _vm.detail
                    ? _c("div", { staticStyle: { "margin-top": "100px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "line-height": "180%",
                              color: "#6A3820",
                              "font-size": "18px",
                              "text-align": "center"
                            }
                          },
                          [
                            _vm.detail.extra_data.third_data
                              .prescription_number > 0
                              ? _c("span", [
                                  _vm._v(" 2022年我一共开具了 "),
                                  _c("span", { staticClass: "span-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.detail.extra_data.third_data
                                          .prescription_number
                                      )
                                    )
                                  ]),
                                  _vm._v(" 个方案"),
                                  _c("br"),
                                  _vm._v(" 帮助 "),
                                  _c("span", { staticClass: "span-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.detail.extra_data.third_data
                                          .archive_number
                                      )
                                    )
                                  ]),
                                  _vm._v(" 位患者免除了就医奔波"),
                                  _c("br")
                                ])
                              : _vm._e(),
                            _vm._v(" 如有健康问题可随时扫码向我咨询"),
                            _c("br"),
                            _vm._v(" 新年将至，愿平安顺遂，健康无忧！")
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              "text-align": "center",
                              "margin-top": "20px"
                            }
                          },
                          [
                            _c("van-image", {
                              staticStyle: { width: "70%", "z-index": "1" },
                              attrs: {
                                fit: "fill",
                                src: _vm.detail.doctor_qrcode
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _vm.type == 1
            ? _c(
                "footer",
                {
                  staticStyle: {
                    width: "100%",
                    position: "fixed",
                    bottom: "0",
                    left: "0",
                    height: "50px",
                    "text-align": "center",
                    display: "flex",
                    "justify-content": "space-evenly",
                    "background-color": "#f39000",
                    "align-items": "center",
                    color: "white",
                    "z-index": "99"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { width: "50%" },
                      on: {
                        click: function($event) {
                          return _vm.shareAction()
                        }
                      }
                    },
                    [_vm._v(" 分享 ")]
                  ),
                  _c("div", {
                    staticStyle: {
                      height: "100%",
                      width: "1px",
                      "background-color": "white"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: { width: "50%" },
                      on: {
                        click: function($event) {
                          return _vm.saveToApp()
                        }
                      }
                    },
                    [_vm._v("保存至相册")]
                  )
                ]
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }