var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.detail
    ? _c(
        "div",
        {
          staticClass: "body",
          style: {
            backgroundColor: _vm.detail.style_items.page_background_color
          }
        },
        [
          _c(
            "van-popup",
            {
              staticStyle: { width: "90%", "background-color": "transparent" },
              model: {
                value: _vm.buttonPopupShow,
                callback: function($$v) {
                  _vm.buttonPopupShow = $$v
                },
                expression: "buttonPopupShow"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "card",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "background-color": "white"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "70vh",
                        "overflow-y": "scroll",
                        "z-index": "0",
                        "border-radius": "10px"
                      },
                      style: {
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                            "padding-top": "20px"
                          }
                        },
                        [
                          _vm.buttonPopupShowType == 0
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "center",
                                      "font-size": "18px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("活动规则")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "20px 23px 12px 23px",
                                      width: "100%"
                                    }
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.detail.text_rule)
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.buttonPopupShowType == 1
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "center",
                                      "font-size": "18px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("常见问题")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "20px 23px 12px 23px",
                                      width: "100%"
                                    }
                                  },
                                  _vm._l(_vm.detail.question_item, function(
                                    item,
                                    index
                                  ) {
                                    return _c("div", { key: index }, [
                                      _c("div", { staticClass: "main-title" }, [
                                        _vm._v(_vm._s(item.key))
                                      ]),
                                      _c("div", { staticClass: "sub-title" }, [
                                        _vm._v(_vm._s(item.value))
                                      ])
                                    ])
                                  }),
                                  0
                                )
                              ])
                            : _vm._e(),
                          _vm.buttonPopupShowType == 2
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "center",
                                      "font-size": "18px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("其他说明")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "20px 23px 12px 23px",
                                      width: "100%"
                                    }
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.detail.other_des)
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center" },
                  on: {
                    click: function($event) {
                      _vm.buttonPopupShow = false
                    }
                  }
                },
                [
                  _c("van-icon", {
                    attrs: { name: "close", size: "40", color: "white" }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              _c("van-image", {
                staticStyle: { width: "100%", "z-index": "0" },
                attrs: {
                  fit: "fill",
                  src: _vm.detail.style_items.page_header_image_id
                }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    "z-index": "2",
                    bottom: "0%"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "card",
                      style: {
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor:
                          _vm.detail.style_items.card_background_color
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "24px 23px 32px 23px",
                            "text-align": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              style: {
                                color:
                                  _vm.detail.style_items
                                    .collection_card_progress_text_color,
                                fontSize: "15px",
                                fontWeight: "bold",
                                padding: "10px"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.detail.style_items
                                    .collection_card_reward_copy
                                )
                              )
                            ]
                          ),
                          _c(
                            "van-button",
                            {
                              style: {
                                margin: "10px 0 20px 0",
                                height: "32px",
                                backgroundColor:
                                  _vm.detail.style_items
                                    .collection_card_progress_color,
                                color:
                                  _vm.detail.style_items
                                    .collection_card_progress_text_color,
                                fontSize: "14px",
                                fontWeight: "bold"
                              },
                              attrs: { round: "" }
                            },
                            [
                              _vm._v(
                                "集卡进度: " +
                                  _vm._s(
                                    _vm.detail.is_all ? "已集齐" : "暂未集齐"
                                  )
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticStyle: { padding: "10px 0" } },
                            [
                              _c(
                                "van-grid",
                                {
                                  attrs: {
                                    "column-num": 4,
                                    gutter: 0,
                                    border: false
                                  }
                                },
                                _vm._l(_vm.detail.reward_items, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "van-grid-item",
                                    [
                                      _c(
                                        "van-badge",
                                        {
                                          staticStyle: {
                                            margin: "10px 10px 0 0"
                                          },
                                          attrs: {
                                            content:
                                              item.count == 0
                                                ? null
                                                : "" + item.count
                                          }
                                        },
                                        [
                                          _c("van-image", {
                                            staticStyle: { padding: "0px" },
                                            attrs: {
                                              src:
                                                item.count == 0
                                                  ? item.url
                                                  : item.after_url
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              "text-align": "center",
                              "margin-bottom": "50px",
                              display: "flex",
                              "flex-direction": "column",
                              "align-items": "center"
                            },
                            on: { click: _vm.lotteryClick }
                          },
                          [
                            _c("van-image", {
                              staticStyle: { width: "80%", "z-index": "0" },
                              attrs: {
                                fit: "fill",
                                src: _vm.detail.style_items.card_draw_button_id
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  "z-index": "12",
                                  "text-align": "center",
                                  width: "100%",
                                  color: "white",
                                  bottom: "35%"
                                }
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "点击抽卡（剩余次数:" +
                                      _vm._s(_vm.detail.current_num) +
                                      "）"
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    "z-index": "10",
                    top: "28%",
                    right: "0"
                  }
                },
                [
                  _vm.detail.style_items.activity_rules_button_id != null &&
                  _vm.detail.style_items.activity_rules_button_id
                    ? _c(
                        "div",
                        [
                          _c("van-image", {
                            staticStyle: { width: "50px", "z-index": "0" },
                            attrs: {
                              fit: "fill",
                              src:
                                _vm.detail.style_items.activity_rules_button_id
                            },
                            on: {
                              click: function($event) {
                                return _vm.buttonPopupShowAction(0)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detail.style_items.frequently_asked_questions_button_id !=
                    null &&
                  _vm.detail.style_items.frequently_asked_questions_button_id
                    ? _c(
                        "div",
                        [
                          _c("van-image", {
                            staticStyle: { width: "50px", "z-index": "0" },
                            attrs: {
                              fit: "fill",
                              src:
                                _vm.detail.style_items
                                  .frequently_asked_questions_button_id
                            },
                            on: {
                              click: function($event) {
                                return _vm.buttonPopupShowAction(1)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detail.style_items.other_instructions_button_id != null &&
                  _vm.detail.style_items.other_instructions_button_id
                    ? _c(
                        "div",
                        [
                          _c("van-image", {
                            staticStyle: { width: "50px", "z-index": "0" },
                            attrs: {
                              fit: "fill",
                              src:
                                _vm.detail.style_items
                                  .other_instructions_button_id
                            },
                            on: {
                              click: function($event) {
                                return _vm.buttonPopupShowAction(2)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _vm.detail.style_items.activity_rules_id != null &&
          _vm.detail.style_items.activity_rules_id
            ? _c(
                "div",
                {
                  staticClass: "card",
                  style: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    boxShadow:
                      "inset 0px 4px 21px 6px " +
                      _vm.detail.style_items.card_shadow_color,
                    backgroundColor:
                      _vm.detail.style_items.card_background_color
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-title" },
                    [
                      _c("van-image", {
                        staticStyle: { padding: "0 25%" },
                        attrs: {
                          fit: "contain",
                          src: _vm.detail.style_items.activity_rules_id
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { padding: "24px 23px 12px 23px" } },
                    [
                      _c("div", {
                        style: {
                          color: _vm.detail.style_items.page_text_color
                        },
                        domProps: { innerHTML: _vm._s(_vm.detail.text_rule) }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm.detail.style_items.frequently_asked_questions_id != null &&
          _vm.detail.style_items.frequently_asked_questions_id
            ? _c(
                "div",
                {
                  staticClass: "card",
                  style: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    boxShadow:
                      "inset 0px 4px 21px 6px " +
                      _vm.detail.style_items.card_shadow_color,
                    backgroundColor:
                      _vm.detail.style_items.card_background_color
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-title" },
                    [
                      _c("van-image", {
                        staticStyle: { padding: "0 25%" },
                        attrs: {
                          fit: "contain",
                          src:
                            _vm.detail.style_items.frequently_asked_questions_id
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { padding: "24px 23px 12px 23px" } },
                    _vm._l(_vm.detail.question_item, function(item, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          {
                            staticClass: "main-title",
                            style: {
                              color: _vm.detail.style_items.page_text_color
                            }
                          },
                          [_vm._v(_vm._s(item.key))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "sub-title",
                            style: {
                              color: _vm.detail.style_items.page_text_color
                            }
                          },
                          [_vm._v(_vm._s(item.value))]
                        )
                      ])
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _vm.detail.style_items.other_instructions_id != null &&
          _vm.detail.style_items.other_instructions_id
            ? _c(
                "div",
                {
                  staticClass: "card",
                  style: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    boxShadow:
                      "inset 0px 4px 21px 6px " +
                      _vm.detail.style_items.card_shadow_color,
                    backgroundColor:
                      _vm.detail.style_items.card_background_color
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-title" },
                    [
                      _c("van-image", {
                        staticStyle: { padding: "0 25%" },
                        attrs: {
                          fit: "contain",
                          src: _vm.detail.style_items.other_instructions_id
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { padding: "24px 23px 12px 23px" } },
                    [
                      _c("div", {
                        style: {
                          color: _vm.detail.style_items.page_text_color
                        },
                        domProps: { innerHTML: _vm._s(_vm.detail.other_des) }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "z-index": "0",
                display: "flex",
                "justify-content": "center"
              }
            },
            [
              _c("van-image", {
                staticStyle: { "z-index": "0" },
                attrs: {
                  fit: "fill",
                  src: _vm.detail.style_items.page_base_image_id
                }
              })
            ],
            1
          ),
          _c("div", { staticStyle: { height: "100px" } }),
          _c(
            "div",
            { staticClass: "footer", staticStyle: { width: "100vw" } },
            [
              _c("van-image", {
                staticStyle: { width: "100%" },
                attrs: {
                  fit: "cover",
                  src: _vm.detail.style_items.lucky_rewards_button_id
                },
                on: { click: _vm.fetchConfigActivityButtonReceive }
              })
            ],
            1
          ),
          _c(
            "van-popup",
            {
              style: { width: "100vw", backgroundColor: "transparent" },
              attrs: { position: "center" },
              model: {
                value: _vm.luckShow,
                callback: function($$v) {
                  _vm.luckShow = $$v
                },
                expression: "luckShow"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "justify-content": "center"
                  }
                },
                [
                  _vm.result_item
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "z-index": "9",
                            top: "30%",
                            "text-align": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "18px",
                                "font-weight": "bold",
                                color: "white",
                                "padding-bottom": "20px"
                              }
                            },
                            [_vm._v("恭喜您抽中了")]
                          ),
                          _c("van-image", {
                            staticStyle: {
                              position: "relative",
                              padding: "20px 0 10px 0"
                            },
                            attrs: { fit: "contain", src: _vm.result_item.url }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.result_item
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "padding-top": "20px"
                          }
                        },
                        [
                          _c(
                            "van-button",
                            {
                              staticStyle: { width: "150px" },
                              attrs: { color: "#f39800", round: "" },
                              on: { click: _vm.luckPopupClose }
                            },
                            [_vm._v(_vm._s("收下卡片"))]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }