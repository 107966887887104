var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.detail
    ? _c(
        "div",
        {
          staticClass: "body",
          style: {
            backgroundColor: _vm.detail.style_items.page_background_color
          }
        },
        [
          _c(
            "van-popup",
            {
              staticStyle: { width: "90%", "background-color": "transparent" },
              model: {
                value: _vm.buttonPopupShow,
                callback: function($$v) {
                  _vm.buttonPopupShow = $$v
                },
                expression: "buttonPopupShow"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "card",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "background-color": "white"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "70vh",
                        "overflow-y": "scroll",
                        "z-index": "0",
                        "border-radius": "10px"
                      },
                      style: {
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                            "padding-top": "20px"
                          }
                        },
                        [
                          _vm.buttonPopupShowType == 0
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "center",
                                      "font-size": "18px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("活动规则")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "20px 23px 12px 23px",
                                      width: "100%"
                                    }
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.detail.text_rule)
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.buttonPopupShowType == 1
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "center",
                                      "font-size": "18px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("常见问题")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "20px 23px 12px 23px",
                                      width: "100%"
                                    }
                                  },
                                  _vm._l(_vm.detail.question_item, function(
                                    item,
                                    index
                                  ) {
                                    return _c("div", { key: index }, [
                                      _c("div", { staticClass: "main-title" }, [
                                        _vm._v(_vm._s(item.key))
                                      ]),
                                      _c("div", { staticClass: "sub-title" }, [
                                        _vm._v(_vm._s(item.value))
                                      ])
                                    ])
                                  }),
                                  0
                                )
                              ])
                            : _vm._e(),
                          _vm.buttonPopupShowType == 2
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "center",
                                      "font-size": "18px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("其他说明")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "20px 23px 12px 23px",
                                      width: "100%"
                                    }
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.detail.other_des)
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center" },
                  on: {
                    click: function($event) {
                      _vm.buttonPopupShow = false
                    }
                  }
                },
                [
                  _c("van-icon", {
                    attrs: { name: "close", size: "40", color: "white" }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              _c("van-image", {
                staticStyle: { width: "100%", "z-index": "0" },
                attrs: {
                  fit: "fill",
                  src: _vm.detail.style_items.page_header_image_id
                }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    "z-index": "10",
                    top: "28%",
                    right: "0"
                  }
                },
                [
                  _vm.detail.style_items.activity_rules_button_id != null &&
                  _vm.detail.style_items.activity_rules_button_id
                    ? _c(
                        "div",
                        [
                          _c("van-image", {
                            staticStyle: { width: "50px", "z-index": "0" },
                            attrs: {
                              fit: "fill",
                              src:
                                _vm.detail.style_items.activity_rules_button_id
                            },
                            on: {
                              click: function($event) {
                                return _vm.buttonPopupShowAction(0)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detail.style_items.frequently_asked_questions_button_id !=
                    null &&
                  _vm.detail.style_items.frequently_asked_questions_button_id
                    ? _c(
                        "div",
                        [
                          _c("van-image", {
                            staticStyle: { width: "50px", "z-index": "0" },
                            attrs: {
                              fit: "fill",
                              src:
                                _vm.detail.style_items
                                  .frequently_asked_questions_button_id
                            },
                            on: {
                              click: function($event) {
                                return _vm.buttonPopupShowAction(1)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detail.style_items.other_instructions_button_id != null &&
                  _vm.detail.style_items.other_instructions_button_id
                    ? _c(
                        "div",
                        [
                          _c("van-image", {
                            staticStyle: { width: "50px", "z-index": "0" },
                            attrs: {
                              fit: "fill",
                              src:
                                _vm.detail.style_items
                                  .other_instructions_button_id
                            },
                            on: {
                              click: function($event) {
                                return _vm.buttonPopupShowAction(2)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    "z-index": "9",
                    bottom: "5%",
                    "text-align": "center",
                    width: "100%"
                  },
                  on: { click: _vm.questionPopClick }
                },
                [
                  _c("van-image", {
                    staticStyle: { width: "70%", "z-index": "0" },
                    attrs: {
                      fit: "fill",
                      src: _vm.detail.style_items.answer_button_id
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "van-popup",
            {
              staticStyle: { width: "90%", "background-color": "transparent" },
              on: { closed: _vm.questionPopClosedAction },
              model: {
                value: _vm.questionPopShow,
                callback: function($$v) {
                  _vm.questionPopShow = $$v
                },
                expression: "questionPopShow"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "card",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "background-color": "white"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "70vh",
                        "overflow-y": "scroll",
                        "z-index": "0",
                        "border-radius": "10px"
                      },
                      style: {
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px"
                      }
                    },
                    [
                      _vm.questionDetail
                        ? _c(
                            "div",
                            { staticStyle: { width: "100%" } },
                            [
                              !_vm.questionIsAnswer &&
                              _vm.questionStep < _vm.questionDetail.total
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        display: "flex",
                                        "justify-content": "center",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                            "background-color": "#B62D2A",
                                            padding: "5px 20px",
                                            color: "white",
                                            height: "40px",
                                            "border-radius": "20px",
                                            display: "flex",
                                            "align-items": "center",
                                            "justify-content": "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "#FADEA7",
                                                "padding-right": "5px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  "第" +
                                                    (_vm.questionStep + 1) +
                                                    "题"
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            _vm._s(
                                              "共" +
                                                _vm.questionDetail.total +
                                                "题"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.questionIsAnswer
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between",
                                          padding: "10px 20px",
                                          "align-items": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                "共答对" +
                                                  _vm.questionDetail
                                                    .answered_num +
                                                  "题"
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "van-button",
                                          {
                                            staticStyle: {
                                              color: "#989898",
                                              "background-color": "#EEEEEE"
                                            },
                                            attrs: { size: "small" },
                                            on: {
                                              click: function($event) {
                                                _vm.questionPopShow = false
                                              }
                                            }
                                          },
                                          [_vm._v("返回首页")]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _c(
                                "van-tabs",
                                {
                                  attrs: { animated: "" },
                                  model: {
                                    value: _vm.questionStep,
                                    callback: function($$v) {
                                      _vm.questionStep = $$v
                                    },
                                    expression: "questionStep"
                                  }
                                },
                                [
                                  _vm._l(_vm.questionDetail.list, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "van-tab",
                                      { attrs: { title: "1" } },
                                      [
                                        _vm.questionIsAnswer
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "justify-content":
                                                      "space-between",
                                                    padding: "0 20px"
                                                  }
                                                },
                                                [
                                                  item.doctor_answer ==
                                                  item.answer
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            color: "#2CAD29"
                                                          }
                                                        },
                                                        [_vm._v("回答正确")]
                                                      )
                                                    : _vm._e(),
                                                  item.doctor_answer !=
                                                  item.answer
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            color: "#BE312D"
                                                          }
                                                        },
                                                        [_vm._v("回答错误")]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          "第" +
                                                            (_vm.questionStep +
                                                              1) +
                                                            "题 共" +
                                                            _vm.questionDetail
                                                              .total +
                                                            "题"
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-top": "0px",
                                              width: "100%"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: { padding: "20px" }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      padding: "10px 0",
                                                      "font-weight": "bold"
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                ),
                                                item.item_type == 1
                                                  ? _c(
                                                      "div",
                                                      [
                                                        !_vm.questionIsAnswer
                                                          ? _c(
                                                              "van-radio-group",
                                                              {
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .questionAnswer[
                                                                      index
                                                                    ],
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.questionAnswer,
                                                                      index,
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "questionAnswer[index]"
                                                                }
                                                              },
                                                              _vm._l(
                                                                item.content,
                                                                function(
                                                                  c,
                                                                  ci
                                                                ) {
                                                                  return _c(
                                                                    "van-radio",
                                                                    {
                                                                      staticStyle: {
                                                                        margin:
                                                                          "10px 5px",
                                                                        padding:
                                                                          "15px 10px",
                                                                        "border-radius":
                                                                          "10px",
                                                                        overflow:
                                                                          "hidden",
                                                                        "text-overflow":
                                                                          "ellipsis",
                                                                        "line-clamp":
                                                                          "3",
                                                                        "-webkit-box-orient":
                                                                          "vertical",
                                                                        "background-color":
                                                                          "#f6f6f6"
                                                                      },
                                                                      attrs: {
                                                                        name: ci,
                                                                        "checked-color":
                                                                          "#BE312D"
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.radioChange
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          c
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          : _c(
                                                              "div",
                                                              _vm._l(
                                                                item.content,
                                                                function(
                                                                  c,
                                                                  ci
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    [
                                                                      (ci ==
                                                                        item.doctor_answer &&
                                                                        ci ==
                                                                          item.answer) ||
                                                                      ci ==
                                                                        item.answer
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "questionText",
                                                                              staticStyle: {
                                                                                border:
                                                                                  "2px solid #2CAD29"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  c
                                                                                ) +
                                                                                  " "
                                                                              ),
                                                                              _c(
                                                                                "van-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    name:
                                                                                      "success",
                                                                                    color:
                                                                                      "#2CAD29"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      ci !=
                                                                        item.doctor_answer &&
                                                                      ci !=
                                                                        item.answer
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "questionText"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  c
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      ci ==
                                                                        item.doctor_answer &&
                                                                      ci !=
                                                                        item.answer
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "questionText",
                                                                              staticStyle: {
                                                                                border:
                                                                                  "2px solid #BE312D"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  c
                                                                                ) +
                                                                                  " "
                                                                              ),
                                                                              _c(
                                                                                "van-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    name:
                                                                                      "cross",
                                                                                    color:
                                                                                      "#BE312D"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.item_type == 2
                                                  ? _c(
                                                      "div",
                                                      [
                                                        !_vm.questionIsAnswer
                                                          ? _c(
                                                              "van-radio-group",
                                                              {
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .questionAnswer[
                                                                      index
                                                                    ],
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.questionAnswer,
                                                                      index,
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "questionAnswer[index]"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "van-grid",
                                                                  {
                                                                    attrs: {
                                                                      "column-num": 2,
                                                                      clickable:
                                                                        "",
                                                                      gutter: 10
                                                                    }
                                                                  },
                                                                  _vm._l(
                                                                    item.content_images,
                                                                    function(
                                                                      c,
                                                                      ci
                                                                    ) {
                                                                      return _c(
                                                                        "van-grid-item",
                                                                        {
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.imageRadioChange(
                                                                                index,
                                                                                ci
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle: {
                                                                                "border-radius":
                                                                                  "5px",
                                                                                "background-color":
                                                                                  "#f6f6f6"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "van-radio",
                                                                                {
                                                                                  staticStyle: {
                                                                                    padding:
                                                                                      "5px"
                                                                                  },
                                                                                  attrs: {
                                                                                    name: ci,
                                                                                    "checked-color":
                                                                                      "#BE312D"
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "van-image",
                                                                                {
                                                                                  staticStyle: {
                                                                                    padding:
                                                                                      "0px 5px 5px 5px"
                                                                                  },
                                                                                  attrs: {
                                                                                    src: c
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "van-radio-group",
                                                                  [
                                                                    _c(
                                                                      "van-grid",
                                                                      {
                                                                        attrs: {
                                                                          "column-num": 2,
                                                                          gutter: 10
                                                                        }
                                                                      },
                                                                      _vm._l(
                                                                        item.content_images,
                                                                        function(
                                                                          c,
                                                                          ci
                                                                        ) {
                                                                          return _c(
                                                                            "van-grid-item",
                                                                            {
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.imageRadioChange(
                                                                                    index,
                                                                                    ci
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              (ci ==
                                                                                item.doctor_answer &&
                                                                                ci ==
                                                                                  item.answer) ||
                                                                              ci ==
                                                                                item.answer
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "questionImg",
                                                                                      staticStyle: {
                                                                                        border:
                                                                                          "2px solid #2CAD29"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "van-image",
                                                                                        {
                                                                                          staticStyle: {
                                                                                            padding:
                                                                                              "5px"
                                                                                          },
                                                                                          attrs: {
                                                                                            src: c
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              ci !=
                                                                                item.doctor_answer &&
                                                                              ci !=
                                                                                item.answer
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "questionImg"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "van-image",
                                                                                        {
                                                                                          staticStyle: {
                                                                                            padding:
                                                                                              "5px"
                                                                                          },
                                                                                          attrs: {
                                                                                            src: c
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              ci ==
                                                                                item.doctor_answer &&
                                                                              ci !=
                                                                                item.answer
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "questionImg",
                                                                                      staticStyle: {
                                                                                        border:
                                                                                          "2px solid #BE312D"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "van-image",
                                                                                        {
                                                                                          staticStyle: {
                                                                                            padding:
                                                                                              "5px"
                                                                                          },
                                                                                          attrs: {
                                                                                            src: c
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            !_vm.questionIsAnswer
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "justify-content":
                                                        "center",
                                                      margin: "0 20px"
                                                    }
                                                  },
                                                  [
                                                    index != 0
                                                      ? _c(
                                                          "van-button",
                                                          {
                                                            staticStyle: {
                                                              flex: "1",
                                                              "margin-right":
                                                                "10px"
                                                            },
                                                            attrs: {
                                                              plain: "",
                                                              round: "",
                                                              color: "#BE312D",
                                                              type: "info"
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.preAction
                                                            }
                                                          },
                                                          [_vm._v("上一题")]
                                                        )
                                                      : _vm._e(),
                                                    index ==
                                                    _vm.questionDetail.list
                                                      .length -
                                                      1
                                                      ? _c(
                                                          "van-button",
                                                          {
                                                            staticStyle: {
                                                              "background-color":
                                                                "#B62D2A",
                                                              color: "white",
                                                              flex: "1"
                                                            },
                                                            attrs: {
                                                              round: ""
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.fetchConfigActivityQuestionSave
                                                            }
                                                          },
                                                          [_vm._v("提交答案")]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "justify-content":
                                                        "center",
                                                      margin: "0 20px"
                                                    }
                                                  },
                                                  [
                                                    index != 0
                                                      ? _c(
                                                          "van-button",
                                                          {
                                                            staticStyle: {
                                                              flex: "1",
                                                              "margin-right":
                                                                "10px"
                                                            },
                                                            attrs: {
                                                              plain: "",
                                                              round: "",
                                                              color: "#BE312D",
                                                              type: "info"
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.preAction
                                                            }
                                                          },
                                                          [_vm._v("上一题")]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "van-button",
                                                      {
                                                        staticStyle: {
                                                          "background-color":
                                                            "#B62D2A",
                                                          color: "white",
                                                          flex: "1"
                                                        },
                                                        attrs: { round: "" },
                                                        on: {
                                                          click:
                                                            _vm.questionStepNext
                                                        }
                                                      },
                                                      [_vm._v("下一题")]
                                                    )
                                                  ],
                                                  1
                                                )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  !_vm.questionIsAnswer
                                    ? _c("van-tab", [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "flex-direction": "column",
                                              "align-items": "center",
                                              margin: "50px 0"
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  "共" +
                                                    _vm.questionDetail.total +
                                                    "题"
                                                )
                                              )
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "font-size": "20px",
                                                  "font-weight": "bold",
                                                  margin: "50px 0"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    "共答对" +
                                                      _vm.answered_num +
                                                      "题"
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "van-button",
                                              {
                                                staticStyle: {
                                                  color: "#989898",
                                                  "background-color": "#EEEEEE",
                                                  width: "200px"
                                                },
                                                attrs: { round: "" },
                                                on: {
                                                  click: function($event) {
                                                    _vm.questionPopShow = false
                                                  }
                                                }
                                              },
                                              [_vm._v("返回首页")]
                                            ),
                                            _c(
                                              "van-button",
                                              {
                                                staticStyle: {
                                                  width: "200px",
                                                  "margin-top": "10px"
                                                },
                                                attrs: {
                                                  round: "",
                                                  color: "#BE312D"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.questionSaveAfterAction()
                                                  }
                                                }
                                              },
                                              [_vm._v("查看答案")]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "padding-bottom": "10px",
                        "font-size": "12px"
                      }
                    },
                    [_vm._v("如有疑问，请联系客服：4006368988")]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center" },
                  on: {
                    click: function($event) {
                      _vm.questionPopShow = false
                    }
                  }
                },
                [
                  _c("van-icon", {
                    attrs: { name: "close", size: "40", color: "white" }
                  })
                ],
                1
              )
            ]
          ),
          _vm.detail.style_items.activity_rules_id != null &&
          _vm.detail.style_items.activity_rules_id
            ? _c(
                "div",
                {
                  staticClass: "card",
                  style: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    boxShadow:
                      "inset 0px 4px 21px 6px " +
                      _vm.detail.style_items.card_shadow_color,
                    backgroundColor:
                      _vm.detail.style_items.card_background_color
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-title" },
                    [
                      _c("van-image", {
                        staticStyle: { padding: "0 25%" },
                        attrs: {
                          fit: "contain",
                          src: _vm.detail.style_items.activity_rules_id
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { padding: "24px 23px 12px 23px" } },
                    [
                      _c("div", {
                        style: {
                          color: _vm.detail.style_items.page_text_color
                        },
                        domProps: { innerHTML: _vm._s(_vm.detail.text_rule) }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm.detail.style_items.frequently_asked_questions_id != null &&
          _vm.detail.style_items.frequently_asked_questions_id
            ? _c(
                "div",
                {
                  staticClass: "card",
                  style: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    boxShadow:
                      "inset 0px 4px 21px 6px " +
                      _vm.detail.style_items.card_shadow_color,
                    backgroundColor:
                      _vm.detail.style_items.card_background_color
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-title" },
                    [
                      _c("van-image", {
                        staticStyle: { padding: "0 25%" },
                        attrs: {
                          fit: "contain",
                          src:
                            _vm.detail.style_items.frequently_asked_questions_id
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { padding: "24px 23px 12px 23px" } },
                    _vm._l(_vm.detail.question_item, function(item, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          {
                            staticClass: "main-title",
                            style: {
                              color: _vm.detail.style_items.page_text_color
                            }
                          },
                          [_vm._v(_vm._s(item.key))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "sub-title",
                            style: {
                              color: _vm.detail.style_items.page_text_color
                            }
                          },
                          [_vm._v(_vm._s(item.value))]
                        )
                      ])
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _vm.detail.style_items.other_instructions_id != null &&
          _vm.detail.style_items.other_instructions_id
            ? _c(
                "div",
                {
                  staticClass: "card",
                  style: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    boxShadow:
                      "inset 0px 4px 21px 6px " +
                      _vm.detail.style_items.card_shadow_color,
                    backgroundColor:
                      _vm.detail.style_items.card_background_color
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-title" },
                    [
                      _c("van-image", {
                        staticStyle: { padding: "0 25%" },
                        attrs: {
                          fit: "contain",
                          src: _vm.detail.style_items.other_instructions_id
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { padding: "24px 23px 12px 23px" } },
                    [
                      _c("div", {
                        style: {
                          color: _vm.detail.style_items.page_text_color
                        },
                        domProps: { innerHTML: _vm._s(_vm.detail.other_des) }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "z-index": "0",
                display: "flex",
                "justify-content": "center"
              }
            },
            [
              _c("van-image", {
                staticStyle: { "z-index": "0" },
                attrs: {
                  fit: "fill",
                  src: _vm.detail.style_items.page_base_image_id
                }
              })
            ],
            1
          ),
          _c("div", { staticStyle: { height: "100px" } }),
          _c(
            "div",
            { staticClass: "footer", staticStyle: { width: "100vw" } },
            [
              _c("van-image", {
                staticStyle: { width: "100%" },
                attrs: {
                  fit: "cover",
                  src: _vm.detail.style_items.lucky_rewards_button_id
                },
                on: { click: _vm.fetchConfigActivityButtonReceive }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }