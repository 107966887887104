var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(3, function(item) {
        return _c(
          "van-card",
          {
            key: item,
            attrs: {
              num: "2",
              price: "2.00",
              desc: "机不可失，失不再来...",
              title: "ipad 大促销",
              thumb: "https://img.yzcdn.cn/vant/ipad.jpeg"
            }
          },
          [
            _c(
              "div",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _c(
                  "van-button",
                  { attrs: { size: "mini" }, on: { click: _vm.addToCart } },
                  [_vm._v("加入购物车")]
                )
              ],
              1
            )
          ]
        )
      }),
      _c(
        "van-goods-action",
        [
          _c("van-goods-action-icon", {
            attrs: { icon: "chat-o", text: "客服" },
            on: { click: _vm.onClickIcon }
          }),
          _c("van-goods-action-icon", {
            attrs: {
              id: "buycar",
              icon: "cart-o",
              text: "购物车",
              info: _vm.productNum
            },
            on: { click: _vm.onClickIcon }
          }),
          _c("van-goods-action-button", {
            attrs: { type: "warning", text: "加入购物车" },
            on: { click: _vm.onClickButton }
          }),
          _c("van-goods-action-button", {
            attrs: { type: "danger", text: "立即购买" },
            on: { click: _vm.onClickButton }
          })
        ],
        1
      ),
      _vm.balls.length
        ? _c("flyin", {
            attrs: {
              "el-left": _vm.elLeft,
              "el-top": _vm.elTop,
              balls: _vm.balls
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }