//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear'; // ES 2015

import 'dayjs/locale/zh-cn'; // ES 2015 

dayjs.locale('zh-cn');
dayjs.extend(quarterOfYear);
export default {
  name: 'national202210',
  props: {},
  data: function data() {
    return {
      detail: undefined,
      show: false,
      showPopupDatePicker: false,
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      datePickerTitle: dayjs(Date()).format('YYYY-MM')
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityPromotionDetail();
  },
  methods: {
    formatter: function formatter(type, val) {
      if (type === 'year') {
        return "".concat(val, "\u5E74");
      } else if (type === 'month') {
        return "".concat(val, "\u6708");
      }

      return val;
    },
    datePickerConfirm: function datePickerConfirm(v) {
      this.showPopupDatePicker = false;
      console.log(dayjs(v).format('YYYY-MM'));
      var value = dayjs(v).format('YYYY-MM');
      this.datePickerTitle = value;
      this.fetchActivityPromotionDetail();
    },
    fetchActivityPromotionDetail: function fetchActivityPromotionDetail() {
      var _this = this;

      mallInteractor.fetchActivityPromotionDetail({
        id: 14,
        date: this.datePickerTitle
      }).then(function (data) {
        _this.detail = data;
      });
    },
    jumpToMall: function jumpToMall(id) {
      if (id != undefined) {
        this.$router.push({
          path: '/mallActivitiesItemList',
          query: {
            id: id
          }
        });
      }
    }
  }
};