var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "white" } },
    [
      _vm.indexData
        ? _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              _c("van-image", {
                staticStyle: { width: "100%", "z-index": "0" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/mall/mall_i_t.png")
                }
              }),
              _c(
                "div",
                {
                  staticStyle: { position: "absolute", top: "15%", left: "6%" }
                },
                [
                  _c("div", [_vm._v("可用经验值")]),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#F7931C",
                          "font-size": "29px",
                          "font-weight": "bold"
                        }
                      },
                      [_vm._v(_vm._s(_vm.indexData.integral))]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    "z-index": "1",
                    bottom: "-10%",
                    "text-align": "center",
                    width: "100%"
                  },
                  on: { click: _vm.pushToMyCard }
                },
                [
                  _c("van-image", {
                    staticStyle: { width: "90%", "z-index": "0" },
                    attrs: {
                      fit: "contain",
                      src: require("../../assets/mall/mall_i_m.png")
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        bottom: "15%",
                        left: "7%"
                      }
                    },
                    [
                      _c("div", { staticClass: "a inline" }, [
                        _c(
                          "span",
                          {
                            staticClass: "b",
                            staticStyle: {
                              "margin-left": "4px",
                              color: "white",
                              "font-size": "14px",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v("可兑换喜分")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "b",
                            staticStyle: {
                              color: "white",
                              "font-size": "26px",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v(_vm._s(_vm.indexData.income))]
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-row",
        {
          staticStyle: { "margin-top": "30px" },
          attrs: { type: "flex", justify: "space-around" }
        },
        [
          _vm.indexData
            ? _c(
                "van-col",
                { attrs: { span: "11" } },
                [
                  _c("van-image", {
                    attrs: {
                      fit: "contain",
                      src: _vm.indexData.market_index_activity,
                      width: "100%"
                    },
                    on: { click: _vm.pushToActivitiesList }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "van-col",
            {
              staticStyle: {
                "justify-content": "inherit",
                display: "flex",
                "flex-direction": "column"
              },
              attrs: { span: "11" }
            },
            [
              _c("van-image", {
                attrs: {
                  fit: "contain",
                  src: _vm.indexData.market_index_income,
                  width: "100%"
                },
                on: {
                  click: function($event) {
                    return _vm.pushToList(2)
                  }
                }
              }),
              _c("van-image", {
                attrs: {
                  fit: "contain",
                  src: _vm.indexData.market_index_integral,
                  width: "100%"
                },
                on: {
                  click: function($event) {
                    return _vm.pushToList(1)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c("van-image", {
            attrs: {
              fit: "contain",
              src: _vm.indexData.market_index_jdcard,
              width: "100%"
            },
            on: { click: _vm.pushToJDCardList }
          })
        ],
        1
      ),
      _c("van-cell", {
        attrs: {
          title: "喜分·海量兑换",
          "title-style": "font-size:16px",
          border: false,
          "is-link": "",
          value: "更多"
        },
        on: {
          click: function($event) {
            return _vm.pushToList(2)
          }
        }
      }),
      _vm._l(_vm.income_goods_list, function(item, index) {
        return _c("van-cell", {
          key: index,
          attrs: { border: false },
          on: {
            click: function($event) {
              return _vm.pushDetail(item)
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function() {
                  return [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            border: "1px solid #ff9000",
                            "border-radius": "5px",
                            display: "flex"
                          }
                        },
                        [
                          _c("van-image", {
                            staticStyle: { padding: "3px" },
                            attrs: {
                              fit: "contain",
                              width: "100",
                              height: "100",
                              src: item.cover_image_file.url
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            "justify-content": "space-between",
                            "margin-left": "5px"
                          }
                        },
                        [
                          _c("p", { staticClass: "goods-name" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          item.buy_model == 1
                            ? _c("div", { staticClass: "price" }, [
                                _vm._v(_vm._s(item.integral) + "经验值")
                              ])
                            : _vm._e(),
                          item.buy_model == 2
                            ? _c("div", { staticClass: "price" }, [
                                _vm._v(_vm._s(item.income) + "喜分")
                              ])
                            : _vm._e(),
                          item.buy_model == 3
                            ? _c("div", { staticClass: "price" }, [
                                _vm._v(
                                  _vm._s(item.integral) +
                                    "经验值+" +
                                    _vm._s(item.income) +
                                    "喜分"
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        })
      }),
      _c("van-cell", {
        attrs: {
          title: "经验值·当钱花",
          "title-style": "font-size:16px",
          border: false,
          "is-link": "",
          value: "更多"
        },
        on: {
          click: function($event) {
            return _vm.pushToList(1)
          }
        }
      }),
      _c(
        "van-swipe",
        {
          staticClass: "my-swipe",
          attrs: {
            autoplay: 3000,
            "indicator-color": "white",
            indicatorColor: "#F7931C",
            height: "210"
          }
        },
        _vm._l(_vm.integral_goods_list, function(itemlist, index) {
          return _c(
            "van-swipe-item",
            { key: index },
            [
              _c(
                "van-grid",
                { attrs: { border: false, "column-num": 3 } },
                _vm._l(itemlist, function(item, index) {
                  return _c(
                    "van-grid-item",
                    {
                      key: index,
                      on: {
                        click: function($event) {
                          return _vm.pushDetail(item)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            height: "160px",
                            padding: "0px 0px",
                            "text-align": "center"
                          }
                        },
                        [
                          _c("van-image", {
                            staticStyle: {
                              padding: "2px",
                              border: "1px solid #ff9000",
                              "border-radius": "5px"
                            },
                            attrs: {
                              fit: "contain",
                              width: "100%",
                              src: item.cover_image_file.url
                            }
                          }),
                          _c(
                            "p",
                            {
                              staticClass: "oneLineText",
                              staticStyle: { margin: "6px 0" }
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          item.buy_model == 1
                            ? _c("div", { staticClass: "gridPrice" }, [
                                _vm._v(_vm._s(item.integral) + "经验值")
                              ])
                            : _vm._e(),
                          item.buy_model == 2
                            ? _c("div", { staticClass: "gridPrice" }, [
                                _vm._v(_vm._s(item.income) + "喜分")
                              ])
                            : _vm._e(),
                          item.buy_model == 3
                            ? _c("div", { staticClass: "gridPrice" }, [
                                _vm._v(
                                  _vm._s(item.integral) +
                                    "经验值+" +
                                    _vm._s(item.income) +
                                    "喜分"
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }