"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PostDetail',
  props: {},
  data: function data() {
    return {
      detail: undefined,
      query: {
        page: 0,
        size: 10,
        object_id: undefined,
        scene: undefined,
        object_type: 'comment'
      },
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      comment: undefined,
      actionSheetShow: false,
      actions: [{
        name: '回复'
      }, {
        name: '投诉'
      }, {
        name: '取消'
      }],
      replyDialogShow: false,
      currentReplyItem: undefined,
      replyDialogContent: undefined,
      isKeyboardShown: false,
      // 记录键盘显示状态
      bottomOffset: 0 // 记录fixed元素的bottom属性值

    };
  },
  created: function created() {},
  mounted: function mounted() {
    console.log('fetchPostDetail');
    console.log(this.$route.query);
    this.query.object_id = this.$route.query.id;
    this.query.scene = this.$route.query.scene;
    this.fetchPostDetail({
      object_id: this.$route.query.id,
      scene: this.$route.query.scene,
      object_type: 'post'
    });
  },
  methods: {
    clickAvatar: function clickAvatar(id) {
      this.$router.push({
        path: '/communityDoctorInfo',
        query: {
          doctor_id: id
        }
      });
    },
    fetchCommentReplyList: function fetchCommentReplyList(item) {
      _core.communityInteractor.fetchCommentList({
        page: 0,
        size: 999,
        object_id: item.id,
        scene: 'app',
        object_type: 'reply'
      }).then(function (data) {
        item.total = data.total;
        item.reply_list = item.reply_list.concat(data.list);
      });
    },
    fetchToggleFollow: function fetchToggleFollow(is_followed) {
      var that = this;

      if (is_followed) {
        _vant.Dialog.confirm({
          message: '确认不再关注此人',
          confirmButtonColor: '#ff9000'
        }).then(function () {
          // on confirm
          that.toggleFollow(is_followed);
        }).catch(function () {// on cancel
        });
      } else {
        that.toggleFollow(is_followed);
      }
    },
    toggleFollow: function toggleFollow(is_followed) {
      var _this = this;

      _core.communityInteractor.fetchToggleFollow({
        doctor_id: this.detail.doctor_id
      }).then(function (data) {
        _this.detail.is_followed = !is_followed;
      });
    },
    replyDialogConfirm: function replyDialogConfirm() {
      if (this.replyDialogContent && this.replyDialogContent.length > 0) {
        this.fetchSetComment({
          object_type: 'comment',
          object_id: this.currentReplyItem.id,
          content: this.replyDialogContent
        });
        this.currentReplyItem = undefined;
        this.replyDialogContent = undefined;
      }
    },
    replyAction: function replyAction(item) {
      this.actionSheetShow = true;
      this.currentReplyItem = item;
    },
    actionSheetOnSelect: function actionSheetOnSelect(item, index) {
      console.log(item);
      console.log(this.currentReplyItem);

      if (index == 0) {
        this.replyDialogShow = true;
      }

      if (index == 1) {
        this.$router.push({
          path: '/communityComplaint',
          query: {
            'object_id': this.currentReplyItem.id,
            'object_type': 'comment'
          }
        });
      }

      if (index == 2) {
        this.currentReplyItem = undefined;
      }

      this.actionSheetShow = false;
    },
    fetchToggleVote: function fetchToggleVote(item) {
      _core.communityInteractor.fetchToggleVote({
        object_type: 'comment',
        object_id: item.id
      }).then(function (data) {
        item.is_voted = !item.is_voted;
        item.vote_count = item.is_voted ? item.vote_count + 1 : item.vote_count - 1;
      });
    },
    fetchPostDetail: function fetchPostDetail(query) {
      var _this2 = this;

      _core.communityInteractor.fetchPostDetail(query).then(function (data) {
        _this2.detail = data;
      });
    },
    sendComment: function sendComment() {
      this.$refs.commentField.blur();

      if (this.comment && this.comment.length > 0) {
        this.fetchSetComment({
          object_type: 'post',
          object_id: this.detail.post_id,
          content: this.comment
        });
        this.comment = undefined;
      }
    },
    shareAction: function shareAction() {
      var _this3 = this;

      _core.communityInteractor.fetchCommunityGetPostShareUrl({
        post_id: this.query.object_id
      }).then(function (data) {
        var dict = {
          'title': _this3.detail.title,
          'info': _this3.detail.strip_tag_content,
          'url': process.env.VUE_APP_URL + "/postDetail?scene=h5&id=".concat(data.id)
        };

        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          // ios
          // JS通知WKWebView
          window.webkit.messageHandlers.share.postMessage(dict);
        } else if (/(Android)/i.test(navigator.userAgent)) {
          // android
          window.a5ixlz.share(JSON.stringify(dict)); // window.a5ixlz.startActivity(dict)
        }
      });
    },
    fetchPostToggleVote: function fetchPostToggleVote() {
      var _this4 = this;

      _core.communityInteractor.fetchToggleVote({
        object_type: 'post',
        object_id: this.query.object_id
      }).then(function (data) {
        _this4.detail.is_voted = !_this4.detail.is_voted;
      });
    },
    fetchCommunityToggleFavorite: function fetchCommunityToggleFavorite() {
      var _this5 = this;

      _core.communityInteractor.fetchCommunityToggleFavorite({
        post_id: this.query.object_id
      }).then(function (data) {
        _this5.detail.is_favorited = !_this5.detail.is_favorited;
      });
    },
    fetchSetComment: function fetchSetComment(query) {
      var _this6 = this;

      _core.communityInteractor.fetchSetComment(query).then(function (data) {
        _this6.onRefresh();
      });
    },
    onLoad: function onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      this.query.page = this.query.page + 1;
      console.log('onLoad');
      this.fetchCommentList(this.query);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false;
      console.log('onRefresh');
      console.log(this.query); // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchCommentList(this.query);
    },
    fetchCommentList: function fetchCommentList(query) {
      var _this7 = this;

      _core.communityInteractor.fetchCommentList(query).then(function (data) {
        if (_this7.query.page === 1) {
          _this7.list = data.list;
        } else {
          _this7.list = [].concat((0, _toConsumableArray2.default)(_this7.list), (0, _toConsumableArray2.default)(data.list));
        }

        if (data.total <= _this7.list.length) {
          _this7.finished = true;
        }

        console.log(_this7.list);
        _this7.loading = false;
      });
    },
    jumpToEditPost: function jumpToEditPost() {
      this.$router.push({
        path: '/editPost'
      });
    }
  }
};
exports.default = _default;