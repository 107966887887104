import { render, staticRenderFns } from "./yearReport2023.vue?vue&type=template&id=2facc308&scoped=true&"
import script from "./yearReport2023.vue?vue&type=script&lang=js&"
export * from "./yearReport2023.vue?vue&type=script&lang=js&"
import style0 from "./yearReport2023.vue?vue&type=style&index=0&id=2facc308&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2facc308",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2facc308')) {
      api.createRecord('2facc308', component.options)
    } else {
      api.reload('2facc308', component.options)
    }
    module.hot.accept("./yearReport2023.vue?vue&type=template&id=2facc308&scoped=true&", function () {
      api.rerender('2facc308', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/activity/yearReport2023.vue"
export default component.exports