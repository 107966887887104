var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#F2E9DC" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: { fit: "fill", src: require("../../assets/invite2024.png") }
      }),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "50px",
            "background-color": "#FFFFFF"
          }
        },
        [
          _vm._m(0),
          _vm.detail
            ? _c(
                "div",
                {
                  staticClass: "inner-card",
                  staticStyle: { "margin-top": "20px" }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        width: "100%",
                        "font-size": "12px",
                        "flex-direction": "column",
                        padding: "30px 20px"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "padding-bottom": "20px"
                          }
                        },
                        [
                          _vm._v("当前邀请患者数："),
                          _c(
                            "span",
                            {
                              staticClass: "color-span",
                              staticStyle: {
                                "font-size": "15px",
                                "font-weight": "bold"
                              }
                            },
                            [_vm._v(_vm._s(_vm.detail.invite_count) + "人")]
                          )
                        ]
                      ),
                      _c("div", { staticStyle: { "font-size": "16px" } }, [
                        _vm._v("已获补贴值："),
                        _c(
                          "span",
                          {
                            staticClass: "color-span",
                            staticStyle: {
                              "font-size": "15px",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v(_vm._s(_vm.detail.total_income) + "喜分")]
                        )
                      ])
                    ]
                  ),
                  _vm._m(1)
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._m(2),
      _c("div", { staticStyle: { height: "60px" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { position: "absolute", "margin-top": "20px" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              position: "relative",
              width: "120px",
              padding: "10px 0px",
              "text-align": "center"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  position: "absolute",
                  "z-index": "1",
                  "font-size": "18px",
                  width: "120px",
                  "text-align": "center",
                  "font-weight": "bold"
                }
              },
              [_vm._v("添加患者好礼")]
            ),
            _c("div", {
              staticStyle: {
                position: "absolute",
                "background-color": "#313244",
                height: "10px",
                width: "120px",
                top: "25px",
                "z-index": "0",
                opacity: "0.14"
              }
            })
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          margin: "0 5% 50px 5%",
          width: "90%",
          "justify-content": "center"
        }
      },
      [
        _c("table", { staticClass: "tg", staticStyle: { width: "100%" } }, [
          _c("thead", [
            _c("tr", [
              _c(
                "th",
                { staticClass: "tg-uc1r", staticStyle: { width: "55%" } },
                [_vm._v("累计邀请新患者数")]
              ),
              _c("th", { staticClass: "tg-uc1r" }, [_vm._v("补贴喜分")])
            ])
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", { staticClass: "tg-zs29" }, [_vm._v("10人")]),
              _c("td", { staticClass: "tg-zs29" }, [_vm._v("5喜分")])
            ]),
            _c("tr", [
              _c("td", { staticClass: "tg-zs29" }, [_vm._v("15人")]),
              _c("td", { staticClass: "tg-zs29" }, [_vm._v("10喜分")])
            ]),
            _c("tr", [
              _c("td", { staticClass: "tg-zs29" }, [_vm._v("30人")]),
              _c("td", { staticClass: "tg-zs29" }, [_vm._v("20喜分")])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "card",
        staticStyle: { display: "flex", "justify-content": "center" }
      },
      [
        _c(
          "div",
          { staticStyle: { position: "absolute", "margin-top": "20px" } },
          [
            _c(
              "div",
              {
                staticStyle: {
                  position: "relative",
                  width: "100px",
                  padding: "10px 0px",
                  "text-align": "center"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      "z-index": "1",
                      "font-size": "18px",
                      width: "100px",
                      "text-align": "center",
                      "font-weight": "bold"
                    }
                  },
                  [_vm._v("其他说明")]
                ),
                _c("div", {
                  staticStyle: {
                    position: "absolute",
                    "background-color": "#313244",
                    height: "10px",
                    width: "100px",
                    top: "25px",
                    "z-index": "0",
                    opacity: "0.14"
                  }
                })
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            staticStyle: {
              padding: "90px 23px 20px 23px",
              color: "#727272",
              "line-height": "180%"
            }
          },
          [
            _vm._v(
              " 1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
            ),
            _c("br"),
            _vm._v(
              " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
            ),
            _c("br"),
            _vm._v(" 3、本次活动最终解释权归喜郎中所有。 ")
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }