"use strict";

require("core-js/modules/es.symbol");

require("core-js/modules/es.symbol.description");

require("core-js/modules/es.array.every");

require("core-js/modules/es.array.find");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.some");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.keys");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.object.values");

require("core-js/modules/es.string.includes");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = enumFactory;

/**
 * Used to provide config privacy.
 */
var CONFIG_SYMBOL = Symbol('config');

var parse = function parse(value) {
  var detail = Object.values(this[CONFIG_SYMBOL]).find(function (e) {
    return e.value === value;
  });

  if (typeof detail !== 'undefined') {
    return detail.name;
  }

  throw new RangeError('Enum parse failed');
};

var getValues = function getValues() {
  var config = this[CONFIG_SYMBOL];
  return Object.values(config);
};

var getValue = function getValue(target) {
  var config = this[CONFIG_SYMBOL];

  if (Object.hasOwnProperty.call(config, target)) {
    return config[target];
  } else {
    throw new RangeError('Invalid target');
  }
};

var getLang = function getLang(value) {
  var detail = Object.values(this[CONFIG_SYMBOL]).find(function (e) {
    return e.value === value;
  });

  if (typeof detail !== 'undefined') {
    return detail.lang;
  }

  throw new RangeError('Enum parse lang failed');
};

var getKeys = function getKeys() {
  return Object.keys(this[CONFIG_SYMBOL]);
};

var is = function is(target) {
  var config = this[CONFIG_SYMBOL];
  var configKeys = Object.keys(config);

  for (var _len = arguments.length, enums = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    enums[_key - 1] = arguments[_key];
  }

  var isValidEnums = enums.every(function (identifier) {
    return configKeys.includes(identifier);
  });

  if (isValidEnums) {
    return enums.some(function (key) {
      return Object.hasOwnProperty.call(config, key) && config[key].value === target;
    });
  } else {
    throw new RangeError('Parameter `enums` must be valid identifiers');
  }
};
/**
 * Enum factory.
 * public APIs should be prefixed with `$`
 * in order to prevent naming collision with `config` keys.
 * @param {Object} config
 * @return {Object}
 */


function enumFactory(config) {
  var publicAPIs = {
    parse: parse,
    getKeys: getKeys,
    getValues: getValues,
    getValue: getValue,
    is: is,
    getLang: getLang
  }; // do NOT point to Object.prototype to prevent naming collision with `config`

  var prototype = Object.create(null);
  Object.keys(publicAPIs).forEach(function (api) {
    prototype["$".concat(api)] = publicAPIs[api];
  });

  var _object = Object.create(prototype); // `CONFIG_SYMBOL` to provide data privacy for `config`


  _object[CONFIG_SYMBOL] = config; // Object.defineProperty to make sure config settings are immutable

  Object.keys(config).forEach(function (key) {
    Object.defineProperty(_object, key, {
      enumerable: false,
      configurable: false,
      writable: false,
      value: config[key]
    });
  });
  return _object;
}