"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MonthlySeted',
  props: {},
  data: function data() {
    return {
      userInfo: undefined
    };
  },
  created: function created() {
    var userInfo = _store.default.getters.userInfo;
    this.userInfo = userInfo;
  },
  mounted: function mounted() {},
  methods: {
    pushToDoctorCashAppList: function pushToDoctorCashAppList() {
      this.$router.push({
        path: '/doctorCashAppList'
      });
    },
    pushAction: function pushAction() {
      this.$router.push({
        path: '/monthlyMonthList'
      });
    }
  }
};
exports.default = _default;