var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body", staticStyle: { width: "100%", height: "100vh" } },
    [
      _vm.detail
        ? _c(
            "van-swipe",
            {
              ref: "swipe",
              staticStyle: { height: "100vh" },
              attrs: { vertical: "", loop: false, "show-indicators": false },
              on: { change: _vm.onChange }
            },
            [
              _c("van-swipe-item", [
                _c(
                  "div",
                  { staticStyle: { position: "relative", height: "100vh" } },
                  [
                    _c("van-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100vh",
                        "z-index": "0"
                      },
                      attrs: {
                        fit: "cover",
                        src: require("../../assets/activity/yearReport2022/y_r_0_b.png")
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          "z-index": "2",
                          top: "15%",
                          "text-align": "center",
                          width: "100vw"
                        }
                      },
                      [
                        _c("van-image", {
                          staticStyle: { width: "80%", "z-index": "1" },
                          attrs: {
                            fit: "contian",
                            src: require("../../assets/activity/yearReport2023/y_r_0_h.png")
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          bottom: "7%",
                          width: "100vw",
                          "text-align": "center"
                        },
                        on: {
                          click: function($event) {
                            return _vm.nextPage()
                          }
                        }
                      },
                      [
                        _c("van-image", {
                          staticStyle: {
                            width: "80%",
                            height: "100px",
                            "z-index": "1"
                          },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2022/y_r_0_b_b.png")
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm.detail.extra_data.third_data.is_new == 0
                ? _c(
                    "div",
                    [
                      _c("van-swipe-item", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              height: "100vh"
                            }
                          },
                          [
                            _c("van-image", {
                              staticStyle: {
                                width: "100%",
                                height: "100vh",
                                "z-index": "0"
                              },
                              attrs: {
                                fit: "cover",
                                src: require("../../assets/activity/yearReport2022/y_r_1_b.png")
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  "z-index": "2",
                                  top: "0",
                                  "text-align": "center",
                                  width: "100vw",
                                  transition: "transform 2.4s ease 0s",
                                  "line-height": "180%"
                                },
                                attrs: { id: "tilte-item-1" }
                              },
                              [
                                _c("div", { staticClass: "main-title" }, [
                                  _vm._v("2023年"),
                                  _c("br"),
                                  _vm._v(" 是您来到喜郎中的第 "),
                                  _c("span", { staticClass: "span-title" }, [
                                    _vm._v(_vm._s(_vm.detail.use_year))
                                  ]),
                                  _vm._v(" 年"),
                                  _c("br"),
                                  _vm._v(" 感恩遇见，携手度过了 "),
                                  _c("span", { staticClass: "span-title" }, [
                                    _vm._v(_vm._s(_vm.detail.use_day))
                                  ]),
                                  _vm._v(" 天"),
                                  _c("br")
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  width: "100vw",
                                  left: "-300px",
                                  "text-align": "center",
                                  transition: "transform 2.4s ease 0s"
                                },
                                attrs: { id: "image-item-1" }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: { width: "80%", "z-index": "1" },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2022/y_r_1_m.png")
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  width: "100vw",
                                  "text-align": "center",
                                  transition: "transform 2.4s ease 0s"
                                },
                                attrs: { id: "image-item-arrow" }
                              },
                              [
                                _c("van-image", {
                                  staticClass: "image-item-arrow",
                                  staticStyle: { width: "10%", "z-index": "1" },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2022/y_c_up.png")
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm.detail.extra_data.third_data.is_open > 0
                        ? _c("van-swipe-item", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  height: "100vh"
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100vh",
                                    "z-index": "0"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2022/y_r_2_b.png")
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "2",
                                      top: "0",
                                      "text-align": "center",
                                      width: "90vw",
                                      transition: "transform 2.4s ease 0s",
                                      "line-height": "180%"
                                    },
                                    attrs: { id: "tilte-item-2" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "main-title",
                                        staticStyle: { "text-align": "right" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "span-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data.third_data
                                                  .first_time
                                              )
                                            )
                                          ]
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " 这一天，是您在喜郎中首次看病人时间"
                                        ),
                                        _c("br"),
                                        _vm._v(" 您累计看病 "),
                                        _c(
                                          "span",
                                          { staticClass: "span-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data.third_data
                                                  .prescription_number
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" 次 "),
                                        _c("br"),
                                        _vm._v(" 您超越平台 "),
                                        _c(
                                          "span",
                                          { staticClass: "span-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data.third_data
                                                  .ranking
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v("的人"),
                                        _c("br"),
                                        _vm._v(
                                          " 悬壶济世医苍生，妙手回春解疾疼"
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      left: "-300px",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-2" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "60%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2022/y_r_2_m.png")
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-arrow-2" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticClass: "image-item-arrow",
                                      staticStyle: {
                                        width: "10%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2022/y_c_up.png")
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.detail.extra_data.third_data.is_open > 0
                        ? _c("van-swipe-item", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  height: "100vh"
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100vh",
                                    "z-index": "0"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2022/y_r_3_b.png")
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "2",
                                      top: "0",
                                      "text-align": "center",
                                      width: "90vw",
                                      transition: "transform 2.4s ease 0s",
                                      "line-height": "180%"
                                    },
                                    attrs: { id: "tilte-item-3" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "main-title",
                                        staticStyle: { "text-align": "left" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "span-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data
                                                  .fourth_data
                                                  .prescription_time_str
                                              )
                                            )
                                          ]
                                        ),
                                        _c("br"),
                                        _vm._v(" 是您看病人习惯时间"),
                                        _c("br"),
                                        _vm.detail.extra_data.fourth_data
                                          .date &&
                                        _vm.detail.extra_data.fourth_data
                                          .hour_str &&
                                        _vm.detail.extra_data.fourth_data
                                          .time_str
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.detail.extra_data
                                                    .fourth_data.date +
                                                    " " +
                                                    _vm.detail.extra_data
                                                      .fourth_data.time_str +
                                                    " "
                                                ) + " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "span-title" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.detail.extra_data
                                                        .fourth_data.hour_str
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" 您仍然为患者解除病痛 "),
                                              _c("br"),
                                              _vm._v(" 不分昼夜，使命必达"),
                                              _c("br")
                                            ])
                                          : _vm._e(),
                                        _vm.detail.extra_data.fourth_data
                                          .extra_str
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.detail.extra_data
                                                    .fourth_data.extra_str
                                                )
                                              ),
                                              _c("br")
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          " 感谢您敬畏生命，守卫人类健康保障贡献！ "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      left: "-300px",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-3" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "60%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2022/y_r_3_m.png")
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-arrow-3" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticClass: "image-item-arrow",
                                      staticStyle: {
                                        width: "10%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2022/y_c_up.png")
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.detail.extra_data.third_data.is_open > 0
                        ? _c("van-swipe-item", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  height: "100vh"
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100vh",
                                    "z-index": "0"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2022/y_r_4_b.png")
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "2",
                                      top: "0",
                                      "text-align": "center",
                                      width: "90vw",
                                      transition: "transform 2.4s ease 0s",
                                      "line-height": "180%"
                                    },
                                    attrs: { id: "tilte-item-4" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "main-title",
                                        staticStyle: { "text-align": "right" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "span-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data.fifth_data
                                                  .max_day
                                              )
                                            )
                                          ]
                                        ),
                                        _c("br"),
                                        _vm._v(" 是您看病人最多的一天"),
                                        _c("br"),
                                        _vm._v(" 这一天，您给患者看了 "),
                                        _c(
                                          "span",
                                          { staticClass: "span-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data.fifth_data
                                                  .max_num
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" 次病 "),
                                        _c("br"),
                                        _vm._v(" 您为 "),
                                        _c(
                                          "span",
                                          { staticClass: "span-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data.fifth_data
                                                  .max_patient
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" 位患者解除病痛 "),
                                        _c("br"),
                                        _vm._v(
                                          " 传承国粹，悬壶济世，医者仁心，福泽万家"
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(
                                          " 用喜郎中 · 为大众 · 成喜郎中 "
                                        ),
                                        _c("br"),
                                        _vm._v("喜郎中代表患者向您致敬！")
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      left: "-300px",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-4" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "70%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2022/y_r_4_m.png")
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-arrow-4" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticClass: "image-item-arrow",
                                      staticStyle: {
                                        width: "10%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2022/y_c_up.png")
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.detail.extra_data.third_data.is_open > 0
                        ? _c("van-swipe-item", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  height: "100vh"
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100vh",
                                    "z-index": "0"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2022/y_r_5_b.png")
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "2",
                                      top: "0",
                                      "text-align": "center",
                                      width: "90vw",
                                      transition: "transform 2.4s ease 0s",
                                      "line-height": "180%"
                                    },
                                    attrs: { id: "tilte-item-5" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "main-title",
                                        staticStyle: { "text-align": "left" }
                                      },
                                      [
                                        _vm._v(" 这一年里"),
                                        _c("br"),
                                        _vm._v(" 您最 "),
                                        _c(
                                          "span",
                                          { staticClass: "span-title" },
                                          [_vm._v("常用")]
                                        ),
                                        _vm._v(" 的药材是"),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.detail.extra_data.sixth_data
                                                .common_medicine
                                            )
                                        ),
                                        _c("br"),
                                        _vm._v(" 知针知药，固是良医。"),
                                        _c("br")
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      left: "-300px",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-5" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "60%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2022/y_r_5_m.png")
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-arrow-5" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticClass: "image-item-arrow",
                                      staticStyle: {
                                        width: "10%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2022/y_c_up.png")
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("van-swipe-item", [
                _c(
                  "div",
                  { staticStyle: { position: "relative", height: "100vh" } },
                  [
                    _c("van-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100vh",
                        "z-index": "0"
                      },
                      attrs: {
                        fit: "cover",
                        src: require("../../assets/activity/yearReport2023/y_r_6_b.png")
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          width: "100vw",
                          "text-align": "center",
                          top: "55%"
                        }
                      },
                      [
                        _c("van-image", {
                          staticStyle: {
                            position: "relative",
                            width: "80%",
                            "z-index": "1"
                          },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2022/y_r_6_m.png")
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "10%",
                              left: "10%",
                              "text-align": "center",
                              width: "80%",
                              "z-index": "2",
                              "font-size": "32pt",
                              "font-weight": "bold",
                              color: "#6A3820",
                              display: "flex",
                              "justify-content": "space-around"
                            }
                          },
                          _vm._l(
                            _vm.detail.extra_data.seventh_data.keyword_str,
                            function(w, index) {
                              return _c("div", { key: index }, [
                                _vm._v(_vm._s(w))
                              ])
                            }
                          ),
                          0
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          width: "100vw",
                          "text-align": "center",
                          top: "70%"
                        },
                        on: {
                          click: function($event) {
                            return _vm.jumpToShare()
                          }
                        }
                      },
                      [
                        _c("van-image", {
                          staticStyle: { width: "60%", "z-index": "1" },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2022/y_r_6_b_b.png")
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }