"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'InviteDoctorDetail',
  props: {},
  data: function data() {
    return {
      did: undefined,
      detail: undefined
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var id = this.$route.query.id;
    this.did = id;
    this.fetchActivityInviteDetail();
  },
  methods: {
    fetchActivityInviteDetail: function fetchActivityInviteDetail() {
      var _this = this;

      _core.mallInteractor.fetchActivityInviteDetail({
        doctor_id: this.did
      }).then(function (data) {
        _this.detail = data;
      });
    },
    fetchActivityInviteDetailCash: function fetchActivityInviteDetailCash() {
      var _this2 = this;

      _core.mallInteractor.fetchActivityInviteDetailCash({
        doctor_id: this.did
      }).then(function (data) {
        (0, _vant.Toast)('提取成功');
        setTimeout(function () {
          _this2.fetchActivityInviteDetail();
        }, 1500);
      });
    }
  }
};
exports.default = _default;