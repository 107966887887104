var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.balls, function(item, index) {
      return _c(
        "transition",
        {
          key: index.id,
          attrs: { appear: "" },
          on: {
            "after-appear": _vm.afterEnter,
            "before-appear": _vm.beforeEnter
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item,
                  expression: "item"
                }
              ],
              staticClass: "flyin"
            },
            [
              _c("img", {
                staticClass: "cutout",
                attrs: { src: _vm.cutout, alt: "" }
              })
            ]
          )
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }