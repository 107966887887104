var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "demo-wrapper" },
    _vm._l(_vm.components, function(item) {
      return _c("div", { key: item.name, staticClass: "demo" }, [
        _c("div", [_vm._v(" " + _vm._s(item.title) + " ")]),
        _c(
          "div",
          [
            _c(
              "van-button",
              {
                attrs: {
                  size: "mini",
                  color: "linear-gradient(to right, #4bb0ff, #6149f6)"
                },
                on: {
                  click: function($event) {
                    return _vm.handleToPage(item.name)
                  }
                }
              },
              [_vm._v("查看")]
            )
          ],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }