import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.join";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import store from '@/store';
import { Dialog, Toast } from 'vant';
export default {
  name: 'MallItemDetail',
  data: function data() {
    return {
      detail: undefined,
      form: {
        a: []
      },
      isShowBuild: false
    };
  },
  methods: {
    onSubmit: function onSubmit() {
      var _this = this;

      console.log(this.form.a);
      var topic = [];
      this.form.a.forEach(function (item, index) {
        console.log(index);
        var content;

        if (item.constructor === Array) {
          content = item.join(',');
        } else {
          content = item;
        }

        topic.push({
          title: _this.detail.question_info[index].title,
          content: content
        });
      });
      console.log(topic);
      mallInteractor.fetchActivityQuestionnaireSurveySubmit({
        question_id: this.$route.query.id,
        topic: topic
      }).then(function (data) {
        Toast.success('提交成功');

        _this.$router.push({
          path: '/questionnaireResult'
        });
      });
    },
    fetchActivityQuestionnaireSurveyDetails: function fetchActivityQuestionnaireSurveyDetails() {
      var _this2 = this;

      var id = this.$route.query.id;
      var params = {
        question_id: id
      };
      mallInteractor.fetchActivityQuestionnaireSurveyDetails(params).then(function (data) {
        _this2.detail = data;
      });
    }
  },
  computed: {},
  mounted: function mounted() {
    this.fetchActivityQuestionnaireSurveyDetails();
  },
  created: function created() {}
};