var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#a7d9cc" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/target202209/banner.png")
        }
      }),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c("van-image", {
            staticStyle: { width: "90%", "z-index": "0" },
            attrs: {
              fit: "fill",
              src: require("../../assets/activity/target202209/t_t.png")
            }
          })
        ],
        1
      ),
      _vm.detail
        ? _c(
            "div",
            {
              staticClass: "card",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "66px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "image-title" },
                [
                  _c("van-image", {
                    staticStyle: { width: "48%" },
                    attrs: {
                      fit: "contain",
                      src: require("../../assets/activity/target202209/t_h_0.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "50px 23px 0px 23px",
                    "line-height": "180%",
                    width: "100%"
                  }
                },
                [
                  _c("div", { staticStyle: { color: "#38404E" } }, [
                    _c("div", [
                      _vm._v("目标值："),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#FF9929",
                            "font-size": "17px",
                            "font-weight": "bold"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.detail.target_amount))]
                      )
                    ]),
                    _c("div", [
                      _vm._v("悬壶值："),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#FF9929",
                            "font-size": "17px",
                            "font-weight": "bold"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.detail.current_amount))]
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        padding: "20px 0 0 0",
                        display: "flex",
                        height: "50px",
                        "flex-direction": "column",
                        "justify-content": "center",
                        width: "100%"
                      }
                    },
                    [
                      _c("van-progress", {
                        attrs: {
                          "pivot-text": _vm.percentageTxt,
                          color: "#FFCE99",
                          percentage: _vm.percentage,
                          "stroke-width": "10"
                        }
                      }),
                      _vm.detail && !_vm.detail.status
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                position: "absolute",
                                right: "18%",
                                display: "flex",
                                "justify-content": "center",
                                "align-items": "center",
                                "font-size": "12px",
                                color: "white",
                                "background-color": "#ff9000",
                                width: "50px",
                                height: "20px",
                                "border-radius": "10px"
                              }
                            },
                            [
                              _c("van-icon", {
                                attrs: {
                                  name: require("../../assets/activity/target202209/t_lock.png")
                                }
                              }),
                              _c("div", [_vm._v("90%")])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#989898",
                        "font-size": "14px",
                        "text-align": "center",
                        padding: "0px 0px 10px 0px"
                      }
                    },
                    [
                      _vm._v(" 再增加 "),
                      _c("span", [_vm._v(_vm._s(_vm.detail.diff_amount))]),
                      _vm._v(" 悬壶值,将可获得 "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.detail.target_jd_card_num))
                      ]),
                      _vm._v(" 张京东卡 ")
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#989898",
                        "font-size": "16px",
                        "text-align": "center",
                        padding: "10px 20px 0px 20px"
                      }
                    },
                    [
                      _vm._v(" 已获得京东卡： "),
                      _c("span", { staticStyle: { color: "#FF9929" } }, [
                        _vm._v(_vm._s(_vm.detail.get_jd_card_num) + "张")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        padding: "0px 20px 10px"
                      }
                    },
                    [
                      _c("van-image", {
                        staticStyle: { width: "45%" },
                        attrs: {
                          fit: "contain",
                          src: require("../../assets/activity/target202209/t_t_" +
                            _vm.detail.button_status +
                            ".png")
                        },
                        on: {
                          click: function($event) {
                            return _vm.jumpToMall(_vm.detail.activity_id)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "70px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "45%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/target202209/t_h_1.png")
                }
              })
            ],
            1
          ),
          _vm._m(0)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "70px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "45%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/target202209/t_h_2.png")
                }
              })
            ],
            1
          ),
          _vm._m(1)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "70px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "45%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/target202209/t_h_3.png")
                }
              })
            ],
            1
          ),
          _vm._m(2)
        ]
      ),
      _c("div", { staticStyle: { height: "60px" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { padding: "50px 23px 0px 23px", "line-height": "180%" } },
      [
        _c("div", { staticStyle: { color: "#38404E" } }, [
          _vm._v(
            " 1. 本月累计悬壶值达到目标值的90%即可领取对应目标的京东卡数(100元/张)奖励，悬壶值达到目标值的百分比越高，可领取的京东卡越多，上不封顶"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" 2. 悬壶值由颗粒、膏方处方订单的基础药费1：1转换"),
          _c("br"),
          _c("br")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { padding: "50px 23px 0px 23px", width: "100%" } },
      [
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v("Q：京东卡什么时候可以兑换？ ")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [_vm._v("A：10月1日开放领取入口。 ")]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v(" Q：开具单品处方可以参与活动吗？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [
            _vm._v(
              "A：不能，只有开具颗粒剂型、膏方的处方订单才会计入悬壶值，单品处方不计入此项活动。"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "50px 23px 20px 23px",
          color: "#38404E",
          "line-height": "180%"
        }
      },
      [
        _vm._v(
          " 1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(" 3、本次活动最终解释权归喜郎中所有。 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }