"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BUILD_KEY = exports.LANGUAGE_KEY = exports.TOKEN_KEY = exports.TITLE = void 0;
var TITLE = '';
exports.TITLE = TITLE;
var TOKEN_KEY = 'XLZ';
exports.TOKEN_KEY = TOKEN_KEY;
var LANGUAGE_KEY = 'language';
exports.LANGUAGE_KEY = LANGUAGE_KEY;
var BUILD_KEY = 'build';
exports.BUILD_KEY = BUILD_KEY;