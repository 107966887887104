var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg" },
    [
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text": "没有更多了",
                offset: "150"
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "cell",
                  on: {
                    click: function($event) {
                      return _vm.clickItem(item)
                    }
                  }
                },
                [
                  _c(
                    "van-panel",
                    {
                      attrs: {
                        title: "订单编号：" + item.no,
                        status: _vm.goodsStatus(item)
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "footer",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row-reverse"
                                    }
                                  },
                                  [
                                    _c(
                                      "van-button",
                                      {
                                        attrs: { size: "small" },
                                        on: {
                                          click: function($event) {
                                            return _vm.statusButton(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.goodsStatusButton(item))
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-left": "10px"
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              item
                                ? _c("van-image", {
                                    attrs: {
                                      width: "100",
                                      height: "100",
                                      src:
                                        item.items[0].goods.cover_image_file.url
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { width: "100%" } },
                            [
                              _c("van-cell", {
                                staticClass: "item-cell",
                                attrs: {
                                  title: item.items[0].goods.name,
                                  border: false,
                                  "title-class": "goods-name",
                                  value: _vm.goodsInfoPrice(item)
                                }
                              }),
                              _c("van-cell", {
                                staticClass: "item-cell",
                                attrs: {
                                  title: item.items[0].goods.title,
                                  border: false,
                                  "title-class": "des",
                                  value: "数量：" + item.items[0].quantity
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }