var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.detail
    ? _c(
        "div",
        {
          staticClass: "body",
          style: {
            width: "100%",
            backgroundColor: _vm.detail.style_items.page_background_color
          }
        },
        [
          _c(
            "van-popup",
            {
              staticStyle: { width: "90%", "background-color": "transparent" },
              model: {
                value: _vm.buttonPopupShow,
                callback: function($$v) {
                  _vm.buttonPopupShow = $$v
                },
                expression: "buttonPopupShow"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "card",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "background-color": "white"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "70vh",
                        "overflow-y": "scroll",
                        "z-index": "0",
                        "border-radius": "10px"
                      },
                      style: { display: "flex", justifyContent: "center" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                            "padding-top": "20px"
                          }
                        },
                        [
                          _vm.buttonPopupShowType == 0
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "center",
                                      "font-size": "18px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("活动规则")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "20px 23px 12px 23px",
                                      width: "100%"
                                    }
                                  },
                                  [
                                    _c("div", {
                                      style: {
                                        color:
                                          _vm.detail.style_items.page_text_color
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(_vm.detail.text_rule)
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.buttonPopupShowType == 1
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "center",
                                      "font-size": "18px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("常见问题")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "20px 23px 12px 23px",
                                      width: "100%"
                                    }
                                  },
                                  _vm._l(_vm.detail.question_item, function(
                                    item,
                                    index
                                  ) {
                                    return _c("div", { key: index }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "main-title",
                                          style: {
                                            color:
                                              _vm.detail.style_items
                                                .page_text_color
                                          }
                                        },
                                        [_vm._v(_vm._s(item.key))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "sub-title",
                                          style: {
                                            color:
                                              _vm.detail.style_items
                                                .page_text_color
                                          }
                                        },
                                        [_vm._v(_vm._s(item.value))]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ])
                            : _vm._e(),
                          _vm.buttonPopupShowType == 2
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "center",
                                      "font-size": "18px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("其他说明")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "20px 23px 12px 23px",
                                      width: "100%"
                                    }
                                  },
                                  [
                                    _c("div", {
                                      style: {
                                        color:
                                          _vm.detail.style_items.page_text_color
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(_vm.detail.other_des)
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center" },
                  on: {
                    click: function($event) {
                      _vm.buttonPopupShow = false
                    }
                  }
                },
                [
                  _c("van-icon", {
                    attrs: { name: "close", size: "40", color: "white" }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              _c("van-image", {
                staticStyle: { width: "100%", "z-index": "0" },
                attrs: {
                  fit: "fill",
                  src: _vm.detail.style_items.page_header_image_id
                }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    "z-index": "10",
                    top: "28%",
                    right: "0"
                  }
                },
                [
                  _vm.detail.style_items.activity_rules_button_id != null &&
                  _vm.detail.style_items.activity_rules_button_id
                    ? _c(
                        "div",
                        [
                          _c("van-image", {
                            staticStyle: { width: "50px", "z-index": "0" },
                            attrs: {
                              fit: "fill",
                              src:
                                _vm.detail.style_items.activity_rules_button_id
                            },
                            on: {
                              click: function($event) {
                                return _vm.buttonPopupShowAction(0)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detail.style_items.frequently_asked_questions_button_id !=
                    null &&
                  _vm.detail.style_items.frequently_asked_questions_button_id
                    ? _c(
                        "div",
                        [
                          _c("van-image", {
                            staticStyle: { width: "50px", "z-index": "0" },
                            attrs: {
                              fit: "fill",
                              src:
                                _vm.detail.style_items
                                  .frequently_asked_questions_button_id
                            },
                            on: {
                              click: function($event) {
                                return _vm.buttonPopupShowAction(1)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detail.style_items.other_instructions_button_id != null &&
                  _vm.detail.style_items.other_instructions_button_id
                    ? _c(
                        "div",
                        [
                          _c("van-image", {
                            staticStyle: { width: "50px", "z-index": "0" },
                            attrs: {
                              fit: "fill",
                              src:
                                _vm.detail.style_items
                                  .other_instructions_button_id
                            },
                            on: {
                              click: function($event) {
                                return _vm.buttonPopupShowAction(2)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    "z-index": "9",
                    top: "28%",
                    "text-align": "center",
                    width: "100%"
                  }
                },
                [
                  _c("vue-lottery-draw", {
                    attrs: {
                      "lottery-start": _vm.lotteryStart,
                      "lottery-prizenum": _vm.prizeNum,
                      "lottery-prizeno": _vm.prizeNo,
                      "prize-list": _vm.prizeListCom,
                      "lottery-class": _vm.lotteryClass,
                      "lottery-bg": _vm.detail.style_items.wheel_background_id,
                      "pointer-bg": _vm.detail.style_items.lottery_button_id
                    },
                    on: {
                      lotteryClick: _vm.lotteryClick,
                      lotteryDone: _vm.lotteryDone
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    "z-index": "9",
                    bottom: "5%",
                    "text-align": "center",
                    width: "100%"
                  }
                },
                [
                  _c("van-image", {
                    staticStyle: { width: "70%", "z-index": "0" },
                    attrs: {
                      fit: "fill",
                      src: _vm.detail.style_items.lucky_draw_count_button_id
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        "z-index": "9",
                        bottom: "40%",
                        "text-align": "center",
                        width: "100%",
                        color: "black"
                      }
                    },
                    [_vm._v(_vm._s("抽奖次数:" + _vm.detail.current_num))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.detail.style_items.activity_rules_id != null &&
          _vm.detail.style_items.activity_rules_id
            ? _c(
                "div",
                {
                  staticClass: "card",
                  style: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    boxShadow:
                      "inset 0px 4px 21px 6px " +
                      _vm.detail.style_items.card_shadow_color,
                    backgroundColor:
                      _vm.detail.style_items.card_background_color
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-title" },
                    [
                      _c("van-image", {
                        staticStyle: { padding: "0 25%" },
                        attrs: {
                          fit: "contain",
                          src: _vm.detail.style_items.activity_rules_id
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { margin: "24px 23px 12px 23px" } },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.detail.text_rule) }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm.detail.style_items.frequently_asked_questions_id != null &&
          _vm.detail.style_items.frequently_asked_questions_id
            ? _c(
                "div",
                {
                  staticClass: "card",
                  style: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    boxShadow:
                      "inset 0px 4px 21px 6px " +
                      _vm.detail.style_items.card_shadow_color,
                    backgroundColor:
                      _vm.detail.style_items.card_background_color
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-title" },
                    [
                      _c("van-image", {
                        staticStyle: { padding: "0 25%" },
                        attrs: {
                          fit: "contain",
                          src:
                            _vm.detail.style_items.frequently_asked_questions_id
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { padding: "24px 23px 12px 23px" } },
                    _vm._l(_vm.detail.question_item, function(item, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          {
                            staticClass: "main-title",
                            style: {
                              color: _vm.detail.style_items.page_text_color
                            }
                          },
                          [_vm._v(_vm._s(item.key))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "sub-title",
                            style: {
                              color: _vm.detail.style_items.page_text_color
                            }
                          },
                          [_vm._v(_vm._s(item.value))]
                        )
                      ])
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _vm.detail.style_items.other_instructions_id != null &&
          _vm.detail.style_items.other_instructions_id
            ? _c(
                "div",
                {
                  staticClass: "card",
                  style: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    boxShadow:
                      "inset 0px 4px 21px 6px " +
                      _vm.detail.style_items.card_shadow_color,
                    backgroundColor:
                      _vm.detail.style_items.card_background_color
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-title" },
                    [
                      _c("van-image", {
                        staticStyle: { padding: "0 25%" },
                        attrs: {
                          fit: "contain",
                          src: _vm.detail.style_items.other_instructions_id
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { padding: "24px 23px 12px 23px" } },
                    [
                      _c("div", {
                        style: {
                          color: _vm.detail.style_items.page_text_color
                        },
                        domProps: { innerHTML: _vm._s(_vm.detail.other_des) }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _c("van-image", {
            staticStyle: { width: "100%", "z-index": "0" },
            attrs: {
              fit: "fill",
              src: _vm.detail.style_items.page_base_image_id
            }
          }),
          _c("div", { staticStyle: { height: "100px" } }),
          _c(
            "van-popup",
            {
              style: { width: "100vw", backgroundColor: "transparent" },
              attrs: { position: "center" },
              model: {
                value: _vm.luckShow,
                callback: function($$v) {
                  _vm.luckShow = $$v
                },
                expression: "luckShow"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { display: "flex", "justify-content": "center" }
                },
                [
                  _c("van-image", {
                    staticStyle: { color: "transparent" },
                    attrs: {
                      fit: "contain",
                      src: require("@/assets/activity/common_alert.png")
                    }
                  }),
                  _vm.result_item
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            "z-index": "9",
                            top: "30%",
                            "text-align": "center"
                          }
                        },
                        [
                          _vm.result_item.status == 1
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "18px",
                                    "font-weight": "bold"
                                  }
                                },
                                [_vm._v("恭喜您，中奖了")]
                              )
                            : _vm._e(),
                          _vm.result_item.status == 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "18px",
                                    "font-weight": "bold"
                                  }
                                },
                                [
                                  _vm._v("很遗憾"),
                                  _c("br"),
                                  _vm._v("您没有抽中奖品")
                                ]
                              )
                            : _vm._e(),
                          _c("van-image", {
                            staticStyle: {
                              width: "60px",
                              height: "60px",
                              padding: "20px 0 10px 0"
                            },
                            attrs: { fit: "contain", src: _vm.result_item.url }
                          }),
                          _c("div", { staticStyle: { "font-size": "16px" } }, [
                            _vm._v(_vm._s(_vm.result_item.name))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.result_item
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            "z-index": "9",
                            bottom: "16%",
                            "text-align": "center"
                          }
                        },
                        [
                          _c(
                            "van-button",
                            {
                              staticStyle: { width: "150px" },
                              attrs: { color: "#f39800", round: "" },
                              on: { click: _vm.luckPopupClose }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.result_item.status == 1
                                    ? "收下奖品"
                                    : "关闭"
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c("van-image", {
                staticStyle: { width: "100%" },
                attrs: {
                  fit: "cover",
                  src: _vm.detail.style_items.claim_button_id
                },
                on: { click: _vm.jumpToRewardList }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }