"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isExternal = void 0;

var isExternal = function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
};

exports.isExternal = isExternal;