var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#a7d9cc" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/national202210/banner.png")
        }
      }),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c("van-image", {
            staticStyle: {
              width: "100%",
              "z-index": "0",
              position: "relative",
              top: "-18px"
            },
            attrs: {
              fit: "fill",
              src: require("../../assets/activity/national202210/t_t.png")
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "0px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/national202210/t_h_t.png")
                }
              })
            ],
            1
          ),
          _vm.detail
            ? _c(
                "div",
                {
                  staticStyle: {
                    padding: "80px 23px 30px 23px",
                    "line-height": "180%",
                    width: "100%",
                    color: "#38404E"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v("今日悬壶值："),
                        _c("span", { staticClass: "red-title" }, [
                          _vm._v(_vm._s(_vm.detail.today_amount))
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-decoration": "underline",
                            color: "#F39800"
                          },
                          on: {
                            click: function($event) {
                              _vm.show = true
                            }
                          }
                        },
                        [_vm._v("奖励记录")]
                      )
                    ]
                  ),
                  _c("div", [
                    _vm._v("今日奖励喜分："),
                    _c("span", { staticClass: "red-title" }, [
                      _vm._v(_vm._s(_vm.detail.today_award_amount))
                    ])
                  ]),
                  _c("div", [
                    _vm._v("累计奖励喜分："),
                    _c("span", { staticClass: "red-title" }, [
                      _vm._v(_vm._s(_vm.detail.total_award_amount))
                    ])
                  ])
                ]
              )
            : _vm._e()
        ]
      ),
      _c(
        "van-popup",
        {
          staticStyle: { width: "90%", height: "60%" },
          attrs: { closeable: "" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "text-align": "center",
                    padding: "20px",
                    "font-size": "18px",
                    "font-weight": "bold"
                  }
                },
                [_vm._v("奖励记录")]
              ),
              _c("div", { staticStyle: { width: "100%", color: "#F39800" } }, [
                _vm._v(
                  "累计奖励：" + _vm._s(_vm.detail.total_award_amount) + "喜分"
                )
              ]),
              _c("van-divider"),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-around"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "cell-tg",
                      staticStyle: { "font-weight": "bold" }
                    },
                    [_vm._v("奖励日期")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "cell-tg",
                      staticStyle: { "font-weight": "bold" }
                    },
                    [_vm._v("奖励喜分")]
                  )
                ]
              ),
              _vm._l(_vm.detail.award_amount_list, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-around",
                      padding: "10px 0px"
                    }
                  },
                  [
                    _c("div", { staticClass: "cell-tg" }, [
                      _vm._v(_vm._s(item.date))
                    ]),
                    _c("div", { staticClass: "cell-tg" }, [
                      _vm._v(_vm._s(item.award_amount))
                    ])
                  ]
                )
              })
            ],
            2
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "30px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/national202210/t_h_0.png")
                }
              })
            ],
            1
          ),
          _vm._m(0)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "30px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/national202210/t_h_1.png")
                }
              })
            ],
            1
          ),
          _vm._m(1)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "30px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/national202210/t_h_2.png")
                }
              })
            ],
            1
          ),
          _vm._m(2)
        ]
      ),
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/national202210/bottom.png")
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: { padding: "80px 23px 30px 23px", "line-height": "180%" }
      },
      [
        _c("div", { staticStyle: { color: "#38404E" } }, [
          _vm._v(
            " 本次活动以单日累计悬壶值为依据，活动期间根据当日累计悬壶值所达到的档位发放喜分补贴，每日奖励喜分将累计，至11.3日统一发放。奖励标准如下："
          ),
          _c("br")
        ]),
        _c(
          "table",
          {
            staticClass: "tg",
            staticStyle: { width: "100%", margin: "10px 0" }
          },
          [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "tg-uc1r" }, [
                  _vm._v("单日悬壶值目标")
                ]),
                _c("th", { staticClass: "tg-uc1r" }, [_vm._v("喜分发放比例")])
              ])
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("10000悬壶值")]),
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("10%")])
              ]),
              _c("tr", [
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("5000悬壶值")]),
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("8%")])
              ]),
              _c("tr", [
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("2000悬壶值")]),
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("7%")])
              ]),
              _c("tr", [
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("1500悬壶值")]),
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("6%")])
              ]),
              _c("tr", [
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("1200悬壶值")]),
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("5%")])
              ]),
              _c("tr", [
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("1000悬壶值")]),
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("4%")])
              ]),
              _c("tr", [
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("700悬壶值")]),
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("3.5%")])
              ]),
              _c("tr", [
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("500悬壶值")]),
                _c("td", { staticClass: "tg-zs29" }, [_vm._v("3%")])
              ])
            ])
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { padding: "80px 23px 0px 23px", width: "100%" } },
      [
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v("Q:悬壶值怎么获得？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [
            _vm._v(
              "A: 医师开具处方且该笔处方完成支付后，对应订单的基础药费将转换为等量悬壶值。"
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v(" Q:若有退货情况怎么办？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [_vm._v("A: 若有退货情况，悬壶值将退回，且不计入活动统计。")]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v("Q:奖励什么时候到账?")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [_vm._v("A: 奖励将至11月3日统一发放。")]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v(" Q：开具单品订单能参与活动吗？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [
            _vm._v(
              "A：不能，只有开具颗粒剂型、膏方的处方悬壶值会计入活动奖励金额，单品处方均不计入此项活动。"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "80px 23px 20px 23px",
          color: "#38404E",
          "line-height": "180%"
        }
      },
      [
        _vm._v(
          " 1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(" 3、本次活动最终解释权归喜郎中所有。 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }