var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg" }, [
    _c(
      "div",
      {
        staticStyle: {
          "font-size": "20px",
          "margin-top": "10px",
          padding: "50px 0 20px 0",
          "font-weight": "bold",
          "text-align": "center"
        }
      },
      [_vm._v("提交成功")]
    ),
    _c("div", { staticStyle: { padding: "10px 20px" } }, [
      _vm._v(
        "非常感谢您的支持，您回答所提供的信息对我们今后的工作非常有价值！我们将会把相关信息进行综合分析，以后改进为您提供更好的服务！"
      )
    ]),
    _c(
      "div",
      {
        staticStyle: {
          margin: "16px",
          "padding-top": "50px",
          "align-items": "center",
          display: "flex",
          "justify-content": "center",
          "flex-direction": "column"
        }
      },
      [
        _vm.isShowBuild
          ? _c(
              "van-button",
              {
                staticClass: "payButton",
                attrs: { block: "" },
                on: { click: _vm.closeAction }
              },
              [_vm._v("回到APP首页")]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "20px",
              color: "#ff9800",
              "text-decoration": "underline"
            },
            on: { click: _vm.popPageAction }
          },
          [_vm._v("查看已提交的问卷")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }