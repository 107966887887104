"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "loginInteractor", {
  enumerable: true,
  get: function get() {
    return _loginInteractor.default;
  }
});
Object.defineProperty(exports, "indexInteractor", {
  enumerable: true,
  get: function get() {
    return _indexInteractor.default;
  }
});
Object.defineProperty(exports, "doctorInteractor", {
  enumerable: true,
  get: function get() {
    return _doctorInteractor.default;
  }
});
Object.defineProperty(exports, "mallInteractor", {
  enumerable: true,
  get: function get() {
    return _mallInteractor.default;
  }
});
Object.defineProperty(exports, "communityInteractor", {
  enumerable: true,
  get: function get() {
    return _communityInteractor.default;
  }
});

var _loginInteractor = _interopRequireDefault(require("./interactors/login-interactor"));

var _indexInteractor = _interopRequireDefault(require("./interactors/index-interactor"));

var _doctorInteractor = _interopRequireDefault(require("./interactors/doctor-interactor"));

var _mallInteractor = _interopRequireDefault(require("./interactors/mall-interactor"));

var _communityInteractor = _interopRequireDefault(require("./interactors/community-interactor"));