var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg" },
    [
      _vm.detail
        ? _c("div", { staticStyle: { "background-color": "white" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "20px",
                  padding: "30px",
                  "font-weight": "bold",
                  "text-align": "center",
                  color: "#ff9800"
                }
              },
              [_vm._v(_vm._s(_vm.detail.name))]
            ),
            _c("div", {
              staticStyle: { padding: "0 20px 30px 20px" },
              domProps: { innerHTML: _vm._s(_vm.detail.description) }
            })
          ])
        : _vm._e(),
      _vm.detail && _vm.detail.answer_info
        ? _c(
            "van-cell-group",
            _vm._l(_vm.detail.answer_info, function(item, index) {
              return _c("van-cell", {
                staticStyle: {
                  "font-size": "16px",
                  "font-weight": "bold",
                  color: "#646566"
                },
                attrs: {
                  title: index + 1 + ". " + item.title,
                  label: item.content,
                  "label-class": "form-label-class"
                }
              })
            }),
            1
          )
        : _vm._e(),
      _vm.detail && _vm.detail.answer_info
        ? _c("div", { staticStyle: { height: "60px" } })
        : _vm._e(),
      _c(
        "van-form",
        { on: { submit: _vm.onSubmit } },
        [
          _vm._l(_vm.detail.question_info, function(item, index) {
            return _vm.detail && _vm.detail.question_info
              ? _c(
                  "div",
                  [
                    item.item_type == 1
                      ? _c("van-field", {
                          staticStyle: { "font-size": "16px" },
                          attrs: {
                            autosize: "",
                            label: item.title,
                            type: "textarea",
                            placeholder: "请填写",
                            "show-word-limit": "",
                            maxlength: "200",
                            required: item.required == 1,
                            rules: [
                              {
                                required: item.required == 1,
                                message: "这道题未回答"
                              }
                            ]
                          },
                          model: {
                            value: _vm.form.a[index],
                            callback: function($$v) {
                              _vm.$set(_vm.form.a, index, $$v)
                            },
                            expression: "form.a[index]"
                          }
                        })
                      : _vm._e(),
                    item.item_type == 2
                      ? _c("van-field", {
                          staticStyle: { "font-size": "16px" },
                          attrs: {
                            name: "radio",
                            label: item.title,
                            required: item.required == 1,
                            rules: [
                              {
                                required: item.required == 1,
                                message: "这道题未回答"
                              }
                            ]
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "input",
                                fn: function() {
                                  return [
                                    _c(
                                      "van-radio-group",
                                      {
                                        model: {
                                          value: _vm.form.a[index],
                                          callback: function($$v) {
                                            _vm.$set(_vm.form.a, index, $$v)
                                          },
                                          expression: "form.a[index]"
                                        }
                                      },
                                      _vm._l(item.content, function(ri) {
                                        return _c(
                                          "van-radio",
                                          {
                                            staticStyle: { padding: "5px 0px" },
                                            attrs: {
                                              "checked-color": "#ff9800",
                                              name: ri.value
                                            }
                                          },
                                          [_vm._v(_vm._s(ri.value))]
                                        )
                                      }),
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    item.item_type == 3
                      ? _c("van-field", {
                          staticStyle: { "font-size": "16px" },
                          attrs: {
                            name: "radio",
                            required: item.required == 1,
                            rules: [
                              {
                                required: item.required == 1,
                                message: "这道题未回答"
                              }
                            ]
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "align-items": "center"
                                            }
                                          },
                                          [
                                            _vm._v(_vm._s(item.title) + " "),
                                            _c(
                                              "van-tag",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                  "margin-bottom": "6px"
                                                },
                                                attrs: { type: "primary" }
                                              },
                                              [_vm._v("多选")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "input",
                                fn: function() {
                                  return [
                                    _c(
                                      "van-checkbox-group",
                                      {
                                        model: {
                                          value: _vm.form.a[index],
                                          callback: function($$v) {
                                            _vm.$set(_vm.form.a, index, $$v)
                                          },
                                          expression: "form.a[index]"
                                        }
                                      },
                                      _vm._l(item.content, function(ri) {
                                        return _c(
                                          "van-checkbox",
                                          {
                                            staticStyle: { padding: "5px 0px" },
                                            attrs: {
                                              shape: "square",
                                              "checked-color": "#ff9800",
                                              name: ri.value
                                            }
                                          },
                                          [_vm._v(_vm._s(ri.value))]
                                        )
                                      }),
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          }),
          _vm.detail && _vm.detail.question_info
            ? _c(
                "div",
                {
                  staticStyle: {
                    margin: "16px",
                    "padding-bottom": "30px",
                    "align-items": "center",
                    display: "flex",
                    "justify-content": "center"
                  }
                },
                [
                  _c(
                    "van-button",
                    {
                      staticClass: "payButton",
                      attrs: { round: "", block: "", "native-type": "submit" }
                    },
                    [_vm._v("提交问卷")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }