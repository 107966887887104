var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#fffbf5" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/share202207/banner.png")
        }
      }),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            "flex-direction": "column",
            display: "flex",
            "justify-content": "center",
            background: "#FFF7EB",
            border: "1px solid #F49F12",
            "border-radius": "10px",
            "line-height": "150%"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "sub-title",
              staticStyle: { color: "#484540", padding: "27px 16px" }
            },
            [
              _vm._v(
                "活动期间，医生可分享系统推荐的协定方到个人主页，或保存协定方二维码链接发送至朋友圈由患者自主下单，成功购买系统推荐协定方即可领取补贴"
              )
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "14px",
                "text-align": "center",
                "font-weight": "500",
                color: "#F39800",
                "line-height": "30px"
              },
              on: {
                click: function($event) {
                  return _vm.jumpTo(_vm.hgDetail.accord_share_url)
                }
              }
            },
            [_vm._v("立即分享协定方")]
          ),
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "14px",
                "text-align": "center",
                "font-weight": "500",
                color: "#F39800",
                "line-height": "30px",
                padding: "0 0 20px 0"
              },
              on: {
                click: function($event) {
                  return _vm.jumpTo(_vm.hgDetail.accord_set_url)
                }
              }
            },
            [_vm._v("设置协定方到我的主页")]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "padding-bottom": "30px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "50%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/share202207/s_h_0.png")
                }
              })
            ],
            1
          )
        ]
      ),
      _c("div", { staticStyle: { margin: "70px 20px 0 30px" } }, [
        _vm._v("已购买协定方数："),
        _c("span", { staticStyle: { color: "#F39800" } }, [
          _vm._v(_vm._s(_vm.hgDetail.count))
        ]),
        _vm._v("单")
      ]),
      _vm.hgDetail.list.length != 0
        ? _c(
            "div",
            {
              staticStyle: {
                "margin-top": "30px",
                "margin-bottom": "30px",
                padding: "0 10%"
              }
            },
            _vm._l(_vm.hgDetail.list, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "scroll-item",
                  staticStyle: { width: "50%" }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        height: "120px"
                      }
                    },
                    [
                      item.status > 0
                        ? _c("div", { staticClass: "red-step-line" })
                        : _c("div", { staticClass: "red-step-line-gray" }),
                      _c(
                        "div",
                        { staticStyle: { width: "90px", height: "120px" } },
                        [
                          _c(
                            "div",
                            {
                              style: {
                                color: item.status == 0 ? "#B9B9B9" : "#F39800",
                                "font-size": "14px",
                                "font-weight": "bold",
                                padding: "5px 0px"
                              }
                            },
                            [_vm._v(_vm._s(index == 0 ? "20" : "150") + "喜分")]
                          ),
                          item.status > 0
                            ? _c("van-image", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  fit: "fill",
                                  src: require("../../assets/activity/return202206/w_r_r_1.png")
                                }
                              })
                            : _c("van-image", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  fit: "fill",
                                  src: require("../../assets/activity/return202206/w_r_r_0.png")
                                }
                              }),
                          _c(
                            "div",
                            {
                              style: {
                                color: item.status == 0 ? "#B9B9B9" : "#F39800",
                                "font-size": "14px",
                                padding: "5px 0px"
                              }
                            },
                            [_vm._v(_vm._s(index == 0 ? "首" : "21") + "单")]
                          ),
                          _c(
                            "div",
                            [
                              item.status == 0
                                ? _c("van-image", {
                                    attrs: {
                                      src: require("../../assets/activity/return202206/w_r_b_0.png")
                                    }
                                  })
                                : _vm._e(),
                              item.status == 1
                                ? _c("van-image", {
                                    attrs: {
                                      src: require("../../assets/activity/return202206/w_r_b_1.png")
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.receiveAction(item, index)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              item.status == 2
                                ? _c("van-image", {
                                    attrs: {
                                      src: require("../../assets/activity/return202206/w_r_b_2.png")
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      item.status > 0
                        ? _c("div", { staticClass: "red-step-line" })
                        : _c("div", { staticClass: "red-step-line-gray" })
                    ]
                  )
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "80px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "50%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/share202207/s_h_1.png")
                }
              })
            ],
            1
          ),
          _vm._m(0)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "50%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/share202207/s_h_2.png")
                }
              })
            ],
            1
          ),
          _vm._m(1)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "50%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/share202207/s_h_3.png")
                }
              })
            ],
            1
          ),
          _vm._m(2)
        ]
      ),
      _c("div", { staticStyle: { height: "60px" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: { padding: "110px 23px 0px 23px", "line-height": "180%" }
      },
      [
        _c("div", { staticStyle: { color: "#38404E" } }, [
          _c("span", { staticStyle: { color: "#ff9000" } }, [
            _vm._v("规则一：")
          ]),
          _vm._v(
            "医生首次推荐患者成功购买系统推荐协定方案,可获得额外20喜分补贴 "
          ),
          _c("br"),
          _c("br"),
          _c("span", { staticStyle: { color: "#ff9000" } }, [
            _vm._v("规则二：")
          ]),
          _vm._v(
            "除首单外，医生推荐患者成功购买系统推荐协定方案超过20单可获得额外补贴150喜分（基础药费金额大于100元的协定方订单纳入统计）"
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "100px 23px 0px 23px" } }, [
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
        [_vm._v("Q：补贴什么时候能到账？")]
      ),
      _c(
        "div",
        {
          staticClass: "sub-title",
          staticStyle: { color: "#38404E", "line-height": "150%" }
        },
        [_vm._v("A：处方成功发货后点击领取按钮即可获取补贴。")]
      ),
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
        [_vm._v(" Q：补贴处方计入规则？ ")]
      ),
      _c(
        "div",
        {
          staticClass: "sub-title",
          staticStyle: { color: "#38404E", "line-height": "150%" }
        },
        [
          _vm._v(
            "A：首单协定方发货即计入统计，剩余20单基础药费大于100元的协定方订单纳入统计。"
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "100px 23px 20px 23px",
          color: "#38404E",
          "line-height": "180%"
        }
      },
      [
        _vm._v(
          " 1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(" 3、本次活动最终解释权归喜郎中所有。 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }