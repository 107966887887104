var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "flex-direction": "column" } },
    [
      _vm.dialogShow
        ? _c("div", { staticClass: "dialog-container" })
        : _vm._e(),
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          { staticStyle: { flex: "0 8 800px" } },
          [
            _c(
              "van-dropdown-menu",
              { attrs: { "active-color": "#ff9000" } },
              [
                _c("van-dropdown-item", {
                  attrs: { options: _vm.option1 },
                  model: {
                    value: _vm.category_id,
                    callback: function($$v) {
                      _vm.category_id = $$v
                    },
                    expression: "category_id"
                  }
                }),
                _c("van-dropdown-item", {
                  attrs: { options: _vm.option2 },
                  model: {
                    value: _vm.user_show,
                    callback: function($$v) {
                      _vm.user_show = $$v
                    },
                    expression: "user_show"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              flex: "0 1 100px",
              "font-size": "14px",
              "background-color": "white",
              "text-align": "center",
              "line-height": "48px",
              color: "#ff9000"
            },
            on: { click: _vm.jumpToScanCode }
          },
          [_vm._v("电脑上编辑")]
        )
      ]),
      _c("van-field", {
        staticClass: "field-text",
        attrs: { size: "large", placeholder: "请输入标题" },
        model: {
          value: _vm.title,
          callback: function($$v) {
            _vm.title = $$v
          },
          expression: "title"
        }
      }),
      _c(
        "div",
        { staticStyle: { "background-color": "white" } },
        [
          _c("vue-editor", {
            staticStyle: { height: "60vh" },
            attrs: {
              id: "editor",
              placeholder: "请输入正文内容",
              editorToolbar: _vm.customToolbar,
              useCustomImageHandler: ""
            },
            on: { imageAdded: _vm.handleImageAdded },
            model: {
              value: _vm.content,
              callback: function($$v) {
                _vm.content = $$v
              },
              expression: "content"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "80px", padding: "0 10px" } },
        [
          _c(
            "van-button",
            {
              attrs: { "text-color": "white", color: "#ff9000", size: "large" },
              on: {
                click: function($event) {
                  return _vm.fetchSetPost()
                }
              }
            },
            [_vm._v("发布")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }