var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-tabs",
        {
          attrs: { color: "#ff9000" },
          on: { click: _vm.tabsOnClick },
          scopedSlots: _vm._u([
            {
              key: "nav-right",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                        width: "40px"
                      }
                    },
                    [
                      _c("van-icon", {
                        attrs: { name: "search", color: "#F7941E", size: "20" },
                        on: { click: _vm.onClickSearch }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.tabsActive,
            callback: function($$v) {
              _vm.tabsActive = $$v
            },
            expression: "tabsActive"
          }
        },
        _vm._l(_vm.tabs, function(tabItem) {
          return _c(
            "van-tab",
            { attrs: { title: tabItem.title, name: tabItem.name } },
            [
              _c(
                "van-pull-refresh",
                {
                  on: { refresh: _vm.onRefresh },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                [
                  _c(
                    "van-list",
                    {
                      attrs: {
                        finished: _vm.finished,
                        "finished-text": "没有更多了",
                        offset: "150"
                      },
                      on: { load: _vm.onLoad },
                      model: {
                        value: _vm.loading,
                        callback: function($$v) {
                          _vm.loading = $$v
                        },
                        expression: "loading"
                      }
                    },
                    _vm._l(_vm.list, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "cell",
                          on: {
                            click: function($event) {
                              return _vm.clickCell(item)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "space-between"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("van-image", {
                                    staticClass: "avatar-img",
                                    attrs: {
                                      width: "40px",
                                      height: "40px",
                                      src: item.doctor_url
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.clickAvatar(item.doctor_id)
                                      }
                                    }
                                  }),
                                  _c("div", [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(_vm._s(item.doctor_name))
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "row",
                                          "padding-left": "5px"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              color: "#A4A4A4",
                                              "line-height": "22px"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.diff_for_humans))]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              color: "#A4A4A4",
                                              "line-height": "22px",
                                              "padding-left": "5px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.click_count) + "阅读"
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c("van-icon", {
                                attrs: {
                                  name: require("../../assets/community/three-point_1.png"),
                                  size: "20"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.threePoitAction(item)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(item.title))
                          ]),
                          _c("div", { staticClass: "card-des" }, [
                            _vm._v(_vm._s(item.strip_tag_content))
                          ]),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(item.img_info, function(img) {
                              return _c(
                                "div",
                                { staticStyle: { padding: "4px 4px 8px 0" } },
                                [
                                  _c("van-image", {
                                    staticClass: "avatar-img",
                                    attrs: {
                                      fit: "contain",
                                      width: "60px",
                                      height: "60px",
                                      src: img
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(item.post_tags, function(itemTag) {
                              return _c(
                                "div",
                                { staticStyle: { padding: "10px 4px 8px 0" } },
                                [
                                  _c(
                                    "van-tag",
                                    {
                                      attrs: {
                                        color: "#E9FFE7",
                                        "text-color": "#41DC33"
                                      }
                                    },
                                    [_vm._v(_vm._s(itemTag.name))]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "0 20px",
                                display: "flex",
                                "flex-direction": "row",
                                "justify-content": "space-between"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.shareAction(item)
                                    }
                                  }
                                },
                                [
                                  _c("van-icon", {
                                    attrs: {
                                      name: require("../../assets/community/share.png"),
                                      size: "20"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#707270",
                                        padding: "0 6px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.share_count))]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("van-icon", {
                                    attrs: {
                                      name: require("../../assets/community/reply.png"),
                                      size: "20"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#707270",
                                        padding: "0 6px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.reply_count))]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.fetchToggleVote(item)
                                    }
                                  }
                                },
                                [
                                  _c("van-icon", {
                                    attrs: {
                                      name: require("../../assets/community/click_" +
                                        (item.is_voted ? 1 : 0) +
                                        ".png"),
                                      size: "20"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#707270",
                                        padding: "0 6px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.vote_count))]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c("van-action-sheet", {
        attrs: { actions: _vm.actions },
        on: { select: _vm.actionSheetOnSelect },
        model: {
          value: _vm.actionSheetShow,
          callback: function($$v) {
            _vm.actionSheetShow = $$v
          },
          expression: "actionSheetShow"
        }
      }),
      _c(
        "footer",
        {
          staticStyle: {
            width: "100%",
            position: "fixed",
            bottom: "50px",
            right: "30px",
            height: "60px"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                height: "60px",
                width: "100vw",
                display: "flex",
                "flex-direction": "row",
                "justify-content": "space-between",
                "padding-right": "10px",
                right: "0"
              }
            },
            [
              _c("div"),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-direction": "row" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-right": "30px",
                        background:
                          "linear-gradient(0deg, #F48600 3%, #FAAE2D 98%)",
                        width: "50px",
                        height: "50px",
                        "align-items": "center",
                        display: "flex",
                        "justify-content": "center",
                        "border-radius": "25px"
                      },
                      on: {
                        click: function($event) {
                          return _vm.jumpToMy()
                        }
                      }
                    },
                    [
                      _c(
                        "van-badge",
                        { attrs: { dot: _vm.detail.news_notice > 0 } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "align-items": "center",
                                display: "flex",
                                "flex-direction": "column",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c("van-icon", {
                                attrs: {
                                  name: require("../../assets/community/i_0.png"),
                                  size: "20",
                                  color: "white"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "white",
                                    "font-size": "10px",
                                    "padding-top": "4px"
                                  }
                                },
                                [_vm._v("我的")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        background:
                          "linear-gradient(0deg, #F48600 3%, #FAAE2D 98%)",
                        width: "50px",
                        height: "50px",
                        "align-items": "center",
                        display: "flex",
                        "justify-content": "center",
                        "border-radius": "25px"
                      },
                      on: {
                        click: function($event) {
                          return _vm.jumpToEditPost()
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "align-items": "center",
                            display: "flex",
                            "flex-direction": "column",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c("van-icon", {
                            attrs: {
                              name: require("../../assets/community/i_1.png"),
                              size: "20",
                              color: "white"
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "white",
                                "font-size": "10px",
                                "padding-top": "4px"
                              }
                            },
                            [_vm._v("发帖")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }