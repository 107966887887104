import { render, staticRenderFns } from "./mallMyCard.vue?vue&type=template&id=79d64297&scoped=true&"
import script from "./mallMyCard.vue?vue&type=script&lang=js&"
export * from "./mallMyCard.vue?vue&type=script&lang=js&"
import style0 from "./mallMyCard.vue?vue&type=style&index=0&id=79d64297&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d64297",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79d64297')) {
      api.createRecord('79d64297', component.options)
    } else {
      api.reload('79d64297', component.options)
    }
    module.hot.accept("./mallMyCard.vue?vue&type=template&id=79d64297&scoped=true&", function () {
      api.rerender('79d64297', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/mall/mallMyCard.vue"
export default component.exports