"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AllSettings',
  props: {},
  data: function data() {
    return {
      gbDetail: undefined
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityGBDetail();
  },
  methods: {
    fetchActivityGBDetail: function fetchActivityGBDetail() {
      var _this = this;

      _core.mallInteractor.fetchActivityGBDetail().then(function (data) {
        _this.gbDetail = data;
      });
    }
  }
};
exports.default = _default;