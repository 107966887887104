var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c(
            "van-cell-group",
            { staticClass: "cell-group" },
            [
              _c("van-field", {
                attrs: {
                  required: "",
                  label: "收货人",
                  type: "name",
                  name: "name",
                  placeholder: "请输入收货人名字",
                  "input-align": "right",
                  border: false,
                  rules: [
                    {
                      pattern: _vm.patternName,
                      required: true,
                      message: "请输入真实姓名"
                    }
                  ]
                },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              }),
              _c("van-field", {
                attrs: {
                  required: "",
                  clearable: "",
                  label: "手机号码",
                  type: "number",
                  "input-align": "right",
                  maxlength: "11",
                  placeholder: "请输入手机号码",
                  name: "mobile",
                  rules: [
                    { pattern: _vm.pattern, message: "请输入正确的手机号码" }
                  ]
                },
                model: {
                  value: _vm.phone,
                  callback: function($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone"
                }
              }),
              _c("van-cell", {
                attrs: {
                  "value-class": "region-value",
                  required: "",
                  name: "region",
                  value: _vm.region,
                  "is-link": ""
                },
                on: {
                  click: function($event) {
                    _vm.showArea = true
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("span", { staticClass: "custom-title" }, [
                          _vm._v("地区")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c(
                "van-popup",
                {
                  attrs: { position: "bottom" },
                  model: {
                    value: _vm.showArea,
                    callback: function($$v) {
                      _vm.showArea = $$v
                    },
                    expression: "showArea"
                  }
                },
                [
                  _c("van-picker", {
                    ref: "area",
                    attrs: {
                      "show-toolbar": "",
                      title: "选择地区",
                      columns: _vm.areaList,
                      "value-key": "name",
                      loading: _vm.loading
                    },
                    on: {
                      cancel: function($event) {
                        _vm.showArea = false
                      },
                      confirm: _vm.onConfirm
                    }
                  })
                ],
                1
              ),
              _c("van-field", {
                attrs: {
                  required: "",
                  label: "详细地址",
                  type: "text",
                  name: "detail",
                  "input-align": "right",
                  placeholder: "例：xx街道x号楼xx室",
                  border: false,
                  rules: [{ required: true, message: "详细地址" }]
                },
                model: {
                  value: _vm.detail,
                  callback: function($$v) {
                    _vm.detail = $$v
                  },
                  expression: "detail"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "16px" } },
            [
              _c(
                "van-button",
                {
                  staticClass: "s-button",
                  attrs: { round: "", block: "", "native-type": "submit" }
                },
                [_vm._v(" 提交 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "16px" } },
        [
          _vm.id
            ? _c(
                "van-button",
                {
                  attrs: { round: "", block: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.deleteAddress($event)
                    }
                  }
                },
                [_vm._v(" 删除地址 ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }