var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#CD2611" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/fourActivity/banner.jpg")
        }
      }),
      _c(
        "van-tabs",
        {
          staticStyle: { "margin-top": "-5px" },
          attrs: {
            scrollspy: "",
            sticky: "",
            type: "card",
            background: "linear-gradient(0deg, #FF7041 0%, #DC2622 100%)",
            color: "#FF7041",
            "title-inactive-color": "#FFF9A7"
          }
        },
        [
          _c(
            "van-tab",
            {
              staticStyle: {
                background: "linear-gradient(0deg, #CD2611 0%, #FE6F41 100%)",
                "padding-top": "10px"
              },
              attrs: { title: "加微好礼" }
            },
            [
              _vm.detail
                ? _c(
                    "div",
                    {
                      staticClass: "card",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "image-title" },
                        [
                          _c("van-image", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              fit: "contain",
                              src: require("../../assets/activity/fourActivity/h_t_0.png")
                            }
                          }),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.detail.step_1_data.step_1_status == 0
                                  ? "第一重礼: 认证有好礼"
                                  : "第一重礼:加V赠好礼"
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.detail
                        ? _c("div", { staticClass: "inner-card" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  width: "100%",
                                  "margin-top": "20px"
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: { width: "50%" },
                                  attrs: {
                                    fit: "contain",
                                    src: require("../../assets/activity/fourActivity/h_i_0.png")
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticStyle: { margin: "30px 20px" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "16px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("活动规则:")]
                                ),
                                _vm.detail.step_1_data.step_1_status == 0
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-top": "16px",
                                          "font-size": "16px",
                                          "line-height": "180%"
                                        }
                                      },
                                      [
                                        _vm._v("1、认证成功后，立即获得 "),
                                        _c(
                                          "span",
                                          { staticClass: "color-span" },
                                          [_vm._v("10喜分")]
                                        ),
                                        _vm._v(" (系统自动发) "),
                                        _c("br"),
                                        _vm._v(
                                          "2、截图保存二维码，添加客服微信，登记收货信息，领取 "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "color-span" },
                                          [_vm._v("手机支架")]
                                        ),
                                        _vm._v(" 一个。")
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.detail.step_1_data.step_1_status == 1
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-top": "16px",
                                          "font-size": "16px",
                                          "line-height": "180%"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "截图保存二维码，添加客服微信，登记收货信息，领取"
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "color-span" },
                                          [_vm._v("手机支架")]
                                        ),
                                        _vm._v("一个。")
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]
          ),
          _c("van-tab", { attrs: { title: "开方好礼" } }, [
            _c(
              "div",
              {
                staticClass: "card",
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "50px"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "image-title" },
                  [
                    _c("van-image", {
                      staticStyle: { width: "70%" },
                      attrs: {
                        fit: "contain",
                        src: require("../../assets/activity/fourActivity/h_t_0.png")
                      }
                    }),
                    _c("div", { staticClass: "title" }, [
                      _vm._v("第二重礼: 开方有好礼")
                    ])
                  ],
                  1
                ),
                _vm.detail
                  ? _c("div", { staticClass: "inner-card" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            width: "100%",
                            "margin-top": "20px",
                            padding: "0 5px"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-evenly"
                              }
                            },
                            _vm._l(
                              _vm.detail.step_2_data.list.slice(0, 3),
                              function(item) {
                                return _c(
                                  "div",
                                  {
                                    class:
                                      "second-card second-card-color-" +
                                      item.status
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          "second-card-title-" + item.status,
                                        staticStyle: { "font-size": "13px" }
                                      },
                                      [_vm._v(" 第" + _vm._s(item.day) + "天 ")]
                                    ),
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "30px",
                                        margin: "5px"
                                      },
                                      attrs: {
                                        fit: "contain",
                                        src: require("../../assets/activity/fourActivity/c_" +
                                          item.status +
                                          ".png")
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        class:
                                          "second-card-title-" + item.status,
                                        staticStyle: { "font-size": "13px" }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.income) + "喜分 "
                                        )
                                      ]
                                    ),
                                    item.status != 1
                                      ? _c(
                                          "div",
                                          {
                                            style: {
                                              fontSize: "12px",
                                              color:
                                                item.status == 2
                                                  ? "#cccccc"
                                                  : "#D29F39",
                                              marginTop: "4px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " 悬壶值满" +
                                                _vm._s(item.amount) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            width: "100%",
                            "margin-top": "2%",
                            padding: "0 5px"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-evenly"
                              }
                            },
                            _vm._l(
                              _vm.detail.step_2_data.list.slice(3, 6),
                              function(item) {
                                return _c(
                                  "div",
                                  {
                                    class:
                                      "second-card second-card-color-" +
                                      item.status
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          "second-card-title-" + item.status,
                                        staticStyle: { "font-size": "13px" }
                                      },
                                      [_vm._v(" 第" + _vm._s(item.day) + "天 ")]
                                    ),
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "30px",
                                        margin: "5px"
                                      },
                                      attrs: {
                                        fit: "contain",
                                        src: require("../../assets/activity/fourActivity/c_" +
                                          item.status +
                                          ".png")
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        class:
                                          "second-card-title-" + item.status,
                                        staticStyle: { "font-size": "13px" }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.income) + "喜分 "
                                        )
                                      ]
                                    ),
                                    item.status != 1
                                      ? _c(
                                          "div",
                                          {
                                            style: {
                                              fontSize: "12px",
                                              color:
                                                item.status == 2
                                                  ? "#cccccc"
                                                  : "#D29F39",
                                              marginTop: "4px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " 悬壶值满" +
                                                _vm._s(item.amount) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            width: "100%",
                            "margin-top": "2%",
                            padding: "0 5px"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-evenly"
                              }
                            },
                            _vm._l(
                              _vm.detail.step_2_data.list.slice(6, 7),
                              function(item, index) {
                                return _c(
                                  "div",
                                  {
                                    class:
                                      "second-card second-card-color-" +
                                      item.status,
                                    style: { width: "95%" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          "second-card-title-" + item.status,
                                        staticStyle: { "font-size": "13px" }
                                      },
                                      [_vm._v(" 第" + _vm._s(item.day) + "天 ")]
                                    ),
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "30px",
                                        margin: "5px"
                                      },
                                      attrs: {
                                        fit: "contain",
                                        src: require("../../assets/activity/fourActivity/c_" +
                                          item.status +
                                          ".png")
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        class:
                                          "second-card-title-" + item.status,
                                        staticStyle: { "font-size": "13px" }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.income) + "喜分 "
                                        )
                                      ]
                                    ),
                                    item.status != 1
                                      ? _c(
                                          "div",
                                          {
                                            style: {
                                              fontSize: "12px",
                                              color:
                                                item.status == 2
                                                  ? "#cccccc"
                                                  : "#D29F39",
                                              marginTop: "4px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " 悬壶值满" +
                                                _vm._s(item.amount) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        ]
                      ),
                      _c("div", { staticStyle: { margin: "30px 20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v("活动规则:")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "16px",
                              "font-size": "16px",
                              "line-height": "180%"
                            }
                          },
                          [
                            _vm._v(
                              "活动期间，自完成首单起连续七天内，每日单笔订单悬壶值达到门槛后都可以获得一次补贴奖励。（订单发货后系统自动发放奖励）"
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "8px",
                              "font-size": "14px",
                              color: "#F33224"
                            }
                          },
                          [_vm._v("*订单发生退款将扣除对应的奖励")]
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            )
          ]),
          _c("van-tab", { attrs: { title: "开方升级" } }, [
            _c(
              "div",
              {
                staticClass: "card",
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "50px"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "image-title" },
                  [
                    _c("van-image", {
                      staticStyle: { width: "70%" },
                      attrs: {
                        fit: "contain",
                        src: require("../../assets/activity/fourActivity/h_t_0.png")
                      }
                    }),
                    _c("div", { staticClass: "title" }, [
                      _vm._v("第三重礼：开方升级好礼")
                    ])
                  ],
                  1
                ),
                _vm.detail
                  ? _c("div", { staticClass: "inner-card" }, [
                      _c(
                        "div",
                        {
                          staticStyle: { padding: "20px", "font-size": "16px" }
                        },
                        [
                          _vm._v("当前累计悬壶值 "),
                          _c(
                            "span",
                            {
                              staticClass: "color-span",
                              staticStyle: {
                                "font-size": "16px",
                                "font-weight": "bold"
                              }
                            },
                            [_vm._v(_vm._s(_vm.detail.step_3_data.amount))]
                          ),
                          _vm._v("， "),
                          _vm.detail.step_3_data.rate == 0
                            ? _c("span", [
                                _vm._v("还需"),
                                _c(
                                  "span",
                                  {
                                    staticClass: "color-span",
                                    staticStyle: {
                                      "font-size": "16px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.detail.step_3_data
                                          .need_to_next_amount
                                      )
                                    )
                                  ]
                                ),
                                _vm._v("可解锁补贴奖励")
                              ])
                            : _c("span", [
                                _vm._v("可领取悬壶值"),
                                _c(
                                  "span",
                                  {
                                    staticClass: "color-span",
                                    staticStyle: {
                                      "font-size": "16px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.detail.step_3_data.rate) + "%"
                                    )
                                  ]
                                ),
                                _vm._v("补贴奖励")
                              ])
                        ]
                      ),
                      _c("div", { staticStyle: { width: "100%" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "6px",
                              "margin-bottom": "6px",
                              padding: "0 5%"
                            }
                          },
                          _vm._l(_vm.detail.step_3_data.list, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "scroll-item",
                                style: {
                                  width:
                                    100 / _vm.detail.step_3_data.list.length +
                                    "%"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      height: "70px"
                                    }
                                  },
                                  [
                                    item.min_amount <
                                    _vm.detail.step_3_data.amount
                                      ? _c("div", {
                                          staticClass: "red-step-line"
                                        })
                                      : _c("div", {
                                          staticClass: "red-step-line-gray"
                                        }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "16px",
                                          height: "80px",
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            style: {
                                              position: "absolute",
                                              color: "#532721",
                                              "font-size": "14px",
                                              "font-weight": "bold",
                                              marginTop: "-40px"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.rate) + "%")]
                                        ),
                                        _c("div", {
                                          style: {
                                            width: "16px",
                                            borderRadius: "50%",
                                            height: "16px",
                                            background: "white",
                                            border:
                                              item.min_amount <
                                              _vm.detail.step_3_data.amount
                                                ? "4px solid #F33123"
                                                : "4px solid #F0CAA6"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            style: {
                                              position: "absolute",
                                              color: "#532721",
                                              "font-size": "14px",
                                              marginTop: "40px"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.min_amount))]
                                        )
                                      ]
                                    ),
                                    item.min_amount <
                                    _vm.detail.step_3_data.amount
                                      ? _c("div", {
                                          staticClass: "red-step-line"
                                        })
                                      : _c("div", {
                                          staticClass: "red-step-line-gray"
                                        })
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]),
                      _vm.detail.step_3_data.step_3_button_status != 2
                        ? _c(
                            "div",
                            {
                              style: {
                                margin: "1% 5%",
                                textAlign: "center",
                                width: "90%",
                                height: "50px",
                                lineHeight: "50px",
                                borderRadius: "25px",
                                background:
                                  _vm.detail.step_3_data.step_3_button_status ==
                                    0 || _vm.detail.step_3_data.income == 0
                                    ? "#DFDFDF"
                                    : "#EC2226",
                                color: "white",
                                fontSize: "16px"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.fetchActivityFourKF202312Receive()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " 可领取奖励值" +
                                  _vm._s(_vm.detail.step_3_data.income) +
                                  "喜分 "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            {
                              style: {
                                margin: "1% 5%",
                                textAlign: "center",
                                width: "90%",
                                height: "50px",
                                lineHeight: "50px",
                                borderRadius: "25px",
                                background: "#FDF0DB",
                                color: "white",
                                fontSize: "16px"
                              }
                            },
                            [_vm._v(" 已领取 ")]
                          ),
                      _c("div", { staticStyle: { margin: "30px 20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v("活动规则:")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "16px",
                              "font-size": "16px",
                              "line-height": "180%"
                            }
                          },
                          [
                            _vm._v(
                              "活动时间范围内，自完成首单开始到活动结束期间，累计悬壶值总额达到阶段值可领取对应悬壶值百分比的补贴奖励。（活动结束后需手动领取奖励）"
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "8px",
                              "font-size": "14px",
                              color: "#F33224"
                            }
                          },
                          [_vm._v("*订单发生退款将扣除对应的奖励")]
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            )
          ]),
          _c("van-tab", { attrs: { title: "添加患者" } }, [
            _c(
              "div",
              {
                staticClass: "card",
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "50px",
                  "background-color": "#F2F2F2"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "image-title" },
                  [
                    _c("van-image", {
                      staticStyle: { width: "70%" },
                      attrs: {
                        fit: "contain",
                        src: require("../../assets/activity/fourActivity/h_t_0.png")
                      }
                    }),
                    _c("div", { staticClass: "title" }, [
                      _vm._v("第四重礼：添加患者好礼")
                    ])
                  ],
                  1
                ),
                _vm.detail
                  ? _c("div", { staticClass: "inner-card" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            width: "100%",
                            padding: "30px 20px",
                            "font-size": "12px",
                            "justify-content": "space-between"
                          }
                        },
                        [
                          _c("div", { staticStyle: { "font-size": "16px" } }, [
                            _vm._v("当前邀请患者数："),
                            _c(
                              "span",
                              {
                                staticClass: "color-span",
                                staticStyle: {
                                  "font-size": "15px",
                                  "font-weight": "bold"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.detail.step_4_data.invite_count) +
                                    "人"
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticStyle: { "font-size": "16px" } }, [
                            _vm._v("已获补贴值："),
                            _c(
                              "span",
                              {
                                staticClass: "color-span",
                                staticStyle: {
                                  "font-size": "15px",
                                  "font-weight": "bold"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.detail.step_4_data.total_income) +
                                    "喜分"
                                )
                              ]
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            margin: "0 5%",
                            width: "90%",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "tg",
                              staticStyle: { width: "100%" }
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticClass: "tg-uc1r",
                                      staticStyle: { width: "55%" }
                                    },
                                    [_vm._v("累计邀请新患者数")]
                                  ),
                                  _c("th", { staticClass: "tg-uc1r" }, [
                                    _vm._v("补贴喜分")
                                  ])
                                ])
                              ]),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", { staticClass: "tg-zs29" }, [
                                    _vm._v("5人")
                                  ]),
                                  _c("td", { staticClass: "tg-zs29" }, [
                                    _vm._v("5喜分/人")
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", { staticClass: "tg-zs29" }, [
                                    _vm._v("10人")
                                  ]),
                                  _c("td", { staticClass: "tg-zs29" }, [
                                    _vm._v("5喜分")
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", { staticClass: "tg-zs29" }, [
                                    _vm._v("15人")
                                  ]),
                                  _c("td", { staticClass: "tg-zs29" }, [
                                    _vm._v("10喜分")
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", { staticClass: "tg-zs29" }, [
                                    _vm._v("30人")
                                  ]),
                                  _c("td", { staticClass: "tg-zs29" }, [
                                    _vm._v("20喜分")
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", { staticClass: "tg-zs29" }, [
                                    _vm._v("50人")
                                  ]),
                                  _c("td", { staticClass: "tg-zs29" }, [
                                    _vm._v("100喜分")
                                  ])
                                ]),
                                _c("tr", [
                                  _c("td", { staticClass: "tg-zs29" }, [
                                    _vm._v("1000人")
                                  ]),
                                  _c("td", { staticClass: "tg-zs29" }, [
                                    _vm._v("500喜分")
                                  ])
                                ])
                              ])
                            ]
                          )
                        ]
                      ),
                      _c("div", { staticStyle: { margin: "30px 20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v("活动规则:")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "16px",
                              "font-size": "16px",
                              "line-height": "180%"
                            }
                          },
                          [
                            _vm._v(
                              "活动期间，邀请新患者关注医生，前5名每人可获得5喜分补贴，后续累计达到一定数量可获得相应喜分补贴。（系统自动发放奖励）"
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "8px",
                              "font-size": "14px",
                              color: "#F33224"
                            }
                          },
                          [_vm._v("*被邀请新患者须绑定微信才计入统计")]
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "top-image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "70%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/fourActivity/h_t_0.png")
                }
              }),
              _c("div", { staticClass: "title" }, [_vm._v("常见问题")])
            ],
            1
          ),
          _vm._m(0)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "top-image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "70%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/fourActivity/h_t_0.png")
                }
              }),
              _c("div", { staticClass: "title" }, [_vm._v("其他说明")])
            ],
            1
          ),
          _vm._m(1)
        ]
      ),
      _c(
        "div",
        { staticStyle: { width: "100%", "text-align": "center" } },
        [
          _c("van-image", {
            staticStyle: { width: "30%" },
            attrs: {
              fit: "contain",
              src: require("../../assets/activity/fourActivity/logo.png")
            }
          })
        ],
        1
      ),
      _c("div", { staticStyle: { height: "60px" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "80px 23px 20px 23px" } }, [
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#F33224" } },
        [_vm._v("Q: 悬壶值怎么获得?")]
      ),
      _c(
        "div",
        { staticClass: "sub-title", staticStyle: { color: "#38404E" } },
        [
          _vm._v(
            "A: 开具订单且该笔订单完成支付后，对应订单的基础药费将转换为等量悬壶值。"
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#F33224" } },
        [_vm._v("Q：第三重礼的奖励如何领取？")]
      ),
      _c(
        "div",
        { staticClass: "sub-title", staticStyle: { color: "#38404E" } },
        [
          _vm._v(
            "A：在2024年4月26日至2024年5月26日期间点击活动页领取按钮即可获取奖励，过期则视为自动放弃领取资格。"
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#F33224" } },
        [_vm._v("Q：是否开具任意剂型订单均可参与活动？")]
      ),
      _c(
        "div",
        { staticClass: "sub-title", staticStyle: { color: "#38404E" } },
        [_vm._v("A：不是，本次参与活动的剂型为：颗粒、膏方、饮片膏及饮片。")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "70px 23px 20px 23px",
          color: "#38404E",
          "line-height": "180%"
        }
      },
      [
        _vm._v(
          " 1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
        ),
        _c("br"),
        _vm._v(
          " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
        ),
        _c("br"),
        _vm._v(" 3、本次活动最终解释权归喜郎中所有。 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }