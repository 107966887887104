var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#4b98f4" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/chnd/ch_d_banner.png")
        }
      }),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c("van-image", {
            staticStyle: {
              width: "95%",
              "z-index": "0",
              position: "relative",
              top: "-18px"
            },
            attrs: {
              fit: "fill",
              src: require("../../assets/activity/chnd/ch_h.png")
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "card", staticStyle: { "margin-top": "0px" } }, [
        _c(
          "div",
          { staticClass: "image-title" },
          [
            _c("van-image", {
              staticStyle: { width: "48%" },
              attrs: {
                fit: "contain",
                src: require("../../assets/activity/chnd/ch_d_h_0.png")
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "card", staticStyle: { "margin-top": "20px" } },
          [
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "div",
                {
                  key: item.amount,
                  staticStyle: {
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    position: "relative"
                  }
                },
                [
                  _c("div", {
                    staticStyle: {
                      position: "absolute",
                      height: "90%",
                      width: "100%",
                      "background-color": "#F5FAFF"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "100px",
                        width: "70px",
                        "text-align": "center",
                        display: "flex",
                        "flex-direction": "column",
                        "align-items": "center",
                        "margin-left": "10px",
                        "z-index": "99"
                      }
                    },
                    [
                      _c("div", {
                        style: {
                          height: "30px",
                          width: "2px",
                          backgroundColor:
                            item.is_ongoing == 0 && item.status == 0
                              ? "#d8d8d8"
                              : "#F39800"
                        }
                      }),
                      item.is_ongoing == 0 && item.status == 0 && index == 0
                        ? _c("van-image", {
                            staticStyle: { height: "40px" },
                            attrs: {
                              fit: "contain",
                              src: require("../../assets/activity/chnd/ch_d_l_0_g.png")
                            }
                          })
                        : _vm._e(),
                      item.is_ongoing == 1 && item.status == 0 && index == 0
                        ? _c("van-image", {
                            staticStyle: { height: "40px" },
                            attrs: {
                              fit: "contain",
                              src: require("../../assets/activity/chnd/ch_d_l_0.png")
                            }
                          })
                        : _vm._e(),
                      item.status == 1 && index == 0
                        ? _c("van-image", {
                            staticStyle: { height: "40px" },
                            attrs: {
                              fit: "contain",
                              src: require("../../assets/activity/chnd/ch_d_l_0.png")
                            }
                          })
                        : _vm._e(),
                      item.is_ongoing == 0 && item.status == 0 && index == 1
                        ? _c("van-image", {
                            staticStyle: { height: "40px" },
                            attrs: {
                              fit: "contain",
                              src: require("../../assets/activity/chnd/ch_d_l_1_g.png")
                            }
                          })
                        : _vm._e(),
                      item.is_ongoing == 1 && item.status == 0 && index == 1
                        ? _c("van-image", {
                            staticStyle: { height: "40px" },
                            attrs: {
                              fit: "contain",
                              src: require("../../assets/activity/chnd/ch_d_l_1.png")
                            }
                          })
                        : _vm._e(),
                      item.status == 1 && index == 1
                        ? _c("van-image", {
                            staticStyle: { height: "40px" },
                            attrs: {
                              fit: "contain",
                              src: require("../../assets/activity/chnd/ch_d_l_1.png")
                            }
                          })
                        : _vm._e(),
                      item.is_ongoing == 0 && item.status == 0 && index == 2
                        ? _c("van-image", {
                            staticStyle: { height: "40px" },
                            attrs: {
                              fit: "contain",
                              src: require("../../assets/activity/chnd/ch_d_l_2_g.png")
                            }
                          })
                        : _vm._e(),
                      item.is_ongoing == 1 && item.status == 0 && index == 2
                        ? _c("van-image", {
                            staticStyle: { height: "40px" },
                            attrs: {
                              fit: "contain",
                              src: require("../../assets/activity/chnd/ch_d_l_2.png")
                            }
                          })
                        : _vm._e(),
                      item.status == 1 && index == 2
                        ? _c("van-image", {
                            staticStyle: { height: "40px" },
                            attrs: {
                              fit: "contain",
                              src: require("../../assets/activity/chnd/ch_d_l_2.png")
                            }
                          })
                        : _vm._e(),
                      _c("div", {
                        style: {
                          height: index == 2 ? "20px" : "30px",
                          width: "2px",
                          backgroundColor:
                            item.is_ongoing == 0 && item.status == 0
                              ? "#d8d8d8"
                              : "#F39800"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { "margin-left": "10px" },
                      on: {
                        click: function($event) {
                          return _vm.receiveAction(item)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "relative",
                            width: "70px",
                            height: "70px",
                            "margin-top": "10px",
                            "z-index": "3"
                          }
                        },
                        [
                          _c("van-image", {
                            staticStyle: {
                              width: "100%",
                              "z-index": "2",
                              height: "76px"
                            },
                            attrs: {
                              fit: "fill",
                              src:
                                item.is_ongoing == 1 || item.status == 1
                                  ? require("../../assets/activity/chnd/ma_r_0.png")
                                  : require("../../assets/activity/chnd/ma_r_1.png")
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                position: "absolute",
                                "z-index": "3",
                                top: "20%",
                                "text-align": "center",
                                width: "70px"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  style: {
                                    "line-height": "16px",
                                    color:
                                      item.is_ongoing == 1 || item.status == 1
                                        ? "#F39800"
                                        : "#D1D0D0"
                                  }
                                },
                                [
                                  _vm._v(_vm._s(item.amount)),
                                  _c("br"),
                                  _vm._v("喜分")
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "68px",
                            "text-align": "center",
                            "z-index": "99"
                          }
                        },
                        [
                          item.is_ongoing == 1 && item.status == 0
                            ? _c("van-image", {
                                staticStyle: {
                                  width: "80%",
                                  bottom: "16px",
                                  "z-index": "99"
                                },
                                attrs: {
                                  src: require("../../assets/activity/chnd/ch_d_b_i.png")
                                }
                              })
                            : _c(
                                "div",
                                [
                                  item.is_ongoing == 0 && item.status == 0
                                    ? _c("van-image", {
                                        staticStyle: {
                                          width: "80%",
                                          bottom: "16px",
                                          "z-index": "99"
                                        },
                                        attrs: {
                                          src: require("../../assets/activity/chnd/ch_d_b_0_g.png")
                                        }
                                      })
                                    : _vm._e(),
                                  item.is_received == 0 && item.status == 1
                                    ? _c("van-image", {
                                        staticStyle: {
                                          width: "80%",
                                          bottom: "16px",
                                          "z-index": "99"
                                        },
                                        attrs: {
                                          src: require("../../assets/activity/chnd/ch_d_b_0.png")
                                        }
                                      })
                                    : _vm._e(),
                                  item.is_received == 1 && item.status == 1
                                    ? _c("van-image", {
                                        staticStyle: {
                                          width: "80%",
                                          bottom: "16px",
                                          "z-index": "99"
                                        },
                                        attrs: {
                                          src: require("../../assets/activity/chnd/ch_d_b_1.png")
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "80px",
                        width: "100%",
                        "text-align": "center",
                        "margin-top": "10px",
                        "z-index": "99",
                        "line-height": "180%",
                        color: "#3C3F42"
                      }
                    },
                    [
                      item.status == 1
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "line-height": "70px",
                                width: "100%"
                              }
                            },
                            [_vm._v("活动已达成")]
                          )
                        : _vm._e(),
                      item.status == 0
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                height: "70px",
                                width: "100%",
                                display: "flex",
                                "flex-direction": "column",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              item.need_user_count_to_next !== 0
                                ? _c("div", [
                                    _vm._v("还差"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#f39800" } },
                                      [
                                        _vm._v(
                                          _vm._s(item.need_user_count_to_next)
                                        )
                                      ]
                                    ),
                                    _vm._v("位新增患者")
                                  ])
                                : _vm._e(),
                              item.need_order_count_to_next !== 0
                                ? _c("div", [
                                    _vm._v("还差"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#f39800" } },
                                      [
                                        _vm._v(
                                          _vm._s(item.need_order_count_to_next)
                                        )
                                      ]
                                    ),
                                    _vm._v("次开方")
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              )
            }),
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "16px",
                  "font-weight": "bold",
                  padding: "10px 0"
                }
              },
              [_vm._v("奖励规则:")]
            ),
            _vm._m(0)
          ],
          2
        )
      ]),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "image-title" },
          [
            _c("van-image", {
              staticStyle: { width: "48%" },
              attrs: {
                fit: "contain",
                src: require("../../assets/activity/chnd/ch_d_h_1.png")
              }
            })
          ],
          1
        ),
        _vm._m(1)
      ]),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "image-title" },
          [
            _c("van-image", {
              staticStyle: { width: "48%" },
              attrs: {
                fit: "contain",
                src: require("../../assets/activity/chnd/ch_d_h_2.png")
              }
            })
          ],
          1
        ),
        _vm._m(2)
      ]),
      _c("div", { staticStyle: { height: "40px" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "line-height": "180%", color: "#3C3F42" } },
      [
        _vm._v(
          " 1. 活动期间首次完成开方（所开具的单笔处方基础药费金额要超过50元计入）可领取 "
        ),
        _c("span", { staticClass: "color-span" }, [_vm._v("20")]),
        _vm._v("喜分；"),
        _c("br"),
        _vm._v(
          " 2. 活动期间新增患者五人同时完成两次开方（两次开方中，所开具的单笔处方基础药费金额要超过200元计入）即可领取"
        ),
        _c("span", { staticClass: "color-span" }, [_vm._v("60")]),
        _vm._v("喜分；"),
        _c("br"),
        _vm._v(
          " 3. 活动期间新增患者八人同时完成五次开方（五次开方中，所开具的单笔处方基础药费金额要超过200元计入）"
        ),
        _c("span", { staticClass: "color-span" }, [_vm._v("120")]),
        _vm._v("喜分。"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "first-card" }, [
      _c("div", { staticStyle: { padding: "0 4px" } }, [
        _vm._v(" 1.本活动面向喜郎中平台的认证医师。"),
        _c("br"),
        _vm._v(
          " 2.患者数量为关联微信号的首个有效患者，统一用户创建多个患者计算为一个患者。"
        ),
        _c("br"),
        _vm._v(
          " 3.完成第一项奖励后开启第二项奖励，完成第二项奖励后开启第三项奖励，不可重复计数。"
        ),
        _c("br"),
        _vm._v(" 4.领取奖励后喜分立即到账。"),
        _c("br")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "first-card" }, [
      _c("div", [
        _vm._v(
          "1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
        ),
        _c("br"),
        _vm._v(
          " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
        ),
        _c("br"),
        _vm._v(" 3、本次活动最终解释权归喜郎中所有。"),
        _c("br")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }