"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.from");

require("core-js/modules/es.string.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _store = _interopRequireDefault(require("@/store"));

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Mall',
  props: {},
  data: function data() {
    return {
      income_goods_list: [],
      integral_goods_list: [],
      indexData: undefined
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchList();
  },
  methods: {
    //商品列表
    fetchList: function fetchList() {
      var _this = this;

      _core.mallInteractor.fetchMallStoreIndex().then(function (data) {
        console.log(data, '错误');
        var integral_goods_list = data.integral_goods_list;
        _this.indexData = data;
        _this.income_goods_list = data.income_goods_list;
        var tempList = [];
        var fList = [];

        for (var index = 0; index < integral_goods_list.length; index++) {
          var element = integral_goods_list[index];

          if (tempList.length == 3) {
            var tlCopy = Array.from(tempList);
            fList.push(tlCopy);
            tempList = [];
            tempList.push(element);
          } else {
            tempList.push(element);
          }

          if (index == integral_goods_list.length - 1) {
            var _tlCopy = Array.from(tempList);

            fList.push(_tlCopy);
          }
        }

        console.log(fList);
        _this.integral_goods_list = fList;
      });
    },
    pushDetail: function pushDetail(item) {
      this.$router.push({
        path: '/mallItemDetail/' + item.id
      });
    },
    pushToList: function pushToList(type) {
      this.$router.push({
        path: '/mallList',
        query: {
          score_type: type
        }
      });
    },
    pushToJDCardList: function pushToJDCardList() {
      this.$router.push({
        path: '/mallJDCardList'
      });
    },
    pushToMyCard: function pushToMyCard() {
      this.$router.push({
        path: '/mallMyCard'
      });
    },
    pushToActivitiesList: function pushToActivitiesList() {
      this.$router.push({
        path: '/mallActivitiesList'
      });
    },
    pushToOrders: function pushToOrders() {
      this.$router.push({
        path: '/mallOrders'
      });
    }
  }
};
exports.default = _default;