"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.join");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _store = _interopRequireDefault(require("@/store"));

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MallItemDetail',
  data: function data() {
    return {
      detail: undefined,
      form: {
        a: []
      },
      isShowBuild: false
    };
  },
  methods: {
    onSubmit: function onSubmit() {
      var _this = this;

      console.log(this.form.a);
      var topic = [];
      this.form.a.forEach(function (item, index) {
        console.log(index);
        var content;

        if (item.constructor === Array) {
          content = item.join(',');
        } else {
          content = item;
        }

        topic.push({
          title: _this.detail.question_info[index].title,
          content: content
        });
      });
      console.log(topic);

      _core.mallInteractor.fetchActivityQuestionnaireSurveySubmit({
        question_id: this.$route.query.id,
        topic: topic
      }).then(function (data) {
        _vant.Toast.success('提交成功');

        _this.$router.push({
          path: '/questionnaireResult'
        });
      });
    },
    fetchActivityQuestionnaireSurveyDetails: function fetchActivityQuestionnaireSurveyDetails() {
      var _this2 = this;

      var id = this.$route.query.id;
      var params = {
        question_id: id
      };

      _core.mallInteractor.fetchActivityQuestionnaireSurveyDetails(params).then(function (data) {
        _this2.detail = data;
      });
    }
  },
  computed: {},
  mounted: function mounted() {
    this.fetchActivityQuestionnaireSurveyDetails();
  },
  created: function created() {}
};
exports.default = _default;