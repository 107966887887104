var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c("div", {
            staticStyle: { "background-color": "#f3f3f3", height: "10px" }
          }),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center"
              }
            },
            [
              _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      margin: "10px 0px"
                    }
                  },
                  [
                    _c("div", {
                      staticStyle: {
                        "background-color": "#ff9000",
                        width: "2px",
                        height: "20px"
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          "font-size": "18px"
                        }
                      },
                      [_vm._v("诊金设置")]
                    )
                  ]
                )
              ])
            ]
          ),
          _c(
            "van-row",
            { staticStyle: { "background-color": "#fff", padding: "5px" } },
            [
              _c("van-col", { attrs: { offset: "1", span: "14" } }, [
                _c("div", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v("默认诊金")
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "gray",
                      height: "20px",
                      "line-height": "20px",
                      "font-size": "12px",
                      "margin-top": "5px"
                    }
                  },
                  [_vm._v(" 设置后，将在开方时自动填写默认诊金 ")]
                )
              ]),
              _c(
                "van-col",
                { attrs: { span: "9" } },
                [
                  _c("van-field", {
                    attrs: {
                      name: "text_visit",
                      type: "number",
                      "input-align": "right",
                      rules: [
                        { pattern: _vm.pattern, message: "请输入正确的数字" }
                      ]
                    },
                    model: {
                      value: _vm.text_visit,
                      callback: function($$v) {
                        _vm.text_visit = _vm._n($$v)
                      },
                      expression: "text_visit"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "van-row",
            { staticStyle: { "background-color": "#fff", padding: "5px" } },
            [
              _c("van-col", { attrs: { offset: "1", span: "24" } }, [
                _c("div", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v("隐藏诊金")
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "gray",
                      height: "20px",
                      "line-height": "20px",
                      "font-size": "12px",
                      "margin-top": "5px"
                    }
                  },
                  [
                    _vm._v(" 开启后患者支付时将无法查看到诊金项 "),
                    _c("van-switch", {
                      staticStyle: {
                        height: "15px",
                        float: "right",
                        "margin-right": "25px"
                      },
                      attrs: { size: "15px", "active-color": "#ff9000" },
                      on: { input: _vm.onText_visit_show },
                      model: {
                        value: _vm.text_visit_show,
                        callback: function($$v) {
                          _vm.text_visit_show = $$v
                        },
                        expression: "text_visit_show"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c("div", {
            staticStyle: { "background-color": "#f3f3f3", height: "10px" }
          }),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center"
              }
            },
            [
              _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      margin: "10px 0px"
                    }
                  },
                  [
                    _c("div", {
                      staticStyle: {
                        "background-color": "#ff9000",
                        width: "2px",
                        height: "20px"
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          "font-size": "18px"
                        }
                      },
                      [_vm._v("问诊设置")]
                    )
                  ]
                )
              ])
            ]
          ),
          _vm.show_open_status
            ? _c(
                "van-row",
                { staticStyle: { "background-color": "#fff", padding: "5px" } },
                [
                  _c("van-col", { attrs: { offset: "1", span: "24" } }, [
                    _c("div", { staticStyle: { "font-size": "14px" } }, [
                      _vm._v("线上开放设置")
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "gray",
                          height: "20px",
                          "line-height": "20px",
                          "font-size": "12px",
                          "margin-top": "5px"
                        }
                      },
                      [
                        _vm._v(" 开启后陌生患者可通过平台找到您 "),
                        _c("van-switch", {
                          staticStyle: {
                            height: "15px",
                            float: "right",
                            "margin-right": "25px"
                          },
                          attrs: { size: "15px", "active-color": "#ff9000" },
                          on: { input: _vm.onOpen_status },
                          model: {
                            value: _vm.open_status,
                            callback: function($$v) {
                              _vm.open_status = $$v
                            },
                            expression: "open_status"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.show_open_consulting
            ? _c(
                "van-row",
                { staticStyle: { "background-color": "#fff", padding: "5px" } },
                [
                  _c(
                    "van-col",
                    { attrs: { span: "24" } },
                    [
                      _c("van-field", {
                        attrs: {
                          name: "open_consulting",
                          label: "开放平台咨询价格(元/次)",
                          placeholder: "",
                          type: "number",
                          "input-align": "right",
                          rules: [
                            {
                              pattern: _vm.pattern,
                              message: "请输入正确的数字"
                            }
                          ]
                        },
                        model: {
                          value: _vm.open_consulting,
                          callback: function($$v) {
                            _vm.open_consulting = _vm._n($$v)
                          },
                          expression: "open_consulting"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "van-row",
            { staticStyle: { "background-color": "#fff", padding: "5px" } },
            [
              _c(
                "van-col",
                { attrs: { span: "24" } },
                [
                  _c("van-field", {
                    attrs: {
                      name: "consulting",
                      label: "老患者咨询价格(元/次)",
                      placeholder: "",
                      type: "number",
                      "input-align": "right",
                      rules: [
                        { pattern: _vm.pattern, message: "请输入正确的数字" }
                      ]
                    },
                    model: {
                      value: _vm.consulting,
                      callback: function($$v) {
                        _vm.consulting = _vm._n($$v)
                      },
                      expression: "consulting"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "van-row",
            { staticStyle: { "background-color": "#fff", padding: "5px" } },
            [
              _c(
                "van-col",
                { attrs: { span: "24" } },
                [
                  _c("van-field", {
                    attrs: {
                      name: "freenumber",
                      label: "免费咨询条数(条)",
                      placeholder: "",
                      type: "number",
                      "input-align": "right",
                      rules: [
                        { pattern: _vm.pattern, message: "请输入正确的数字" }
                      ]
                    },
                    model: {
                      value: _vm.freenumber,
                      callback: function($$v) {
                        _vm.freenumber = _vm._n($$v)
                      },
                      expression: "freenumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "16px" } },
            [
              _c(
                "van-button",
                {
                  staticClass: "s-button",
                  attrs: {
                    round: "",
                    block: "",
                    "native-type": "submit",
                    color: "linear-gradient(to right, #F7941E, #F24316)"
                  }
                },
                [_vm._v(" 提交 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }