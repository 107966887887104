var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#528bf8" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/mofang/banner.png")
        }
      }),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "30px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "45%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/mofang/t_h_0.png")
                }
              })
            ],
            1
          ),
          _vm.detail
            ? _c(
                "div",
                {
                  staticStyle: {
                    padding: "60px 23px 30px 23px",
                    "line-height": "180%",
                    width: "100%",
                    color: "#38404E"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center"
                      }
                    },
                    [
                      _c(
                        "van-button",
                        {
                          attrs: {
                            icon: require("../../assets/activity/mofang/button.png"),
                            plain: "",
                            color: "#528BF8",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              _vm.showPopupDatePicker = true
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.datePickerTitle))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "padding-top": "10px"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v("累计订单数："),
                        _c("span", { staticClass: "red-title" }, [
                          _vm._v(_vm._s(_vm.detail.order_count))
                        ])
                      ]),
                      _c("div", [
                        _vm._v("累计悬壶值："),
                        _c("span", { staticClass: "red-title" }, [
                          _vm._v(_vm._s(_vm.detail.sum_base_medicine_charge))
                        ])
                      ]),
                      _c("div", [
                        _vm._v("累计奖励值："),
                        _c("span", { staticClass: "red-title" }, [
                          _vm._v(_vm._s(_vm.detail.sum_reward))
                        ])
                      ])
                    ]
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _c(
        "van-popup",
        {
          style: { width: "80%" },
          model: {
            value: _vm.showPopupDatePicker,
            callback: function($$v) {
              _vm.showPopupDatePicker = $$v
            },
            expression: "showPopupDatePicker"
          }
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "year-month",
              formatter: _vm.formatter,
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate
            },
            on: {
              confirm: _vm.datePickerConfirm,
              cancel: function($event) {
                _vm.showPopupDatePicker = false
              }
            },
            model: {
              value: _vm.currentDate,
              callback: function($$v) {
                _vm.currentDate = $$v
              },
              expression: "currentDate"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "30px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "45%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/mofang/t_h_1.png")
                }
              })
            ],
            1
          ),
          _vm._m(0)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "30px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "45%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/mofang/t_h_2.png")
                }
              })
            ],
            1
          ),
          _vm._m(1)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "30px",
            "margin-bottom": "30px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "45%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/mofang/t_h_3.png")
                }
              })
            ],
            1
          ),
          _vm._m(2)
        ]
      ),
      _c("div", { staticStyle: { height: "10px" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { padding: "60px 23px 23px 23px", width: "100%" } },
      [
        _c(
          "div",
          { staticStyle: { color: "#38404E", "line-height": "180%" } },
          [
            _vm._v(
              "活动期间开具颗粒、膏方剂型处方可享受悬壶值10%的补贴，饮片、单品、成药及袋装颗粒剂型除外。"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { padding: "80px 23px 0px 23px", width: "100%" } },
      [
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v(" Q:若有退货情况怎么办？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [_vm._v("A: 若有退货情况，悬壶值将退回，且不计入活动统计。")]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v("Q：奖励如何发放？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [
            _vm._v(
              "A: 每笔符合活动规则的订单完成后，奖励将会以喜分的形式自动发放至您的账户。"
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v(" Q：开具单品订单能参与活动吗？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [
            _vm._v(
              "A:不能，只有开具颗粒、膏方剂型的处方会计入活动奖励，单品、饮片、袋装颗粒等其它剂型处方均不计入此项活动"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "80px 23px 20px 23px",
          color: "#38404E",
          "line-height": "180%"
        }
      },
      [
        _vm._v(
          " 1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(" 3、本次活动最终解释权归喜郎中所有。 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }