"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _core = require("@/core");

var _vant = require("vant");

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'activityType2AppRecipeList',
  props: {},
  data: function data() {
    return {
      userInfo: undefined,
      data: undefined,
      query: {
        type: 'secret',
        seed_type: 2,
        page: 0,
        size: 10,
        is_share: 1
      },
      list: [],
      loading: false,
      finished: false,
      refreshing: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var userInfo = _store.default.getters.userInfo;
    this.userInfo = userInfo;
  },
  methods: {
    popPage: function popPage() {
      this.$router.go(-1);
    },
    onLoad: function onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      this.query.page = this.query.page + 1;
      this.fetchActivityGFRecipes202309AppRecipeList(this.query);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false; // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchActivityGFRecipes202309AppRecipeList(this.query);
    },
    switchOnInput: function switchOnInput(item) {
      item.activity202309_added = !item.activity202309_added;
      this.fetchActivityGFRecipes202309AddAccord({
        type: 1,
        recipe_id: item.id
      });
    },
    fetchActivityGFRecipes202309AppRecipeList: function fetchActivityGFRecipes202309AppRecipeList(query) {
      var _this = this;

      _core.mallInteractor.fetchActivityGFRecipes202309AppRecipeList(query).then(function (data) {
        if (_this.query.page === 1) {
          _this.list = data.recipes;
        } else {
          _this.list = [].concat((0, _toConsumableArray2.default)(_this.list), (0, _toConsumableArray2.default)(data.recipes));
        }

        if (data.total <= _this.list.length) {
          _this.finished = true;
        }

        _this.loading = false;
      });
    },
    fetchActivityGFRecipes202309AddAccord: function fetchActivityGFRecipes202309AddAccord(query) {
      _core.mallInteractor.fetchActivityGFRecipes202309AddAccord(query).then(function (data) {});
    }
  }
};
exports.default = _default;