//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'yearReport2024',
  props: {},
  data: function data() {
    return {
      detail: undefined
    };
  },
  created: function created() {
    this.fetchActivityDoctorReport2024();
  },
  mounted: function mounted() {
    this.fetchActivityLogs();
  },
  methods: {
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    nextPage: function nextPage() {
      this.$refs.swipe.next();
    },
    onChange: function onChange(index) {
      document.getElementById('image-item-arrow').style.transform = "translateY(0px)"; // document.getElementById('image-item-arrow-2').style.transform = `translateY(0px)`;

      if (index == 1) {
        document.getElementById('image-item-arrow').style.transform = "translateY(-70px)";
        document.getElementById('tilte-item-1').style.transform = "translate( 0px,100px)";
        document.getElementById('image-item-1').style.transform = "translate( 300px,-450px)";
      } else {
        document.getElementById('tilte-item-1').style.transform = "translate( 0px,0px)";
        document.getElementById('image-item-1').style.transform = "translate(-300px, -300px)";
        document.getElementById('image-item-arrow').style.transform = "translateY(-170px)";
      }

      if (index == 2) {
        document.getElementById('image-item-arrow-2').style.transform = "translateY(-70px)";
        document.getElementById('tilte-item-2').style.transform = "translate( 0px,100px)";
        document.getElementById('image-item-2').style.transform = "translate( 300px,-450px)";
      } else {
        document.getElementById('tilte-item-2').style.transform = "translate( 0px,0px)";
        document.getElementById('image-item-2').style.transform = "translate(-300px, -300px)";
      }

      if (index == 3) {
        document.getElementById('image-item-arrow-3').style.transform = "translateY(-70px)";
        document.getElementById('tilte-item-3').style.transform = "translate( 0px,100px)";
        document.getElementById('image-item-3').style.transform = "translate( 300px,-450px)";
      } else {
        document.getElementById('tilte-item-3').style.transform = "translate( 0px,0px)";
        document.getElementById('image-item-3').style.transform = "translate(-300px, -300px)";
      }

      if (index == 4) {
        document.getElementById('image-item-arrow-4').style.transform = "translateY(-70px)";
        document.getElementById('tilte-item-4').style.transform = "translate( 0px,100px)";
        document.getElementById('image-item-4').style.transform = "translate( 300px,-450px)";
      } else {
        document.getElementById('tilte-item-4').style.transform = "translate( 0px,0px)";
        document.getElementById('image-item-4').style.transform = "translate(-300px, -300px)";
      }

      if (index == 5) {
        document.getElementById('image-item-arrow-5').style.transform = "translateY(-70px)";
        document.getElementById('tilte-item-5').style.transform = "translate( 0px,100px)";
        document.getElementById('image-item-5').style.transform = "translate( 300px,-450px)";
      } else {
        document.getElementById('tilte-item-5').style.transform = "translate( 0px,0px)";
        document.getElementById('image-item-5').style.transform = "translate(-300px, -300px)";
      }

      if (index == 6) {
        document.getElementById('image-item-arrow-6').style.transform = "translateY(-70px)";
        document.getElementById('tilte-item-6').style.transform = "translate( 0px,100px)";
        document.getElementById('image-item-6').style.transform = "translate( 300px,-450px)";
      } else {
        document.getElementById('tilte-item-6').style.transform = "translate( 0px,0px)";
        document.getElementById('image-item-6').style.transform = "translate(-300px, -300px)";
      }
    },
    fetchActivityDoctorReport2024: function fetchActivityDoctorReport2024() {
      var _this = this;

      mallInteractor.fetchActivityDoctorReport2024().then(function (data) {
        _this.detail = data;
      });
    },
    jumpToShare: function jumpToShare() {
      var url = process.env.NODE_ENV === 'production' ? 'http://im.5ixlz.com/' : 'http://im_t.5ixlz.com/';
      var pararm = {
        url: url + 'yearReport2024Share',
        year: '2024'
      };
      mallInteractor.fetchActivityDoctorReportURL(pararm).then(function (data) {
        // this.detail = data;
        var share_url = data.share_url;
        window.location.href = share_url;
      }); // this.$router.push({ path: '/yearReport2022Share'})
    }
  }
};