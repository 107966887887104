var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#1281bd" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/red202207/banner.png")
        }
      }),
      _vm.detail
        ? _c(
            "div",
            { staticStyle: { "margin-top": "-130px", position: "absolute" } },
            [
              _c("vue-danmaku", {
                staticStyle: { height: "130px", width: "100vw" },
                attrs: {
                  danmus: _vm.danmus,
                  loop: true,
                  speeds: 50,
                  top: 10,
                  randomChannel: true,
                  channels: 3,
                  "use-slot": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "dm",
                      fn: function(ref) {
                        var danmu = ref.danmu
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                background: "#F8FCFD",
                                opacity: "0.43",
                                "border-radius": "19px",
                                "font-size": "12px",
                                "font-weight": "400",
                                color: "#1281BD",
                                padding: "0 10px"
                              }
                            },
                            [_vm._v(_vm._s(danmu))]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  673506865
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.detail
        ? _c(
            "div",
            {
              staticClass: "card",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "0px",
                "flex-direction": "column",
                "align-items": "center"
              }
            },
            [
              _vm._m(0),
              _c("van-image", {
                staticStyle: { width: "80%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/red202207/b_" +
                    _vm.detail.button_status +
                    ".png")
                },
                on: { click: _vm.imageAction }
              }),
              _c(
                "div",
                {
                  staticClass: "main-title",
                  staticStyle: { "font-size": "16px", padding: "30px 0 0 20px" }
                },
                [
                  _vm._v(" 拆取红包数 "),
                  _c("span", { staticClass: "orange-title" }, [
                    _vm._v(_vm._s(_vm.detail.total_count))
                  ]),
                  _vm._v(" 个 共 "),
                  _c("span", { staticClass: "orange-title" }, [
                    _vm._v(_vm._s(_vm.detail.total_amount) + " ")
                  ]),
                  _vm._v("喜分 ")
                ]
              ),
              _c(
                "van-button",
                {
                  staticStyle: { margin: "20px" },
                  attrs: { color: "#ff9000", size: "small" },
                  on: {
                    click: function($event) {
                      _vm.popupShow = true
                    }
                  }
                },
                [_vm._v("我的红包")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/red202207/r_0.png")
                }
              })
            ],
            1
          ),
          _vm._m(1)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/red202207/r_1.png")
                }
              })
            ],
            1
          ),
          _vm._m(2)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/red202207/r_2.png")
                }
              })
            ],
            1
          ),
          _vm._m(3)
        ]
      ),
      _c(
        "van-overlay",
        {
          attrs: { show: _vm.receiveShow },
          on: {
            click: function($event) {
              _vm.receiveShow = false
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "wrapper",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.receiveShowDismiss($event)
                }
              }
            },
            [
              _c("van-image", {
                staticStyle: { width: "50%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/red202207/p_0.png")
                }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "-80px",
                    position: "absolute",
                    color: "#F39800",
                    "text-align": "center",
                    "font-weight": "500",
                    "font-size": "15px"
                  }
                },
                [
                  _vm._v(" 恭喜您！"),
                  _c("br"),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "35px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.amount))]
                  ),
                  _vm._v(" 喜分 ")
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "van-popup",
        {
          style: { height: "70%" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.popupShow,
            callback: function($$v) {
              _vm.popupShow = $$v
            },
            expression: "popupShow"
          }
        },
        [
          _c(
            "van-cell-group",
            [
              _c("van-cell", {
                attrs: {
                  title: "红包明细",
                  "title-style": "flex: 1;",
                  value:
                    "已拆红包" +
                    _vm.detail.total_count +
                    "个，共" +
                    _vm.detail.total_amount +
                    "喜分",
                  "value-class": "header-cell-value"
                }
              }),
              _vm._l(_vm.detail.log_list, function(item, index) {
                return _c("van-cell", {
                  key: index,
                  attrs: { title: item.created_at },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "cell-value" }, [
                              _vm._v(_vm._s(item.amount) + "喜分")
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "icon",
                        fn: function() {
                          return [
                            _c("van-icon", {
                              staticStyle: { padding: "2px 10px 0 0" },
                              attrs: {
                                size: "22px",
                                name: require("../../assets/activity/red202207/r_icon.png")
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                })
              })
            ],
            2
          )
        ],
        1
      ),
      _c("div", { staticStyle: { height: "30px" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "main-title",
        staticStyle: { "font-size": "16px", padding: "40px 0px 40px 0px" }
      },
      [
        _vm._v(" 每天 "),
        _c("span", { staticClass: "orange-title" }, [_vm._v("9:00")]),
        _vm._v(" 开启红包 快来参与吧 ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "50px 23px 20px 23px" } }, [
      _c(
        "div",
        {
          staticClass: "main-title",
          staticStyle: { color: "#38404E", "line-height": "180%" }
        },
        [
          _vm._v(" 1.活动期间，有过处方记录的医生每天打开喜郎中进入活动页面，"),
          _c("span", { staticClass: "orange-title" }, [_vm._v("9:00")]),
          _vm._v("可领取最高"),
          _c("span", { staticClass: "orange-title" }, [_vm._v("100")]),
          _vm._v(
            "喜分的随机金额红包，每成功开过一次处方即增加一天参与机会，最高可连续领取"
          ),
          _c("span", { staticClass: "orange-title" }, [_vm._v("16")]),
          _vm._v("天。 "),
          _c("br"),
          _c("br"),
          _vm._v(" 2.每日红包发放数量为"),
          _c("span", { staticClass: "orange-title" }, [_vm._v("300")]),
          _vm._v("个，抢完即止。 "),
          _c("br"),
          _c("br"),
          _vm._v(" 3.红包金额将累计至"),
          _c("span", { staticClass: "orange-title" }, [_vm._v("8月1日")]),
          _vm._v("统一发放。 ")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "50px 23px 20px 23px" } }, [
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
        [_vm._v("Q：怎样获取参与资格？")]
      ),
      _c(
        "div",
        { staticClass: "sub-title", staticStyle: { color: "#38404E" } },
        [_vm._v("A：加入平台后，每成功完成一次处方，即获得一次参与机会。")]
      ),
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
        [_vm._v("Q：红包奖励什么时候到账？")]
      ),
      _c(
        "div",
        { staticClass: "sub-title", staticStyle: { color: "#38404E" } },
        [_vm._v("A：奖励累计至8月1日由系统自动发放到账。")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "50px 23px 20px 23px",
          color: "#38404E",
          "line-height": "180%"
        }
      },
      [
        _vm._v(
          " 1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(" 3、本次活动最终解释权归喜郎中所有。 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }