"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

var _CommonHeader = _interopRequireDefault(require("@/components/CommonHeader"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CommunityComplaint',
  components: {
    CommonHeader: _CommonHeader.default
  },
  props: {},
  data: function data() {
    return {
      query: {
        object_type: undefined,
        object_id: undefined,
        type: undefined,
        content: undefined
      },
      tagList: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    console.log(this.$route.query);
    this.query.object_id = this.$route.query.object_id;
    this.query.object_type = this.$route.query.object_type;
    this.fetchCommunityComplaintTypeList();
  },
  methods: {
    clickCell: function clickCell(item) {
      this.$router.push({
        path: '/postDetail',
        query: {
          id: item.id,
          scene: 'app'
        }
      });
    },
    fetchCommunityComplaintTypeList: function fetchCommunityComplaintTypeList() {
      var _this = this;

      _core.communityInteractor.fetchCommunityComplaintTypeList().then(function (data) {
        _this.tagList = data.list;
      });
    },
    fetchCommunitySetComplaint: function fetchCommunitySetComplaint() {
      var _this2 = this;

      if (!this.query.type) {
        (0, _vant.Toast)('请选择投诉类型');
        return;
      }

      if (!this.query.content || this.query.content.length == 0) {
        (0, _vant.Toast)('请填写投诉说明');
        return;
      }

      _core.communityInteractor.fetchCommunitySetComplaint(this.query).then(function (data) {
        _this2.$router.back(-1);
      });
    }
  }
};
exports.default = _default;