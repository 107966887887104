var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.detail
    ? _c(
        "div",
        {
          staticClass: "body",
          style: { width: "100%", backgroundColor: _vm.detail.page_color }
        },
        [
          _c("van-image", {
            staticStyle: { width: "100%", "z-index": "0" },
            attrs: { fit: "fill", src: _vm.detail.banner_id }
          }),
          _c(
            "div",
            {
              staticClass: "card",
              style: {
                marginTop: "40px",
                boxShadow: "0px 4px 21px 6px " + _vm.detail.shadow_color,
                backgroundColor: _vm.detail.card_color
              }
            },
            [
              _c(
                "div",
                { staticClass: "image-title" },
                [
                  _c("van-image", {
                    staticStyle: { padding: "0 25%" },
                    attrs: { fit: "contain", src: _vm.detail.title_reward_id }
                  })
                ],
                1
              ),
              _vm.detail && _vm.detail.activity_type == 1
                ? _c("div", { staticClass: "rule-card" }, [
                    _c("div", [
                      _vm._v("累计悬壶值："),
                      _c("span", { staticClass: "red-title" }, [
                        _vm._v(_vm._s(_vm.detail.amount))
                      ])
                    ]),
                    _vm.detail.difference_next_amount &&
                    _vm.detail.difference_next_amount == 0
                      ? _c("div", [_vm._v("恭喜您已达成最高奖励目标！")])
                      : _c("div", [
                          _vm._v("距离下一个目标还差"),
                          _c("span", { staticClass: "red-title" }, [
                            _vm._v(_vm._s(_vm.detail.difference_next_amount))
                          ]),
                          _vm._v("悬壶值")
                        ])
                  ])
                : _vm._e(),
              _vm.detail && _vm.detail.activity_type == 2
                ? _c("div", { staticClass: "rule-card" }, [
                    _c("div", [
                      _vm._v("目标悬壶值："),
                      _c("span", { staticClass: "red-title" }, [
                        _vm._v(_vm._s(_vm.detail.target_amount))
                      ])
                    ]),
                    _c("div", [
                      _vm._v("本月悬壶值："),
                      _c("span", { staticClass: "red-title" }, [
                        _vm._v(_vm._s(_vm.detail.amount))
                      ])
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.detail.huanbi_type == "num"
                            ? "环比悬壶值："
                            : "环比目标比例："
                        )
                      ),
                      _c("span", { staticClass: "red-title" }, [
                        _vm._v(_vm._s(_vm.detail.huanbi_amount))
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.detail && _vm.detail.activity_type == 3
                ? _c("div", { staticClass: "rule-card" }, [
                    _c("div", [
                      _vm._v("累计订单数："),
                      _c("span", { staticClass: "red-title" }, [
                        _vm._v(_vm._s(_vm.detail.order_num))
                      ])
                    ]),
                    _vm.detail.difference_order_num &&
                    _vm.detail.difference_order_num == 0
                      ? _c("div", [_vm._v("恭喜您已达成最高奖励目标！")])
                      : _c("div", [
                          _vm._v("距离下一个目标还差"),
                          _c("span", { staticClass: "red-title" }, [
                            _vm._v(
                              _vm._s(_vm.detail.difference_order_num) + "笔"
                            )
                          ]),
                          _vm._v("订单")
                        ])
                  ])
                : _vm._e(),
              _vm.detail && _vm.detail.activity_type == 4
                ? _c("div", { staticClass: "rule-card" }, [
                    _c("div", [
                      _vm._v("累计新患者数："),
                      _c("span", { staticClass: "red-title" }, [
                        _vm._v(_vm._s(_vm.detail.user_num))
                      ])
                    ]),
                    _vm.detail.difference_user_num &&
                    _vm.detail.difference_user_num == 0
                      ? _c("div", [_vm._v("恭喜您已达成最高奖励目标！")])
                      : _c("div", [
                          _vm._v("距离下一个目标还差"),
                          _c("span", { staticClass: "red-title" }, [
                            _vm._v(_vm._s(_vm.detail.difference_user_num))
                          ]),
                          _vm._v("人")
                        ])
                  ])
                : _vm._e(),
              _vm.detail && _vm.detail.activity_type == 5
                ? _c("div", { staticClass: "rule-card" }, [
                    _c("div", [
                      _vm._v("当前悬壶值："),
                      _c("span", { staticClass: "red-title" }, [
                        _vm._v(_vm._s(_vm.detail.amount))
                      ])
                    ]),
                    _c("div", [
                      _vm._v("当前排名："),
                      _c("span", { staticClass: "red-title" }, [
                        _vm._v(_vm._s(_vm.detail.rank_info))
                      ])
                    ]),
                    _vm.detail.difference_next_rank_amount &&
                    _vm.detail.difference_next_rank_amount == 0
                      ? _c("div", [_vm._v("恭喜您已排名首位！")])
                      : _c("div", [
                          _vm._v("距离上一名还差"),
                          _c("span", { staticClass: "red-title" }, [
                            _vm._v(
                              _vm._s(_vm.detail.difference_next_rank_amount)
                            )
                          ]),
                          _vm._v("悬壶值")
                        ])
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              staticClass: "card",
              style: {
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
                boxShadow: "0px 4px 21px 6px " + _vm.detail.shadow_color,
                backgroundColor: _vm.detail.card_color
              }
            },
            [
              _c(
                "div",
                { staticClass: "image-title" },
                [
                  _c("van-image", {
                    staticStyle: { padding: "0 25%" },
                    attrs: { fit: "contain", src: _vm.detail.title_rule_id }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "24px 23px 32px 23px",
                    "line-height": "180%"
                  }
                },
                [
                  _c("div", {
                    staticStyle: { color: "#38404E" },
                    domProps: { innerHTML: _vm._s(_vm.detail.text_rule) }
                  }),
                  _c(
                    "table",
                    {
                      staticClass: "tg",
                      staticStyle: { width: "100%", margin: "10px 0" }
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { staticClass: "tg-uc1r" }, [
                            _vm._v(_vm._s(_vm.detail.reward.target_title))
                          ]),
                          _c("th", { staticClass: "tg-uc1r" }, [
                            _vm._v(_vm._s(_vm.detail.reward.reward_title))
                          ])
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.detail.reward.item, function(item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "tg-zs29" }, [
                              _vm._v(_vm._s(item.target))
                            ]),
                            _vm.detail.reward.reward_column_type == "string"
                              ? _c("td", { staticClass: "tg-zs29" }, [
                                  _vm._v(_vm._s(item.reward))
                                ])
                              : _vm._e(),
                            _vm.detail.reward.reward_column_type == "url"
                              ? _c("td", { staticClass: "tg-zs29" }, [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: {
                                        color: "blue",
                                        "text-decoration": "underline"
                                      },
                                      attrs: { href: item.redirect_url }
                                    },
                                    [_vm._v(_vm._s(item.reward))]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }),
                        0
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "card",
              style: {
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
                boxShadow: "0px 4px 21px 6px " + _vm.detail.shadow_color,
                backgroundColor: _vm.detail.card_color
              }
            },
            [
              _c(
                "div",
                { staticClass: "image-title" },
                [
                  _c("van-image", {
                    staticStyle: { padding: "0 25%" },
                    attrs: { fit: "contain", src: _vm.detail.title_question_id }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { padding: "24px 23px 12px 23px", width: "100%" }
                },
                _vm._l(_vm.detail.question_item, function(item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      {
                        staticClass: "main-title",
                        staticStyle: { color: "#ff9000" }
                      },
                      [_vm._v(_vm._s(item.key))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "sub-title",
                        staticStyle: { color: "#38404E", "line-height": "150%" }
                      },
                      [_vm._v(_vm._s(item.value))]
                    )
                  ])
                }),
                0
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "card",
              style: {
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
                boxShadow: "0px 4px 21px 6px " + _vm.detail.shadow_color,
                backgroundColor: _vm.detail.card_color
              }
            },
            [
              _c(
                "div",
                { staticClass: "image-title" },
                [
                  _c("van-image", {
                    staticStyle: { padding: "0 25%" },
                    attrs: { fit: "contain", src: _vm.detail.title_des_id }
                  })
                ],
                1
              ),
              _c("div", {
                staticStyle: {
                  padding: "24px 23px 32px 23px",
                  color: "#38404E",
                  "line-height": "180%"
                },
                domProps: { innerHTML: _vm._s(_vm.detail.other_des) }
              })
            ]
          ),
          _c("van-image", {
            staticStyle: { width: "100%", "z-index": "0" },
            attrs: { fit: "fill", src: _vm.detail.footer_id }
          }),
          _c("div", { staticStyle: { height: "60px" } }),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "van-button",
                {
                  staticStyle: { width: "94%", "margin-top": "15px" },
                  attrs: {
                    color: _vm._f("statusColorFilter")(
                      _vm.detail.receive_button_status
                    )
                  },
                  on: { click: _vm.fetchActivityConfigReceive }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("statusTextFilter")(
                        _vm.detail.receive_button_status
                      )
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }