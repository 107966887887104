var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "#F9F9F8" } },
    [
      _c(
        "van-dropdown-menu",
        { attrs: { "active-color": "#F7931C" } },
        [
          _c("van-dropdown-item", {
            attrs: { options: _vm.option1 },
            on: { change: _vm.dropdownItemChange },
            model: {
              value: _vm.query.type,
              callback: function($$v) {
                _vm.$set(_vm.query, "type", $$v)
              },
              expression: "query.type"
            }
          }),
          _c("van-dropdown-item", {
            attrs: { options: _vm.option2 },
            on: { change: _vm.dropdownItemChange },
            model: {
              value: _vm.query.income_order_by,
              callback: function($$v) {
                _vm.$set(_vm.query, "income_order_by", $$v)
              },
              expression: "query.income_order_by"
            }
          })
        ],
        1
      ),
      _vm._l(_vm.list, function(item, index) {
        return _c("van-cell", {
          key: index,
          attrs: { border: false },
          on: {
            click: function($event) {
              return _vm.pushDetail(item)
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function() {
                  return [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            border: "1px solid #ff9000",
                            "border-radius": "5px",
                            display: "flex"
                          }
                        },
                        [
                          _c("van-image", {
                            staticStyle: { padding: "3px" },
                            attrs: {
                              fit: "contain",
                              width: "100",
                              height: "100",
                              src: item.url
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            "justify-content": "space-between",
                            "margin-left": "5px"
                          }
                        },
                        [
                          _c("p", { staticClass: "goods-name" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          item.free_status == 1
                            ? _c(
                                "div",
                                [
                                  _c("van-image", {
                                    attrs: {
                                      fit: "contain",
                                      width: "70",
                                      height: "19",
                                      src: require("../../assets/mall/mall_free.png")
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      item.buy_model == 1
                                        ? _c("div", { staticClass: "price" }, [
                                            _vm._v(
                                              _vm._s(item.free_integral) +
                                                "经验值"
                                            )
                                          ])
                                        : _vm._e(),
                                      item.buy_model == 2
                                        ? _c("div", { staticClass: "price" }, [
                                            _vm._v(
                                              _vm._s(item.free_income) + "喜分"
                                            )
                                          ])
                                        : _vm._e(),
                                      item.buy_model == 3
                                        ? _c("div", { staticClass: "price" }, [
                                            _vm._v(
                                              _vm._s(item.free_integral) +
                                                "经验值+" +
                                                _vm._s(item.free_income) +
                                                "喜分"
                                            )
                                          ])
                                        : _vm._e(),
                                      item.buy_model == 1
                                        ? _c(
                                            "div",
                                            { staticClass: "deletePrice" },
                                            [
                                              _vm._v(
                                                _vm._s(item.integral) + "经验值"
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item.buy_model == 2
                                        ? _c(
                                            "div",
                                            { staticClass: "deletePrice" },
                                            [
                                              _vm._v(
                                                _vm._s(item.income) + "喜分"
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item.buy_model == 3
                                        ? _c(
                                            "div",
                                            { staticClass: "deletePrice" },
                                            [
                                              _vm._v(
                                                _vm._s(item.integral) +
                                                  "经验值+" +
                                                  _vm._s(item.income) +
                                                  "喜分"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.free_status == 0
                            ? _c("div", [
                                item.buy_model == 1
                                  ? _c("div", { staticClass: "price" }, [
                                      _vm._v(_vm._s(item.integral) + "经验值")
                                    ])
                                  : _vm._e(),
                                item.buy_model == 2
                                  ? _c("div", { staticClass: "price" }, [
                                      _vm._v(_vm._s(item.income) + "喜分")
                                    ])
                                  : _vm._e(),
                                item.buy_model == 3
                                  ? _c("div", { staticClass: "price" }, [
                                      _vm._v(
                                        _vm._s(item.integral) +
                                          "经验值+" +
                                          _vm._s(item.income) +
                                          "喜分"
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ]
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }