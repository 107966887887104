import { render, staticRenderFns } from "./activityType2AppRecipeList.vue?vue&type=template&id=761c2a90&scoped=true&"
import script from "./activityType2AppRecipeList.vue?vue&type=script&lang=js&"
export * from "./activityType2AppRecipeList.vue?vue&type=script&lang=js&"
import style0 from "./activityType2AppRecipeList.vue?vue&type=style&index=0&id=761c2a90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "761c2a90",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('761c2a90')) {
      api.createRecord('761c2a90', component.options)
    } else {
      api.reload('761c2a90', component.options)
    }
    module.hot.accept("./activityType2AppRecipeList.vue?vue&type=template&id=761c2a90&scoped=true&", function () {
      api.rerender('761c2a90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/activity/activityType2AppRecipeList.vue"
export default component.exports