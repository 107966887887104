var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-tabs",
        {
          attrs: { color: "#F83E53", "title-active-color": "#F83E53" },
          on: { click: _vm.tabsOnClick },
          model: {
            value: _vm.tabsActive,
            callback: function($$v) {
              _vm.tabsActive = $$v
            },
            expression: "tabsActive"
          }
        },
        _vm._l(_vm.tabs, function(tabItem) {
          return _c(
            "van-tab",
            {
              attrs: {
                title: tabItem.title,
                name: tabItem.name,
                "title-style": "font-size: 16px;font-weight: bold;"
              }
            },
            [
              _c(
                "van-pull-refresh",
                {
                  on: { refresh: _vm.onRefresh },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                [
                  _c(
                    "van-list",
                    {
                      attrs: {
                        finished: _vm.finished,
                        "finished-text": "",
                        offset: "150"
                      },
                      on: { load: _vm.onLoad },
                      model: {
                        value: _vm.loading,
                        callback: function($$v) {
                          _vm.loading = $$v
                        },
                        expression: "loading"
                      }
                    },
                    [
                      _c(
                        "van-cell-group",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { inset: "" }
                        },
                        _vm._l(_vm.list, function(item, index) {
                          return _c("van-cell", {
                            key: index,
                            attrs: {
                              title: item.title,
                              label: item.created_at,
                              "title-class": "cell-title"
                            },
                            on: {
                              click: function($event) {
                                return _vm.jumpToActivitiesItemList(item)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      item.status == 1
                                        ? _c(
                                            "van-button",
                                            {
                                              staticStyle: { width: "90px" },
                                              attrs: {
                                                color: "#F83E53",
                                                round: "",
                                                size: "small"
                                              }
                                            },
                                            [_vm._v("立即兑换")]
                                          )
                                        : _c(
                                            "van-button",
                                            {
                                              staticStyle: {
                                                width: "90px",
                                                color: "black"
                                              },
                                              attrs: {
                                                color: "#EEEEEE",
                                                round: "",
                                                size: "small"
                                              }
                                            },
                                            [_vm._v("已发放")]
                                          )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.list.length == 0 && _vm.loading == false
                ? _c("van-empty", {
                    staticClass: "custom-image",
                    attrs: {
                      image: require("../../assets/activity/reward_list_temp.png"),
                      description: "暂无奖品"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }