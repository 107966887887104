"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CommunityFollowList',
  props: {},
  data: function data() {
    return {
      query: {
        page: 0,
        size: 10
      },
      list: [],
      loading: false,
      finished: false,
      refreshing: false
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    clickCell: function clickCell(item) {},
    fetchToggleFollow: function fetchToggleFollow(item) {
      var that = this;

      if (item.is_followed) {
        _vant.Dialog.confirm({
          message: '确认不再关注此人',
          confirmButtonColor: '#ff9000'
        }).then(function () {
          // on confirm
          that.toggleFollow(item);
        }).catch(function () {// on cancel
        });
      } else {
        that.toggleFollow(item);
      }
    },
    toggleFollow: function toggleFollow(item) {
      var _this = this;

      _core.communityInteractor.fetchToggleFollow({
        doctor_id: item.doctor_id
      }).then(function (data) {
        _this.onRefresh();
      });
    },
    onLoad: function onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      this.query.page = this.query.page + 1;
      console.log('onLoad');
      this.fetchCommunityNewsFollowList(this.query);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false;
      console.log('onRefresh');
      console.log(this.query); // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchCommunityNewsFollowList(this.query);
    },
    fetchCommunityNewsFollowList: function fetchCommunityNewsFollowList(query) {
      var _this2 = this;

      _core.communityInteractor.fetchCommunityNewsFollowList(query).then(function (data) {
        if (_this2.query.page === 1) {
          _this2.list = data.list;
        } else {
          _this2.list = [].concat((0, _toConsumableArray2.default)(_this2.list), (0, _toConsumableArray2.default)(data.list));
        }

        if (data.total <= _this2.list.length) {
          _this2.finished = true;
        }

        console.log(_this2.list);
        _this2.loading = false;
      });
    },
    jumpToEditPost: function jumpToEditPost() {
      this.$router.push({
        path: '/editPost'
      });
    },
    jumpToCommunityMessages: function jumpToCommunityMessages() {
      this.$router.push({
        path: '/communityMessages'
      });
    }
  }
};
exports.default = _default;