var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "white" } },
    [
      _vm.query.score_type == 2
        ? _c(
            "van-swipe",
            { attrs: { autoplay: 3000, "indicator-color": "#ff9000" } },
            _vm._l(_vm.img_list, function(item, index) {
              return _c(
                "van-swipe-item",
                {
                  key: index,
                  on: {
                    click: function($event) {
                      return _vm.swipeClick(item.redirect_url)
                    }
                  }
                },
                [_c("van-image", { attrs: { src: item.url } })],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c("van-search", {
        attrs: { "show-action": "", placeholder: "请输入搜索关键词" },
        on: { search: _vm.onSearch, cancel: _vm.onCancel },
        model: {
          value: _vm.query.keyword,
          callback: function($$v) {
            _vm.$set(_vm.query, "keyword", $$v)
          },
          expression: "query.keyword"
        }
      }),
      _vm.query.score_type == "1"
        ? _c(
            "van-dropdown-menu",
            { attrs: { "active-color": "#F7931C" } },
            [
              _c("van-dropdown-item", {
                attrs: { options: _vm.option1 },
                on: { change: _vm.dropdownItemChange },
                model: {
                  value: _vm.integral,
                  callback: function($$v) {
                    _vm.integral = $$v
                  },
                  expression: "integral"
                }
              }),
              _c("van-dropdown-item", {
                attrs: { options: _vm.option2 },
                on: { change: _vm.dropdownItemChange },
                model: {
                  value: _vm.category_id,
                  callback: function($$v) {
                    _vm.category_id = $$v
                  },
                  expression: "category_id"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.query.score_type == "2"
        ? _c(
            "van-dropdown-menu",
            { attrs: { "active-color": "#F7931C" } },
            [
              _c("van-dropdown-item", {
                attrs: { options: _vm.option3 },
                on: { change: _vm.dropdownItemChange },
                model: {
                  value: _vm.income,
                  callback: function($$v) {
                    _vm.income = $$v
                  },
                  expression: "income"
                }
              }),
              _c("van-dropdown-item", {
                attrs: { options: _vm.option2 },
                on: { change: _vm.dropdownItemChange },
                model: {
                  value: _vm.category_id,
                  callback: function($$v) {
                    _vm.category_id = $$v
                  },
                  expression: "category_id"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-pull-refresh",
        {
          attrs: { disabled: true },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text": "没有更多了",
                offset: "150"
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.list, function(item, index) {
              return _c("van-cell", {
                key: index,
                attrs: { border: false },
                on: {
                  click: function($event) {
                    return _vm.pushDetail(item)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("div", { staticStyle: { display: "flex" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  border: "1px solid #ff9000",
                                  "border-radius": "5px",
                                  display: "flex"
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: { padding: "3px" },
                                  attrs: {
                                    fit: "contain",
                                    width: "100",
                                    height: "100",
                                    src: item.cover_image_file.url
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "column",
                                  "justify-content": "space-between",
                                  "margin-left": "5px"
                                }
                              },
                              [
                                _c("p", { staticClass: "goods-name" }, [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                item.buy_model == 1
                                  ? _c("div", { staticClass: "price" }, [
                                      _vm._v(_vm._s(item.integral) + "经验值")
                                    ])
                                  : _vm._e(),
                                item.buy_model == 2
                                  ? _c("div", { staticClass: "price" }, [
                                      _vm._v(_vm._s(item.income) + "喜分")
                                    ])
                                  : _vm._e(),
                                item.buy_model == 3
                                  ? _c("div", { staticClass: "price" }, [
                                      _vm._v(
                                        _vm._s(item.integral) +
                                          "经验值+" +
                                          _vm._s(item.income) +
                                          "喜分"
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }