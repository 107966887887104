"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNumeric = void 0;

var isNumeric = function isNumeric(val) {
  return /^\d+(\.\d+)?$/.test(val);
};

exports.isNumeric = isNumeric;