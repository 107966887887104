var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#FFD196" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/inviteDoctor202311/banner.png")
        }
      }),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/inviteDoctor202311/i_t_0.png")
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                padding: "50px 16px 20px 16px",
                width: "100%",
                "text-align": "center"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "padding-bottom": "20px",
                    "justify-content": "space-evenly"
                  }
                },
                [
                  _vm.detail
                    ? _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c("div", { staticClass: "f-title" }, [
                          _vm._v("邀请奖励")
                        ]),
                        _c("div", { staticClass: "f-value" }, [
                          _vm._v(_vm._s(_vm.detail.referee_income) + "喜分")
                        ])
                      ])
                    : _vm._e(),
                  _vm.detail && _vm.detail.status == 1
                    ? _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c("div", { staticClass: "f-title" }, [
                          _vm._v("受邀奖励")
                        ]),
                        _c("div", { staticClass: "f-value" }, [
                          _vm._v(_vm._s(_vm.detail.joiner_income) + "喜分")
                        ])
                      ])
                    : _vm._e(),
                  _vm.detail
                    ? _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c("div", { staticClass: "f-title" }, [
                          _vm._v("可提现余额")
                        ]),
                        _c("div", { staticClass: "f-value" }, [
                          _vm._v(_vm._s(_vm.detail.invite_income) + "喜分")
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _c("van-image", {
                staticStyle: { width: "70%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/inviteDoctor202311/b.png")
                },
                on: { click: _vm.fetchActivityInviteDoctor202311InviteCash }
              }),
              _c(
                "div",
                {
                  staticClass: "color-span",
                  staticStyle: {
                    "font-size": "14px",
                    "text-decoration": "underline",
                    "padding-top": "10px"
                  },
                  on: {
                    click: function($event) {
                      return _vm.jumpToDetail()
                    }
                  }
                },
                [_vm._v("查看明细")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/inviteDoctor202311/i_t_1.png")
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { padding: "50px 16px 20px 16px", width: "100%" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    color: "#A76230",
                    "font-size": "16px",
                    "font-weight": "bold",
                    "padding-bottom": "10px"
                  }
                },
                [_vm._v(" 邀请人奖励 ")]
              ),
              _vm._m(0),
              _c(
                "div",
                {
                  staticStyle: {
                    color: "#A76230",
                    "font-size": "16px",
                    "font-weight": "bold",
                    padding: "10px 0"
                  }
                },
                [_vm._v(" 被邀请人奖励 ")]
              ),
              _vm._m(1),
              _vm.detail
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#A76230",
                        "font-size": "14px",
                        padding: "10px 0"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.detail.activity_time_str) + " ")]
                  )
                : _vm._e()
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/inviteDoctor202311/i_t_2.png")
                }
              })
            ],
            1
          ),
          _vm.detail
            ? _c(
                "div",
                {
                  staticStyle: {
                    padding: "50px 16px 20px 16px",
                    width: "100%",
                    "text-align": "center"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#A76230",
                        "font-size": "16px",
                        "font-weight": "bold",
                        "padding-bottom": "10px"
                      }
                    },
                    [
                      _vm._v(
                        "累计邀请医生" +
                          _vm._s(_vm.detail.invite_total_count) +
                          "人"
                      )
                    ]
                  ),
                  _c(
                    "table",
                    {
                      staticClass: "tg",
                      staticStyle: { width: "100%", margin: "10px 0" }
                    },
                    [
                      _vm._m(2),
                      _c(
                        "tbody",
                        _vm._l(_vm.detail.invite_list, function(item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "tg-zs29" }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _c("td", { staticClass: "tg-zs29" }, [
                              _vm._v(_vm._s(item.mobile))
                            ]),
                            _c(
                              "td",
                              {
                                class:
                                  item.credential_status == 1
                                    ? "tg-zs31"
                                    : "tg-zs30"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.credential_status == 1
                                      ? "已认证"
                                      : "未认证"
                                  )
                                )
                              ]
                            ),
                            _c("td", { staticClass: "tg-zs29" }, [
                              _vm._v(_vm._s(item.referee_income))
                            ])
                          ])
                        }),
                        0
                      )
                    ]
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._m(3),
      _c(
        "footer",
        {
          staticStyle: {
            width: "100%",
            position: "fixed",
            bottom: "0",
            left: "0",
            height: "70px",
            "justify-content": "center",
            "background-color": "white",
            "align-items": "center",
            display: "flex"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "background-color": "#f38900",
                color: "white",
                "line-height": "44px",
                width: "80%",
                "font-size": "16px",
                "border-radius": "5px",
                "text-align": "center"
              },
              on: {
                click: function($event) {
                  return _vm.shareAction()
                }
              }
            },
            [_vm._v("立即邀请医生入驻")]
          )
        ]
      ),
      _c("div", { staticStyle: { height: "70px" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-div" }, [
      _c("span", { staticClass: "color-span dot" }, [_vm._v("· ")]),
      _vm._v("每成功邀请"),
      _c("span", { staticClass: "color-span" }, [_vm._v("一名")]),
      _vm._v("医生完成一笔满 "),
      _c("span", { staticClass: "color-span" }, [_vm._v("100元")]),
      _vm._v("基础药费处方，您即可获得 "),
      _c("span", { staticClass: "color-span" }, [_vm._v("50喜分")]),
      _vm._v("奖励 "),
      _c("br"),
      _c("span", { staticClass: "color-span dot" }, [_vm._v("· ")]),
      _vm._v(
        "被邀请医生自完成首单时起30天内，每完成一笔处方，您都可以获得处方基础药费5%的额外奖励 "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-div" }, [
      _c("span", { staticClass: "color-span dot" }, [_vm._v("· ")]),
      _vm._v("认证成功后添加客服微信可领取一个"),
      _c("span", { staticClass: "color-span" }, [_vm._v("手机支架")]),
      _c("br"),
      _c("span", { staticClass: "color-span dot" }, [_vm._v("· ")]),
      _vm._v("完成一笔满"),
      _c("span", { staticClass: "color-span" }, [_vm._v("50元")]),
      _vm._v("基础药费处方，可获得"),
      _c("span", { staticClass: "color-span" }, [_vm._v("50喜分")]),
      _vm._v("奖励"),
      _c("br"),
      _c("span", { staticClass: "color-span dot" }, [_vm._v("· ")]),
      _vm._v("完成一笔满"),
      _c("span", { staticClass: "color-span" }, [_vm._v("100元")]),
      _vm._v("基础药费处方，可获得"),
      _c("span", { staticClass: "color-span" }, [_vm._v("100喜分")]),
      _vm._v("奖励（需先解锁50元基础药费处方任务） ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "tg-uc1r" }, [_vm._v("邀请医生")]),
        _c("th", { staticClass: "tg-uc1r" }, [_vm._v("手机号码")]),
        _c("th", { staticClass: "tg-uc1r" }, [_vm._v("认证状态")]),
        _c("th", { staticClass: "tg-uc1r" }, [_vm._v("奖励(喜分)")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "text-align": "center", "font-size": "12px" } },
      [
        _vm._v("如有疑问，请联系客服："),
        _c("span", { staticStyle: { color: "#A76230" } }, [
          _vm._v("4006368988")
        ]),
        _c("br"),
        _c("br"),
        _vm._v("最终解释权归喜郎中所有")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }