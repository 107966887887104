"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'InviteDoctor',
  props: {},
  data: function data() {
    return {
      doctor: undefined
    };
  },
  created: function created() {},
  mounted: function mounted() {
    console.log(this.$route.query);
    this.fetchInviteUrl(this.$route.query);
  },
  methods: {
    fetchInviteUrl: function fetchInviteUrl(q) {
      var _this = this;

      _core.doctorInteractor.fetchInviteUrl(q).then(function (data) {
        console.log(data);
        _this.doctor = data;
      });
    },
    jumpToUrl: function jumpToUrl() {
      window.location.href = this.doctor.promotion_url;
    }
  }
};
exports.default = _default;