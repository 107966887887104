"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Community = exports.CommunityHandleScan = exports.CommunityCheckPostDrafts = exports.CommunityDeletePost = exports.CommunityGetPostShareUrl = exports.CommunityToggleFavorite = exports.CommunityComplaintTypeList = exports.CommunitySetComplaint = exports.CommunityFileUpload = exports.CommunityHotTagList = exports.CommunityFollowOFansList = exports.CommunityNewsSysList = exports.CommunityNewsCommentList = exports.CommunityNewsVoteList = exports.CommunityNewsFollowList = exports.CommunityNewsList = exports.CommunityDoctorInfo = exports.toggleFollow = exports.setComment = exports.commentList = exports.postDetail = exports.toggleVote = exports.postList = exports.categoriesList = exports.setPost = void 0;
var setPost = '/api/set_post';
exports.setPost = setPost;
var categoriesList = '/api/categories_list';
exports.categoriesList = categoriesList;
var postList = '/api/post_list';
exports.postList = postList;
var toggleVote = '/api/toggle_vote';
exports.toggleVote = toggleVote;
var postDetail = '/api/post_detail';
exports.postDetail = postDetail;
var commentList = '/api/comment_list';
exports.commentList = commentList;
var setComment = '/api/set_comment';
exports.setComment = setComment;
var toggleFollow = '/api/toggle_follow';
exports.toggleFollow = toggleFollow;
var CommunityDoctorInfo = '/api/lz_doctor_info';
exports.CommunityDoctorInfo = CommunityDoctorInfo;
var CommunityNewsList = '/api/news_list';
exports.CommunityNewsList = CommunityNewsList;
var CommunityNewsFollowList = '/api/news_follow_list';
exports.CommunityNewsFollowList = CommunityNewsFollowList;
var CommunityNewsVoteList = '/api/news_vote_list';
exports.CommunityNewsVoteList = CommunityNewsVoteList;
var CommunityNewsCommentList = '/api/news_comment_list';
exports.CommunityNewsCommentList = CommunityNewsCommentList;
var CommunityNewsSysList = '/api/news_sys_list';
exports.CommunityNewsSysList = CommunityNewsSysList;
var CommunityFollowOFansList = '/api/follow_or_fans_list';
exports.CommunityFollowOFansList = CommunityFollowOFansList;
var CommunityHotTagList = '/api/hot_tag_list';
exports.CommunityHotTagList = CommunityHotTagList;
var CommunityFileUpload = '/common/file/upload';
exports.CommunityFileUpload = CommunityFileUpload;
var CommunitySetComplaint = '/api/set_complaint';
exports.CommunitySetComplaint = CommunitySetComplaint;
var CommunityComplaintTypeList = '/api/complaint_type_list';
exports.CommunityComplaintTypeList = CommunityComplaintTypeList;
var CommunityToggleFavorite = '/api/toggle_favorite';
exports.CommunityToggleFavorite = CommunityToggleFavorite;
var CommunityGetPostShareUrl = '/api/get_post_share_url';
exports.CommunityGetPostShareUrl = CommunityGetPostShareUrl;
var CommunityDeletePost = '/api/delete_post';
exports.CommunityDeletePost = CommunityDeletePost;
var CommunityCheckPostDrafts = '/api/check_post_drafts';
exports.CommunityCheckPostDrafts = CommunityCheckPostDrafts;
var CommunityHandleScan = '/api/handle_scan';
exports.CommunityHandleScan = CommunityHandleScan;
var Community = '';
exports.Community = Community;