var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg" },
    [
      _c(
        "div",
        {},
        [
          _vm.integralInfo
            ? _c(
                "van-grid",
                { attrs: { border: false, "column-num": 2 } },
                [
                  _c("van-grid-item", [
                    _c("div", { staticStyle: { "text-align": "center" } }, [
                      _c("div", [_vm._v("账户剩余经验值")]),
                      _c("div", { staticClass: "header-title" }, [
                        _vm._v(_vm._s(_vm.integralInfo.integral))
                      ])
                    ])
                  ]),
                  _c("van-grid-item", [
                    _c("div", { staticStyle: { "text-align": "center" } }, [
                      _c("div", [_vm._v("账户剩余喜分")]),
                      _c("div", { staticClass: "header-title" }, [
                        _vm._v(_vm._s(_vm.integralInfo.income))
                      ])
                    ])
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("div"),
      _vm.goodsInfoType != 2
        ? _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "van-panel",
                { attrs: { title: "", "use-header-slot": "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "address-header",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { "font-size": "14px", color: "#9B9B9B" }
                        },
                        [_vm._v("收货地址")]
                      )
                    ]
                  ),
                  _c(
                    "van-radio-group",
                    {
                      model: {
                        value: _vm.address,
                        callback: function($$v) {
                          _vm.address = $$v
                        },
                        expression: "address"
                      }
                    },
                    [
                      _c(
                        "van-cell-group",
                        _vm._l(_vm.addresses, function(item) {
                          return _c(
                            "van-cell",
                            {
                              key: item.id,
                              attrs: {
                                title:
                                  item.region +
                                  item.detail +
                                  " " +
                                  item.name +
                                  " " +
                                  item.mobile,
                                clickable: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addressOnChange(item)
                                }
                              }
                            },
                            [
                              _c("van-radio", {
                                attrs: {
                                  slot: "right-icon",
                                  "checked-color": "#ff9000",
                                  name: item.id
                                },
                                slot: "right-icon"
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "line-height": "44px",
                    "text-align": "center",
                    "background-color": "white"
                  }
                },
                [
                  _c(
                    "van-button",
                    {
                      staticStyle: { border: "0" },
                      attrs: {
                        plain: "",
                        size: "small",
                        color: "#ff9000",
                        to: "/mallAddressEdit?type=2"
                      }
                    },
                    [_vm._v("+ 添加地址")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.goodsInfo
        ? _c(
            "van-panel",
            {
              staticStyle: { "margin-top": "10px" },
              attrs: { title: "商品详情" }
            },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-card", {
                    staticStyle: { "background-color": "white" },
                    attrs: {
                      num: "1",
                      desc: _vm.goodsInfo.title,
                      title: _vm.goodsInfo.name,
                      currency: "",
                      thumb: _vm.goodsInfo.cover_image_file.url
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "price",
                          fn: function() {
                            return [
                              _c("div", [_vm._v(_vm._s(_vm.goodsInfoPrice()))])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3607606083
                    )
                  }),
                  _c("van-field", {
                    attrs: {
                      name: "quantity",
                      label: "数量",
                      "input-align": "right"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function() {
                            return [
                              _c("van-stepper", {
                                attrs: {
                                  value: _vm.quantity,
                                  integer: "",
                                  min: "1",
                                  max: "99",
                                  "async-change": ""
                                },
                                on: { change: _vm.stepperOnChange }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      471696952
                    )
                  }),
                  _c("van-cell", { attrs: { title: "快递费", value: "包邮" } }),
                  _c("van-cell", {
                    attrs: {
                      title: "优惠券",
                      value:
                        _vm.usableCouponsList.length == 0
                          ? "不可用"
                          : "-" + _vm.selectCoupon.reduce + "喜分",
                      "value-class":
                        _vm.usableCouponsList.length == 0
                          ? "gary-value"
                          : "orange-value",
                      "is-link": ""
                    },
                    on: { click: _vm.couponClick }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-popup",
        {
          style: { height: "70%", backgroundColor: "#f3f3f3" },
          attrs: { position: "top" },
          model: {
            value: _vm.popupShow,
            callback: function($$v) {
              _vm.popupShow = $$v
            },
            expression: "popupShow"
          }
        },
        [
          _c(
            "van-radio-group",
            {
              on: { change: _vm.radioChange },
              model: {
                value: _vm.couponCheckbox,
                callback: function($$v) {
                  _vm.couponCheckbox = $$v
                },
                expression: "couponCheckbox"
              }
            },
            _vm._l(_vm.usableCouponsList, function(item, index) {
              return _c(
                "van-radio",
                {
                  key: index,
                  staticStyle: { width: "100%", padding: "4px" },
                  attrs: { name: item.id, "checked-color": "#ff9000" }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        display: "-webkit-flex",
                        "align-items": "center",
                        height: "90px",
                        "border-radius": "10px",
                        overflow: "hidden"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "1",
                            height: "90px",
                            "text-align": "center",
                            width: "120px",
                            "flex-direction": "column",
                            display: "flex",
                            color: "white",
                            "justify-content": "center",
                            background:
                              "linear-gradient(269deg, #FEBF50 2%, #FEBF50 2%, #FFA437 99%, #FFA437 99%)"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "30px",
                                "font-weight": "400"
                              }
                            },
                            [_vm._v("¥" + _vm._s(item.reduce))]
                          ),
                          _c("div", { staticStyle: { "font-size": "12px" } }, [
                            _vm._v("满" + _vm._s(item.minimum) + "可用")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-left": "10px",
                            flex: "2",
                            height: "90px",
                            background: "white",
                            "flex-direction": "column",
                            display: "flex",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "van-tag",
                                {
                                  attrs: {
                                    color: "#F8CE50",
                                    "text-color": "#623A05"
                                  }
                                },
                                [_vm._v("集市券")]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#373637",
                                    "font-weight": "400",
                                    "font-size": "16px"
                                  }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                color: "#A3A3A3"
                              }
                            },
                            [_vm._v("有效期至" + _vm._s(item.expire_time))]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                color: "#F7931C"
                              }
                            },
                            [_vm._v(_vm._s(item.remark))]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "van-button",
                {
                  staticStyle: { width: "80%" },
                  attrs: { round: "", color: "#ff9000" },
                  on: {
                    click: function($event) {
                      _vm.popupShow = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-submit-bar",
        {
          attrs: {
            label: "总计 ",
            "text-align": "left",
            "button-text": "立即兑换"
          },
          on: { submit: _vm.payAction }
        },
        [
          _c("div", { staticStyle: { width: "260px" } }, [
            _c("div", [_vm._v("总计")]),
            _c(
              "div",
              {
                staticStyle: {
                  color: "#f99000",
                  "font-weight": "bold",
                  "font-size": "16px"
                }
              },
              [_vm._v(_vm._s(_vm.totalAmount))]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }