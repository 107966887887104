"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'november202211',
  props: {},
  data: function data() {
    return {
      type: 1,
      detail: undefined,
      show: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var type = this.$route.query.type;

    if (type) {
      this.type = type;
      console.log(type);
    }

    this.fetchActivitySubsidiesDetail({
      type: this.type
    });
  },
  methods: {
    fetchActivitySubsidiesDetail: function fetchActivitySubsidiesDetail(dict) {
      var _this = this;

      _core.mallInteractor.fetchActivitySubsidiesDetail(dict).then(function (data) {
        _this.detail = data;
      });
    }
  }
};
exports.default = _default;