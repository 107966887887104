var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#fcf1df" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/november202211/banner_" +
            _vm.type +
            ".png")
        }
      }),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "30px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/november202211/t_h_0.png")
                }
              })
            ],
            1
          ),
          _vm.detail
            ? _c(
                "div",
                {
                  staticStyle: {
                    padding: "50px 23px 30px 23px",
                    "line-height": "180%",
                    width: "100%",
                    color: "#38404E",
                    "text-align": "center",
                    "font-size": "16px"
                  }
                },
                [
                  _vm.type == 1
                    ? _c("div", [
                        _c("div", [
                          _vm._v("累计悬壶值："),
                          _c("span", { staticClass: "red-title" }, [
                            _vm._v(_vm._s(_vm.detail.amount))
                          ])
                        ]),
                        _c("div", [
                          _vm._v("奖励喜分："),
                          _c("span", { staticClass: "red-title" }, [
                            _vm._v(_vm._s(_vm.detail.income))
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm.type == 2
                    ? _c("div", [
                        _c("div", [
                          _vm._v("上月悬壶值："),
                          _c("span", { staticClass: "red-title" }, [
                            _vm._v(_vm._s(_vm.detail.last_amount))
                          ])
                        ]),
                        _c("div", [
                          _vm._v("本月悬壶值："),
                          _c("span", { staticClass: "red-title" }, [
                            _vm._v(_vm._s(_vm.detail.amount))
                          ])
                        ]),
                        _c("div", [
                          _vm._v("环比增长悬壶值："),
                          _c("span", { staticClass: "red-title" }, [
                            _vm._v(_vm._s(_vm.detail.difference_amount))
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm.type == 3
                    ? _c("div", [
                        _c("div", [
                          _vm._v("当前悬壶值："),
                          _c("span", { staticClass: "red-title" }, [
                            _vm._v(_vm._s(_vm.detail.amount))
                          ])
                        ]),
                        _c("div", [
                          _vm._v("当前排名："),
                          _c("span", { staticClass: "red-title" }, [
                            _vm._v(_vm._s(_vm.detail.rank_info))
                          ])
                        ]),
                        _vm.detail.rank_info == 1
                          ? _c("div", [_vm._v("恭喜您已排名首位")])
                          : _c("div", [
                              _vm._v("距离上一名还差"),
                              _c("span", { staticClass: "red-title" }, [
                                _vm._v(_vm._s(_vm.detail.difference_amount))
                              ]),
                              _vm._v("悬壶值")
                            ])
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      ),
      _c(
        "van-popup",
        {
          staticStyle: { width: "90%", height: "60%" },
          attrs: { closeable: "" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "text-align": "center",
                    padding: "20px",
                    "font-size": "18px",
                    "font-weight": "bold"
                  }
                },
                [_vm._v("奖励记录")]
              ),
              _c("div", { staticStyle: { width: "100%", color: "#F39800" } }, [
                _vm._v(
                  "累计奖励：" + _vm._s(_vm.detail.total_award_amount) + "喜分"
                )
              ]),
              _c("van-divider"),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-around"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "cell-tg",
                      staticStyle: { "font-weight": "bold" }
                    },
                    [_vm._v("奖励日期")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "cell-tg",
                      staticStyle: { "font-weight": "bold" }
                    },
                    [_vm._v("奖励喜分")]
                  )
                ]
              ),
              _vm._l(_vm.detail.award_amount_list, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-around",
                      padding: "10px 0px"
                    }
                  },
                  [
                    _c("div", { staticClass: "cell-tg" }, [
                      _vm._v(_vm._s(item.date))
                    ]),
                    _c("div", { staticClass: "cell-tg" }, [
                      _vm._v(_vm._s(item.award_amount))
                    ])
                  ]
                )
              })
            ],
            2
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "30px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/november202211/t_h_1.png")
                }
              })
            ],
            1
          ),
          _vm.type == 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    padding: "40px 23px 30px 23px",
                    "line-height": "180%"
                  }
                },
                [_vm._m(0), _vm._m(1)]
              )
            : _vm._e(),
          _vm.type == 2
            ? _c(
                "div",
                {
                  staticStyle: {
                    padding: "40px 23px 30px 23px",
                    "line-height": "180%"
                  }
                },
                [_vm._m(2), _vm._m(3)]
              )
            : _vm._e(),
          _vm.type == 3
            ? _c(
                "div",
                {
                  staticStyle: {
                    padding: "40px 23px 30px 23px",
                    "line-height": "180%"
                  }
                },
                [_vm._m(4), _vm._m(5), _vm._m(6)]
              )
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "30px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/november202211/t_h_2.png")
                }
              })
            ],
            1
          ),
          _vm._m(7)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "30px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/november202211/t_h_3.png")
                }
              })
            ],
            1
          ),
          _vm._m(8)
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            padding: "0px 0 30px 0"
          }
        },
        [
          _c("van-image", {
            staticStyle: { width: "35%", "z-index": "0" },
            attrs: {
              fit: "fill",
              src: require("../../assets/activity/november202211/bottom_logo.png")
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { color: "#38404E" } }, [
      _vm._v(
        " 本次活动以悬壶值为标准，用户本月达到一定的悬壶目标值可获得相应的补贴奖励，奖励详情如下:"
      ),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "table",
      { staticClass: "tg", staticStyle: { width: "100%", margin: "10px 0" } },
      [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "tg-uc1r" }, [_vm._v("累计悬壶值")]),
            _c("th", { staticClass: "tg-uc1r" }, [_vm._v("目标奖励戏份")])
          ])
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("2000")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("100喜分")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("3000")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("200喜分")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("5000")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("300喜分")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("8000")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("500喜分")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("10000")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("800喜分")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("15000")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("1000喜分")])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { color: "#38404E" } }, [
      _vm._v(
        " 本次活动以悬壶值为标准，在活动期间达到环比上个月增长悬壶值可得相应喜分，奖励档位详情如下："
      ),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "table",
      { staticClass: "tg", staticStyle: { width: "100%", margin: "10px 0" } },
      [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "tg-uc1r" }, [_vm._v("环比悬壶值")]),
            _c("th", { staticClass: "tg-uc1r" }, [_vm._v("奖励喜分")])
          ])
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("1000")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("88喜分")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("2000")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("188喜分")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("3000")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("285喜分")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("5000")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("490喜分")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("8000")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("800喜分")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("10000")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("1500喜分")])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { color: "#38404E" } }, [
      _vm._v(
        " 本次活动以累计悬壶值为标准，活动期间根据以下排名获得相应补贴，可实时观看自己排名情况，以及距离上一名还差多少金额可进行超越，奖励发放以最终排名为准。"
      ),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "table",
      { staticClass: "tg", staticStyle: { width: "100%", margin: "10px 0" } },
      [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "tg-uc1r" }, [_vm._v("排名")]),
            _c("th", { staticClass: "tg-uc1r" }, [_vm._v("补贴")])
          ])
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("第1名")]),
            _c("td", { staticClass: "tg-zs29" }, [
              _vm._v("5000元京东卡+1000喜分")
            ])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("第2名")]),
            _c("td", { staticClass: "tg-zs29" }, [
              _vm._v("3000元京东卡+500喜分")
            ])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("第3名")]),
            _c("td", { staticClass: "tg-zs29" }, [
              _vm._v("2000元京东卡+200喜分")
            ])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("第4-10名")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("1500元京东卡")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("第11-30名")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("1000元京东卡")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("第31-50名")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("500元京东卡")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("第51-100名")]),
            _c("td", { staticClass: "tg-zs29" }, [_vm._v("100元京东卡")])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { color: "#38404E" } }, [
      _vm._v(" 注：第一名且基础药费达"),
      _c("span", { staticStyle: { color: "#F39800" } }, [_vm._v("80000")]),
      _vm._v("以上才能得"),
      _c("span", { staticStyle: { color: "#F39800" } }, [_vm._v("1000")]),
      _vm._v("喜分，若没达到则只有京东卡。 第二名且基础药费达"),
      _c("span", { staticStyle: { color: "#F39800" } }, [_vm._v("70000")]),
      _vm._v("以上才能得"),
      _c("span", { staticStyle: { color: "#F39800" } }, [_vm._v("500")]),
      _vm._v("喜分，若没达到则只有京东卡。 第三名且基础药费达"),
      _c("span", { staticStyle: { color: "#F39800" } }, [_vm._v("60000")]),
      _vm._v("以上才能得"),
      _c("span", { staticStyle: { color: "#F39800" } }, [_vm._v("200")]),
      _vm._v("喜分，若没达到则只有京东卡。 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { padding: "40px 23px 0px 23px", width: "100%" } },
      [
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v("Q:悬壶值怎么获得？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [
            _vm._v(
              "A: 医师开具处方且该笔处方完成支付后，对应订单的基础药费将转换为等量悬壶值。"
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v(" Q:若有退货情况怎么办？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [_vm._v("A: 若有退货情况，悬壶值将退回，且不计入活动统计。")]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v("Q:奖励什么时候到账?")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [_vm._v("A: 奖励将至12月5日统一发放。")]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
          [_vm._v(" Q：开具单品订单能参与活动吗？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [
            _vm._v(
              "A：不能，只有开具颗粒剂型、膏方的处方悬壶值会计入活动奖励金额，单品处方均不计入此项活动。"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "40px 23px 20px 23px",
          color: "#38404E",
          "line-height": "180%"
        }
      },
      [
        _vm._v(
          " 1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(" 3、本次活动最终解释权归喜郎中所有。 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }