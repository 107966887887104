var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            "line-height": "40px"
          }
        },
        [
          _c("van-icon", { attrs: { color: "#ff9000", name: "balance-pay" } }),
          _c(
            "div",
            {
              staticStyle: { color: "#ff9000" },
              on: { click: _vm.pushAction }
            },
            [_vm._v("查看对账记录")]
          )
        ],
        1
      ),
      _c("div", {
        staticStyle: { "background-color": "#f3f3f3", height: "10px" }
      }),
      _c("div", [
        _c("div", { staticStyle: { margin: "10px" } }, [
          _vm._v("月结提现至当前银行卡")
        ]),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "line-height": "40px",
              "justify-content": "space-evenly"
            }
          },
          [
            _c("van-image", {
              attrs: {
                fit: "contain",
                width: "60",
                height: "30",
                src: require("../../assets/bank.png")
              }
            }),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.userInfo.bank_card.bank +
                    " 尾号(" +
                    _vm.userInfo.bank_card.account_number.slice(-4) +
                    ")"
                )
              )
            ])
          ],
          1
        )
      ]),
      _c("van-divider", { attrs: { dashed: "" } }),
      _c(
        "div",
        {
          staticStyle: { "text-align": "center", color: "red", margin: "10px" }
        },
        [_vm._v("月结自动提现服务已开通")]
      ),
      _c(
        "div",
        {
          staticStyle: {
            "text-align": "center",
            color: "gray",
            "font-size": "12px"
          }
        },
        [_vm._v("每月10日（非工作日顺延）将转入对应账号中")]
      ),
      _c("van-divider"),
      _c(
        "div",
        {
          staticStyle: {
            "text-align": "left",
            margin: "10px",
            color: "#ff9000",
            "font-size": "13px"
          },
          on: { click: _vm.pushToDoctorCashAppList }
        },
        [_vm._v("兑换记录")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }