import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'configActivity1',
  props: {},
  data: function data() {
    return {
      code: undefined,
      detail: undefined,
      show: false,
      result_item: undefined,
      // 中奖结果
      luckShowLoading: false,
      luckShow: false,
      itemList: [],
      buttonPopupShow: false,
      buttonPopupShowType: 0
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var code = this.$route.query.code;

    if (code) {
      this.code = code;
      console.log(code);
    }

    this.fetchConfigActivityDetail();
    this.fetchActivityLogs();
  },
  methods: {
    buttonPopupShowAction: function buttonPopupShowAction(type) {
      this.buttonPopupShowType = type;
      this.buttonPopupShow = true;
    },
    fetchConfigActivityDetail: function fetchConfigActivityDetail() {
      var _this = this;

      mallInteractor.fetchConfigActivityDetail({
        code: this.code
      }).then(function (data) {
        _this.detail = data;
        var pl = data.reward_items;
        var tmp = [];
        pl.forEach(function (element) {
          if (element.type != 6) {
            tmp.push(element);
          }
        });
        _this.itemList = tmp;

        if (_this.detail.activity_status == 1) {
          Dialog.alert({
            title: '温馨提醒',
            message: '活动暂未开始'
          }).then(function () {// on close
          });
        }
      });
    },
    //点击抽奖按钮
    lotteryClick: function lotteryClick() {
      var _this2 = this;

      if (this.luckShowLoading == true) {
        return;
      }

      this.luckShowLoading = true;
      setTimeout(function () {
        mallInteractor.fetchConfigActivityReceive({
          code: _this2.code
        }).then(function (data) {
          _this2.result_item = data.result_item;
          _this2.luckShow = true;
          _this2.luckShowLoading = false;
        }).catch(function (e) {
          _this2.luckShowLoading = false;
        });
      }, 2000);
    },
    luckPopupClose: function luckPopupClose() {
      this.luckShow = false;
      this.luckShowLoading = false;
      this.result_item = undefined;
      this.fetchConfigActivityDetail();
    },
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    jumpToRewardList: function jumpToRewardList() {
      this.$router.push({
        path: '/configActivityRewardList',
        query: {
          code: this.code
        }
      });
    },
    fetchActivityConfigReceive: function fetchActivityConfigReceive() {
      var _this3 = this;

      if (this.detail.receive_button_status != 1 || this.luckShowLoading == true) {
        return;
      }

      mallInteractor.fetchActivityConfigReceive({
        code: this.code
      }).then(function (data) {
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
        } else {
          Toast('领取成功');
          setTimeout(function () {
            _this3.fetchActivityConfigDetail();
          }, 1000);
        }
      });
    }
  }
};