var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#FFFBF6" }
    },
    [
      _c(
        "div",
        { staticStyle: { "text-align": "center", padding: "20px 10px" } },
        [
          _c("van-image", {
            staticStyle: { width: "80%" },
            attrs: {
              fit: "contain",
              src: require("../../assets/activity/activityType2202310/r_l_t.png")
            },
            on: {
              click: function($event) {
                return _vm.pushToList()
              }
            }
          })
        ],
        1
      ),
      _vm.data && _vm.data.doctor_recipes && _vm.data.doctor_recipes.list.length
        ? _c(
            "div",
            { staticClass: "card" },
            [
              _vm._m(0),
              _vm._l(_vm.data.doctor_recipes.list, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "inner-card" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#A76230",
                          "font-weight": "bold",
                          "line-height": "20px",
                          "font-size": "16px",
                          "padding-bottom": "10px"
                        }
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                    _vm._l(item.recipe_items, function(r, ri) {
                      return _c(
                        "span",
                        {
                          key: ri,
                          staticStyle: {
                            "line-height": "22px",
                            "font-size": "14px"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                r.name +
                                  r.weight * item.quantity +
                                  r.main_unit +
                                  (ri == item.recipe_items.length - 1
                                    ? ""
                                    : "、")
                              )
                            )
                          ])
                        ]
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "margin-top": "14px"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("van-switch", {
                              attrs: {
                                value: item.added == 1,
                                size: "15",
                                "active-color": "#F39800"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.switchOnInput(item)
                                }
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#A76230",
                                  "font-size": "14px",
                                  padding: "0px 10px"
                                }
                              },
                              [_vm._v("生成海报")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            },
                            on: {
                              click: function($event) {
                                return _vm.recipeShare(item)
                              }
                            }
                          },
                          [
                            _c("van-image", {
                              staticStyle: { width: "24px" },
                              attrs: {
                                fit: "contain",
                                src: require("../../assets/activity/activityType2202310/r_l_wx.png")
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#A76230",
                                  "font-size": "14px",
                                  padding: "0px 10px"
                                }
                              },
                              [_vm._v("发给患者")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  2
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm.data && _vm.data.platform_recipes
        ? _c(
            "div",
            { staticClass: "card" },
            [
              _vm._m(1),
              _vm._l(_vm.data.platform_recipes.list, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "inner-card" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#A76230",
                          "font-weight": "bold",
                          "font-size": "16px",
                          "padding-bottom": "10px"
                        }
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                    _vm._l(item.recipe_items, function(r, ri) {
                      return _c(
                        "span",
                        {
                          key: ri,
                          staticStyle: {
                            "line-height": "22px",
                            "font-size": "14px"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                r.name +
                                  r.weight * item.quantity +
                                  r.main_unit +
                                  (ri == item.recipe_items.length - 1
                                    ? ""
                                    : "、")
                              )
                            )
                          ])
                        ]
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#A76230",
                          "font-size": "14px",
                          "margin-top": "14px"
                        }
                      },
                      [
                        _vm._v(" 售价："),
                        _c("span", [_vm._v(_vm._s(item.sale_price))]),
                        _vm._v("元 "),
                        _c(
                          "span",
                          { staticStyle: { "padding-left": "20px" } },
                          [_vm._v("收益：" + _vm._s(item.income))]
                        ),
                        _vm._v("喜分 ")
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "margin-top": "14px"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("van-switch", {
                              attrs: {
                                value: item.added == 1,
                                size: "15",
                                "active-color": "#F39800"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.switchOnInput(item)
                                }
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#A76230",
                                  "font-size": "14px",
                                  padding: "0px 10px"
                                }
                              },
                              [_vm._v("生成海报")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            },
                            on: {
                              click: function($event) {
                                return _vm.accordRecipeShare(item)
                              }
                            }
                          },
                          [
                            _c("van-image", {
                              staticStyle: { width: "24px" },
                              attrs: {
                                fit: "contain",
                                src: require("../../assets/activity/activityType2202310/r_l_wx.png")
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#A76230",
                                  "font-size": "14px",
                                  padding: "0px 10px"
                                }
                              },
                              [_vm._v("发给患者")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  2
                )
              })
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("van-image", {
            staticStyle: { width: "78%" },
            attrs: {
              fit: "contain",
              src: require("../../assets/activity/activityType2202310/r_l_b.png")
            },
            on: {
              click: function($event) {
                return _vm.pushToPatientUrl()
              }
            }
          })
        ],
        1
      ),
      _c("div", { staticStyle: { height: "40px" } })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [_vm._v("专属膏方")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [_vm._v("平台推荐")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }