"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Community',
  props: {},
  data: function data() {
    return {
      detail: undefined,
      tabsActive: 2,
      tabs: [{
        title: '关注',
        name: 1
      }, {
        title: '热门',
        name: 2
      }, {
        title: '最新',
        name: 0
      }],
      query: {
        page: 0,
        size: 10,
        index_type: 2
      },
      actionSheetItem: undefined,
      actionSheetShow: false,
      actions: [{
        name: '分享'
      }, {
        name: '投诉'
      }, {
        name: '取消'
      }],
      list: [],
      loading: false,
      finished: false,
      refreshing: false
    };
  },
  created: function created() {},
  activated: function activated() {
    this.fetchCommunityDoctorInfo();
  },
  methods: {
    fetchCommunityDoctorInfo: function fetchCommunityDoctorInfo() {
      var _this = this;

      _core.communityInteractor.fetchCommunityDoctorInfo().then(function (data) {
        _this.detail = data;
      });
    },
    onClickSearch: function onClickSearch() {
      this.$router.push({
        path: '/communitySearch'
      });
    },
    threePoitAction: function threePoitAction(item) {
      this.actionSheetShow = true;
      this.actionSheetItem = item;
    },
    actionSheetOnSelect: function actionSheetOnSelect(item, index) {
      console.log(item);

      if (index == 0) {
        this.shareAction(this.actionSheetItem);
      }

      if (index == 1) {
        this.$router.push({
          path: '/communityComplaint',
          query: {
            'object_id': this.actionSheetItem.id,
            'object_type': 'post'
          }
        });
      }

      if (index == 2) {
        this.actionSheetItem = undefined;
      }

      this.actionSheetShow = false;
    },
    shareAction: function shareAction(item) {
      _core.communityInteractor.fetchCommunityGetPostShareUrl({
        post_id: item.id
      }).then(function (data) {
        var dict = {
          'title': item.title,
          'info': item.strip_tag_content,
          'url': process.env.VUE_APP_URL + "/postDetail?scene=h5&id=".concat(data.id)
        };

        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          // ios
          // JS通知WKWebView
          window.webkit.messageHandlers.share.postMessage(dict);
        } else if (/(Android)/i.test(navigator.userAgent)) {
          // android
          window.a5ixlz.share(JSON.stringify(dict)); // window.a5ixlz.startActivity(dict)
        }
      });
    },
    clickCell: function clickCell(item) {
      this.$router.push({
        path: '/postDetail',
        query: {
          id: item.id,
          scene: 'app'
        }
      });
    },
    clickAvatar: function clickAvatar(id) {
      this.$router.push({
        path: '/communityDoctorInfo',
        query: {
          doctor_id: id
        }
      });
    },
    fetchToggleVote: function fetchToggleVote(item) {
      _core.communityInteractor.fetchToggleVote({
        object_type: 'post',
        object_id: item.id
      }).then(function (data) {
        item.is_voted = !item.is_voted;
        item.vote_count = item.is_voted ? item.vote_count + 1 : item.vote_count - 1;
      });
    },
    tabsOnClick: function tabsOnClick(name, title) {
      this.query.index_type = name;
      this.onRefresh();
    },
    onLoad: function onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      this.query.page = this.query.page + 1;
      console.log('onLoad');
      this.fetchPostList(this.query);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false;
      console.log('onRefresh');
      console.log(this.query);
      this.fetchCommunityDoctorInfo(); // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchPostList(this.query);
    },
    fetchPostList: function fetchPostList(query) {
      var _this2 = this;

      _core.communityInteractor.fetchPostList(query).then(function (data) {
        if (_this2.query.page === 1) {
          _this2.list = data.list;
        } else {
          _this2.list = [].concat((0, _toConsumableArray2.default)(_this2.list), (0, _toConsumableArray2.default)(data.list));
        }

        if (data.total <= _this2.list.length) {
          _this2.finished = true;
        }

        console.log(_this2.list);
        _this2.loading = false;
      });
    },
    jumpToMy: function jumpToMy() {
      this.$router.push({
        path: '/mySpace'
      });
    },
    jumpToEditPost: function jumpToEditPost() {
      this.$router.push({
        path: '/editPost'
      });
    }
  }
};
exports.default = _default;