"use strict";

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.number.to-fixed");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'target202209',
  props: {},
  data: function data() {
    return {
      detail: undefined,
      percentageTxt: 100,
      percentage: 0
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityTargetDetail();
  },
  methods: {
    fetchActivityTargetDetail: function fetchActivityTargetDetail() {
      var _this = this;

      _core.mallInteractor.fetchActivityTargetDetail().then(function (data) {
        _this.detail = data;
        var n = (Number(data.current_amount / data.target_amount) * 100).toFixed(0);

        if (n > 100) {
          _this.percentage = 100;
        } else {
          _this.percentage = n;
        }

        _this.percentageTxt = n + '%';
      });
    },
    jumpToMall: function jumpToMall(id) {
      if (id != undefined) {
        this.$router.push({
          path: '/mallActivitiesItemList',
          query: {
            id: id
          }
        });
      }
    }
  }
};
exports.default = _default;