var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "white" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/inviteDoctor/banner.png")
        }
      }),
      _vm.detail
        ? _c(
            "div",
            {
              staticClass: "card",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "0px",
                "flex-direction": "column",
                "align-items": "center"
              }
            },
            [
              _c("van-image", {
                staticStyle: { width: "80%", padding: "10px 0" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/inviteDoctor/top.png")
                },
                on: {
                  click: function($event) {
                    _vm.popupShow = true
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/inviteDoctor/i_t_0.png")
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { padding: "50px 16px 20px 16px", width: "100%" } },
            [
              _c(
                "div",
                {
                  staticClass: "main-title",
                  staticStyle: { color: "#5D5A57", "font-weight": "bold" }
                },
                [
                  _vm._v("累计邀请医生"),
                  _c("span", { staticStyle: { color: "#ff9000" } }, [
                    _vm._v(_vm._s(_vm.detail.total))
                  ]),
                  _vm._v("人 共获得"),
                  _c("span", { staticStyle: { color: "#ff9000" } }, [
                    _vm._v(_vm._s(_vm.detail.total_amount) + "喜分")
                  ])
                ]
              ),
              _vm._l(_vm.detail.list, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticStyle: {
                      "margin-top": "20px",
                      padding: "6px",
                      display: "flex",
                      "justify-content": "space-between",
                      "align-items": "center",
                      border: "1px solid #F39800",
                      "border-radius": "10px"
                    }
                  },
                  [
                    _c("div", { staticStyle: { padding: "4px" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            color: "#5D5A57",
                            "font-size": "15px"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(item.name) +
                              " | " +
                              _vm._s(item.status ? "已认证" : "未认证")
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "400",
                            color: "#A7A7A7",
                            "font-size": "14px",
                            "padding-top": "5px"
                          }
                        },
                        [_vm._v(_vm._s(item.created_at))]
                      )
                    ]),
                    item.button_status == 1
                      ? _c(
                          "a",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              color: "#F39800",
                              "font-size": "15px",
                              "text-decoration": "underline"
                            },
                            on: {
                              click: function($event) {
                                return _vm.jumpToDetail(item.doctor_id)
                              }
                            }
                          },
                          [_vm._v("可领取" + _vm._s(item.amount) + "喜分")]
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              color: "#A7A7A7",
                              "font-size": "15px",
                              "text-decoration": "underline"
                            },
                            on: {
                              click: function($event) {
                                return _vm.jumpToDetail(item.doctor_id)
                              }
                            }
                          },
                          [_vm._v("可领取" + _vm._s(item.amount) + "喜分")]
                        )
                  ]
                )
              })
            ],
            2
          )
        ]
      ),
      _c(
        "footer",
        {
          staticStyle: {
            width: "100%",
            position: "fixed",
            bottom: "0",
            left: "0",
            height: "60px",
            "text-align": "center"
          }
        },
        [
          _c("van-image", {
            staticStyle: { width: "55%" },
            attrs: {
              fit: "contain",
              src: require("../../assets/activity/inviteDoctor/b_b.png")
            },
            on: {
              click: function($event) {
                return _vm.shareAction()
              }
            }
          })
        ],
        1
      ),
      _c(
        "van-popup",
        {
          style: { width: "90vw" },
          attrs: { position: "center", closeable: "" },
          model: {
            value: _vm.popupShow,
            callback: function($$v) {
              _vm.popupShow = $$v
            },
            expression: "popupShow"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "50px",
                display: "flex",
                "justify-content": "center"
              }
            },
            [
              _c("div", { staticStyle: { padding: "20px 23px 20px 23px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "sub-title",
                    staticStyle: { color: "#38404E" }
                  },
                  [
                    _vm._v(
                      "1. 每邀请一名医生完成注册并认证成功，可获得50喜分奖励。"
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "sub-title",
                    staticStyle: { color: "#38404E" }
                  },
                  [
                    _vm._v(
                      "2. 被邀请医生每邀请一名患者，发起邀请的医生可额外获得5喜分奖励，最多可获得5次。"
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "sub-title",
                    staticStyle: { color: "#38404E" }
                  },
                  [
                    _vm._v(
                      "3. 被邀请医生处方达到一定基础药费金额，发起邀请的医生可额外获得喜分奖励："
                    )
                  ]
                ),
                _c(
                  "table",
                  {
                    staticClass: "tg",
                    staticStyle: { width: "100%", margin: "10px 0" }
                  },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "tg-uc1r" }, [
                          _vm._v("处方基础药费")
                        ]),
                        _c("th", { staticClass: "tg-uc1r" }, [
                          _vm._v("喜分奖励")
                        ])
                      ])
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "tg-zs29" }, [_vm._v("200")]),
                        _c("td", { staticClass: "tg-zs29" }, [_vm._v("50")])
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "tg-zs29" }, [_vm._v("500")]),
                        _c("td", { staticClass: "tg-zs29" }, [_vm._v("30")])
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "tg-zs29" }, [_vm._v("1000")]),
                        _c("td", { staticClass: "tg-zs29" }, [_vm._v("60")])
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "tg-zs29" }, [_vm._v("3000")]),
                        _c("td", { staticClass: "tg-zs29" }, [_vm._v("90")])
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "tg-zs29" }, [_vm._v("5000")]),
                        _c("td", { staticClass: "tg-zs29" }, [_vm._v("120")])
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "sub-title",
                    staticStyle: { color: "#38404E" }
                  },
                  [
                    _vm._v(
                      "4. 本奖励针对每位被邀请的医生设独立奖池，累计达到100喜分即可提取至您的收益中。"
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "sub-title",
                    staticStyle: { color: "#38404E" }
                  },
                  [
                    _vm._v(
                      "5. 奖励任务需在被邀请后一个月内完成，超时完成视为无效，将不发放奖励。"
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "sub-title",
                    staticStyle: { color: "#38404E" }
                  },
                  [_vm._v("6. 此活动最终解释权归喜郎中所有。")]
                )
              ])
            ]
          )
        ]
      ),
      _c("div", { staticStyle: { height: "60px" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }