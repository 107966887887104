"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AgentIndex = exports.PartnerPeport = exports.PromoterInfo = exports.Index = void 0;
var Index = '/api/index';
exports.Index = Index;
var PromoterInfo = '/api/promoter_info';
exports.PromoterInfo = PromoterInfo;
var PartnerPeport = '/api/partner_peport';
exports.PartnerPeport = PartnerPeport;
var AgentIndex = '/api/agent_index';
exports.AgentIndex = AgentIndex;