import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { doctorInteractor } from '@/core';
import store from '@/store';
export default {
  name: 'month_list',
  props: {},
  data: function data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      status_name: '收益',
      total_amount: 0,
      option: [],
      title: '全部',
      query: {
        page: 0,
        size: 10,
        status: this.$route.query.status,
        year: this.$route.query.year,
        month: this.$route.query.month,
        type: 0
      }
    };
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        1: 'color: orange;',
        2: 'color: red;',
        3: 'color: blue;'
      };
      return statusMap[status];
    },
    IncomestatusFilter: function IncomestatusFilter(status) {
      var IncomestatusMap = {
        1: 'color: red;',
        2: 'color: orange;',
        3: 'color: red;'
      };
      return IncomestatusMap[status];
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    dropdownItemChange: function dropdownItemChange(value, text) {
      this.title = text;
      this.finished = false;
      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchInfo(this.query);
    },
    onClick: function onClick(name, title) {
      this.finished = false;
      this.loading = true;
      this.query.page = 1;
      this.query.status = name;
      this.query.type = 0;
      this.status_name = title;
      this.list = [];
      this.fetchInfo(this.query);
    },
    reFetchData: function reFetchData() {
      this.finished = false;
      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchInfo(this.query);
    },
    fetchInfo: function fetchInfo(params) {
      var _this = this;

      doctorInteractor.fetchDoctorIncomeDetailList(params).then(function (data) {
        _this.total_amount = data.total_amount;
        _this.option = [];

        if (_this.query.page === 1) {
          _this.list = data.list;
        } else {
          _this.list = [].concat(_toConsumableArray(_this.list), _toConsumableArray(data.list));
        }

        var type_list = data.type_list[_this.query.status - 1]['list'];
        type_list.forEach(function (element) {
          var item = {
            text: element.type_name,
            value: element.type_id
          };

          _this.option.push(item);
        });

        if (data.total <= _this.list.length) {
          _this.finished = true;
        }

        _this.loading = false;
      });
    },
    onLoad: function onLoad() {
      this.loading = true;
      this.query.page = this.query.page + 1;
      this.fetchInfo(this.query);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.monthList = [];
      this.fetchInfo(this.query);
    }
  }
};