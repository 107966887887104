//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'national202210',
  props: {},
  data: function data() {
    return {
      code: undefined
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var code = this.$route.query.code;

    if (code) {
      this.code = code;
      console.log(code);
    }

    this.fetchCheckDoctorCollegeServiceGroup();
    this.fetchActivityLogs();
  },
  methods: {
    fetchCheckDoctorCollegeServiceGroup: function fetchCheckDoctorCollegeServiceGroup() {
      var _this = this;

      mallInteractor.fetchCheckDoctorCollegeServiceGroup().then(function (data) {
        // this.detail = data;
        // if (this.detail.activity_status == 1) {
        //   Dialog.alert({
        //     title: '温馨提醒',
        //     message: '活动暂未开始',
        //   }).then(() => {
        //     // on close
        //   });
        // }
        _this.jumpAction(1);
      }).catch(function (error) {
        console.log(error);
        Toast('加入群聊失败，返回主页面');
        setTimeout(function () {
          _this.jumpAction(0);
        }, 1000);
      });
    },
    jumpAction: function jumpAction(index) {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // ios
        // JS通知WKWebView
        window.webkit.messageHandlers.toMainPage.postMessage(index);
      } else if (/(Android)/i.test(navigator.userAgent)) {
        // android
        window.a5ixlz.toMainPage(index); // window.a5ixlz.startActivity(dict)
      }
    },
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    }
  }
};