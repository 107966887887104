var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body", staticStyle: { width: "100%", height: "100vh" } },
    [
      _vm.detail
        ? _c(
            "van-swipe",
            {
              ref: "swipe",
              staticStyle: { height: "100vh" },
              attrs: { vertical: "", loop: false, "show-indicators": false },
              on: { change: _vm.onChange }
            },
            [
              _c("van-swipe-item", [
                _c(
                  "div",
                  { staticStyle: { position: "relative", height: "100vh" } },
                  [
                    _c("van-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100vh",
                        "z-index": "0"
                      },
                      attrs: {
                        fit: "cover",
                        src: require("../../assets/activity/yearReport2024/y_r_0_b.png")
                      }
                    }),
                    _c("div", {
                      staticStyle: {
                        position: "absolute",
                        "z-index": "2",
                        top: "15%",
                        "text-align": "center",
                        width: "100vw"
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          bottom: "7%",
                          width: "100vw",
                          "text-align": "center"
                        },
                        on: {
                          click: function($event) {
                            return _vm.nextPage()
                          }
                        }
                      },
                      [
                        _c("van-image", {
                          staticStyle: { width: "60%", "z-index": "1" },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2024/y_r_0_b_b.png")
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm.detail.extra_data.third_data.is_new == 0
                ? _c(
                    "div",
                    [
                      _c("van-swipe-item", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              height: "100vh"
                            }
                          },
                          [
                            _c("van-image", {
                              staticStyle: {
                                width: "100%",
                                height: "100vh",
                                "z-index": "0"
                              },
                              attrs: {
                                fit: "cover",
                                src: require("../../assets/activity/yearReport2024/y_r_1_b.png")
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  "z-index": "2",
                                  top: "0",
                                  "text-align": "center",
                                  width: "100vw",
                                  transition: "transform 2.4s ease 0s",
                                  "line-height": "180%"
                                },
                                attrs: { id: "tilte-item-1" }
                              },
                              [
                                _c("div", { staticClass: "main-title" }, [
                                  _vm._v("2024年"),
                                  _c("br"),
                                  _vm._v(" 是您来到喜郎中的第 "),
                                  _c("span", { staticClass: "span-title" }, [
                                    _vm._v(_vm._s(_vm.detail.use_year))
                                  ]),
                                  _vm._v(" 年"),
                                  _c("br"),
                                  _vm._v(" 一转眼，我们已经一起度过了"),
                                  _c("span", { staticClass: "span-title" }, [
                                    _vm._v(_vm._s(_vm.detail.use_day))
                                  ]),
                                  _vm._v(" 天"),
                                  _c("br"),
                                  _vm._v(" 感恩在茫茫人海中与您相遇 ")
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  width: "100vw",
                                  left: "-300px",
                                  "text-align": "center",
                                  transition: "transform 2.4s ease 0s"
                                },
                                attrs: { id: "image-item-1" }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: {
                                    width: "100%",
                                    "z-index": "1"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2024/y_r_1_m.png")
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  width: "100vw",
                                  "text-align": "center",
                                  transition: "transform 2.4s ease 0s"
                                },
                                attrs: { id: "image-item-arrow" }
                              },
                              [
                                _c("van-image", {
                                  staticClass: "image-item-arrow",
                                  staticStyle: { width: "5%", "z-index": "1" },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2024/y_c_up.png")
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm.detail.extra_data.third_data.is_open > 0
                        ? _c("van-swipe-item", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  height: "100vh"
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100vh",
                                    "z-index": "0"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2024/y_r_1_b.png")
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "2",
                                      top: "0",
                                      "text-align": "center",
                                      width: "90vw",
                                      transition: "transform 2.4s ease 0s",
                                      "line-height": "180%"
                                    },
                                    attrs: { id: "tilte-item-2" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "main-title",
                                        staticStyle: { "text-align": "right" }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.detail.extra_data.third_data
                                                .first_time
                                            )
                                          )
                                        ]),
                                        _c("br"),
                                        _vm._v(" 是您在喜郎中首次开单的时间"),
                                        _c("br"),
                                        _vm._v(" 累计至今开方金额 "),
                                        _c(
                                          "span",
                                          { staticClass: "span-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data.third_data
                                                  .prescription_amount
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" 元 "),
                                        _c("br"),
                                        _vm._v(
                                          " 爱心真挚连千里，医德崇高惠万家"
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      left: "-300px",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-2" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "100%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2024/y_r_2_m.png")
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-arrow-2" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticClass: "image-item-arrow",
                                      staticStyle: {
                                        width: "5%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2024/y_c_up.png")
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.detail.extra_data.third_data.is_open > 0 &&
                      _vm.detail.extra_data.third_data.prescription_number
                        ? _c("van-swipe-item", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  height: "100vh"
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100vh",
                                    "z-index": "0"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2024/y_r_1_b.png")
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "2",
                                      top: "0",
                                      "text-align": "center",
                                      width: "90vw",
                                      transition: "transform 2.4s ease 0s",
                                      "line-height": "180%"
                                    },
                                    attrs: { id: "tilte-item-3" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "main-title",
                                        staticStyle: { "text-align": "left" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "span-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data
                                                  .fourth_data
                                                  .prescription_time_str
                                              )
                                            )
                                          ]
                                        ),
                                        _c("br"),
                                        _vm._v(" 是您最常开方的时间"),
                                        _c("br"),
                                        _vm.detail.extra_data.fourth_data
                                          .date &&
                                        _vm.detail.extra_data.fourth_data
                                          .hour_str &&
                                        _vm.detail.extra_data.fourth_data
                                          .time_str
                                          ? _c("span", [
                                              _c(
                                                "span",
                                                { staticClass: "span-title" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.detail.extra_data
                                                        .fourth_data.month
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v("月 "),
                                              _c(
                                                "span",
                                                { staticClass: "span-title" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.detail.extra_data
                                                        .fourth_data.day
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v("日 "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.detail.extra_data
                                                      .fourth_data.time_str
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "span-title" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.detail.extra_data
                                                        .fourth_data.hour
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v("点"),
                                              _c("br")
                                            ])
                                          : _vm._e(),
                                        _vm.detail.extra_data.fourth_data
                                          .extra_str
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.detail.extra_data
                                                    .fourth_data.extra_str
                                                )
                                              ),
                                              _c("br")
                                            ])
                                          : _vm._e(),
                                        _vm._v(" 感谢您时刻守卫患者的健康 ")
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      left: "-300px",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-3" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "100%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2024/y_r_3_m.png")
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-arrow-3" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticClass: "image-item-arrow",
                                      staticStyle: {
                                        width: "5%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2024/y_c_up.png")
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.detail.extra_data.third_data.is_open > 0 &&
                      _vm.detail.extra_data.third_data.prescription_number
                        ? _c("van-swipe-item", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  height: "100vh"
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100vh",
                                    "z-index": "0"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2024/y_r_1_b.png")
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "2",
                                      top: "0",
                                      "text-align": "center",
                                      width: "90vw",
                                      transition: "transform 2.4s ease 0s",
                                      "line-height": "180%"
                                    },
                                    attrs: { id: "tilte-item-4" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "main-title",
                                        staticStyle: { "text-align": "right" }
                                      },
                                      [
                                        _c("span", [
                                          _c(
                                            "span",
                                            { staticClass: "span-title" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.detail.extra_data
                                                    .fifth_data.max_num
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v("月 "),
                                          _c(
                                            "span",
                                            { staticClass: "span-title" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.detail.extra_data
                                                    .fifth_data.max_day
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v("日")
                                        ]),
                                        _c("br"),
                                        _vm._v(" 是您开方最多的一天"),
                                        _c("br"),
                                        _vm._v(" 开具了 "),
                                        _c(
                                          "span",
                                          { staticClass: "span-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data.fifth_data
                                                  .max_num
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" 个处方 "),
                                        _c("br"),
                                        _vm._v(
                                          " 人命至重，有贵千金，一方济之，德逾于此"
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      left: "-300px",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-4" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "100%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2024/y_r_4_m.png")
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-arrow-4" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticClass: "image-item-arrow",
                                      staticStyle: {
                                        width: "5%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2024/y_c_up.png")
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.detail.extra_data.third_data.is_open > 0 &&
                      _vm.detail.extra_data.third_data.prescription_number
                        ? _c("van-swipe-item", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  height: "100vh"
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100vh",
                                    "z-index": "0"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2024/y_r_1_b.png")
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "2",
                                      top: "0",
                                      "text-align": "center",
                                      width: "90vw",
                                      transition: "transform 2.4s ease 0s",
                                      "line-height": "180%"
                                    },
                                    attrs: { id: "tilte-item-5" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "main-title",
                                        staticStyle: { "text-align": "left" }
                                      },
                                      [
                                        _vm._v(" 今年"),
                                        _c("br"),
                                        _vm._v(" 您看病 "),
                                        _c(
                                          "span",
                                          { staticClass: "span-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data.sixth_data
                                                  .city_number
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" 个城市"),
                                        _c("br"),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#B23434",
                                              padding: "10px 0",
                                              "font-size": "20px",
                                              "font-weight": "bold"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data.sixth_data
                                                  .city_list
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" 感恩有您，辛苦了~"),
                                        _c("br")
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      left: "-300px",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-5" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "100%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2024/y_r_5_m.png")
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-arrow-5" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticClass: "image-item-arrow",
                                      staticStyle: {
                                        width: "5%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2024/y_c_up.png")
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.detail.extra_data.third_data.is_open > 0 &&
                      _vm.detail.extra_data.third_data.prescription_number
                        ? _c("van-swipe-item", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  height: "100vh"
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100vh",
                                    "z-index": "0"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: require("../../assets/activity/yearReport2024/y_r_1_b.png")
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "2",
                                      top: "0",
                                      "text-align": "center",
                                      width: "90vw",
                                      transition: "transform 2.4s ease 0s",
                                      "line-height": "180%"
                                    },
                                    attrs: { id: "tilte-item-6" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "main-title",
                                        staticStyle: { "text-align": "right" }
                                      },
                                      [
                                        _vm._v(" 这一年里"),
                                        _c("br"),
                                        _vm._v(" 您最常用的药材是"),
                                        _c("br"),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#B23434",
                                              padding: "10px 0",
                                              "font-size": "20px",
                                              "font-weight": "bold"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.detail.extra_data
                                                  .seventh_data.common_medicine
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" 知针知药，固是良医"),
                                        _c("br")
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      left: "-300px",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-6" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "100%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2024/y_r_6_m.png")
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100vw",
                                      "text-align": "center",
                                      transition: "transform 2.4s ease 0s"
                                    },
                                    attrs: { id: "image-item-arrow-6" }
                                  },
                                  [
                                    _c("van-image", {
                                      staticClass: "image-item-arrow",
                                      staticStyle: {
                                        width: "5%",
                                        "z-index": "1"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: require("../../assets/activity/yearReport2024/y_c_up.png")
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("van-swipe-item", [
                _c(
                  "div",
                  { staticStyle: { position: "relative", height: "100vh" } },
                  [
                    _c("van-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100vh",
                        "z-index": "0"
                      },
                      attrs: {
                        fit: "cover",
                        src: require("../../assets/activity/yearReport2024/y_r_1_b.png")
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          width: "100vw",
                          "text-align": "center",
                          top: "15%"
                        }
                      },
                      [
                        _c("van-image", {
                          staticStyle: { width: "90%", "z-index": "1" },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2024/y_r_7_m.png")
                          }
                        }),
                        _c("van-image", {
                          staticStyle: {
                            width: "90%",
                            "z-index": "1",
                            "padding-top": "20px"
                          },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2024/key_" +
                              _vm.detail.extra_data.eighth_data.keyword +
                              ".png")
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          width: "100vw",
                          "text-align": "center",
                          bottom: "12%"
                        },
                        on: {
                          click: function($event) {
                            return _vm.jumpToShare()
                          }
                        }
                      },
                      [
                        _c("van-image", {
                          staticStyle: { width: "70%", "z-index": "1" },
                          attrs: {
                            fit: "cover",
                            src: require("../../assets/activity/yearReport2024/y_r_6_b_b.png")
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }