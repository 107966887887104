"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'national202210',
  props: {},
  filters: {
    statusColorFilter: function statusColorFilter(status) {
      var statusMap = {
        1: '#ff9000',
        2: '#ff900045',
        3: '#d5d5d5',
        4: '#d5d5d5'
      };
      return statusMap[status];
    },
    statusTextFilter: function statusTextFilter(status) {
      var statusMap = {
        1: '领取奖励',
        2: '已领取',
        3: '暂未到达领取时间',
        4: '活动已结束'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      code: undefined,
      detail: undefined,
      show: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var code = this.$route.query.code;

    if (code) {
      this.code = code;
      console.log(code);
    }

    this.fetchActivityConfigDetail();
    this.fetchActivityLogs();
  },
  methods: {
    fetchActivityConfigDetail: function fetchActivityConfigDetail() {
      var _this = this;

      _core.mallInteractor.fetchActivityConfigDetail({
        code: this.code
      }).then(function (data) {
        _this.detail = data;

        if (_this.detail.activity_status == 1) {
          _vant.Dialog.alert({
            title: '温馨提醒',
            message: '活动暂未开始'
          }).then(function () {// on close
          });
        }
      });
    },
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      _core.mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    fetchActivityConfigReceive: function fetchActivityConfigReceive() {
      var _this2 = this;

      if (this.detail.receive_button_status != 1) {
        return;
      }

      _core.mallInteractor.fetchActivityConfigReceive({
        code: this.code
      }).then(function (data) {
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
        } else {
          (0, _vant.Toast)('领取成功');
          setTimeout(function () {
            _this2.fetchActivityConfigDetail();
          }, 1000);
        }
      });
    }
  }
};
exports.default = _default;