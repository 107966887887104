var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#f23c2b" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/january202301/banner.png")
        }
      }),
      _c(
        "div",
        {
          staticStyle: {
            "z-index": "2",
            position: "absolute",
            "background-color": "#f23c2b"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "card",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "-30px"
              }
            },
            [
              _vm.detail
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "20px 23px 30px 23px",
                        "line-height": "180%",
                        width: "100%",
                        color: "#38404E"
                      }
                    },
                    [
                      _c("p", { staticStyle: { "text-indent": "2em" } }, [
                        _vm._v(
                          " 瑞兔贺新春，快乐过大年！在此新春佳节来临之际，平台向各位医生致以最真挚的祝福和诚挚的谢意，祝愿大家春节快乐，身体健康，万事顺意，2023年我们继续与您同行！"
                        )
                      ]),
                      _vm.detail && _vm.detail.activity_status == 0
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                position: "relative"
                              }
                            },
                            [
                              _c("van-image", {
                                staticStyle: { width: "75%", "z-index": "0" },
                                attrs: {
                                  fit: "fill",
                                  src: require("../../assets/activity/january202301/button_status_0_g.png")
                                }
                              }),
                              _vm._m(0)
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.detail && _vm.detail.activity_status == 1
                        ? _c("div", [
                            _vm.detail && _vm.detail.button_status == 0
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      position: "relative"
                                    },
                                    on: { click: _vm.clickMainButton }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "75%",
                                        "z-index": "0"
                                      },
                                      attrs: {
                                        fit: "fill",
                                        src: require("../../assets/activity/january202301/button_status_0.png")
                                      }
                                    }),
                                    _vm._m(1)
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.detail && _vm.detail.button_status == 1
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      position: "relative"
                                    }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "75%",
                                        "z-index": "0"
                                      },
                                      attrs: {
                                        fit: "fill",
                                        src: require("../../assets/activity/january202301/button_status_0_g.png")
                                      }
                                    }),
                                    _vm._m(2)
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.detail && _vm.detail.button_status == 2
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      position: "relative"
                                    }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "75%",
                                        "z-index": "0"
                                      },
                                      attrs: {
                                        fit: "fill",
                                        src: require("../../assets/activity/january202301/button_status_0_g.png")
                                      }
                                    }),
                                    _vm._m(3)
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.detail && _vm.detail.button_status == 3
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      position: "relative"
                                    }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "75%",
                                        "z-index": "0"
                                      },
                                      attrs: {
                                        fit: "fill",
                                        src: require("../../assets/activity/january202301/button_status_0_g.png")
                                      }
                                    }),
                                    _vm._m(4)
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.detail &&
                            _vm.detail.button_status == 4 &&
                            _vm.detail.double_status == 0
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      position: "relative"
                                    }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "75%",
                                        "z-index": "0"
                                      },
                                      attrs: {
                                        fit: "fill",
                                        src: require("../../assets/activity/january202301/button_status_4_0_d_1.png")
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "30px",
                                          "text-align": "center",
                                          display: "flex",
                                          "justify-content": "center",
                                          "flex-direction": "column",
                                          width: "100%"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#625B5A",
                                              "font-size": "14px",
                                              "line-height": "16px"
                                            }
                                          },
                                          [_vm._v("已领取")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-top": "5px",
                                              color: "#DB3D49",
                                              display: "flex",
                                              "align-items": "baseline",
                                              "justify-content": "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                attrs: {
                                                  st: "",
                                                  i吗: "",
                                                  fv78yle:
                                                    "font-size: 24px; font-weight: 800;"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.detail
                                                      .today_multiple_amount
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "15px",
                                                  "padding-left": "5px"
                                                }
                                              },
                                              [_vm._v(" 喜分")]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.detail &&
                            _vm.detail.button_status == 4 &&
                            _vm.detail.double_status == 1 &&
                            _vm.detail.double == 0
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      position: "relative"
                                    },
                                    on: { click: _vm.multipleAction }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "75%",
                                        "z-index": "0"
                                      },
                                      attrs: {
                                        fit: "fill",
                                        src: require("../../assets/activity/january202301/button_status_4_d_0.png")
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "30px",
                                          "text-align": "center",
                                          display: "flex",
                                          "justify-content": "center",
                                          "flex-direction": "column",
                                          width: "100%"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#625B5A",
                                              "font-size": "14px",
                                              "line-height": "16px"
                                            }
                                          },
                                          [_vm._v("已领取")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-top": "5px",
                                              color: "#DB3D49",
                                              display: "flex",
                                              "align-items": "baseline",
                                              "justify-content": "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "24px",
                                                  "font-weight": "800"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.detail
                                                      .today_multiple_amount
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "15px",
                                                  "padding-left": "5px"
                                                }
                                              },
                                              [_vm._v(" 喜分")]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.detail &&
                            _vm.detail.button_status == 4 &&
                            _vm.detail.double_status == 1 &&
                            _vm.detail.double == 1
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      position: "relative"
                                    }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "75%",
                                        "z-index": "0"
                                      },
                                      attrs: {
                                        fit: "fill",
                                        src: require("../../assets/activity/january202301/button_status_4_d_1.png")
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "30px",
                                          "text-align": "center",
                                          display: "flex",
                                          "justify-content": "center",
                                          "flex-direction": "column",
                                          width: "100%"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#625B5A",
                                              "font-size": "14px",
                                              "line-height": "16px"
                                            }
                                          },
                                          [_vm._v("已领取")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-top": "5px",
                                              color: "#DB3D49",
                                              display: "flex",
                                              "align-items": "baseline",
                                              "justify-content": "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "24px",
                                                  "font-weight": "800"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.detail
                                                      .today_multiple_amount
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "15px",
                                                  "padding-left": "5px"
                                                }
                                              },
                                              [_vm._v(" 喜分")]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(" 拆取福袋数"),
                        _c("span", { staticStyle: { color: "#FF8225" } }, [
                          _vm._v(_vm._s(_vm.detail.total_count))
                        ]),
                        _vm._v("个，共"),
                        _c("span", { staticStyle: { color: "#FF8225" } }, [
                          _vm._v(_vm._s(_vm.detail.total_amount))
                        ]),
                        _vm._v("喜分 ")
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            display: "flex",
                            "justify-content": "center",
                            "margin-top": "10px"
                          },
                          on: {
                            click: function($event) {
                              _vm.popupShow = true
                            }
                          }
                        },
                        [_vm._m(5)]
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _c("van-overlay", { attrs: { show: _vm.redPackShow } }, [
            _vm.detail.double_status == 1
              ? _c("div", { attrs: { id: "try" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "rollbox",
                      class: { box_rolling: _vm.isRolling }
                    },
                    [
                      _c("div", { staticClass: "rollbox_front" }, [
                        _c("div", { staticClass: "contentbox" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                position: "relative",
                                top: "40%"
                              },
                              on: {
                                click: [
                                  function($event) {
                                    $event.stopPropagation()
                                  },
                                  _vm.multipleAction
                                ]
                              }
                            },
                            [
                              _c("van-image", {
                                staticStyle: { width: "80%", "z-index": "0" },
                                attrs: {
                                  fit: "fill",
                                  src: require("../../assets/activity/january202301/button_status_4_d_0.png")
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    top: "50px",
                                    "text-align": "center",
                                    display: "flex",
                                    "justify-content": "center",
                                    "flex-direction": "column",
                                    width: "100%"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#625B5A",
                                        "font-size": "14px",
                                        "line-height": "16px"
                                      }
                                    },
                                    [_vm._v("已领取")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "padding-top": "5px",
                                        color: "#DB3D49",
                                        display: "flex",
                                        "align-items": "baseline",
                                        "justify-content": "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "24px",
                                            "font-weight": "800"
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.redPackAmount))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "15px",
                                            "padding-left": "5px"
                                          }
                                        },
                                        [_vm._v(" 喜分")]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "rollbox_behind" }, [
                        _c("div", { staticClass: "contentbox" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                position: "relative",
                                top: "40%"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [
                              _c("van-image", {
                                staticStyle: { width: "80%", "z-index": "0" },
                                attrs: {
                                  fit: "fill",
                                  src: require("../../assets/activity/january202301/button_status_4_d_1.png")
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    top: "50px",
                                    "text-align": "center",
                                    display: "flex",
                                    "justify-content": "center",
                                    "flex-direction": "column",
                                    width: "100%"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#625B5A",
                                        "font-size": "14px",
                                        "line-height": "16px"
                                      }
                                    },
                                    [_vm._v("已领取")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "padding-top": "5px",
                                        color: "#DB3D49",
                                        display: "flex",
                                        "align-items": "baseline",
                                        "justify-content": "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "24px",
                                            "font-weight": "800"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.today_multiple_amount)
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "15px",
                                            "padding-left": "5px"
                                          }
                                        },
                                        [_vm._v(" 喜分")]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  )
                ])
              : _vm._e(),
            _vm.detail.double_status == 0
              ? _c("div", { staticStyle: { "margin-top": "40%" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        position: "relative",
                        top: "30%"
                      },
                      on: {
                        click: [
                          function($event) {
                            $event.stopPropagation()
                          },
                          _vm.overlayDismiss
                        ]
                      }
                    },
                    [
                      _c("van-image", {
                        staticStyle: { width: "80%", "z-index": "0" },
                        attrs: {
                          fit: "fill",
                          src: require("../../assets/activity/january202301/button_status_4_0_d_0.png")
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "50px",
                            "text-align": "center",
                            display: "flex",
                            "justify-content": "center",
                            "flex-direction": "column",
                            width: "100%"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#625B5A",
                                "font-size": "14px",
                                "line-height": "16px"
                              }
                            },
                            [_vm._v("已领取")]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "padding-top": "5px",
                                color: "#DB3D49",
                                display: "flex",
                                "align-items": "baseline",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "24px",
                                    "font-weight": "800"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.redPackAmount))]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "15px",
                                    "padding-left": "5px"
                                  }
                                },
                                [_vm._v(" 喜分")]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticStyle: { "text-align": "center" },
                on: { click: _vm.overlayDismiss }
              },
              [
                _c("van-icon", {
                  attrs: { size: "30", color: "white", name: "cross" }
                })
              ],
              1
            )
          ]),
          _c(
            "van-popup",
            {
              staticStyle: { width: "90%", height: "60%" },
              attrs: { closeable: "" },
              model: {
                value: _vm.popupShow,
                callback: function($$v) {
                  _vm.popupShow = $$v
                },
                expression: "popupShow"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { padding: "10px 0px" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "padding-top": "20px"
                      }
                    },
                    [_vm._v("领取记录")]
                  ),
                  _c(
                    "div",
                    { staticStyle: { color: "#ff9000", padding: "20px 16px" } },
                    [
                      _vm._v("已拆福袋 "),
                      _c("span", [_vm._v(_vm._s(_vm.detail.total_count))]),
                      _vm._v(" 个，共"),
                      _c("span", [_vm._v(_vm._s(_vm.detail.total_amount))]),
                      _vm._v("喜分")
                    ]
                  ),
                  _c(
                    "van-cell-group",
                    _vm._l(_vm.detail.log_list, function(item, index) {
                      return _c("van-cell", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "space-between"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          _c("van-image", {
                                            staticStyle: {
                                              width: "20px",
                                              "z-index": "0"
                                            },
                                            attrs: {
                                              fit: "contain",
                                              src: require("../../assets/activity/january202301/p_0.png")
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                "font-size": "14px",
                                                color: "#373637"
                                              }
                                            },
                                            [_vm._v(_vm._s(item.created_at))]
                                          ),
                                          item.multiple > 1
                                            ? _c("van-image", {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                  width: "40px",
                                                  "z-index": "0"
                                                },
                                                attrs: {
                                                  fit: "contain",
                                                  src: require("../../assets/activity/january202301/p_1.png")
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "#F39800",
                                            "font-size": "14px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.multiple > 1
                                                ? item.multiple_amount
                                                : item.amount
                                            ) + "喜分"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "card",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "30px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "image-title" },
                [
                  _c("van-image", {
                    staticStyle: { width: "55%" },
                    attrs: {
                      fit: "contain",
                      src: require("../../assets/activity/january202301/t_h_0.png")
                    }
                  })
                ],
                1
              ),
              _vm._m(6)
            ]
          ),
          _c(
            "div",
            {
              staticClass: "card",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "30px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "image-title" },
                [
                  _c("van-image", {
                    staticStyle: { width: "55%" },
                    attrs: {
                      fit: "contain",
                      src: require("../../assets/activity/january202301/t_h_1.png")
                    }
                  })
                ],
                1
              ),
              _vm._m(7)
            ]
          ),
          _c(
            "div",
            {
              staticClass: "card",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "30px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "image-title" },
                [
                  _c("van-image", {
                    staticStyle: { width: "55%" },
                    attrs: {
                      fit: "contain",
                      src: require("../../assets/activity/january202301/t_h_2.png")
                    }
                  })
                ],
                1
              ),
              _vm._m(8)
            ]
          ),
          _c("van-image", {
            staticStyle: { width: "100%", "z-index": "0" },
            attrs: {
              fit: "fill",
              src: require("../../assets/activity/january202301/logo.png")
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          position: "absolute",
          bottom: "50px",
          "text-align": "center",
          display: "flex",
          "justify-content": "center",
          width: "100%",
          color: "white"
        }
      },
      [_c("div", [_vm._v("不在活动期间")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          position: "absolute",
          bottom: "50px",
          "text-align": "center",
          display: "flex",
          "justify-content": "center",
          width: "100%",
          color: "white"
        }
      },
      [_c("div", [_vm._v("点击领取")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          position: "absolute",
          bottom: "50px",
          "text-align": "center",
          display: "flex",
          "justify-content": "center",
          width: "100%",
          color: "white"
        }
      },
      [_c("div", [_vm._v("福袋9:00准时开放")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          position: "absolute",
          bottom: "50px",
          "text-align": "center",
          display: "flex",
          "justify-content": "center",
          width: "100%",
          color: "white"
        }
      },
      [_c("div", [_vm._v("福袋已抢光"), _c("br"), _vm._v("明天早点来哦")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          position: "absolute",
          bottom: "50px",
          "text-align": "center",
          display: "flex",
          "justify-content": "center",
          width: "100%",
          color: "white",
          "font-size": "14px",
          "line-height": "16px"
        }
      },
      [
        _c("div", [
          _vm._v("您未获得参与资格"),
          _c("br"),
          _vm._v("完成处方即可领取")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          width: "90px",
          height: "30px",
          background: "#FFF1E7",
          "border-radius": "5px"
        }
      },
      [
        _c(
          "a",
          {
            staticStyle: {
              "font-weight": "bold",
              "text-decoration": "underline",
              color: "#FF8226"
            }
          },
          [_vm._v("我的福袋")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: { padding: "90px 23px 30px 23px", "line-height": "180%" }
      },
      [
        _c("div", { staticStyle: { color: "#38404E" } }, [
          _vm._v(" 1. 在"),
          _c("span", { staticStyle: { color: "#FF8225" } }, [
            _vm._v("1月10日")
          ]),
          _vm._v("之后有过处方记录的医生，活动期间每天早上九点可领取最高"),
          _c("span", { staticStyle: { color: "#FF8225" } }, [
            _vm._v("100喜分")
          ]),
          _vm._v("的随机金额福袋，另外除夕和大年初一，福袋金额翻倍，最高领"),
          _c("span", { staticStyle: { color: "#FF8225" } }, [
            _vm._v("200喜分")
          ]),
          _vm._v(
            "；每日成功开过一次处方即增加一天参与机会，最高可连续领取7天。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" 2. 每日福袋发放数量为"),
          _c("span", { staticStyle: { color: "#FF8225" } }, [_vm._v("300个")]),
          _vm._v("，抢完即止。 ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { padding: "90px 23px 0px 23px", width: "100%" } },
      [
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#FF8225" } },
          [_vm._v("Q：怎样获取参与资格？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [
            _vm._v(
              "A：1月10日之后，每日成功完成一次处方，即获得一次参与机会，连续开方7天，可连续7天参与领福袋。"
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#FF8225" } },
          [_vm._v("Q：福袋是不是人人都有?")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [_vm._v("A：不是，每日福袋发放数量为300个，先到先得，抢完为止。")]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#FF8225" } },
          [_vm._v("Q：除夕和大年初一的福袋怎么翻倍？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [
            _vm._v(
              "A：在领取普通福袋后，点击【立即翻倍】才能领取翻倍福袋，不点击【立即翻倍】即只有普通福袋的金额。"
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#FF8225" } },
          [_vm._v("Q:奖励什么时候到账?")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [_vm._v("A：奖励将于1月28日由系统自动发放到账。")]
        ),
        _c(
          "div",
          { staticClass: "main-title", staticStyle: { color: "#FF8225" } },
          [_vm._v(" Q：开具单品订单能参与活动吗？")]
        ),
        _c(
          "div",
          {
            staticClass: "sub-title",
            staticStyle: { color: "#38404E", "line-height": "150%" }
          },
          [
            _vm._v(
              "A：不能，只有完成颗粒及膏方剂型的处方才会计入活动，单品、自营成药处方均不计入此项活动。"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "90px 23px 20px 23px",
          color: "#38404E",
          "line-height": "180%"
        }
      },
      [
        _vm._v(
          " 1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(" 3、本次活动最终解释权归喜郎中所有。 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }