//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'national202210',
  props: {},
  data: function data() {
    return {
      detail: undefined,
      show: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityNationalDetail();
  },
  methods: {
    fetchActivityNationalDetail: function fetchActivityNationalDetail() {
      var _this = this;

      mallInteractor.fetchActivityNationalDetail().then(function (data) {
        _this.detail = data;
      });
    },
    jumpToMall: function jumpToMall(id) {
      if (id != undefined) {
        this.$router.push({
          path: '/mallActivitiesItemList',
          query: {
            id: id
          }
        });
      }
    }
  }
};