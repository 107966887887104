"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'national202210',
  props: {},
  data: function data() {
    return {
      detail: undefined,
      show: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityNationalDetail();
  },
  methods: {
    fetchActivityNationalDetail: function fetchActivityNationalDetail() {
      var _this = this;

      _core.mallInteractor.fetchActivityNationalDetail().then(function (data) {
        _this.detail = data;
      });
    },
    jumpToMall: function jumpToMall(id) {
      if (id != undefined) {
        this.$router.push({
          path: '/mallActivitiesItemList',
          query: {
            id: id
          }
        });
      }
    }
  }
};
exports.default = _default;