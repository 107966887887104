import "core-js/modules/es.array.concat";
import _toConsumableArray from "/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'RewardList',
  props: {},
  data: function data() {
    return {
      code: undefined,
      integral_activity_id: undefined,
      tabsActive: 0,
      tabs: [{
        title: '全部',
        name: 0
      }, {
        title: '待发放',
        name: 1
      }, {
        title: '已发放',
        name: 2
      }],
      query: {
        code: undefined,
        page: 0,
        size: 10,
        status: 0
      },
      list: [],
      loading: false,
      finished: false,
      refreshing: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var code = this.$route.query.code;

    if (code) {
      this.code = code;
      console.log(code);
    }

    this.fetchActivityLogs();
  },
  methods: {
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    jumpToActivitiesItemList: function jumpToActivitiesItemList(item) {
      if (item.status == 1) {
        if (item.type == 4) {
          this.$router.push({
            path: '/mallActivitiesItemList',
            query: {
              id: process.env.NODE_ENV === 'production' ? '3' : '1348'
            }
          });
        } else {
          if (this.integral_activity_id != null) {
            this.$router.push({
              path: '/mallActivitiesItemList',
              query: {
                id: this.integral_activity_id
              }
            });
          } else {
            this.$router.push({
              path: '/mallActivitiesList'
            });
          }
        }
      }
    },
    tabsOnClick: function tabsOnClick(name, title) {
      this.query.status = name;
      this.onRefresh();
    },
    onLoad: function onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      this.query.page = this.query.page + 1;
      this.query.code = this.code;
      console.log('onLoad');
      this.fetchConfigActivityRewardList(this.query);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false;
      console.log('onRefresh');
      console.log(this.query); // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchConfigActivityRewardList(this.query);
    },
    fetchConfigActivityRewardList: function fetchConfigActivityRewardList(query) {
      var _this = this;

      mallInteractor.fetchConfigActivityRewardList(query).then(function (data) {
        _this.integral_activity_id = data.integral_activity_id;

        if (_this.query.page === 1) {
          _this.list = data.list;
        } else {
          _this.list = [].concat(_toConsumableArray(_this.list), _toConsumableArray(data.list));
        }

        if (data.total <= _this.list.length) {
          _this.finished = true;
        }

        _this.finished = true;
        _this.loading = false;
      });
    }
  }
};