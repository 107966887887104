var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", [
    _c("div", { staticClass: "loader-layer active" }, [
      _vm.type === "animation"
        ? _c("div", { staticClass: "loader-animation-c" }, [
            _c("div", {
              staticClass: "cascade-bounce-in-one",
              style: "background-color: " + _vm.color + ";"
            }),
            _c(
              "div",
              {
                staticClass: "cascade-bounce-in-two",
                style: "background-color: " + _vm.color + ";"
              },
              [_vm._v(" " + _vm._s(_vm.message) + " ")]
            ),
            _c("div", {
              staticClass: "cascade-bounce-in-three",
              style: "background-color: " + _vm.color + ";"
            })
          ])
        : _vm.type === "sprite"
        ? _c(
            "div",
            { staticClass: "loader-icon-c" },
            [
              _c("div", {
                staticClass: "loader-icon",
                style: {
                  backgroundPositionY: -(_vm.positionY % 7) * 2.5 + "rem"
                }
              }),
              _c("svg-icon", {
                attrs: {
                  "icon-class": "loading",
                  "class-name": "loader-ellipse"
                }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }