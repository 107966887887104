var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        _vm._l(_vm.list, function(item) {
          return _c("van-cell", { key: item, attrs: { title: item } })
        }),
        1
      ),
      _c("BackToTop")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }