import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor, doctorInteractor } from '@/core';
import store from '@/store';
import { Dialog, Toast } from 'vant';
export default {
  name: 'InviteDoctor',
  props: {},
  data: function data() {
    return {
      userInfo: undefined,
      detail: undefined,
      popupShow: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // const { userInfo } = store.getters
    // this.userInfo = userInfo
    this.fetchActivityInviteIndex();
    this.fetchDoctorInfo();
  },
  methods: {
    shareAction: function shareAction() {
      var dict = {
        'title': this.userInfo.name + '医生诚邀您入驻喜郎中',
        'info': '我正在使用喜郎中平台，在这里可以方便的为患者在线开方及管理新老患者，为患者定制方案。',
        'url': this.userInfo.promotion_url
      };

      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // ios
        // JS通知WKWebView
        window.webkit.messageHandlers.share.postMessage(dict);
      } else if (/(Android)/i.test(navigator.userAgent)) {
        // android
        window.a5ixlz.share(JSON.stringify(dict)); // window.a5ixlz.startActivity(dict)
      }
    },
    jumpToDetail: function jumpToDetail(id) {
      this.$router.push({
        path: '/inviteDoctorActivityDetail',
        query: {
          id: id
        }
      });
    },
    fetchActivityInviteIndex: function fetchActivityInviteIndex() {
      var _this = this;

      mallInteractor.fetchActivityInviteIndex().then(function (data) {
        _this.detail = data;
      });
    },
    fetchDoctorInfo: function fetchDoctorInfo() {
      var _this2 = this;

      doctorInteractor.fetchDoctorInfo().then(function (data) {
        console.log(data);
        _this2.userInfo = data.doctor;
      });
    }
  }
};