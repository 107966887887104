var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg" },
    [
      _vm.detail
        ? _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "background-color": "white"
              }
            },
            [
              _c(
                "a",
                {
                  staticStyle: {
                    "font-size": "18px",
                    color: "rgb(247, 147, 28)",
                    "text-align": "center",
                    padding: "20px 0px 0px",
                    "border-bottom": "10px solid #F7931C40",
                    "line-height": "9px",
                    display: "inline-block"
                  }
                },
                [_vm._v("京东卡使用说明")]
              ),
              _c("div", {
                staticStyle: { padding: "20px 10px" },
                domProps: { innerHTML: _vm._s(_vm.detail.rule_string) }
              })
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "font-size": "12px",
            "justify-content": "space-between",
            padding: "10px 6px 0px",
            color: "gray"
          }
        },
        [
          _c("div", [_vm._v("兑换时间：" + _vm._s(_vm.detail.created_at))]),
          _c("div", [_vm._v("订单编号：" + _vm._s(_vm.detail.order_no))])
        ]
      ),
      _vm._l(_vm.cardList, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticStyle: {
              position: "relative",
              height: "200px",
              "margin-bottom": "10%"
            }
          },
          [
            _c("van-image", {
              staticStyle: { padding: "10px" },
              attrs: {
                fit: "contain",
                src: require("../../assets/mall/mall_jd.png")
              }
            }),
            _c(
              "div",
              {
                staticStyle: {
                  position: "absolute",
                  "z-index": "1",
                  bottom: "1%",
                  width: "100%"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      padding: "10px 20px 0px 10px",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "background-color": "white",
                          "line-height": "30px",
                          width: "80%",
                          "text-align": "center",
                          "font-size": "14px"
                        }
                      },
                      [_vm._v("卡号：" + _vm._s(item.card_number))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      padding: "10px 20px 10px 10px",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "background-color": "white",
                          "line-height": "30px",
                          width: "80%",
                          "text-align": "center",
                          "font-size": "14px"
                        }
                      },
                      [_vm._v("卡密：" + _vm._s(item.card_pwd))]
                    ),
                    _c(
                      "van-button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: item.card_pwd,
                            expression: "item.card_pwd",
                            arg: "copy"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onCopy,
                            expression: "onCopy",
                            arg: "success"
                          }
                        ],
                        attrs: { round: "", size: "mini" }
                      },
                      [_vm._v("复制")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "white",
                      "z-index": "1",
                      "margin-left": "20px",
                      "font-size": "14px"
                    }
                  },
                  [_vm._v("过期时间： " + _vm._s(item.card_deadline))]
                )
              ]
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }