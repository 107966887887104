var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#70A7F0" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/return202206/banner.png")
        }
      }),
      _c(
        "div",
        { staticClass: "card", staticStyle: { "margin-top": "-3px" } },
        [
          _c(
            "van-row",
            {
              staticStyle: {
                margin: "40px 0px",
                "align-items": "center",
                display: "flex"
              },
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c(
                "van-col",
                { attrs: { span: "4" } },
                [
                  _c("van-image", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      src: require("../../assets/activity/return202206/c_h_l_0.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "van-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: "16" }
                },
                [
                  _c("van-image", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      src: require("../../assets/activity/return202206/c_h_0.png")
                    }
                  })
                ],
                1
              ),
              _c("van-col", { attrs: { span: "4" } })
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                padding: "10px 20px",
                color: "#38404E",
                "line-height": "180%"
              }
            },
            [
              _vm._v(
                " 活动规则：添加专属客服微信，联系客服提供您的收货地址即可领取。 "
              )
            ]
          ),
          _c(
            "div",
            { staticStyle: { padding: "20px 20px 50px 20px" } },
            [
              _c("van-image", {
                staticStyle: { width: "100%" },
                attrs: {
                  src: require("../../assets/activity/return202206/c_h_i_0.png")
                }
              })
            ],
            1
          ),
          _c(
            "van-row",
            {
              staticStyle: {
                margin: "20px 0px",
                "align-items": "center",
                display: "flex"
              },
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c(
                "van-col",
                { attrs: { span: "4" } },
                [
                  _c("van-image", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      src: require("../../assets/activity/return202206/c_h_l_1.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "van-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: "16" }
                },
                [
                  _c("van-image", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      src: require("../../assets/activity/return202206/c_h_1.png")
                    }
                  })
                ],
                1
              ),
              _c("van-col", { attrs: { span: "4" } })
            ],
            1
          ),
          _vm.hgDetail.list.length != 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    "margin-bottom": "30px",
                    padding: "0 10%"
                  }
                },
                _vm._l(_vm.hgDetail.list, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "scroll-item",
                      staticStyle: { width: "50%" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            height: "120px"
                          }
                        },
                        [
                          item.status > 0
                            ? _c("div", { staticClass: "red-step-line" })
                            : _c("div", { staticClass: "red-step-line-gray" }),
                          _c(
                            "div",
                            { staticStyle: { width: "90px", height: "120px" } },
                            [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      item.status == 0 ? "#B9B9B9" : "#F39800",
                                    "font-size": "14px",
                                    "font-weight": "bold",
                                    padding: "5px 0px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(index == 0 ? "50" : "100") + "喜分"
                                  )
                                ]
                              ),
                              item.status > 0
                                ? _c("van-image", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      fit: "fill",
                                      src: require("../../assets/activity/return202206/w_r_r_1.png")
                                    }
                                  })
                                : _c("van-image", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      fit: "fill",
                                      src: require("../../assets/activity/return202206/w_r_r_0.png")
                                    }
                                  }),
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      item.status == 0 ? "#B9B9B9" : "#F39800",
                                    "font-size": "14px",
                                    padding: "5px 0px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(index == 0 ? "首" : "次") + "单"
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                [
                                  item.status == 0
                                    ? _c("van-image", {
                                        attrs: {
                                          src: require("../../assets/activity/return202206/w_r_b_0.png")
                                        }
                                      })
                                    : _vm._e(),
                                  item.status == 1
                                    ? _c("van-image", {
                                        attrs: {
                                          src: require("../../assets/activity/return202206/w_r_b_1.png")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.receiveAction(
                                              item,
                                              index
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.status == 2
                                    ? _c("van-image", {
                                        attrs: {
                                          src: require("../../assets/activity/return202206/w_r_b_2.png")
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          item.status > 0
                            ? _c("div", { staticClass: "red-step-line" })
                            : _c("div", { staticClass: "red-step-line-gray" })
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._m(0),
          _c(
            "van-row",
            {
              staticStyle: {
                margin: "20px 0px",
                "align-items": "center",
                display: "flex"
              },
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c(
                "van-col",
                { attrs: { span: "4" } },
                [
                  _c("van-image", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      src: require("../../assets/activity/return202206/c_h_l_2.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "van-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: "16" }
                },
                [
                  _c("van-image", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      src: require("../../assets/activity/return202206/c_h_2.png")
                    }
                  })
                ],
                1
              ),
              _c("van-col", { attrs: { span: "4" } })
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                padding: "10px 20px",
                "text-align": "center",
                color: "#38404E",
                "line-height": "180%"
              }
            },
            [
              _vm._v(" 您已邀请患者"),
              _c("span", { staticClass: "color-span" }, [
                _vm._v(_vm._s(_vm.hgDetail.count))
              ]),
              _vm._v("名"),
              _c("br"),
              _vm._v(" 成功获得喜帖券 "),
              _c("span", { staticClass: "color-span" }, [
                _vm._v(_vm._s(_vm.hgDetail.count))
              ]),
              _vm._v("张 ")
            ]
          ),
          _c(
            "div",
            { staticStyle: { padding: "10px 20px" } },
            [
              _c("van-image", {
                staticStyle: { width: "100%" },
                attrs: {
                  src: require("../../assets/activity/return202206/c_h_i_1.png")
                }
              })
            ],
            1
          ),
          _vm._m(1)
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "50%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/return202206/c_h_3.png")
                }
              })
            ],
            1
          ),
          _vm._m(2)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "50%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/return202206/c_h_4.png")
                }
              })
            ],
            1
          ),
          _vm._m(3)
        ]
      ),
      _c("div", { staticStyle: { height: "60px" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "10px 20px 50px 20px",
          color: "#38404E",
          "line-height": "180%"
        }
      },
      [
        _vm._v(
          " 活动规则：获得本次活动资格后，开方基础药费在50元以上时，可以领取额外"
        ),
        _c("span", { staticClass: "color-span" }, [_vm._v("50")]),
        _vm._v(
          "喜分奖励（限领一次），之后开方基础药费达到100元以上时,可以领取额外"
        ),
        _c("span", { staticClass: "color-span" }, [_vm._v("100")]),
        _vm._v("喜分奖励（限领一次） ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "10px 20px 50px 20px",
          "text-align": "center",
          color: "#38404E",
          "line-height": "180%"
        }
      },
      [
        _vm._v(" 活动规则：每邀请一位新患者关注可获得一张满"),
        _c("span", { staticClass: "color-span" }, [_vm._v("100-20")]),
        _vm._v("的喜帖券，可以在处方时为患者减免药费（限十张） ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "80px 23px 20px 23px" } }, [
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
        [_vm._v("Q：喜分什么时候能到账？")]
      ),
      _c(
        "div",
        { staticClass: "sub-title", staticStyle: { color: "#38404E" } },
        [_vm._v("A：处方成功发货后点击领取按钮即可获取奖励。")]
      ),
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
        [_vm._v("Q：喜帖券什么时候能到账？")]
      ),
      _c(
        "div",
        { staticClass: "sub-title", staticStyle: { color: "#38404E" } },
        [_vm._v("A：成功邀请患者时，系统自动发放。")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          padding: "80px 23px 20px 23px",
          color: "#38404E",
          "line-height": "180%"
        }
      },
      [
        _vm._v(
          " 1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
        ),
        _c("br"),
        _c("br"),
        _vm._v(" 3、本次活动最终解释权归喜郎中所有。 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }