var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("van-search", {
        attrs: { "show-action": "", placeholder: "请输入搜索关键词" },
        on: { search: _vm.onSearch, cancel: _vm.onCancel },
        model: {
          value: _vm.query.keyword,
          callback: function($$v) {
            _vm.$set(_vm.query, "keyword", $$v)
          },
          expression: "query.keyword"
        }
      }),
      _vm.query.keyword == undefined
        ? _c(
            "div",
            { staticStyle: { padding: "10px", "background-color": "white" } },
            [
              _c("CommonHeader", { attrs: { title: "搜索历史" } }),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-wrap": "wrap",
                    "background-color": "white"
                  }
                },
                _vm._l(_vm.searchHistoryList, function(item, index) {
                  return _c(
                    "van-tag",
                    {
                      staticStyle: { margin: "5px" },
                      attrs: {
                        size: "large",
                        "text-color": "#373637",
                        color: "#F3F3F3"
                      },
                      on: {
                        click: function($event) {
                          return _vm.tagSearch(item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                1
              ),
              _c("CommonHeader", { attrs: { title: "分类筛选" } }),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-wrap": "wrap",
                    "background-color": "white"
                  }
                },
                _vm._l(_vm.categoriesList, function(item, index) {
                  return _c(
                    "van-tag",
                    {
                      staticStyle: { margin: "5px" },
                      attrs: {
                        size: "large",
                        "text-color": "#373637",
                        color: "#F3F3F3"
                      },
                      on: {
                        click: function($event) {
                          return _vm.tagSearch(item.name)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "van-pull-refresh",
                {
                  on: { refresh: _vm.onRefresh },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                [
                  _c(
                    "van-list",
                    {
                      attrs: {
                        finished: _vm.finished,
                        "finished-text": "没有更多了",
                        offset: "150"
                      },
                      on: { load: _vm.onLoad },
                      model: {
                        value: _vm.loading,
                        callback: function($$v) {
                          _vm.loading = $$v
                        },
                        expression: "loading"
                      }
                    },
                    _vm._l(_vm.list, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "cell",
                          on: {
                            click: function($event) {
                              return _vm.clickCell(item)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("van-image", {
                                staticClass: "avatar-img",
                                attrs: {
                                  width: "40px",
                                  height: "40px",
                                  src: item.doctor_url
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.clickAvatar(item.doctor_id)
                                  }
                                }
                              }),
                              _c("div", [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(_vm._s(item.doctor_name))
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                      "padding-left": "5px"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "14px",
                                          color: "#A4A4A4",
                                          "line-height": "22px"
                                        }
                                      },
                                      [_vm._v(_vm._s(item.created_at))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "14px",
                                          color: "#A4A4A4",
                                          "line-height": "22px",
                                          "padding-left": "5px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.click_count) + "阅读"
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c("div", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(item.title))
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "card-des",
                              staticStyle: { "padding-bottom": "10px" }
                            },
                            [_vm._v(_vm._s(item.strip_tag_content))]
                          ),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(item.img_info, function(img) {
                              return _c(
                                "div",
                                { staticStyle: { padding: "4px 4px 8px 0" } },
                                [
                                  _c("van-image", {
                                    staticClass: "avatar-img",
                                    attrs: {
                                      width: "60px",
                                      height: "60px",
                                      src: img
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(item.post_tags, function(itemTag) {
                              return _c(
                                "div",
                                { staticStyle: { padding: "10px 4px 8px 0" } },
                                [
                                  _c(
                                    "van-tag",
                                    {
                                      attrs: {
                                        color: "#E9FFE7",
                                        "text-color": "#41DC33"
                                      }
                                    },
                                    [_vm._v(_vm._s(itemTag.name))]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "0 20px",
                                display: "flex",
                                "flex-direction": "row",
                                "justify-content": "space-between"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.shareAction(item)
                                    }
                                  }
                                },
                                [
                                  _c("van-icon", {
                                    attrs: {
                                      name: require("../../assets/community/share.png"),
                                      size: "20"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#707270",
                                        padding: "0 6px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.share_count))]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("van-icon", {
                                    attrs: {
                                      name: require("../../assets/community/reply.png"),
                                      size: "20"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#707270",
                                        padding: "0 6px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.reply_count))]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.fetchToggleVote(item)
                                    }
                                  }
                                },
                                [
                                  _c("van-icon", {
                                    attrs: {
                                      name: require("../../assets/community/click_" +
                                        (item.is_voted ? 1 : 0) +
                                        ".png"),
                                      size: "20"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#707270",
                                        padding: "0 6px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.vote_count))]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }