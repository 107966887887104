var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: {
        width: "100%",
        "background-color": "#FF4129",
        position: "relative"
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: { "background-color": "#FF4129", position: "relative" }
        },
        [
          _c("van-image", {
            staticStyle: { width: "100%", "z-index": "0" },
            attrs: {
              fit: "fill",
              src: require("../../assets/activity/red202401/banner.jpg")
            }
          }),
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                "z-index": "99",
                right: "0",
                bottom: "0",
                "margin-bottom": "350px",
                width: "20%"
              },
              on: {
                click: function($event) {
                  return _vm.activityRuleShowAction()
                }
              }
            },
            [
              _c("van-image", {
                staticStyle: { width: "100%", "z-index": "0" },
                attrs: {
                  fit: "fill",
                  src: require("../../assets/activity/red202401/r_l_b.png")
                }
              })
            ],
            1
          ),
          _vm.redDetail
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    bottom: "0",
                    "z-index": "90",
                    "text-align": "center",
                    "align-items": "center",
                    display: "flex",
                    "flex-direction": "column"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-bottom": "10vh",
                        position: "relative"
                      }
                    },
                    [
                      _vm.redDetail.is_received == 1 &&
                      _vm.redDetail.double_status == 1 &&
                      _vm.redDetail.double == 0
                        ? _c(
                            "div",
                            [
                              _c("van-image", {
                                staticStyle: { width: "56%", "z-index": "0" },
                                attrs: {
                                  fit: "fill",
                                  src: require("../../assets/activity/red202401/rr_2.png")
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    top: "30px",
                                    "text-align": "center",
                                    display: "flex",
                                    "justify-content": "center",
                                    "flex-direction": "column",
                                    width: "100%"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#A63B3F",
                                        "font-size": "20px",
                                        "line-height": "16px",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [_vm._v("恭喜您！")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "padding-top": "5px",
                                        color: "#F32430",
                                        display: "flex",
                                        "align-items": "baseline",
                                        "justify-content": "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "40px",
                                            "font-weight": "bold"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.redDetail.received_amount
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "20px",
                                            "padding-left": "5px",
                                            "font-weight": "bold"
                                          }
                                        },
                                        [_vm._v(" 喜分")]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._m(0),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "z-index": "0",
                                    position: "absolute",
                                    bottom: "46px"
                                  }
                                },
                                [
                                  _c("van-image", {
                                    staticStyle: { width: "40%" },
                                    attrs: {
                                      fit: "fill",
                                      src: require("../../assets/activity/red202401/r_d_0.png")
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.redDetail.button_status == 0
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#FF2E2A",
                                        position: "absolute",
                                        width: "100%",
                                        bottom: "60px",
                                        "font-size": "16px",
                                        "font-weight": "bold",
                                        "text-align": "center"
                                      },
                                      on: { click: _vm.willMultipleAction }
                                    },
                                    [_vm._v("立即翻倍")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _vm.redDetail.button_status < 2
                                ? _c("van-image", {
                                    staticStyle: {
                                      width: "56%",
                                      "z-index": "0"
                                    },
                                    attrs: {
                                      fit: "fill",
                                      src: require("../../assets/activity/red202401/rr_0.png")
                                    },
                                    on: { click: _vm.clickMainButton }
                                  })
                                : _c("van-image", {
                                    staticStyle: {
                                      width: "56%",
                                      "z-index": "0"
                                    },
                                    attrs: {
                                      fit: "fill",
                                      src: require("../../assets/activity/red202401/rr_1.png")
                                    }
                                  }),
                              _vm.redDetail.button_status == 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "action-text",
                                      on: { click: _vm.clickMainButton }
                                    },
                                    [_vm._v("点击领取")]
                                  )
                                : _vm._e(),
                              _vm.redDetail.button_status == 1
                                ? _c("div", { staticClass: "action-text" }, [
                                    _vm._v("红包9:00准时开放"),
                                    _c("br"),
                                    _vm._v("每日数量限200个")
                                  ])
                                : _vm._e(),
                              _vm.redDetail.button_status == 2
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "action-text",
                                      staticStyle: {
                                        "text-shadow": "none",
                                        color: "white",
                                        "font-size": "16px",
                                        "font-weight": "800"
                                      }
                                    },
                                    [
                                      _vm._v("红包已抢光"),
                                      _c("br"),
                                      _vm._v("明天早点来哦")
                                    ]
                                  )
                                : _vm._e(),
                              _vm.redDetail.button_status == 3
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "action-text",
                                      staticStyle: {
                                        "text-shadow": "none",
                                        color: "white",
                                        "font-size": "16px",
                                        "font-weight": "800"
                                      }
                                    },
                                    [_vm._v("活动已结束")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#F9DCBA",
                        "font-size": "18px",
                        "font-weight": "bold"
                      }
                    },
                    [
                      _vm._v(
                        "今日剩余" +
                          _vm._s(_vm.redDetail.today_count) +
                          "次领取机会，每日零点清零"
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "rgba(255, 255, 255, 0.2)",
                        color: "white",
                        height: "46px",
                        "border-radius": "23px",
                        width: "300px",
                        "line-height": "46px",
                        "font-size": "14px",
                        "font-weight": "bold",
                        margin: "10px 0 20px 0"
                      }
                    },
                    [
                      _vm._v(
                        "已拆取红包数" +
                          _vm._s(_vm.redDetail.total_count) +
                          "个，共" +
                          _vm._s(_vm.redDetail.total_amount) +
                          "喜分"
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _c("van-image", {
                        staticStyle: { width: "50%", "z-index": "0" },
                        attrs: {
                          fit: "fill",
                          src: require("../../assets/activity/red202401/c_b.png")
                        },
                        on: {
                          click: function($event) {
                            _vm.popupShow = true
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.redDetail
        ? _c(
            "van-popup",
            {
              staticStyle: { width: "90%", "border-radius": "10px" },
              attrs: { closeable: "" },
              model: {
                value: _vm.popupShow,
                callback: function($$v) {
                  _vm.popupShow = $$v
                },
                expression: "popupShow"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "0px 0px",
                    height: "70vh",
                    overflow: "scroll",
                    "overflow-y": "auto"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        padding: "20px",
                        "font-size": "18px",
                        color: "#D42121",
                        "font-weight": "bold",
                        "background-color": "rgba(254, 103, 75, 0.16)"
                      }
                    },
                    [_vm._v("领取记录")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        padding: "20px 16px"
                      }
                    },
                    [
                      _vm._v("已拆红包"),
                      _c("span", [_vm._v(_vm._s(_vm.redDetail.total_count))]),
                      _vm._v(" 个，共"),
                      _c("span", [_vm._v(_vm._s(_vm.redDetail.total_amount))]),
                      _vm._v("喜分")
                    ]
                  ),
                  _c(
                    "van-cell-group",
                    { staticStyle: { margin: "20px" } },
                    _vm._l(_vm.redDetail.doctor_log_list, function(
                      item,
                      index
                    ) {
                      return _c("van-cell", {
                        key: index,
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "space-between"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          _c("van-image", {
                                            staticStyle: {
                                              width: "20px",
                                              "z-index": "0"
                                            },
                                            attrs: {
                                              fit: "contain",
                                              src: require("../../assets/activity/red202401/r_l_0.png")
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                "font-size": "14px",
                                                color: "#373637"
                                              }
                                            },
                                            [_vm._v(_vm._s(item.created_at))]
                                          )
                                        ],
                                        1
                                      ),
                                      item.multiple > 1
                                        ? _c("van-image", {
                                            staticStyle: {
                                              position: "absolute",
                                              right: "30%",
                                              width: "60px",
                                              "z-index": "0"
                                            },
                                            attrs: {
                                              fit: "contain",
                                              src: require("../../assets/activity/red202401/r_l_1.png")
                                            }
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          style: {
                                            color:
                                              item.multiple > 1
                                                ? "#D42121"
                                                : "black",
                                            fontSize: "14px",
                                            lineHeight: "60px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.multiple > 1
                                                ? item.multiple_amount
                                                : item.amount
                                            ) + "喜分"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "van-popup",
        {
          staticStyle: { width: "90%", "background-color": "transparent" },
          model: {
            value: _vm.activityRuleShow,
            callback: function($$v) {
              _vm.activityRuleShow = $$v
            },
            expression: "activityRuleShow"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "card",
              staticStyle: { display: "flex", "justify-content": "center" }
            },
            [
              _c(
                "div",
                { staticClass: "image-title", staticStyle: { "z-index": "1" } },
                [
                  _c("van-image", {
                    staticStyle: { width: "57%" },
                    attrs: {
                      fit: "contain",
                      src: require("../../assets/activity/red202401/t_h_0.png")
                    }
                  }),
                  _c("div", { staticClass: "image-value" }, [
                    _vm._v("常见问题")
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "inner-class",
                  staticStyle: {
                    height: "70vh",
                    "overflow-y": "scroll",
                    "z-index": "0"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "40px 10px 0px 10px",
                        color: "#A0510E",
                        "font-weight": "bold",
                        "line-height": "180%"
                      }
                    },
                    [
                      _vm._v(" Q：悬壶值怎么获得？"),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "14px"
                          }
                        },
                        [
                          _vm._v(
                            "A：开具处方且该笔处方完成支付后，对应订单的基础药费将转换为等量悬壶值。"
                          ),
                          _c("br"),
                          _c("br")
                        ]
                      ),
                      _vm._v(" Q：如何算作一次完成问诊？"),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "14px"
                          }
                        },
                        [
                          _vm._v("A：开具处方且该笔处方完成支付。"),
                          _c("br"),
                          _c("br")
                        ]
                      ),
                      _vm._v(" Q：怎样获取参与资格？"),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "14px"
                          }
                        },
                        [
                          _vm._v(
                            "A：活动期间完成任务可获得抽取机会，最高当日可获得5次领取机会。"
                          ),
                          _c("br"),
                          _c("br")
                        ]
                      ),
                      _vm._v(" Q：当天处方大于含一单，可以领取多少次？"),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "14px"
                          }
                        },
                        [
                          _vm._v(
                            "A：前一天只要有处方，次日增加一次领红包机会。"
                          ),
                          _c("br"),
                          _c("br")
                        ]
                      ),
                      _vm._v(" Q：红包是不是人人都有?"),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "14px"
                          }
                        },
                        [
                          _vm._v(
                            "A：不是，每日红包发放数量为200个，先到先得，抢完即止。"
                          ),
                          _c("br"),
                          _c("br")
                        ]
                      ),
                      _vm._v(
                        " Q：除夕、大年初一至初八以及元宵节这10天的红包怎么翻倍？"
                      ),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "14px"
                          }
                        },
                        [
                          _vm._v(
                            "A：在领取普通福袋后，点击【立即翻倍】才能领取翻倍福袋，不点击【立即翻倍】即只有普通福袋的金额。"
                          ),
                          _c("br"),
                          _c("br")
                        ]
                      ),
                      _vm._v(" Q：奖励什么时候到账？"),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "14px"
                          }
                        },
                        [
                          _vm._v("A：点击领取后由系统自动发放到账。"),
                          _c("br"),
                          _c("br")
                        ]
                      ),
                      _vm._v(" Q：什么剂型的订单能参与活动吗？"),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "14px"
                          }
                        },
                        [
                          _vm._v(
                            "A：仅完成颗粒及膏方剂型的处方才会计入活动，饮片膏、单品、饮片、自营成药处方均不计入此项活动。"
                          ),
                          _c("br"),
                          _c("br")
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              on: {
                click: function($event) {
                  _vm.activityRuleShow = false
                }
              }
            },
            [
              _c("van-icon", {
                attrs: { name: "close", size: "40", color: "white" }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            "z-index": "100",
            position: "absolute",
            "background-color": "#FF4129"
          }
        },
        [
          _vm.redDetail
            ? _c("van-overlay", { attrs: { show: _vm.redPackShow } }, [
                _vm.redDetail.double_status == 1
                  ? _c(
                      "div",
                      {
                        staticStyle: { "margin-top": "40%" },
                        attrs: { id: "try" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "rollbox",
                            class: { box_rolling: _vm.isRolling }
                          },
                          [
                            _c("div", { staticClass: "rollbox_front" }, [
                              _c("div", { staticClass: "contentbox" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      position: "relative",
                                      top: "40%"
                                    }
                                  },
                                  [
                                    _c("van-image", {
                                      staticStyle: {
                                        width: "80%",
                                        "z-index": "0"
                                      },
                                      attrs: {
                                        fit: "fill",
                                        src: require("../../assets/activity/red202401/rr_p_b.png")
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "16vh",
                                          "text-align": "center",
                                          display: "flex",
                                          "justify-content": "center",
                                          "flex-direction": "column",
                                          width: "100%"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#F83432",
                                              "font-size": "20px",
                                              "line-height": "16px",
                                              "font-weight": "bold"
                                            }
                                          },
                                          [_vm._v("已领取")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-top": "5px",
                                              color: "#F32430",
                                              display: "flex",
                                              "align-items": "baseline",
                                              "justify-content": "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "40px",
                                                  "font-weight": "bold"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.redDetail.double == 0
                                                      ? _vm.redDetail
                                                          .received_amount
                                                      : _vm.today_multiple_amount
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "20px",
                                                  "padding-left": "5px",
                                                  "font-weight": "bold"
                                                }
                                              },
                                              [_vm._v(" 喜分")]
                                            )
                                          ]
                                        ),
                                        _vm.redDetail.double == 1
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "z-index": "0",
                                                  position: "absolute",
                                                  top: "-40px",
                                                  right: "60px"
                                                }
                                              },
                                              [
                                                _c("van-image", {
                                                  staticStyle: { width: "50%" },
                                                  attrs: {
                                                    fit: "fill",
                                                    src: require("../../assets/activity/red202401/r_l_1.png")
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              "z-index": "0",
                                              position: "absolute",
                                              top: "180px"
                                            }
                                          },
                                          [
                                            _c("van-image", {
                                              staticStyle: { width: "40%" },
                                              attrs: {
                                                fit: "fill",
                                                src: require("../../assets/activity/red202401/r_d_0.png")
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm.redDetail.double == 0
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  color: "#FF2E2A",
                                                  position: "absolute",
                                                  width: "100%",
                                                  top: "194px",
                                                  "font-size": "16px",
                                                  "font-weight": "bold",
                                                  "text-align": "center"
                                                },
                                                on: {
                                                  click: [
                                                    function($event) {
                                                      $event.stopPropagation()
                                                    },
                                                    _vm.multipleAction
                                                  ]
                                                }
                                              },
                                              [_vm._v("立即翻倍")]
                                            )
                                          : _vm._e(),
                                        _vm.redDetail.double == 1
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  color: "#FF2E2A",
                                                  position: "absolute",
                                                  width: "100%",
                                                  top: "194px",
                                                  "font-size": "16px",
                                                  "font-weight": "bold",
                                                  "text-align": "center"
                                                },
                                                on: {
                                                  click: [
                                                    function($event) {
                                                      $event.stopPropagation()
                                                    },
                                                    _vm.overlayDismiss
                                                  ]
                                                }
                                              },
                                              [_vm._v("收下红包")]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.redDetail.double_status == 0
                  ? _c("div", { staticStyle: { "margin-top": "40%" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            position: "relative",
                            top: "40%"
                          },
                          on: {
                            click: [
                              function($event) {
                                $event.stopPropagation()
                              },
                              _vm.overlayDismiss
                            ]
                          }
                        },
                        [
                          _c("van-image", {
                            staticStyle: { width: "80%", "z-index": "0" },
                            attrs: {
                              fit: "fill",
                              src: require("../../assets/activity/red202401/rr_p_b.png")
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                position: "absolute",
                                top: "16vh",
                                "text-align": "center",
                                display: "flex",
                                "justify-content": "center",
                                "flex-direction": "column",
                                width: "100%"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#F83432",
                                    "font-size": "20px",
                                    "line-height": "16px",
                                    "font-weight": "bold"
                                  }
                                },
                                [_vm._v("恭喜您！")]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "padding-top": "5px",
                                    color: "#F32430",
                                    display: "flex",
                                    "align-items": "baseline",
                                    "justify-content": "center"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "40px",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.redPackAmount))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "20px",
                                        "padding-left": "5px",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [_vm._v(" 喜分")]
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                "z-index": "0",
                                position: "absolute",
                                bottom: "130px"
                              }
                            },
                            [
                              _c("van-image", {
                                staticStyle: { width: "40%" },
                                attrs: {
                                  fit: "fill",
                                  src: require("../../assets/activity/red202401/r_d_0.png")
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#FF2E2A",
                                position: "absolute",
                                width: "100%",
                                bottom: "148px",
                                "font-size": "16px",
                                "font-weight": "bold",
                                "text-align": "center"
                              },
                              on: {
                                click: function($event) {
                                  _vm.redPackShow = false
                                }
                              }
                            },
                            [_vm._v("收下红包")]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "center" },
                    on: { click: _vm.overlayDismiss }
                  },
                  [
                    _c("van-icon", {
                      attrs: { size: "30", color: "white", name: "cross" }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.redDetail
            ? _c(
                "div",
                {
                  staticClass: "card",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "margin-top": "60px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-title" },
                    [
                      _c("van-image", {
                        staticStyle: { width: "55%" },
                        attrs: {
                          fit: "contain",
                          src: require("../../assets/activity/red202401/t_h_0.png")
                        }
                      }),
                      _c("div", { staticClass: "image-value" }, [
                        _vm._v("活动规则")
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "inner-class" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "40px 10px 20px 10px",
                          color: "#A0510E",
                          "font-weight": "bold",
                          "line-height": "180%"
                        }
                      },
                      [
                        _vm._v(
                          " 1. 活动期间，完成任务当日最高可获得5次领取机会，每日零点清零。"
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._l(_vm.redDetail.rule_list, function(item, index) {
                          return _c(
                            "div",
                            [
                              _c("van-cell", {
                                attrs: {
                                  title: item.title,
                                  label: item.label,
                                  border: false,
                                  "title-class": "cell-title",
                                  "label-class": "cell-label"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "icon",
                                      fn: function() {
                                        return [
                                          _c("van-image", {
                                            staticStyle: {
                                              width: "46px",
                                              margin: "0 4px"
                                            },
                                            attrs: {
                                              fit: "contain",
                                              src: require("../../assets/activity/red202401/c_i_" +
                                                index +
                                                ".png")
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                                height: "100%",
                                                "justify-content": "end"
                                              }
                                            },
                                            [
                                              item.status == 1
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "80px",
                                                        height: "32px",
                                                        background:
                                                          "linear-gradient(0deg, #FEA861 0%, #FCDABD 100%)",
                                                        border:
                                                          "2px solid #FDE8BA",
                                                        "box-shadow":
                                                          "0px 2px 2px 0px rgba(171,11,9,0.44)",
                                                        "border-radius": "16px",
                                                        "text-align": "center",
                                                        color: "#A0510E",
                                                        "line-height": "32px"
                                                      }
                                                    },
                                                    [_vm._v("已完成")]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "80px",
                                                        height: "32px",
                                                        background: "#E8C389",
                                                        "border-radius": "16px",
                                                        "text-align": "center",
                                                        color: "#A0510E",
                                                        "line-height": "32px"
                                                      }
                                                    },
                                                    [_vm._v("未完成")]
                                                  )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("van-divider", {
                                staticStyle: {
                                  color: "#B36E30",
                                  "border-color": "#B36E30"
                                }
                              })
                            ],
                            1
                          )
                        }),
                        _vm._v(
                          " 2. 每日09:00开放领取，领取后系统实时发放到账。"
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " 3. 在除夕、大年初一至初八以及元宵节这10天内，红包金额可翻倍领取。"
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " 4. 每日红包发放数量为200个，先到先得，抢完即止。"
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " 5. 仅颗粒及膏方剂型处方可参与活动，饮片膏、饮片、单品及成药剂型处方均不计入活动。 "
                        )
                      ],
                      2
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm.redDetail
            ? _c(
                "div",
                {
                  staticClass: "card",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "margin-top": "30px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-title" },
                    [
                      _c("van-image", {
                        staticStyle: { width: "55%" },
                        attrs: {
                          fit: "contain",
                          src: require("../../assets/activity/red202401/t_h_0.png")
                        }
                      }),
                      _c("div", { staticClass: "image-value" }, [
                        _vm._v("获奖名单")
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "inner-class" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "40px 10px 20px 10px",
                          color: "#A0510E",
                          "font-weight": "bold",
                          "line-height": "180%"
                        }
                      },
                      [
                        _vm.redDetail && _vm.redDetail.total_log_list.length > 0
                          ? _c(
                              "vue-seamless-scroll",
                              {
                                staticStyle: {
                                  height: "150px",
                                  overflow: "hidden"
                                },
                                attrs: {
                                  data: _vm.redDetail.total_log_list,
                                  "class-option": _vm.classOption
                                }
                              },
                              _vm._l(_vm.redDetail.total_log_list, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "row"
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(item.doctor_name))
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px",
                                              "text-align": "left"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.created_at))]
                                        )
                                      ]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        "成功领取" +
                                          _vm._s(item.multiple_amount) +
                                          "元"
                                      )
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "center"
                                }
                              },
                              [_vm._v("暂无数据")]
                            )
                      ],
                      1
                    )
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "card",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "30px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "image-title" },
                [
                  _c("van-image", {
                    staticStyle: { width: "55%" },
                    attrs: {
                      fit: "contain",
                      src: require("../../assets/activity/red202401/t_h_0.png")
                    }
                  }),
                  _c("div", { staticClass: "image-value" }, [
                    _vm._v("其他说明")
                  ])
                ],
                1
              ),
              _vm._m(1)
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "text-align": "center",
                "margin-bottom": "40px"
              }
            },
            [
              _c("van-image", {
                staticStyle: { width: "30%", "z-index": "0" },
                attrs: {
                  fit: "fill",
                  src: require("../../assets/activity/red202401/logo.png")
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          position: "absolute",
          bottom: "100px",
          width: "100%",
          color: "rgba(255, 255, 255, 0.5)"
        }
      },
      [_vm._v("点击立即翻倍按钮"), _c("br"), _vm._v("可领取双倍红包")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inner-class" }, [
      _c(
        "div",
        {
          staticStyle: {
            padding: "40px 10px 20px 10px",
            color: "#A0510E",
            "font-weight": "bold",
            "line-height": "180%"
          }
        },
        [
          _vm._v(
            " 1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" 3、本次活动最终解释权归喜郎中所有。 ")
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }