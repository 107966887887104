//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { doctorInteractor } from '@/core';
import { Dialog, Toast, Field, CellGroup, Col, Row, Form } from 'vant';
import { ref } from 'vue';
export default {
  name: 'AllSettings',
  props: {},
  data: function data() {
    return {
      text_visit: 0.0,
      pattern: /^\d+$/,
      text_visit_show: 1,
      open_status: 1,
      show_open_status: 0,
      open_consulting: 0,
      consulting: 0,
      freenumber: 0,
      show_open_consulting: 0
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchDoctorConfigList();
  },
  methods: {
    fetchDoctorConfigList: function fetchDoctorConfigList() {
      var _this = this;

      doctorInteractor.fetchDoctorConfigList().then(function (data) {
        _this.show_open_status = data.config.open_status == 2 ? true : false, _this.open_status = data.config.is_open == 1 ? true : false, _this.show_open_consulting = data.config.is_open == 1 ? true : false, _this.open_consulting = data.config.open_consulting, _this.consulting = data.config.consulting, _this.freenumber = data.config.freenumber, _this.text_visit_show = data.config.text_visit_show == 1 ? false : true, _this.text_visit = data.config.text;
      });
    },
    onSubmit: function onSubmit(values) {
      doctorInteractor.fetchPrescriptionsConfigSave(values).then(function (data) {
        Toast('设置成功');
      });
    },
    onText_visit_show: function onText_visit_show(checked) {
      doctorInteractor.fetchDoctorConfigSave({
        field: 'text_visit_show',
        value: checked == true ? 0 : 1
      }).then(function (data) {});
    },
    onOpen_status: function onOpen_status(checked) {
      var _this2 = this;

      doctorInteractor.fetchDoctorConfigSave({
        field: 'is_open',
        value: checked == true ? 1 : 0
      }).then(function (data) {
        _this2.show_open_consulting = checked;
      });
    }
  }
};