//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { doctorInteractor } from '@/core';
import store from '@/store';
export default {
  name: 'MonthlySeted',
  props: {},
  data: function data() {
    return {
      userInfo: undefined
    };
  },
  created: function created() {
    var userInfo = store.getters.userInfo;
    this.userInfo = userInfo;
  },
  mounted: function mounted() {},
  methods: {
    pushToDoctorCashAppList: function pushToDoctorCashAppList() {
      this.$router.push({
        path: '/doctorCashAppList'
      });
    },
    pushAction: function pushAction() {
      this.$router.push({
        path: '/monthlyMonthList'
      });
    }
  }
};