//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { communityInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'CommunityMessages',
  props: {},
  data: function data() {
    return {
      detail: undefined
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchCommunityNewsList();
  },
  methods: {
    clickCell: function clickCell(item) {
      this.$router.push({
        path: '/postDetail',
        query: {
          id: item.id,
          scene: 'app'
        }
      });
    },
    fetchCommunityNewsList: function fetchCommunityNewsList() {
      var _this = this;

      communityInteractor.fetchCommunityNewsList().then(function (data) {
        _this.detail = data;
      });
    },
    jumpToCommunityCommentList: function jumpToCommunityCommentList() {
      this.$router.push({
        path: '/communityCommentList'
      });
    },
    jumpToFollowList: function jumpToFollowList() {
      this.$router.push({
        path: '/communityFollowList'
      });
    },
    jumpToSysList: function jumpToSysList() {
      this.$router.push({
        path: '/communitySysList'
      });
    },
    jumpToCommunityVoteList: function jumpToCommunityVoteList() {
      this.$router.push({
        path: '/communityVoteList'
      });
    }
  }
};