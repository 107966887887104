var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg" }, [
    _c("img", {
      staticClass: "icon",
      staticStyle: { "margin-top": "40px", width: "150px", height: "150px" },
      attrs: { slot: "img", src: require("@/assets/success.png") },
      slot: "img"
    }),
    _c("div", { staticStyle: { "margin-top": "20px" } }, [_vm._v("兑换成功!")]),
    _c(
      "div",
      {
        staticStyle: {
          color: "#f99000",
          "font-size": "18px",
          "margin-top": "20px"
        }
      },
      [_vm._v(_vm._s(_vm.title))]
    ),
    _c(
      "div",
      [
        _c(
          "van-button",
          {
            staticStyle: { "margin-top": "20px", width: "200px" },
            attrs: { round: "", type: "info", color: "#f99000" },
            on: { click: _vm.popToRoot }
          },
          [_vm._v("返回首页")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }