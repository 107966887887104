//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor, loginInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
import store from '@/store';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn'; // ES 2015

dayjs.locale('zh-cn');
export default {
  name: 'Invite202405',
  props: {},
  filters: {
    statusHeaderFilter: function statusHeaderFilter(status) {
      var statusMap = {
        0: 'backgroundColor: #BDBDBD;',
        1: 'backgroundColor: #FFD5C4;',
        2: 'backgroundColor: #FF8452;'
      };
      return statusMap[status];
    },
    statusFilter: function statusFilter(status) {
      var statusMap = {
        0: 'backgroundColor: #F0F0F0;',
        1: 'backgroundColor: #FFEFE9;',
        2: 'backgroundColor: #FF6D31;'
      };
      return statusMap[status];
    },
    statusTitleColorFilter: function statusTitleColorFilter(status) {
      var statusMap = {
        0: 'color: #D9D9D9;',
        1: 'color: #FF6D31;',
        2: 'color: #FFFFFF;'
      };
      return statusMap[status];
    },
    statusTitleFilter: function statusTitleFilter(status) {
      var statusMap = {
        0: '未签到',
        1: '已签到',
        2: '点击签到'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      detail: undefined,
      active: 1,
      dialogShow: false,
      userInfo: undefined,
      content: '发送验证码',
      totalTime: 60,
      canClick: true,
      // 添加canClick
      signId: undefined,
      sms: undefined,
      timer: null // 添加计时器引用

    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityGetTrainingSignIn();
    this.fetchActivityLogs();
    var userInfo = store.getters.userInfo;
    this.userInfo = userInfo;
  },
  methods: {
    gridOnClick: function gridOnClick(item) {
      if (item.status == 1) {
        Toast('已签到');
        return;
      }

      var begin_time = dayjs(item.begin_time);
      var end_time = dayjs(item.end_time);

      if (begin_time < dayjs() && dayjs() <= end_time) {
        // this.fetchActivitySetTrainingSignIn(item.id)
        this.signId = item.id;
        this.dialogShow = true;
      } else {
        if (begin_time < dayjs()) {
          Toast('已过期');
        } else {
          Toast('未到指定时间');
        }
      }
    },
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    fetchActivityGetTrainingSignIn: function fetchActivityGetTrainingSignIn() {
      var _this = this;

      mallInteractor.fetchActivityGetTrainingSignIn().then(function (data) {
        _this.detail = data;
      });
    },
    fetchActivitySetTrainingSignIn: function fetchActivitySetTrainingSignIn() {
      var _this2 = this;

      mallInteractor.fetchActivitySetTrainingSignIn({
        id: this.signId,
        code: this.sms
      }).then(function (data) {
        _this2.reset();

        _this2.fetchActivityGetTrainingSignIn();
      }).catch(function (e) {
        _this2.reset();
      });
    },
    reset: function reset() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }

      this.content = '发送验证码';
      this.totalTime = 60;
      this.canClick = true;
      this.sms = undefined;
      this.signId = undefined;
    },
    handleBeforeClose: function handleBeforeClose(action, done) {
      if (action == 'cancel') {
        done(true);
        this.reset();
      } else {
        if (this.sms != undefined && this.sms.length == 4) {
          done(true);
          this.fetchActivitySetTrainingSignIn();
        } else {
          done(false);
          Toast('请输入正确的验证码');
        }
      }
    },
    sendCodeAction: function sendCodeAction() {
      var _this3 = this;

      if (this.userInfo.mobile && this.userInfo.mobile.length === 11) {
        loginInteractor.sendSmsCode({
          mobile: this.userInfo.mobile
        }).then(function (data) {
          Toast.success('已发送验证码');

          _this3.countDown();
        });
      } else {
        Toast.fail('请输入正确的手机号码');
      }
    },
    countDown: function countDown() {
      var _this4 = this;

      if (!this.canClick) return; // 改动的是这两行代码

      this.canClick = false;
      this.content = this.totalTime + 's';

      if (this.timer) {
        clearInterval(this.timer);
      }

      this.timer = window.setInterval(function () {
        _this4.totalTime--;
        _this4.content = _this4.totalTime + 's';

        if (_this4.totalTime < 0) {
          clearInterval(_this4.timer);
          _this4.timer = null;
          _this4.content = '重新发送验证码';
          _this4.totalTime = 60;
          _this4.canClick = true;
        }
      }, 1000);
    }
  }
};