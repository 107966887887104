"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  language: function language(state) {
    return state.app.language;
  },
  token: function token(state) {
    return state.user.token;
  },
  build: function build(state) {
    return state.user.build;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  }
};
var _default = getters;
exports.default = _default;