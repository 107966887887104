//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'november202211',
  props: {},
  data: function data() {
    return {
      type: 1,
      detail: undefined,
      show: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var type = this.$route.query.type;

    if (type) {
      this.type = type;
      console.log(type);
    }

    this.fetchActivitySubsidiesDetail({
      type: this.type
    });
  },
  methods: {
    fetchActivitySubsidiesDetail: function fetchActivitySubsidiesDetail(dict) {
      var _this = this;

      mallInteractor.fetchActivitySubsidiesDetail(dict).then(function (data) {
        _this.detail = data;
      });
    }
  }
};