var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.userInfo
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                height: "80px",
                "background-color": "white",
                "margin-left": "20px",
                "justify-content": "space-between"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("van-image", {
                    staticClass: "avatar-img",
                    attrs: {
                      round: "",
                      width: "60px",
                      height: "60px",
                      src: _vm.avatar
                    }
                  }),
                  _c("div", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v(_vm._s(_vm.userInfo.name))
                  ])
                ],
                1
              ),
              _c(
                "van-button",
                {
                  staticStyle: { "margin-right": "20px" },
                  attrs: {
                    color: "#ff9000",
                    round: "",
                    type: "info",
                    size: "small"
                  },
                  on: {
                    click: function($event) {
                      return _vm.logoutAction()
                    }
                  }
                },
                [_vm._v("账号退出")]
              )
            ],
            1
          )
        : _vm._e(),
      _c("van-divider", { attrs: { dashed: "" } }),
      _c(
        "van-grid",
        { attrs: { "column-num": 3, border: false } },
        [
          _c("van-grid-item", {
            attrs: {
              "icon-size": 60,
              icon: require("../../assets/home_00.jpg"),
              text: "我的收益",
              to: "/myEarnings"
            }
          }),
          _c("van-grid-item", {
            attrs: {
              "icon-size": 60,
              icon: require("../../assets/home_01.jpg"),
              text: "服务设置",
              to: "/allSettings"
            }
          }),
          _c("van-grid-item", {
            attrs: {
              "icon-size": 60,
              icon: require("../../assets/mall.png"),
              text: "郎中集市",
              to: "/mall"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }