var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "white" } },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "contain",
          src: require("../../assets/mall/mall_jd_header.png")
        }
      }),
      _c(
        "van-pull-refresh",
        {
          attrs: { disabled: true },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text": "没有更多了",
                offset: "150"
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.list, function(item, index) {
              return _c("van-cell", {
                key: index,
                attrs: { border: false },
                on: {
                  click: function($event) {
                    return _vm.pushDetail(item)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("div", { staticStyle: { display: "flex" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  border: "1px solid #ff9000",
                                  "border-radius": "5px",
                                  display: "flex"
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: { padding: "3px" },
                                  attrs: {
                                    fit: "contain",
                                    width: "100",
                                    height: "100",
                                    src: item.cover_image_file.url
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "column",
                                  "justify-content": "space-between",
                                  "margin-left": "5px",
                                  width: "100%"
                                }
                              },
                              [
                                _c("p", { staticClass: "goods-name" }, [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    item.buy_model == 1
                                      ? _c("div", { staticClass: "price" }, [
                                          _vm._v(
                                            _vm._s(item.integral) + "经验值"
                                          )
                                        ])
                                      : _vm._e(),
                                    item.buy_model == 2
                                      ? _c("div", { staticClass: "price" }, [
                                          _vm._v(_vm._s(item.income) + "喜分")
                                        ])
                                      : _vm._e(),
                                    item.buy_model == 3
                                      ? _c("div", { staticClass: "price" }, [
                                          _vm._v(
                                            _vm._s(item.integral) +
                                              "经验值+" +
                                              _vm._s(item.income) +
                                              "喜分"
                                          )
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "van-button",
                                      {
                                        staticStyle: { width: "70px" },
                                        attrs: {
                                          round: "",
                                          size: "mini",
                                          color:
                                            "linear-gradient(to right, #F99215, #F8740A)"
                                        }
                                      },
                                      [_vm._v("立即兑换")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }