import "core-js/modules/es.array.splice";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import vueSeamlessScroll from 'vue-seamless-scroll';
import { Dialog, Toast } from 'vant';
export default {
  name: 'red202401',
  props: {},
  components: {
    vueSeamlessScroll: vueSeamlessScroll
  },
  data: function data() {
    return {
      redDetail: undefined,
      activityRuleShow: false,
      detail: undefined,
      popupShow: false,
      redPackShow: false,
      isRolling: false,
      redPackAmount: 0,
      today_multiple_amount: undefined,
      scrollTop: 0,
      //列表滚动高度
      speed: 15,
      //滚动的速度
      copyHtml: '',
      classOption: {
        limitMoveNum: 2,
        step: 0.2
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // this.fetchActivityLKDetailJan()
    // ActivityRed202401Detail, ActivityRed202401Receive, ActivityRed202401Multiple
    this.fetchActivityRed202401Detail();
    this.fetchActivityLogs();
  },
  methods: {
    activityRuleShowAction: function activityRuleShowAction() {
      console.log('activityRuleShowAction');
      this.activityRuleShow = true;
    },
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    fetchActivityRed202401Detail: function fetchActivityRed202401Detail() {
      var _this = this;

      mallInteractor.fetchActivityRed202401Detail().then(function (data) {
        var rule_list = data.rule_list;
        rule_list[2] = rule_list.splice(3, 1, rule_list[2])[0];
        var t = ['任务一', '任务二', '任务三', '任务四'];
        var l = ['每日登录获得1次抢红包机会', '前一日有成功问诊，获得1次抢红包机会', '前一日单次问诊悬壶值达1000以上，获得2次抢红包机会', '连续7日问诊，获得1次抢红包机会'];

        for (var index = 0; index < rule_list.length; index++) {
          var element = rule_list[index];
          element.title = t[index];
          element.label = l[index];
        }

        data.rule_list = rule_list;
        _this.redDetail = data;
      });
    },
    willMultipleAction: function willMultipleAction() {
      var _this2 = this;

      mallInteractor.fetchActivityRed202401Multiple().then(function (data) {
        _this2.redPackShow = true;
        _this2.today_multiple_amount = data.today_multiple_amount;

        _this2.fetchActivityRed202401Detail();
      });
    },
    clickMainButton: function clickMainButton() {
      var _this3 = this;

      //已领取未翻倍
      if (this.redDetail.is_received == 1 && this.redDetail.double_status == 1 && this.redDetail.double == 0) {
        this.redPackShow = true;
      } else {
        //领取
        mallInteractor.fetchActivityRed202401Receive().then(function (data) {
          _this3.redPackShow = true;
          _this3.redPackAmount = data.amount;

          _this3.fetchActivityRed202401Detail();
        });
      }
    },
    multipleAction: function multipleAction() {
      var _this4 = this;

      mallInteractor.fetchActivityRed202401Multiple().then(function (data) {
        _this4.today_multiple_amount = data.today_multiple_amount;

        _this4.fetchActivityRed202401Detail();
      });
    },
    overlayDismiss: function overlayDismiss() {
      var _this5 = this;

      this.redPackShow = false;
      this.fetchActivityRed202401Detail();
      setTimeout(function () {
        _this5.redPackAmount = 0;
        _this5.today_multiple_amount = 0;
      }, 500); // mallInteractor.fetchActivityLKReceiveJan().then(data => {
      //   this.detail = data;
      // })
    } // fetchActivityLKDetailJan() {
    //   mallInteractor.fetchActivityLKDetailJan().then(data => {
    //     this.detail = data;
    //     if (data.today_log_id != 0 ) {
    //       this.today_log_id = data.today_log_id;
    //     }
    //   })
    // }

  }
};