import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.string.includes";
import _toConsumableArray from "/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { communityInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
import CommonHeader from '@/components/CommonHeader';
export default {
  name: 'CommunitySearch',
  components: {
    CommonHeader: CommonHeader
  },
  props: {},
  data: function data() {
    return {
      searchHistoryList: undefined,
      detail: undefined,
      hotTagList: [],
      categoriesList: [],
      tabsActive: 0,
      tabs: [{
        title: '全部',
        name: 0
      }, {
        title: '热门',
        name: 2
      }, {
        title: '关注',
        name: 1
      }],
      query: {
        keyword: undefined,
        page: 0,
        size: 10,
        index_type: 0
      },
      list: undefined,
      loading: false,
      finished: false,
      refreshing: false
    };
  },
  created: function created() {
    var list = JSON.parse(window.localStorage.getItem('searchList')); //list 去重

    if (list) {
      list = list.filter(function (item, index) {
        return list.indexOf(item) === index;
      });
    } else {
      list = [];
    }

    window.localStorage.setItem('searchList', JSON.stringify(list));

    if (list) {
      this.searchHistoryList = list;
    } else {
      this.searchHistoryList = [];
    }

    this.fetchCommunityHotTagList();
    this.fetchCategoriesList();
  },
  // //页面退出后清空搜索列表
  beforeDestroy: function beforeDestroy() {
    this.resetData();
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    if (to.path === '/community') {
      this.resetData();
    }

    next();
  },
  mounted: function mounted() {},
  methods: {
    resetData: function resetData() {
      this.query = {
        keyword: undefined,
        page: 0,
        size: 10,
        index_type: 0
      };
      this.tabsActive = 0;
      this.list = undefined;
    },
    //清除搜索历史
    clearSearchHistory: function clearSearchHistory() {
      var _this = this;

      Dialog.confirm({
        title: '温馨提示',
        message: '是否清除搜索历史？'
      }).then(function () {
        // on confirm
        window.localStorage.setItem('searchList', JSON.stringify([]));

        _this.resetData();

        _this.searchHistoryList = [];
      }).catch(function () {// on cancel
      });
    },
    clickCell: function clickCell(item) {
      this.$router.push({
        path: '/postDetail',
        query: {
          id: item.id,
          scene: 'app'
        }
      });
    },
    tabsOnClick: function tabsOnClick(name, title) {
      this.query.index_type = name;
      this.onRefresh();
    },
    onSearch: function onSearch() {
      console.log('onSearch');
      this.onRefresh();
    },
    onCancel: function onCancel() {
      this.query.keyword = undefined;
      this.tabsActive = 0;
      this.list = undefined;
      var list = JSON.parse(window.localStorage.getItem('searchList'));

      if (list) {
        this.searchHistoryList = list;
      } else {
        this.searchHistoryList = [];
      }
    },
    tagSearch: function tagSearch(keyword) {
      this.query.keyword = keyword;
      this.onRefresh();
    },
    shareAction: function shareAction(item) {
      communityInteractor.fetchCommunityGetPostShareUrl({
        post_id: item.id
      }).then(function (data) {
        var dict = {
          'title': item.title,
          'info': item.strip_tag_content,
          'url': process.env.VUE_APP_URL + "/postDetail?scene=h5&id=".concat(data.id)
        };

        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          // ios
          // JS通知WKWebView
          window.webkit.messageHandlers.share.postMessage(dict);
        } else if (/(Android)/i.test(navigator.userAgent)) {
          // android
          window.a5ixlz.share(JSON.stringify(dict)); // window.a5ixlz.startActivity(dict)
        }
      });
    },
    fetchToggleVote: function fetchToggleVote(item) {
      communityInteractor.fetchToggleVote({
        object_type: 'post',
        object_id: item.id
      }).then(function (data) {
        item.is_voted = !item.is_voted;
        item.vote_count = item.is_voted ? item.vote_count + 1 : item.vote_count - 1;
      });
    },
    onLoad: function onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      this.query.page = this.query.page + 1;
      console.log('onLoad');
      this.fetchPostList(this.query);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      var list = JSON.parse(window.localStorage.getItem('searchList'));
      console.log('onRefresh');

      if (list) {
        // 如果关键词不为空且不存在于列表中才添加
        if (this.query.keyword && !list.includes(this.query.keyword)) {
          list.push(this.query.keyword);
        }
      } else {
        // 如果关键词不为空才创建新列表
        list = this.query.keyword ? [this.query.keyword] : [];
      }

      window.localStorage.setItem('searchList', JSON.stringify(list));
      this.finished = false;
      console.log('onRefresh');
      console.log(this.query); // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchPostList(this.query);
    },
    fetchPostList: function fetchPostList(query) {
      var _this2 = this;

      communityInteractor.fetchPostList(query).then(function (data) {
        if (_this2.query.page === 1) {
          _this2.list = data.list;
        } else {
          _this2.list = [].concat(_toConsumableArray(_this2.list), _toConsumableArray(data.list));
        }

        if (data.total <= _this2.list.length) {
          _this2.finished = true;
        }

        console.log(_this2.list);
        _this2.loading = false;
      });
    },
    fetchCommunityHotTagList: function fetchCommunityHotTagList() {
      var _this3 = this;

      communityInteractor.fetchCommunityHotTagList().then(function (data) {
        _this3.hotTagList = data.list;
      });
    },
    fetchCategoriesList: function fetchCategoriesList() {
      var _this4 = this;

      communityInteractor.fetchCategoriesList().then(function (data) {
        _this4.categoriesList = data.list;
      });
    }
  }
};