var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-cell-group",
        [
          _c("van-cell", {
            attrs: { "is-link": "" },
            on: { click: _vm.jumpToFollowList },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "align-items": "center",
                          display: "flex",
                          height: "44px"
                        }
                      },
                      [
                        _c("van-icon", {
                          attrs: {
                            name: require("@/assets/community/m_0.png"),
                            size: "25"
                          }
                        }),
                        _c("span", { staticClass: "custom-title" }, [
                          _vm._v("关注")
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function() {
                  return [
                    _vm.detail.follow > 0
                      ? _c("van-badge", {
                          attrs: { content: _vm.detail.follow }
                        })
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("van-cell", {
            attrs: { "is-link": "" },
            on: { click: _vm.jumpToCommunityVoteList },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "align-items": "center",
                          display: "flex",
                          height: "44px"
                        }
                      },
                      [
                        _c("van-icon", {
                          attrs: {
                            name: require("@/assets/community/m_1.png"),
                            size: "25"
                          }
                        }),
                        _c("span", { staticClass: "custom-title" }, [
                          _vm._v("点赞")
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function() {
                  return [
                    _vm.detail.vote > 0
                      ? _c("van-badge", { attrs: { content: _vm.detail.vote } })
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("van-cell", {
            attrs: { "is-link": "" },
            on: { click: _vm.jumpToCommunityCommentList },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "align-items": "center",
                          display: "flex",
                          height: "44px"
                        }
                      },
                      [
                        _c("van-icon", {
                          attrs: {
                            name: require("@/assets/community/m_2.png"),
                            size: "25"
                          }
                        }),
                        _c("span", { staticClass: "custom-title" }, [
                          _vm._v("评论")
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function() {
                  return [
                    _vm.detail.comment > 0
                      ? _c("van-badge", {
                          attrs: { content: _vm.detail.comment }
                        })
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("van-cell", {
            attrs: { "is-link": "" },
            on: { click: _vm.jumpToSysList },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "align-items": "center",
                          display: "flex",
                          height: "44px"
                        }
                      },
                      [
                        _c("van-icon", {
                          attrs: {
                            name: require("@/assets/community/m_3.png"),
                            size: "25"
                          }
                        }),
                        _c("span", { staticClass: "custom-title" }, [
                          _vm._v("系统消息")
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function() {
                  return [
                    _vm.detail.sys_msg > 0
                      ? _c("van-badge", {
                          attrs: { content: _vm.detail.sys_msg }
                        })
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }