var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { padding: "50px 10px 0 20px" } },
      [
        _c("van-image", {
          attrs: {
            fit: "contain",
            width: "180",
            height: "60",
            src: require("../../assets/xlz.jpg")
          }
        }),
        _c("div", { staticStyle: { "font-size": "30px", margin: "10px" } }, [
          _vm._v("你好！欢迎登录喜郎中")
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { margin: "50px 20px" } },
      [
        _c(
          "van-form",
          { on: { submit: _vm.onSubmit } },
          [
            _c(
              "van-cell-group",
              [
                _c("van-field", {
                  attrs: {
                    clearable: "",
                    label: "",
                    type: "number",
                    maxlength: "11",
                    placeholder: "请输入手机号码",
                    name: "mobile",
                    "left-icon": "manager",
                    rules: [
                      { pattern: _vm.pattern, message: "请输入正确的手机号码" }
                    ]
                  },
                  model: {
                    value: _vm.bind.mobile,
                    callback: function($$v) {
                      _vm.$set(_vm.bind, "mobile", $$v)
                    },
                    expression: "bind.mobile"
                  }
                }),
                _c("van-field", {
                  attrs: {
                    clearable: "",
                    label: "",
                    name: "code",
                    "left-icon": "browsing-history",
                    placeholder: "请输入短信验证码"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button",
                      fn: function() {
                        return [
                          _c(
                            "van-button",
                            {
                              attrs: {
                                size: "small",
                                type: "primary",
                                color: "#ff9000",
                                round: "",
                                plain: "",
                                disabled: !_vm.canClick
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.sendCodeAction($event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.content))]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.bind.code,
                    callback: function($$v) {
                      _vm.$set(_vm.bind, "code", $$v)
                    },
                    expression: "bind.code"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { margin: "40px 20px" } },
              [
                _c(
                  "van-button",
                  {
                    staticClass: "s-button",
                    attrs: {
                      round: "",
                      block: "",
                      "native-type": "submit",
                      color: "linear-gradient(to right, #F7941E, #F24316)"
                    }
                  },
                  [_vm._v(" 登录 ")]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "line-height": "50px" } },
                  [
                    _c("div", { staticStyle: { "font-size": "14px" } }, [
                      _vm._v("联系客服：")
                    ]),
                    _c(
                      "a",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          color: "#ff9000",
                          "text-decoration": "underline"
                        },
                        attrs: { href: "tel:4006368988" }
                      },
                      [_vm._v("4006368988")]
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }