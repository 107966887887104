import { render, staticRenderFns } from "./mallActivitiesItemList.vue?vue&type=template&id=6fb88f99&scoped=true&"
import script from "./mallActivitiesItemList.vue?vue&type=script&lang=js&"
export * from "./mallActivitiesItemList.vue?vue&type=script&lang=js&"
import style0 from "./mallActivitiesItemList.vue?vue&type=style&index=0&id=6fb88f99&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fb88f99",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6fb88f99')) {
      api.createRecord('6fb88f99', component.options)
    } else {
      api.reload('6fb88f99', component.options)
    }
    module.hot.accept("./mallActivitiesItemList.vue?vue&type=template&id=6fb88f99&scoped=true&", function () {
      api.rerender('6fb88f99', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/mall/mallActivitiesItemList.vue"
export default component.exports