"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CommunitySysList',
  props: {},
  data: function data() {
    return {
      icon_url: undefined,
      query: {
        page: 0,
        size: 10
      },
      list: [],
      loading: false,
      finished: false,
      refreshing: false
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    clickCell: function clickCell(item) {
      this.$router.push({
        path: '/postDetail',
        query: {
          id: item.post_info.id,
          scene: 'app'
        }
      });
    },
    onLoad: function onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      this.query.page = this.query.page + 1;
      console.log('onLoad');
      this.fetchCommunityNewsSysList(this.query);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false;
      console.log('onRefresh');
      console.log(this.query); // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchCommunityNewsSysList(this.query);
    },
    fetchCommunityNewsSysList: function fetchCommunityNewsSysList(query) {
      var _this = this;

      _core.communityInteractor.fetchCommunityNewsSysList(query).then(function (data) {
        _this.icon_url = data.icon_url;

        if (_this.query.page === 1) {
          _this.list = data.list;
        } else {
          _this.list = [].concat((0, _toConsumableArray2.default)(_this.list), (0, _toConsumableArray2.default)(data.list));
        }

        if (data.total <= _this.list.length) {
          _this.finished = true;
        }

        console.log(_this.list);
        _this.loading = false;
      });
    },
    jumpToEditPost: function jumpToEditPost() {
      this.$router.push({
        path: '/editPost'
      });
    },
    jumpToCommunityMessages: function jumpToCommunityMessages() {
      this.$router.push({
        path: '/communityMessages'
      });
    }
  }
};
exports.default = _default;