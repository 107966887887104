"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
// import ServiceWorkerUpdatePopup from '@/pwa/components/ServiceWorkerUpdatePopup.vue'
var _default = {
  name: 'App',
  components: {// ServiceWorkerUpdatePopup
  },
  created: function created() {
    this.handleFocusOut();
    this.handleResize();
  },
  methods: {
    handleFocusOut: function handleFocusOut() {
      document.addEventListener('focusout', function () {
        document.body.scrollTop = 0;
      });
    },
    handleResize: function handleResize() {
      var clientHeight = document.documentElement.clientHeight;

      var resizeHandler = function resizeHandler() {
        var tagName = document.activeElement.tagName;

        if (tagName) {
          var inputBox = tagName === 'INPUT' || tagName === 'TEXTAREA';

          if (inputBox) {
            setTimeout(function () {
              document.activeElement.scrollIntoView();
            }, 0);
          }
        }

        var bodyHeight = document.documentElement.clientHeight;
        var ele = document.getElementById('fixed-bottom');

        if (ele) {
          if (clientHeight > bodyHeight) ele.style.display = 'none';else ele.style.display = 'block';
        }
      };

      window.addEventListener('resize', resizeHandler);
    }
  }
};
exports.default = _default;