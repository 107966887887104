var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "#F9F9F8" } },
    [
      _c(
        "van-swipe",
        {
          staticClass: "my-swipe",
          attrs: { autoplay: 3000, "indicator-color": "white" }
        },
        _vm._l(_vm.slideList, function(item, index) {
          return _c(
            "van-swipe-item",
            { key: index },
            [
              _c("van-image", {
                attrs: { src: item.url },
                on: {
                  click: function($event) {
                    return _vm.jumpToActivity(item)
                  }
                }
              })
            ],
            1
          )
        }),
        1
      ),
      _vm.list.length == 0
        ? _c("van-empty", {
            attrs: {
              image: require("../../assets/empty.png"),
              description: "暂无数据"
            }
          })
        : _vm._e(),
      _vm._l(_vm.list, function(item, index) {
        return _c(
          "div",
          { key: index, staticStyle: { "padding-top": "6px" } },
          [
            _c("CommonHeader", { attrs: { title: item.name } }, [
              item.redirect_url
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        color: "#F7931C",
                        "line-height": "20px",
                        "margin-right": "10px",
                        "text-align": "right",
                        width: "20%",
                        display: "flex",
                        "justify-content": "end",
                        "align-items": "center"
                      },
                      attrs: { slot: "subtitle" },
                      on: {
                        click: function($event) {
                          return _vm.jumpToActivity(item)
                        }
                      },
                      slot: "subtitle"
                    },
                    [
                      _c("div", [_vm._v("活动详情")]),
                      _c("van-icon", { attrs: { name: "info" } })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c(
              "van-grid",
              {
                staticStyle: { "background-color": "white" },
                attrs: { border: false, "column-num": 3 }
              },
              _vm._l(item.goods_list, function(gitem, index) {
                return _c(
                  "van-grid-item",
                  {
                    key: index,
                    on: {
                      click: function($event) {
                        return _vm.pushDetail(gitem)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { height: "120px", "text-align": "center" }
                      },
                      [
                        _c("van-image", {
                          staticStyle: {
                            padding: "3px",
                            border: "1px solid #ff9000",
                            "border-radius": "5px"
                          },
                          attrs: {
                            fit: "contain",
                            width: "100",
                            height: "100",
                            src: gitem.url
                          }
                        }),
                        _c("p", { staticClass: "oneLineText" }, [
                          _vm._v(_vm._s(gitem.name))
                        ])
                      ],
                      1
                    )
                  ]
                )
              }),
              1
            ),
            item.redirect_activity_button
              ? _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        return _vm.jumpToActivitiesItemList(item)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          "background-color": "white",
                          padding: "0 6px 6px 0",
                          color: "#1AA1FB",
                          "font-size": "12px"
                        }
                      },
                      [_vm._v("查看全部商品>>")]
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }