"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Loading',
  props: {
    color: {
      type: String,
      default: 'red'
    },
    message: {
      type: String,
      default: 'loading...'
    },
    type: {
      type: String,
      default: 'sprite' // sprite | animation

    }
  },
  data: function data() {
    return {
      positionY: 0,
      timer: null
    };
  },
  mounted: function mounted() {
    var _this = this;

    if (this.type === 'sprite') {
      this.timer = setInterval(function () {
        _this.positionY++;
      }, 600);
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.type === 'sprite') {
      clearInterval(this.timer);
    }
  }
};
exports.default = _default;