"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'activityType2202310',
  props: {},
  data: function data() {
    return {
      userInfo: undefined,
      data: undefined,
      list: []
    };
  },
  created: function created() {
    this.fetchDoctorInfo();
  },
  mounted: function mounted() {
    this.fetchActivityGF202309();
  },
  methods: {
    pushToList: function pushToList() {
      this.$router.push({
        path: '/activityType2RecipeList'
      });
    },
    pushToMallItem: function pushToMallItem() {
      this.$router.push({
        path: '/mallItemDetail/691'
      });
    },
    pushToMall: function pushToMall() {
      var _this = this;

      _core.mallInteractor.fetchActivityGF202309Receive().then(function (data) {
        _this.$router.push({
          path: "/mallActivitiesItemList?id=".concat(data.activity_id)
        });
      });
    },
    pushToPatientUrl: function pushToPatientUrl() {
      _core.mallInteractor.fetchActivityCommonGetLockStr({
        object_id: this.userInfo.id
      }).then(function (data) {
        window.location.href = process.env.VUE_APP_PATIENT_URL + '/pages/activity/activityType2200309?doctor=' + data.lock_str;
      });
    },
    fetchActivityGF202309: function fetchActivityGF202309() {
      var _this2 = this;

      _core.mallInteractor.fetchActivityGF202309().then(function (data) {
        _this2.data = data; // this.list = data.list
      });
    },
    fetchDoctorInfo: function fetchDoctorInfo() {
      var _this3 = this;

      _core.doctorInteractor.fetchDoctorInfo().then(function (data) {
        _this3.userInfo = data.doctor;
      });
    }
  }
};
exports.default = _default;