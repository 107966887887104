"use strict";

require("core-js/modules/es.array.map");

require("core-js/modules/es.function.name");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue2Editor = require("vue2-editor");

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EditPost',
  components: {
    VueEditor: _vue2Editor.VueEditor
  },
  props: {},
  data: function data() {
    return {
      dialogShow: false,
      status: 1,
      drafts_post_id: undefined,
      tabsActive: 1,
      title: '',
      content: '',
      category_id: -1,
      user_show: 0,
      option1: [],
      option2: [{
        text: '患者不可见',
        value: 0
      }, {
        text: '患者可见',
        value: 1
      }],
      customToolbar: [[{
        header: [false, 1, 2, 3, 4, 5, 6]
      }], ["bold", "italic", "underline"], [{
        align: ""
      }, {
        align: "center"
      }], ["image"]],
      isJumpToScanCode: false
    };
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    var _this = this;

    console.log(this.status);
    console.log(from);

    if (this.isJumpToScanCode) {
      next();
      return;
    }

    if (this.status != undefined && this.content && this.content.length > 0) {
      console.log('editPost title');
      console.log(this.content);
      this.dialogShow = true;
      this.$dialog.confirm({
        title: '提示',
        message: '是否保存草稿箱',
        getContainer: function getContainer() {
          return document.querySelector('.dialog-container');
        }
      }).then(function () {
        // on confirm
        _this.dialogShow = false;
        next(false);
        _this.status = 0;

        _this.fetchSetPost();
      }).catch(function () {
        // on cancel
        _this.dialogShow = false;
        next();
      });
    } else {
      next();
    }
  },
  created: function created() {// this.$bus.on("editPost", (msg) => {
    //   if ((this.content && this.content.length > 0)) {
    //     console.log('editPost title')
    //     console.log(this.content)
    //     Dialog.confirm({
    //       title: '提示',
    //       message: '是否保留此次编辑',
    //     })
    //       .then(() => {
    //         // on confirm
    //         this.status = 0
    //         this.fetchSetPost()
    //       })
    //       .catch(() => {
    //         // on cancel
    //       });
    //   } else {
    //     console.log('editPost')
    //     this.$router.back(-1)
    //   }
    // });
  },
  beforeDestroy: function beforeDestroy() {
    this.$bus.off("editPost");
  },
  mounted: function mounted() {
    this.fetchCategoriesList();
    var id = this.$route.query.post_id;

    if (id) {
      this.drafts_post_id = id;
      this.fetchPostDetail();
    } else {
      this.fetchCommunityCheckPostDrafts();
    }
  },
  methods: {
    htmlReg: function htmlReg(msg) {
      var msg = msg.replace(/<[^>]+>|&[^>]+;/g, ''); //去除HTML Tag

      msg = msg.replace(/[|]*\n/, ''); //去除行尾空格

      msg = msg.replace(/&npsp;/ig, ''); //去掉npsp

      return msg;
    },
    jumpToScanCode: function jumpToScanCode() {
      this.isJumpToScanCode = true;

      if (this.content && this.htmlReg(this.content).length > 0) {
        this.content = this.content.replaceAll("<img", '<img style="display: inline-block;height: auto;max-width: 100%;"');
        var dict = {
          status: 0,
          category_id: this.category_id,
          user_show: this.user_show,
          title: this.title,
          content: this.content,
          post_id: this.drafts_post_id
        }; // return

        var that = this;

        _core.communityInteractor.fetchSetPost(dict).then(function (data) {
          that.jumpToScan();
        });
      } else {
        this.jumpToScan();
      }
    },
    jumpToScan: function jumpToScan() {
      var dict = {};

      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // ios
        // JS通知WKWebView
        window.webkit.messageHandlers.scan.postMessage(dict);
        this.$router.back(-1);
      } else if (/(Android)/i.test(navigator.userAgent)) {
        // android
        window.a5ixlz.scan(JSON.stringify(dict));
        this.$router.back(-1); // window.a5ixlz.startActivity(dict)
      }
    },
    fetchCommunityCheckPostDrafts: function fetchCommunityCheckPostDrafts() {
      var _this2 = this;

      _core.communityInteractor.fetchCommunityCheckPostDrafts().then(function (data) {
        if (data.drafts_post_id > 0) {
          _this2.drafts_post_id = data.drafts_post_id;

          _this2.fetchPostDetail();
        }
      });
    },
    fetchPostDetail: function fetchPostDetail() {
      var _this3 = this;

      _core.communityInteractor.fetchPostDetail({
        object_id: this.drafts_post_id,
        scene: "app",
        object_type: 'post'
      }).then(function (data) {
        _this3.title = data.title;
        _this3.content = data.content;
      });
    },
    handleImageAdded: function handleImageAdded(file, Editor, cursorLocation) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      console.log('handleImageAdded');
      console.log(file);
      var formData = new FormData();
      formData.append("file", file);

      _core.communityInteractor.fetchCommunityFileUpload(formData).then(function (result) {
        var url = result.file.url; // Get url from response

        Editor.insertEmbed(cursorLocation, "image", url);
      }).catch(function (err) {
        console.log(err);
      });
    },
    fetchCategoriesList: function fetchCategoriesList() {
      var _this4 = this;

      _core.communityInteractor.fetchCategoriesList().then(function (data) {
        var list = [{
          text: '选择文章分类',
          value: -1
        }];
        data.list.map(function (item) {
          list.push({
            text: item.name,
            value: item.id
          });
        });
        _this4.option1 = list;
      });
    },
    fetchSetPost: function fetchSetPost() {
      if (this.status == 1) {
        if (this.category_id == -1) {
          (0, _vant.Toast)('请选择文章分类');
          return;
        }

        if (this.title.length == 0) {
          (0, _vant.Toast)('请输入标题');
          return;
        }

        if (this.content.length == 0) {
          (0, _vant.Toast)('请输入内容');
          return;
        }
      }

      this.content = this.content.replaceAll("<img", '<img style="display: inline-block;height: auto;max-width: 100%;"');
      var dict = {
        status: this.status,
        category_id: this.category_id,
        user_show: this.user_show,
        title: this.title,
        content: this.content,
        post_id: this.drafts_post_id
      };
      console.log(this.content); // return

      var that = this;

      _core.communityInteractor.fetchSetPost(dict).then(function (data) {
        _vant.Toast.success(that.status == 0 ? '保存成功' : '发布成功，等待后台审核');

        that.status = undefined; // this.$router.back(-1)

        setTimeout(function () {
          that.$router.back(-1);
        }, 1000);
      });
    }
  }
};
exports.default = _default;