"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _store = _interopRequireDefault(require("@/store"));

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'InviteDoctor',
  props: {},
  data: function data() {
    return {
      userInfo: undefined,
      detail: undefined,
      popupShow: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityInviteDoctor202311();
    this.fetchDoctorInfo();
  },
  methods: {
    shareAction: function shareAction() {
      var dict = {
        'title': this.userInfo.name + '医生诚邀您入驻喜郎中',
        'info': '我正在使用喜郎中平台，在这里可以方便的为患者在线开方及管理新老患者，为患者定制方案。',
        'url': this.userInfo.promotion_url
      };

      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // ios
        // JS通知WKWebView
        window.webkit.messageHandlers.share.postMessage(dict);
      } else if (/(Android)/i.test(navigator.userAgent)) {
        // android
        window.a5ixlz.share(JSON.stringify(dict)); // window.a5ixlz.startActivity(dict)
      }
    },
    jumpToDetail: function jumpToDetail() {
      this.$router.push({
        path: '/inviteDoctor202311Detail'
      });
    },
    fetchActivityInviteDoctor202311: function fetchActivityInviteDoctor202311() {
      var _this = this;

      _core.mallInteractor.fetchActivityInviteDoctor202311().then(function (data) {
        _this.detail = data;
      });
    },
    fetchActivityInviteDoctor202311InviteCash: function fetchActivityInviteDoctor202311InviteCash() {
      var _this2 = this;

      _core.mallInteractor.fetchActivityInviteDoctor202311InviteCash().then(function (data) {
        (0, _vant.Toast)('提现成功');
        setTimeout(function () {
          _this2.fetchActivityInviteDoctor202311();
        }, 1500);
      });
    },
    fetchDoctorInfo: function fetchDoctorInfo() {
      var _this3 = this;

      _core.doctorInteractor.fetchDoctorInfo().then(function (data) {
        _this3.userInfo = data.doctor;
      });
    }
  }
};
exports.default = _default;