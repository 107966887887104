var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "background-color": "gray" } }, [
    _c(
      "div",
      {
        staticStyle: { margin: "0px 0px 0px 0px", "background-color": "white" }
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
              "padding-top": "8px",
              "padding-bottom": "8px",
              width: "100%"
            }
          },
          [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("div", {
                  staticStyle: {
                    "background-color": "#F7941E",
                    width: "4px",
                    height: "20px"
                  }
                }),
                _c(
                  "div",
                  {
                    staticStyle: { "margin-left": "6px", "font-size": "16px" }
                  },
                  [_vm._v(_vm._s(_vm.title))]
                )
              ]
            ),
            _vm._t("subtitle")
          ],
          2
        ),
        _vm._t("content")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }