"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _cache = require("@/utils/cache");

var _cache2 = require("@/core/services/cache");

var _core = require("@/core");

var _main = _interopRequireDefault(require("../../main.js"));

var state = {
  token: (0, _cache2.getToken)(),
  build: (0, _cache2.getBuild)(),
  userInfo: (0, _cache.loadUserInfo)() // 用户登录信息

};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_BUILD: function SET_BUILD(state, build) {
    state.build = build;
  },
  SET_USERINFO: function SET_USERINFO(state, userInfo) {
    state.userInfo = userInfo;
  }
};
var actions = {
  smsLogin: function smsLogin(_ref, params) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _core.loginInteractor.smsLogin(params).then(function (res) {
        // 存用户信息，token
        (0, _cache2.setToken)(res.token);
        commit('SET_TOKEN', res.token);
        resolve(res);
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  },
  // 刷新用户信息
  refreshUserInfo: function refreshUserInfo(_ref2, p) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _core.doctorInteractor.fetchDoctorInfo().then(function (res) {
        // 存用户信息，token
        commit('SET_USERINFO', (0, _cache.saveUserInfo)(res.doctor));
        resolve(res);
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  },
  saveToken: function saveToken(_ref3, t) {
    var commit = _ref3.commit;
    return new Promise(function (resolve) {
      (0, _cache2.setToken)(t);
      commit('SET_TOKEN', t);

      _main.default.$bus.emit('getToken', t);

      resolve();
    });
  },
  saveBuild: function saveBuild(_ref4, t) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      (0, _cache2.setBuild)(t);
      commit('SET_BUILD', t);
      resolve();
    });
  },
  logout: function logout(_ref5) {
    var commit = _ref5.commit,
        state = _ref5.state,
        dispatch = _ref5.dispatch;
    commit('SET_TOKEN', '');
    (0, _cache.removeUserInfo)();
    (0, _cache2.removeToken)();
  },
  resetToken: function resetToken(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      (0, _cache2.removeToken)();
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;