"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadUserInfo = loadUserInfo;
exports.saveUserInfo = saveUserInfo;
exports.removeUserInfo = removeUserInfo;
exports.loadAddress = loadAddress;
exports.saveAddress = saveAddress;
exports.removeAddress = removeAddress;

var _goodStorage = _interopRequireDefault(require("good-storage"));

var UserInfoKey = 'User-Info'; // 用户信息 {} {...}
// 获取用户信息

function loadUserInfo() {
  return _goodStorage.default.get(UserInfoKey, {});
} // 保存用户信息


function saveUserInfo(userInfo) {
  _goodStorage.default.set(UserInfoKey, userInfo);

  return userInfo;
} // 删除用户信息


function removeUserInfo() {
  _goodStorage.default.remove(UserInfoKey);

  return {};
} // 获取地址信息


function loadAddress() {
  return _goodStorage.default.get(AddressKey);
} // 保存地址信息


function saveAddress(a) {
  _goodStorage.default.set(AddressKey, a);

  return a;
} // 删除地址信息


function removeAddress() {
  _goodStorage.default.remove(AddressKey);

  return {};
}