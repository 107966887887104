"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dayjs = _interopRequireDefault(require("dayjs"));

var timeFilter = function timeFilter(value) {
  value = value.toString();

  if (value) {
    if (value.length === 13) {
      return (0, _dayjs.default)(Number(value)).format('YYYY-MM-DD HH:mm:ss');
    }

    return _dayjs.default.unix(Number(value)).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return '-';
  }
};

var _default = {
  timeFilter: timeFilter
};
exports.default = _default;