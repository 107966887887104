var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.detail
        ? _c(
            "div",
            {
              staticStyle: {
                "background-color": "white",
                "padding-bottom": "60px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex", padding: "10px 14px" } },
                    [
                      _c("van-image", {
                        staticClass: "avatar-img",
                        attrs: {
                          width: "40px",
                          height: "40px",
                          src: _vm.detail.doctor_url
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.clickAvatar(_vm.detail.doctor_id)
                          }
                        }
                      }),
                      _c("div", [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.detail.doctor_name))
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row",
                              "padding-left": "5px"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#707270",
                                  "line-height": "22px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.detail.diff_for_humans))]
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.query.scene == "app"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "padding-right": "10px"
                          }
                        },
                        [
                          _vm.detail.is_followed
                            ? _c(
                                "van-tag",
                                {
                                  attrs: {
                                    round: "",
                                    size: "large",
                                    color: "#F39800",
                                    "text-color": "white"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.fetchToggleFollow(
                                        _vm.detail.is_followed
                                      )
                                    }
                                  }
                                },
                                [_vm._v("已关注")]
                              )
                            : _c(
                                "van-tag",
                                {
                                  attrs: {
                                    plain: "",
                                    round: "",
                                    size: "large",
                                    color: "#F39800",
                                    "text-color": "#F39800"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.fetchToggleFollow(
                                        _vm.detail.is_followed
                                      )
                                    }
                                  }
                                },
                                [_vm._v("+关注")]
                              )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                { staticStyle: { padding: "4px 10px" } },
                [
                  _c("div", { staticClass: "detail-card-title" }, [
                    _vm._v(_vm._s(_vm.detail.title))
                  ]),
                  _c("div", {
                    staticClass: "container",
                    staticStyle: { "padding-bottom": "0px" },
                    domProps: { innerHTML: _vm._s(_vm.detail.content) },
                    on: {
                      click: function($event) {
                        return _vm.htmlOnClick($event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(_vm.detail.img_info, function(img) {
                      return _c(
                        "div",
                        { staticStyle: { padding: "4px 4px 8px 0" } },
                        [
                          _c("van-image", {
                            staticClass: "avatar-img",
                            attrs: {
                              width: "60px",
                              height: "60px",
                              src: img.url
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.clickAvatar(_vm.item.doctor_id)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(_vm.detail.post_tags, function(itemTag) {
                      return _c(
                        "div",
                        { staticStyle: { padding: "10px 4px 8px 0" } },
                        [
                          _c(
                            "van-tag",
                            {
                              attrs: {
                                color: "#E9FFE7",
                                "text-color": "#41DC33"
                              }
                            },
                            [_vm._v(_vm._s(itemTag.name))]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("van-divider"),
                  _vm.query.scene == "app"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "bold",
                            "padding-bottom": "6px"
                          }
                        },
                        [_vm._v("回复" + _vm._s(_vm.list.length))]
                      )
                    : _vm._e(),
                  _vm.query.scene == "h5"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "padding-bottom": "6px",
                            color: "#A4A4A4",
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("van-icon", { attrs: { name: "eye-o" } }),
                              _vm._v(_vm._s(" " + _vm.detail.click_count))
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "padding-left": "20px" } },
                            [
                              _c("van-icon", { attrs: { name: "good-job-o" } }),
                              _vm._v(_vm._s(" " + _vm.detail.vote_count))
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.query.scene == "app"
                ? _c(
                    "van-pull-refresh",
                    {
                      on: { refresh: _vm.onRefresh },
                      model: {
                        value: _vm.loading,
                        callback: function($$v) {
                          _vm.loading = $$v
                        },
                        expression: "loading"
                      }
                    },
                    [
                      _c(
                        "van-list",
                        {
                          staticStyle: { "background-color": "#F3F3F3" },
                          attrs: {
                            finished: _vm.finished,
                            "finished-text": "没有更多了",
                            offset: "150"
                          },
                          on: { load: _vm.onLoad },
                          model: {
                            value: _vm.loading,
                            callback: function($$v) {
                              _vm.loading = $$v
                            },
                            expression: "loading"
                          }
                        },
                        _vm._l(_vm.list, function(item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "comment-cell",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.directReplyAction(item)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _c("van-image", {
                                        staticClass: "avatar-img",
                                        attrs: {
                                          width: "25px",
                                          height: "25px",
                                          src: item.doctor_url
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.clickAvatar(
                                              item.doctor_id
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "padding-left": "5px",
                                            "font-size": "16px",
                                            color: "#373637"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.doctor_name))]
                                      ),
                                      item.is_author
                                        ? _c(
                                            "van-tag",
                                            {
                                              staticStyle: { margin: "0 6px" },
                                              attrs: {
                                                color: "#E9FFE7",
                                                "text-color": "#41DC33"
                                              }
                                            },
                                            [_vm._v("作者")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.fetchToggleVote(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("van-icon", {
                                            attrs: {
                                              name: "good-job-o",
                                              size: "26",
                                              color: item.is_voted
                                                ? "#ff9000"
                                                : "#A4A4A4"
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#707270",
                                                padding: "0 6px"
                                              }
                                            },
                                            [_vm._v(_vm._s(item.vote_count))]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("van-icon", {
                                        staticStyle: { "padding-left": "6px" },
                                        attrs: {
                                          name: require("../../assets/community/three-point.png"),
                                          size: "18"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.replyAction(item)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "padding-left": "35px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#373637",
                                        padding: "10px 0 6px 0",
                                        "word-break": "break-all"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.content))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        color: "#A4A4A4",
                                        padding: "10px 0 6px 0"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.diff_for_humans))]
                                  ),
                                  item.reply_list.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            background: "#F9F9F9",
                                            padding: "6px"
                                          }
                                        },
                                        _vm._l(item.reply_list, function(
                                          reply,
                                          rIndex
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: rIndex,
                                              staticStyle: {
                                                padding: "4px",
                                                display: "flex",
                                                "flex-wrap": "wrap",
                                                "align-items": "center"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.replyAction(reply)
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "white-space": "nowrap",
                                                    color: "#373637",
                                                    "font-size": "14px",
                                                    "margin-right": "4px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(reply.doctor_name)
                                                  )
                                                ]
                                              ),
                                              reply.is_author
                                                ? _c(
                                                    "van-tag",
                                                    {
                                                      attrs: {
                                                        color: "#E9FFE7",
                                                        "text-color": "#41DC33"
                                                      }
                                                    },
                                                    [_vm._v("作者")]
                                                  )
                                                : _vm._e(),
                                              _c("div", [_vm._v("：")]),
                                              reply.reply_to_doctor_name
                                                ? _c("span", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                          color: "#373637",
                                                          "font-size": "14px",
                                                          "margin-right": "4px"
                                                        }
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("回复")
                                                        ]),
                                                        _vm._v(
                                                          _vm._s(
                                                            reply.reply_to_doctor_name
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c("span", [_vm._v("：")])
                                                  ])
                                                : _vm._e(),
                                              _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px",
                                                      color: "#373637",
                                                      "padding-right": "6px",
                                                      "word-break": "break-all"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(reply.content)
                                                    )
                                                  ]
                                                )
                                              ]),
                                              !item.total &&
                                              rIndex ==
                                                item.reply_list.length - 1 &&
                                              item.reply_more == 1
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "#007ED3",
                                                        "font-size": "14px",
                                                        "text-align": "center"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.fetchCommentReplyList(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("查看更多")]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c("van-divider", {
                                staticStyle: { margin: "4px 0 0 0" }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("van-action-sheet", {
        attrs: { actions: _vm.actions },
        on: { select: _vm.actionSheetOnSelect },
        model: {
          value: _vm.actionSheetShow,
          callback: function($$v) {
            _vm.actionSheetShow = $$v
          },
          expression: "actionSheetShow"
        }
      }),
      _vm.currentReplyItem
        ? _c(
            "van-action-sheet",
            {
              attrs: { title: "回复", "show-cancel-button": "" },
              on: { confirm: _vm.replyDialogConfirm },
              model: {
                value: _vm.replyDialogShow,
                callback: function($$v) {
                  _vm.replyDialogShow = $$v
                },
                expression: "replyDialogShow"
              }
            },
            [
              _c("div", { staticStyle: { padding: "10px 10px 0 10px" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "white-space": "nowrap",
                      color: "#373637",
                      "font-size": "16px",
                      "margin-right": "4px"
                    }
                  },
                  [_vm._v("回复" + _vm._s(_vm.currentReplyItem.doctor_name))]
                ),
                _c("div", { staticStyle: { padding: "6px" } }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        color: "#707270",
                        "word-break": "break-all"
                      }
                    },
                    [_vm._v(_vm._s(_vm.currentReplyItem.content))]
                  )
                ])
              ]),
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("van-field", {
                    staticStyle: {
                      "background-color": "#F3F3F3",
                      "border-radius": "5px",
                      margin: "10px"
                    },
                    attrs: {
                      rows: "1",
                      autosize: "",
                      type: "textarea",
                      placeholder: "请输入评论"
                    },
                    model: {
                      value: _vm.replyDialogContent,
                      callback: function($$v) {
                        _vm.replyDialogContent = $$v
                      },
                      expression: "replyDialogContent"
                    }
                  }),
                  _c(
                    "van-button",
                    {
                      staticStyle: {
                        height: "40px",
                        width: "80px",
                        "margin-right": "5px"
                      },
                      attrs: { color: "#ff9000", plain: "" },
                      on: { click: _vm.replyDialogConfirm }
                    },
                    [_vm._v("发表")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.query.scene == "app"
        ? _c(
            "footer",
            {
              staticStyle: {
                width: "100%",
                position: "fixed",
                bottom: "0",
                left: "0",
                "min-height": "60px",
                "z-index": "10",
                "background-color": "white",
                "justify-content": "center"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                    "min-height": "60px"
                  }
                },
                [
                  _c("van-field", {
                    ref: "commentField",
                    staticStyle: {
                      "background-color": "#F3F3F3",
                      "margin-left": "10px",
                      "border-radius": "5px"
                    },
                    attrs: {
                      placeholder: "写回复",
                      rows: "1",
                      autosize: "",
                      type: "textarea"
                    },
                    model: {
                      value: _vm.comment,
                      callback: function($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment"
                    }
                  }),
                  _vm.comment && _vm.comment.length > 0
                    ? _c(
                        "div",
                        { staticStyle: { width: "120px" } },
                        [
                          _c(
                            "van-button",
                            {
                              staticStyle: {
                                height: "30px",
                                "margin-left": "10px"
                              },
                              attrs: { color: "#ff9000", plain: "" },
                              on: { click: _vm.sendComment }
                            },
                            [_vm._v("发表")]
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "flex",
                            "justify-content": "space-evenly"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "input-right" },
                            [
                              _c("van-icon", {
                                attrs: {
                                  name: require("../../assets/community/share.png"),
                                  size: "24"
                                },
                                on: { click: _vm.shareAction }
                              }),
                              _c("div", { staticClass: "input-right-title" }, [
                                _vm._v("分享")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "input-right" },
                            [
                              _c("van-icon", {
                                attrs: {
                                  name: require("../../assets/community/click_" +
                                    (_vm.detail.is_voted ? 1 : 0) +
                                    ".png"),
                                  size: "24"
                                },
                                on: { click: _vm.fetchPostToggleVote }
                              }),
                              _c("div", { staticClass: "input-right-title" }, [
                                _vm._v("点赞")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "input-right" },
                            [
                              _c("van-icon", {
                                attrs: {
                                  name: require("../../assets/community/f_" +
                                    (_vm.detail.is_favorited ? 1 : 0) +
                                    ".png"),
                                  size: "24"
                                },
                                on: { click: _vm.fetchCommunityToggleFavorite }
                              }),
                              _c("div", { staticClass: "input-right-title" }, [
                                _vm._v("收藏")
                              ])
                            ],
                            1
                          )
                        ]
                      )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }