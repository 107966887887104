"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.VueVantLocales = exports.getLocale = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vue = _interopRequireDefault(require("vue"));

var _cache = require("@/core/services/cache");

var _vant = require("vant");

var _vueI18n = _interopRequireDefault(require("vue-i18n"));

var _zhCN = _interopRequireDefault(require("vant/lib/locale/lang/zh-CN"));

var _enUS = _interopRequireDefault(require("vant/lib/locale/lang/en-US"));

var _en = _interopRequireDefault(require("./en"));

var _zh = _interopRequireDefault(require("./zh"));

_vue.default.use(_vueI18n.default);

var messages = {
  en: (0, _objectSpread2.default)({}, _en.default),
  zh: (0, _objectSpread2.default)({}, _zh.default)
};

var getLocale = function getLocale() {
  var cookieLanguage = (0, _cache.getLanguage)();

  if (cookieLanguage) {
    return cookieLanguage;
  }

  var language = navigator.language.toLowerCase();
  var locales = Object.keys(messages);

  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];

    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }

  return 'zh';
};

exports.getLocale = getLocale;

var VueVantLocales = function VueVantLocales() {
  var lang = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getLocale();

  switch (lang) {
    case 'zh':
      _vant.Locale.use('zh-CN', _zhCN.default);

      break;

    case 'en':
      _vant.Locale.use('en-US', _enUS.default);

      break;
  }
};

exports.VueVantLocales = VueVantLocales;

var _default = new _vueI18n.default({
  locale: getLocale(),
  fallbackLocale: getLocale(),
  messages: messages
});

exports.default = _default;