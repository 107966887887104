"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

var _html2canvas = _interopRequireDefault(require("html2canvas"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'yearReport2023Share',
  props: {},
  data: function data() {
    return {
      detail: undefined,
      type: 1,
      code: undefined
    };
  },
  created: function created() {
    var code = this.$route.query.code;
    var type = this.$route.query.type;

    if (type) {
      this.type = this.$route.query.type;
    }

    console.log(this.$route.query);

    if (code) {
      this.code = code;
      this.fetchActivityDoctorReport2023Share();
    }
  },
  mounted: function mounted() {},
  methods: {
    fetchActivityDoctorReport2023Share: function fetchActivityDoctorReport2023Share() {
      var _this = this;

      _core.mallInteractor.fetchActivityDoctorReport2023Share({
        code: this.code
      }).then(function (data) {
        _this.detail = data;
      });
    },
    shareAction: function shareAction() {
      var dict = {
        'title': this.detail.doctor_name + '医师为您健康助力',
        'info': '点击查看医生年报',
        'url': window.location.href + '&type=2'
      };

      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // ios
        // JS通知WKWebView
        window.webkit.messageHandlers.share.postMessage(dict);
      } else if (/(Android)/i.test(navigator.userAgent)) {
        // android
        window.a5ixlz.share(JSON.stringify(dict)); // window.a5ixlz.startActivity(dict)
      }
    },
    saveToApp: function saveToApp() {
      var _this2 = this;

      this.type = 2;

      _vant.Toast.loading({
        message: '加载中...',
        forbidClick: true
      });

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      var imgHeight = this.$refs.imageWrapper.offsetHeight; // 获取DOM高度

      var imgWidth = this.$refs.imageWrapper.offsetWidth; // 获取DOM宽度

      var scale = window.devicePixelRatio; // 获取设备像素比

      console.log(imgHeight, imgWidth);
      (0, _html2canvas.default)(document.getElementById("imageWrapper"), {
        useCORS: true,
        scale: scale,
        width: imgWidth,
        height: imgHeight,
        timeout: 100
      }).then(function (canvas) {
        _vant.Toast.clear();

        var saveData = canvas.toDataURL('image/png');

        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          // ios
          // JS通知WKWebView
          window.webkit.messageHandlers.savePicture.postMessage(saveData);
        } else if (/(Android)/i.test(navigator.userAgent)) {
          // android
          window.a5ixlz.savePictureBase64(saveData); // window.a5ixlz.onScreenCapture()
        }

        _this2.type = 1;
      });
    }
  }
};
exports.default = _default;