//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'national202210',
  props: {},
  data: function data() {
    return {
      detail: undefined,
      popupShow: false,
      redPackShow: false,
      isRolling: false,
      redPackAmount: 0,
      today_log_id: undefined,
      today_multiple_amount: undefined
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityLKDetailJan();
  },
  methods: {
    clickMainButton: function clickMainButton() {
      var _this = this;

      mallInteractor.fetchActivityLKReceiveJan().then(function (data) {
        _this.redPackAmount = data.amount;
        _this.redPackShow = true;
        _this.today_log_id = data.today_log_id;
      });
    },
    multipleAction: function multipleAction() {
      var _this2 = this;

      mallInteractor.fetchActivityLKMultipleJan({
        today_log_id: this.today_log_id
      }).then(function (data) {
        _this2.today_multiple_amount = data.today_multiple_amount;
        _this2.isRolling = !_this2.isRolling;

        _this2.fetchActivityLKDetailJan();
      });
    },
    overlayDismiss: function overlayDismiss() {
      this.redPackShow = false;
      this.fetchActivityLKDetailJan(); // mallInteractor.fetchActivityLKReceiveJan().then(data => {
      //   this.detail = data;
      // })
    },
    fetchActivityLKDetailJan: function fetchActivityLKDetailJan() {
      var _this3 = this;

      mallInteractor.fetchActivityLKDetailJan().then(function (data) {
        _this3.detail = data;

        if (data.today_log_id != 0) {
          _this3.today_log_id = data.today_log_id;
        }
      });
    }
  }
};