import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import store from '@/store';
import { Dialog, Toast } from 'vant';
export default {
  name: 'MallExpress',
  props: {},
  data: function data() {
    return {
      no: undefined,
      detail: undefined,
      steps: [],
      active: 0
    };
  },
  created: function created() {
    this.no = this.$route.query.no;
    this.logisticsTracking({
      no: this.no
    });
  },
  mounted: function mounted() {},
  methods: {
    logisticsTracking: function logisticsTracking(query) {
      var _this = this;

      mallInteractor.fetchMallGetLogisticsTracking(query).then(function (data) {
        _this.detail = data;
        var steps = [];

        if (data.data) {
          data.data.forEach(function (dict) {
            var item = {
              context: dict.context,
              time: dict.time
            };
            steps.push(item);
          });
          _this.steps = steps;
        }
      });
    }
  }
};