var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: _vm.transitionName } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible"
          }
        ],
        staticClass: "back-to-ceiling",
        style: _vm.customStyle,
        on: { click: _vm.backToTop }
      },
      [
        _c("img", {
          attrs: {
            width: "100%",
            height: "100%",
            src: require("@/assets/systems/back-to-top.png"),
            alt: "",
            srcset: ""
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }