var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: {
        "min-height": "97%",
        width: "100%",
        "background-color": "#FFD196",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "space-between"
      }
    },
    [
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          [
            _c(
              "van-tabs",
              {
                staticStyle: { "padding-top": "10px" },
                attrs: { type: "card", color: "#f39800" },
                on: { change: _vm.tabsChange },
                model: {
                  value: _vm.tabIndex,
                  callback: function($$v) {
                    _vm.tabIndex = $$v
                  },
                  expression: "tabIndex"
                }
              },
              [
                _c(
                  "van-tab",
                  { attrs: { title: "奖励总额" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          margin: "20px 30px 20px 30px",
                          padding: "20px",
                          "text-align": "center",
                          "border-radius": "6px",
                          border: "1px solid #F39800"
                        }
                      },
                      [
                        _vm.detail.total
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row",
                                  "justify-content": "space-around"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _c("div", { staticClass: "f-title" }, [
                                      _vm._v("奖励总额")
                                    ]),
                                    _c("div", { staticClass: "f-value" }, [
                                      _vm._v(
                                        _vm._s(_vm.detail.total.total_income) +
                                          "喜分"
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _c("div", {
                                      staticStyle: {
                                        width: "1px",
                                        height: "40px",
                                        "background-color": "lightgray"
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _c("div", { staticClass: "f-title" }, [
                                      _vm._v("可提现")
                                    ]),
                                    _c("div", { staticClass: "f-value" }, [
                                      _vm._v(
                                        _vm._s(_vm.detail.total.cash_income) +
                                          "喜分"
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "0 16px",
                          "align-items": "center",
                          display: "flex"
                        }
                      },
                      [
                        _c("van-icon", {
                          attrs: {
                            name: "wap-nav",
                            color: "#f39800",
                            size: "20"
                          }
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              "font-weight": "bold",
                              "padding-left": "4px"
                            }
                          },
                          [_vm._v("提现记录")]
                        )
                      ],
                      1
                    ),
                    _c("van-divider"),
                    _c(
                      "van-pull-refresh",
                      {
                        on: { refresh: _vm.onRefresh },
                        model: {
                          value: _vm.loading,
                          callback: function($$v) {
                            _vm.loading = $$v
                          },
                          expression: "loading"
                        }
                      },
                      [
                        _c(
                          "van-list",
                          {
                            attrs: {
                              finished: _vm.finished,
                              "finished-text": "没有更多了",
                              offset: "150"
                            },
                            on: { load: _vm.onLoad },
                            model: {
                              value: _vm.loading,
                              callback: function($$v) {
                                _vm.loading = $$v
                              },
                              expression: "loading"
                            }
                          },
                          _vm._l(_vm.list, function(item, index) {
                            return _c("van-cell", {
                              attrs: {
                                title: "提现至收益",
                                value: "-" + item.amount + "喜分",
                                "value-class": "value-class",
                                label: item.created_at
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "van-tab",
                  { attrs: { title: "邀请奖励明细" } },
                  [
                    _vm.detail.invite
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              padding: "30px 16px 0px 16px",
                              "align-items": "center",
                              display: "flex"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  "font-weight": "bold",
                                  "padding-left": "4px",
                                  color: "#F39800"
                                }
                              },
                              [
                                _vm._v(
                                  "共获得奖励" +
                                    _vm._s(
                                      _vm.detail.invite.total_referee_income
                                    ) +
                                    "喜分"
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _c("van-divider"),
                    _c(
                      "van-pull-refresh",
                      {
                        on: { refresh: _vm.onRefresh },
                        model: {
                          value: _vm.loading,
                          callback: function($$v) {
                            _vm.loading = $$v
                          },
                          expression: "loading"
                        }
                      },
                      [
                        _c(
                          "van-list",
                          {
                            attrs: {
                              finished: _vm.finished,
                              "finished-text": "没有更多了",
                              offset: "150"
                            },
                            on: { load: _vm.onLoad },
                            model: {
                              value: _vm.loading,
                              callback: function($$v) {
                                _vm.loading = $$v
                              },
                              expression: "loading"
                            }
                          },
                          _vm._l(_vm.list, function(item, index) {
                            return _c("van-cell", {
                              attrs: {
                                title: item.title,
                                value: "+" + item.amount + "喜分",
                                "value-class": "value-class",
                                label: item.created_at
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.detail.join && _vm.detail.join.status == 1
                  ? _c(
                      "van-tab",
                      { attrs: { title: "受邀奖励明细" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                              padding: "20px 16px 0px 16px",
                              "align-items": "center"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "align-items": "center",
                                  display: "flex"
                                }
                              },
                              [
                                _c("van-icon", {
                                  attrs: {
                                    name: "wap-nav",
                                    color: "#f39800",
                                    size: "20"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "15px",
                                      "font-weight": "bold",
                                      "padding-left": "4px"
                                    }
                                  },
                                  [_vm._v("奖励任务")]
                                )
                              ],
                              1
                            ),
                            _vm.detail.join
                              ? _c(
                                  "div",
                                  { staticStyle: { "font-size": "14px" } },
                                  [
                                    _vm._v("已获取奖励："),
                                    _c("span", { staticClass: "f-value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.detail.join.total_joiner_income
                                        ) + "喜分"
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _c("van-divider"),
                        _vm.detail.join &&
                        _vm.detail.join.joiner_list.length > 0
                          ? _c(
                              "van-cell-group",
                              {
                                staticStyle: { "margin-bottom": "20px" },
                                attrs: { border: false }
                              },
                              _vm._l(_vm.detail.join.joiner_list, function(
                                item,
                                index
                              ) {
                                return _c("van-cell", {
                                  attrs: { title: item.title, label: item.msg },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function() {
                                          return [
                                            _c(
                                              "van-tag",
                                              {
                                                attrs: {
                                                  color:
                                                    item.status == 1
                                                      ? "#f39800"
                                                      : "gray",
                                                  plain: item.status != 1,
                                                  size: "large"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.status == 1
                                                      ? "已完成"
                                                      : "待完成"
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              }),
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "footer",
      {
        staticStyle: { width: "100%", height: "40px", "text-align": "center" }
      },
      [
        _c(
          "div",
          { staticStyle: { "text-align": "center", "font-size": "12px" } },
          [
            _vm._v("如有疑问，请联系客服："),
            _c("span", { staticStyle: { color: "#A76230" } }, [
              _vm._v("4000918999")
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }