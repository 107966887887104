"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.reduce");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _store = _interopRequireDefault(require("@/store"));

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MallItemEdit',
  data: function data() {
    return {
      address: undefined,
      addresses: [],
      integralInfo: undefined,
      quantity: 1,
      goodsInfoType: 2,
      goodsInfo: undefined,
      totalAmount: undefined,
      usableCouponsList: [],
      selectCoupon: undefined,
      popupShow: false,
      couponCheckbox: undefined
    };
  },
  methods: {
    radioChange: function radioChange(item) {
      var _this = this;

      console.log(item);
      this.usableCouponsList.forEach(function (element) {
        if (element.id === item) {
          _this.selectCoupon = element;
          _this.totalAmount = _this.goodsInfoPriceQuantity();
        }
      });
    },
    couponClick: function couponClick() {
      if (this.usableCouponsList.length > 0) {
        this.popupShow = true;
      }
    },
    fetchMallCheckCoupons: function fetchMallCheckCoupons() {
      var _this2 = this;

      var id = this.$route.query.id;
      var params = {
        goods_id: id
      };

      _core.mallInteractor.fetchMallCheckCoupons(params).then(function (data) {
        _this2.usableCouponsList = data.usable;

        if (_this2.usableCouponsList.length > 0) {
          _this2.selectCoupon = _this2.usableCouponsList[0];
          _this2.couponCheckbox = _this2.selectCoupon.id;
          _this2.totalAmount = _this2.goodsInfoPriceQuantity();
        } else {
          _this2.selectCoupon = undefined;
          _this2.couponCheckbox = undefined;
          _this2.totalAmount = _this2.goodsInfoPriceQuantity();
        }
      });
    },
    fetchGoodsInfo: function fetchGoodsInfo() {
      var _this3 = this;

      var id = this.$route.query.id;
      var params = {
        id: id
      };

      _core.mallInteractor.fetchMallGoodsInfo(params).then(function (data) {
        _this3.goodsInfo = data.goodsinfo;
        _this3.totalAmount = _this3.goodsInfoPriceQuantity();
      });
    },
    fetchIntegralInfo: function fetchIntegralInfo() {
      var _this4 = this;

      _core.mallInteractor.fetchMallIntegralInfo().then(function (data) {
        _this4.integralInfo = data;
      });
    },
    fetchAddressList: function fetchAddressList() {
      var _this5 = this;

      _core.mallInteractor.fetchMallAddressList().then(function (data) {
        _this5.addresses = data.address;
      }).catch(function (err) {
        _this5.$message({
          message: err,
          type: 'error'
        });

        console.log(err, '错误');
      });
    },
    goodsInfoPrice: function goodsInfoPrice() {
      if (this.goodsInfo.buy_model == 1) {
        return this.goodsInfo.integral + '经验值';
      } else if (this.goodsInfo.buy_model == 2) {
        return this.goodsInfo.income + '喜分';
      } else {
        return this.goodsInfo.integral + '经验值+' + this.goodsInfo.income + '喜分';
      }
    },
    stepperOnChange: function stepperOnChange(value) {
      var _this6 = this;

      _vant.Toast.loading({
        forbidClick: true
      });

      var id = this.$route.query.id;
      var params = {
        id: id,
        number: value
      };

      _core.mallInteractor.fetchMallBuyGoods(params).then(function (res) {
        _this6.totalAmount = _this6.goodsInfoPriceQuantity();
        _this6.quantity = value;

        _this6.fetchMallCheckCoupons();
      });
    },
    addressOnChange: function addressOnChange(item) {
      this.address = item.id;
    },
    goodsInfoPriceQuantity: function goodsInfoPriceQuantity() {
      _vant.Toast.clear();

      if (this.goodsInfo.buy_model == 1) {
        return this.goodsInfo.integral * this.quantity + '经验值';
      } else if (this.goodsInfo.buy_model == 2) {
        var reduce = this.selectCoupon ? Number(this.selectCoupon.reduce) : 0;
        return this.goodsInfo.income * 100 * this.quantity * 0.01 - reduce + '喜分';
      } else {
        var _reduce = this.selectCoupon ? Number(this.selectCoupon.reduce) : 0;

        return this.goodsInfo.integral * this.quantity + '经验值+' + (this.goodsInfo.income * 100 * this.quantity * 0.01 - _reduce) + '喜分';
      }
    },
    payAction: function payAction() {
      var _this7 = this;

      var params;
      var id = this.$route.query.id;

      if (this.goodsInfoType == 1) {
        if (!this.address) {
          (0, _vant.Toast)('请选择收货地址');
          return;
        }

        params = {
          address_id: this.address,
          goods_id: id
        };
      } else {
        params = {
          goods_id: id
        };
      }

      if (this.selectCoupon) {
        params['coupon_id'] = this.selectCoupon.id;
      }

      _core.mallInteractor.fetchMallCreateOrder(params).then(function (data) {
        _this7.$router.replace({
          path: '/mallItemBuySuccess',
          query: {
            totalAmount: _this7.totalAmount,
            img: _this7.goodsInfo.cover_image_file.url
          }
        });
      }).catch(function (err) {
        _vant.Toast.fail(err.message);

        console.log(err);
      });
    }
  },
  computed: {},
  mounted: function mounted() {
    this.fetchIntegralInfo();
    this.fetchGoodsInfo();

    if (this.goodsInfoType == 1) {
      this.fetchAddressList();
    }

    this.fetchMallCheckCoupons();
  },
  created: function created() {
    this.goodsInfoType = this.$route.query.type;
  }
};
exports.default = _default;