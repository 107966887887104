var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#fedcb5" }
    },
    [
      _c("van-image", {
        staticStyle: { width: "100%", "z-index": "0" },
        attrs: {
          fit: "fill",
          src: require("../../assets/activity/activityType2202310/banner.png")
        }
      }),
      _c(
        "div",
        {
          staticStyle: {
            "text-align": "center",
            "margin-top": "14px",
            position: "absolute"
          }
        },
        [
          _c("van-image", {
            staticStyle: { width: "48%" },
            attrs: {
              fit: "contain",
              src: require("../../assets/activity/activityType2202310/c_h_0.png")
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "first-card" }, [
          _vm._m(0),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                "justify-content": "space-between",
                padding: "10px 0px"
              }
            },
            [
              _c("van-image", {
                staticStyle: { "padding-right": "8px" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/activityType2202310/c_1_i_0.png")
                },
                on: {
                  click: function($event) {
                    return _vm.pushToList()
                  }
                }
              }),
              _c("van-image", {
                staticStyle: { "padding-left": "8px" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/activityType2202310/c_1_i_1.png")
                },
                on: {
                  click: function($event) {
                    return _vm.pushToMallItem()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                "justify-content": "space-between",
                padding: "10px 0px"
              }
            },
            [
              _c("van-image", {
                staticStyle: { "padding-right": "5px" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/activityType2202310/c_1_i_d_0.png")
                }
              }),
              _c("van-image", {
                staticStyle: { "padding-left": "5px" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/activityType2202310/c_1_i_d_1.png")
                }
              })
            ],
            1
          ),
          _vm.data
            ? _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #F89C48",
                    "text-align": "center",
                    "margin-top": "10px",
                    height: "300px",
                    overflow: "hidden"
                  }
                },
                [
                  _c("van-image", {
                    staticStyle: { width: "70%", padding: "20px 0" },
                    attrs: {
                      fit: "contain",
                      src: require("../../assets/activity/activityType2202310/c_1_i_s.png")
                    },
                    on: {
                      click: function($event) {
                        return _vm.pushToPatientUrl()
                      }
                    }
                  }),
                  _c("div", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v("已获补贴总额："),
                    _vm.data
                      ? _c("span", { staticStyle: { color: "#ff9000" } }, [
                          _vm._v(_vm._s(_vm.data.total_income))
                        ])
                      : _vm._e(),
                    _vm._v("喜分")
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        margin: "10px 20px 10px 20px",
                        height: "150px",
                        display: "flex",
                        "flex-direction": "column",
                        overflow: "scroll",
                        background: "#FFF7F0"
                      }
                    },
                    [
                      _vm._l(_vm.data.list, function(item, index) {
                        return _vm.data && _vm.data.list.length != 0
                          ? _c(
                              "div",
                              {
                                key: index,
                                staticStyle: {
                                  display: "flex",
                                  "font-size": "14px",
                                  "justify-content": "center"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      display: "block",
                                      width: "70px",
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      "text-overflow": "ellipsis"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.user_name))]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      " " +
                                        item.coupon_name +
                                        item.num +
                                        "张(已用" +
                                        item.used_num +
                                        "张)"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      }),
                      _vm.data && _vm.data.list.length == 0
                        ? _c(
                            "div",
                            { staticStyle: { "padding-top": "50px" } },
                            [_vm._v("暂无数据")]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _c(
        "div",
        {
          staticStyle: {
            "text-align": "center",
            "margin-top": "-6px",
            position: "absolute"
          }
        },
        [
          _c("van-image", {
            staticStyle: { width: "48%" },
            attrs: {
              fit: "contain",
              src: require("../../assets/activity/activityType2202310/c_h_1.png")
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "first-card" }, [
          _vm._m(1),
          _vm.data.jd_activity_status == 1
            ? _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v("已获得京东卡金额："),
                _c("span", { staticStyle: { color: "#f38900" } }, [
                  _vm._v(_vm._s(_vm.data.jd_amount) + "元")
                ])
              ])
            : _vm._e(),
          _vm.data
            ? _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "padding-top": "20px" }
                },
                [
                  _vm.data.jd_activity_status == 0
                    ? _c("van-image", {
                        staticStyle: { width: "50%" },
                        attrs: {
                          fit: "contain",
                          src: require("../../assets/activity/activityType2202310/c_2_0.png")
                        }
                      })
                    : _vm._e(),
                  _vm.data.jd_activity_status == 1 &&
                  _vm.data.jd_activity_button_status == 0
                    ? _c("van-image", {
                        staticStyle: { width: "50%" },
                        attrs: {
                          fit: "contain",
                          src: require("../../assets/activity/activityType2202310/c_2_1_0.png")
                        }
                      })
                    : _vm._e(),
                  _vm.data.jd_activity_status == 1 &&
                  _vm.data.jd_activity_button_status == 1
                    ? _c("van-image", {
                        staticStyle: { width: "50%" },
                        attrs: {
                          fit: "contain",
                          src: require("../../assets/activity/activityType2202310/c_2_1_1.png")
                        },
                        on: {
                          click: function($event) {
                            return _vm.pushToMall()
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.data.jd_activity_status == 1 &&
          _vm.data.jd_activity_button_status == 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    "padding-top": "10px",
                    color: "#5A5855",
                    "font-size": "12px",
                    "text-align": "center"
                  }
                },
                [_vm._v(_vm._s(_vm.data.jd_str))]
              )
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "image-inner" },
          [
            _c("van-image", {
              staticStyle: { width: "24%" },
              attrs: {
                fit: "contain",
                src: require("../../assets/activity/activityType2202310/c_h_3.png")
              }
            })
          ],
          1
        ),
        _vm._m(2)
      ]),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "image-inner" },
          [
            _c("van-image", {
              staticStyle: { width: "24%" },
              attrs: {
                fit: "contain",
                src: require("../../assets/activity/activityType2202310/c_h_4.png")
              }
            })
          ],
          1
        ),
        _vm._m(3)
      ]),
      _c("div", { staticStyle: { height: "40px" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "0 4px" } }, [
      _vm._v(" 在2023年9月20日至2024年1月10日期间，邀请患者预定 "),
      _c("span", { staticStyle: { color: "#ff9000" } }, [_vm._v("膏方定金券")]),
      _vm._v(" ，可立享 "),
      _c("span", { staticStyle: { color: "#ff9000" } }, [_vm._v("50%")]),
      _vm._v("定金补贴。另有膏方体验装限量兑换，快来参与吧！ ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "0px 4px 20px 4px" } }, [
      _vm._v(" 在2023年10月21日至2024年1月10日期间开具膏方，基础药费每满"),
      _c("span", { staticStyle: { color: "#ff9000" } }, [_vm._v("1000元")]),
      _vm._v("可获得一张"),
      _c("span", { staticStyle: { color: "#ff9000" } }, [_vm._v("100元")]),
      _vm._v("京东卡，上不封顶。 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inner-card" }, [
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
        [_vm._v("Q：补贴什么时候能到账？")]
      ),
      _c(
        "div",
        { staticClass: "sub-title", staticStyle: { color: "#38404E" } },
        [
          _vm._v(
            "A：患者支付定金成功后，系统自动发放，可在「我的收益」中查看补贴记录。"
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
        [_vm._v("Q：什么时候可以领取京东卡？")]
      ),
      _c(
        "div",
        { staticClass: "sub-title", staticStyle: { color: "#38404E" } },
        [
          _vm._v(
            "A：在2024年1月15日至2024年2月15日期间可领取京东卡，过期则视为自动放弃领取资格。"
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "main-title", staticStyle: { color: "#ff9000" } },
        [_vm._v("Q：京东卡如何领取？")]
      ),
      _c(
        "div",
        { staticClass: "sub-title", staticStyle: { color: "#38404E" } },
        [
          _vm._v(
            "A：在开放领取资格期间，点击活动页「立即兑换」按钮跳转进入郎中集市，选择京东卡兑换即可。"
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inner-card" }, [
      _c("div", [
        _vm._v(
          "1、本活动提倡公平参与，有违规行为的用户将被取消参与资格，违规所得奖励将被平台收回。"
        ),
        _c("br"),
        _vm._v(
          " 2、本平台依法运营此次活动，如因不可抗力或相关政策等原因导致本次活动调整、暂停举办或无法进行，本平台有权利随时决定修改、暂停或终止本活动。"
        ),
        _c("br"),
        _vm._v(" 3、本次活动最终解释权归喜郎中所有。"),
        _c("br")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }