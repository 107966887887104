"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
var _default = {
  name: 'AppAllSlideList',
  props: {},
  data: function data() {
    return {
      list: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchAppAllSlideList();
    this.fetchActivityLogs();
  },
  methods: {
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      _core.mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    fetchAppAllSlideList: function fetchAppAllSlideList() {
      var _this = this;

      _core.mallInteractor.fetchAppAllSlideList({
        display_client: '2'
      }).then(function (data) {
        _this.list = data.slides;
      });
    },
    jumpToUrl: function jumpToUrl(url) {
      window.location.href = url;
    }
  }
};
exports.default = _default;