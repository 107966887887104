import "core-js/modules/es.array.concat";
import _toConsumableArray from "/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { communityInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'CommunityMyFansList',
  props: {},
  data: function data() {
    return {
      query: {
        object_type: 'fans',
        page: 0,
        size: 10
      },
      list: [],
      loading: false,
      finished: false,
      refreshing: false
    };
  },
  created: function created() {
    var doctor_id = this.$route.query.doctor_id;

    if (doctor_id) {
      this.query.doctor_id = doctor_id;
    }
  },
  mounted: function mounted() {},
  methods: {
    clickCell: function clickCell(item) {},
    fetchToggleFollow: function fetchToggleFollow(item) {
      var that = this;

      if (item.is_followed) {
        Dialog.confirm({
          message: '确认不再关注此人',
          confirmButtonColor: '#ff9000'
        }).then(function () {
          // on confirm
          that.toggleFollow(item);
        }).catch(function () {// on cancel
        });
      } else {
        that.toggleFollow(item);
      }
    },
    toggleFollow: function toggleFollow(item) {
      var _this = this;

      communityInteractor.fetchToggleFollow({
        doctor_id: item.doctor_id
      }).then(function (data) {
        _this.onRefresh();
      });
    },
    onLoad: function onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      this.query.page = this.query.page + 1;
      console.log('onLoad');
      this.fetchCommunityFollowOFansList(this.query);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false;
      console.log('onRefresh');
      console.log(this.query); // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchCommunityFollowOFansList(this.query);
    },
    fetchCommunityFollowOFansList: function fetchCommunityFollowOFansList(query) {
      var _this2 = this;

      communityInteractor.fetchCommunityFollowOFansList(query).then(function (data) {
        if (_this2.query.page === 1) {
          _this2.list = data.list;
        } else {
          _this2.list = [].concat(_toConsumableArray(_this2.list), _toConsumableArray(data.list));
        }

        if (data.total <= _this2.list.length) {
          _this2.finished = true;
        }

        console.log(_this2.list);
        _this2.loading = false;
      });
    }
  }
};