"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoginSendSMS = exports.SMSLogin = exports.Login = void 0;
var Login = '/api/sms_login';
exports.Login = Login;
var SMSLogin = '/api/sms_login';
exports.SMSLogin = SMSLogin;
var LoginSendSMS = '/api/login_sendsms';
exports.LoginSendSMS = LoginSendSMS;