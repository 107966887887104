var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#FFFBF6" }
    },
    [
      _c(
        "div",
        { staticStyle: { display: "flex", padding: "20px" } },
        [
          _c("van-image", {
            attrs: {
              fit: "contain",
              src: require("../../assets/activity/activityType2202310/r_l_light.png")
            }
          }),
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "14px",
                "padding-left": "10px",
                color: "#A76230"
              }
            },
            [
              _vm._v(
                "仅支持添加允许分享给患者的常用方。若未找到处方，请前往APP内「药方·科教—我的常用方」中编辑或新增常用方。"
              )
            ]
          )
        ],
        1
      ),
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text": "没有更多了",
                offset: "150"
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "inner-card" },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center",
                        margin: "10px 0 20px 0"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#A76230",
                            "font-weight": "bold",
                            "font-size": "16px"
                          }
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        [
                          _c("van-switch", {
                            attrs: {
                              value: item.activity202309_added == 1,
                              size: "15",
                              "active-color": "#F39800"
                            },
                            on: {
                              input: function($event) {
                                return _vm.switchOnInput(item)
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#A76230",
                                "font-size": "14px",
                                padding: "0px 10px"
                              }
                            },
                            [_vm._v("生成海报")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._l(item.recipe_items_, function(r, ri) {
                    return _c(
                      "span",
                      {
                        key: ri,
                        staticStyle: {
                          "line-height": "22px",
                          "font-size": "14px"
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              r.name +
                                r.weight * item.quantity +
                                r.main_unit +
                                (ri == item.recipe_items_.length - 1
                                  ? ""
                                  : "、")
                            )
                          )
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("van-image", {
            staticStyle: { width: "80%" },
            attrs: {
              fit: "contain",
              src: require("../../assets/activity/activityType2202310/r_l_bottom.png")
            },
            on: {
              click: function($event) {
                return _vm.popPage()
              }
            }
          })
        ],
        1
      ),
      _c("div", { staticStyle: { height: "40px" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }