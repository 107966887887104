//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'configActivity1',
  props: {},
  data: function data() {
    return {
      code: undefined,
      detail: undefined,
      show: false,
      result_item: undefined,
      // 中奖结果
      luckShowLoading: false,
      luckShow: false,
      buttonPopupShow: false,
      buttonPopupShowType: 0
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var code = this.$route.query.code;

    if (code) {
      this.code = code;
      console.log(code);
    }

    this.fetchConfigActivityDetail();
    this.fetchActivityLogs();
  },
  methods: {
    buttonPopupShowAction: function buttonPopupShowAction(type) {
      this.buttonPopupShowType = type;
      this.buttonPopupShow = true;
    },
    fetchConfigActivityDetail: function fetchConfigActivityDetail() {
      var _this = this;

      mallInteractor.fetchConfigActivityDetail({
        code: this.code
      }).then(function (data) {
        _this.detail = data;

        if (_this.detail.activity_status == 1) {
          Dialog.alert({
            title: '温馨提醒',
            message: '活动暂未开始'
          }).then(function () {// on close
          });
        }
      });
    },
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    fetchConfigActivityButtonReceive: function fetchConfigActivityButtonReceive() {
      var _this2 = this;

      mallInteractor.fetchConfigActivityButtonReceive({
        code: this.code
      }).then(function (data) {
        if (data.redirect == 1) {
          _this2.jumpToRewardList();
        } else {
          Toast(data.reward_msg);
        }
      });
    },
    //点击抽奖按钮
    lotteryClick: function lotteryClick() {
      var _this3 = this;

      if (this.luckShowLoading == true) {
        return;
      }

      this.luckShowLoading = true;
      mallInteractor.fetchConfigActivityReceive({
        code: this.code
      }).then(function (data) {
        _this3.result_item = data.result_item;
        _this3.luckShow = true;
        _this3.luckShowLoading = false;
      }).catch(function (e) {
        _this3.luckShowLoading = false;
      });
    },
    luckPopupClose: function luckPopupClose() {
      this.luckShow = false;
      this.luckShowLoading = false;
      this.result_item = undefined;
      this.fetchConfigActivityDetail();
    },
    jumpToRewardList: function jumpToRewardList() {
      this.$router.push({
        path: '/configActivityRewardList',
        query: {
          code: this.code
        }
      });
    }
  }
};