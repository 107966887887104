"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.join");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _core = require("@/core");

var _store = _interopRequireDefault(require("@/store"));

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'InviteDoctor',
  props: {},
  data: function data() {
    return {
      userInfo: undefined,
      tabIndex: 0,
      detail: {
        total: undefined,
        invite: undefined,
        join: undefined
      },
      query: {
        page: 0,
        size: 10
      },
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      popupShow: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityDetail();
    this.fetchDoctorInfo();
    this.fetchActivityInviteDoctor202311JoinDetail();
  },
  methods: {
    tabsChange: function tabsChange() {
      console.log(this.tabIndex);
      this.fetchActivityDetail();
    },
    jumpToDetail: function jumpToDetail(id) {
      this.$router.push({
        path: '/inviteDoctorActivityDetail',
        query: {
          id: id
        }
      });
    },
    fetchActivityDetail: function fetchActivityDetail() {
      if (this.tabIndex == 0 || this.tabIndex == 1) {
        this.onRefresh();
      } else {
        this.fetchActivityInviteDoctor202311JoinDetail();
      }
    },
    fetchActivityInviteDoctor202311JoinDetail: function fetchActivityInviteDoctor202311JoinDetail() {
      var _this = this;

      _core.mallInteractor.fetchActivityInviteDoctor202311JoinDetail().then(function (data) {
        _this.detail.join = data;
      });
    },
    onLoad: function onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      this.query.page = this.query.page + 1;
      console.log('onLoad');
      this.fetchList(this.query);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchList(this.query);
    },
    fetchList: function fetchList(query) {
      var _this2 = this;

      if (this.tabIndex == 0) {
        _core.mallInteractor.fetchActivityInviteDoctor202311TotalIncome(query).then(function (data) {
          _this2.detail.total = data;

          if (_this2.query.page === 1) {
            _this2.list = data.cash_list.list;
          } else {
            _this2.list = [].concat((0, _toConsumableArray2.default)(_this2.list), (0, _toConsumableArray2.default)(data.cash_list.list));
          }

          if (data.cash_list.total <= _this2.list.length) {
            _this2.finished = true;
          }

          console.log(_this2.list);
          _this2.loading = false;
        });
      } else {
        _core.mallInteractor.fetchActivityInviteDoctor202311InviteDetail(query).then(function (data) {
          _this2.detail.invite = data;

          if (_this2.query.page === 1) {
            _this2.list = data.referee_list.list;
          } else {
            _this2.list = [].concat((0, _toConsumableArray2.default)(_this2.list), (0, _toConsumableArray2.default)(data.referee_list.list));
          }

          if (data.referee_list.total <= _this2.list.length) {
            _this2.finished = true;
          }

          console.log(_this2.list);
          _this2.loading = false;
        });
      }
    },
    fetchDoctorInfo: function fetchDoctorInfo() {
      var _this3 = this;

      _core.doctorInteractor.fetchDoctorInfo().then(function (data) {
        console.log(data);
        _this3.userInfo = data.doctor;
      });
    }
  }
};
exports.default = _default;