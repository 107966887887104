//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
import vueDanmaku from 'vue-danmaku';
export default {
  name: 'AllSettings',
  components: {
    vueDanmaku: vueDanmaku
  },
  props: {},
  data: function data() {
    return {
      receiveShow: false,
      detail: undefined,
      danmus: [],
      amount: '',
      popupShow: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityLKDetail();
  },
  methods: {
    imageAction: function imageAction() {
      this.fetchActivityLKReceive();
    },
    fetchActivityLKReceive: function fetchActivityLKReceive() {
      var _this = this;

      mallInteractor.fetchActivityLKReceive().then(function (data) {
        _this.receiveShow = true;
        _this.amount = data.amount;
      });
    },
    receiveShowDismiss: function receiveShowDismiss() {
      this.receiveShow = false;
      this.fetchActivityLKDetail();
    },
    fetchActivityLKDetail: function fetchActivityLKDetail() {
      var _this2 = this;

      mallInteractor.fetchActivityLKDetail().then(function (data) {
        _this2.detail = data;
        _this2.danmus = data.barrage_list;
      });
    }
  }
};