//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import store from '@/store';
import { Dialog, Toast } from 'vant';
export default {
  name: 'MallItemDetail',
  data: function data() {
    return {
      slideList: [],
      list: [],
      goodsInfo: undefined
    };
  },
  methods: {
    fetchGoodsInfo: function fetchGoodsInfo() {
      var id = this.$route.params.id;
      var params = {
        id: id
      };

      var _this = this;

      mallInteractor.fetchMallGoodsInfo(params).then(function (data) {
        _this.goodsInfo = data.goodsinfo;
        _this.slideList = data.goodsinfo.albums;
      });
    },
    goodsInfoPrice: function goodsInfoPrice() {
      if (this.goodsInfo.buy_model == 1) {
        return this.goodsInfo.integral + '经验值';
      } else if (this.goodsInfo.buy_model == 2) {
        return this.goodsInfo.income + '喜分';
      } else {
        return this.goodsInfo.integral + '经验值+' + this.goodsInfo.income + '喜分';
      }
    },
    payButtonAction: function payButtonAction() {
      this.fetchBuyGoods();
    },
    fetchBuyGoods: function fetchBuyGoods() {
      var _this2 = this;

      var id = this.$route.params.id;
      var params = {
        id: id,
        number: 1
      };
      mallInteractor.fetchMallBuyGoods(params).then(function (data) {
        _this2.$router.push({
          path: '/mallItemEdit',
          query: {
            id: _this2.$route.params.id,
            type: _this2.goodsInfo.type
          }
        });
      });
    }
  },
  computed: {},
  mounted: function mounted() {
    this.fetchGoodsInfo();
  },
  created: function created() {}
};