var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text": "没有更多了",
                offset: "150"
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "cell" },
                [
                  _c(
                    "van-cell-group",
                    [
                      _c("van-cell", {
                        attrs: { "is-link": "" },
                        on: {
                          click: function($event) {
                            return _vm.pushToItem(item)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "custom-title" },
                                        [_vm._v(_vm._s(item.month))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px",
                                            color: "red"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.amount))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px",
                                            color: "gray"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.status))]
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }