"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _dayjs = _interopRequireDefault(require("dayjs"));

var _quarterOfYear = _interopRequireDefault(require("dayjs/plugin/quarterOfYear"));

require("dayjs/locale/zh-cn");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ES 2015
// ES 2015 
_dayjs.default.locale('zh-cn');

_dayjs.default.extend(_quarterOfYear.default);

var _default = {
  name: 'national202210',
  props: {},
  data: function data() {
    return {
      detail: undefined,
      show: false,
      showPopupDatePicker: false,
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      datePickerTitle: (0, _dayjs.default)(Date()).format('YYYY-MM')
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityPromotionDetail();
  },
  methods: {
    formatter: function formatter(type, val) {
      if (type === 'year') {
        return "".concat(val, "\u5E74");
      } else if (type === 'month') {
        return "".concat(val, "\u6708");
      }

      return val;
    },
    datePickerConfirm: function datePickerConfirm(v) {
      this.showPopupDatePicker = false;
      console.log((0, _dayjs.default)(v).format('YYYY-MM'));
      var value = (0, _dayjs.default)(v).format('YYYY-MM');
      this.datePickerTitle = value;
      this.fetchActivityPromotionDetail();
    },
    fetchActivityPromotionDetail: function fetchActivityPromotionDetail() {
      var _this = this;

      _core.mallInteractor.fetchActivityPromotionDetail({
        id: 14,
        date: this.datePickerTitle
      }).then(function (data) {
        _this.detail = data;
      });
    },
    jumpToMall: function jumpToMall(id) {
      if (id != undefined) {
        this.$router.push({
          path: '/mallActivitiesItemList',
          query: {
            id: id
          }
        });
      }
    }
  }
};
exports.default = _default;