"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
// import ServiceWorkerUpdatePopup from '@/pwa/components/ServiceWorkerUpdatePopup.vue'
var _default = {
  name: 'App',
  components: {// ServiceWorkerUpdatePopup
  },
  created: function created() {
    this.handleFocusOut();
    this.handleResize();
    console.log("app .vue result:"); // 检查是否在iOS环境中

    try {
      var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

      if (isIOS && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.getAppToken) {
        // 调用 iOS 原生方法
        var result = window.webkit.messageHandlers.getAppToken.postMessage(null);
        result.then(function (appToken) {
          _store.default.dispatch('user/saveToken', appToken);
        }).catch(function (error) {
          console.log("error:", error);
        });
      } else {
        if (window.a5ixlz && typeof window.a5ixlz.getAppToken === 'function') {
          // 直接调用同步方法
          var appToken = window.a5ixlz.getAppToken();

          _store.default.dispatch('user/saveToken', appToken);
        } else {
          throw new Error("a5ixlz or getAppToken is not available");
        }
      }
    } catch (error) {
      // 捕获错误
      console.error("Error fetching app token:", error);
    }
  },
  methods: {
    handleFocusOut: function handleFocusOut() {
      document.addEventListener('focusout', function () {
        document.body.scrollTop = 0;
      });
    },
    handleResize: function handleResize() {
      var clientHeight = document.documentElement.clientHeight;

      var resizeHandler = function resizeHandler() {
        var tagName = document.activeElement.tagName;

        if (tagName) {
          var inputBox = tagName === 'INPUT' || tagName === 'TEXTAREA';

          if (inputBox) {
            setTimeout(function () {
              document.activeElement.scrollIntoView();
            }, 0);
          }
        }

        var bodyHeight = document.documentElement.clientHeight;
        var ele = document.getElementById('fixed-bottom');

        if (ele) {
          if (clientHeight > bodyHeight) ele.style.display = 'none';else ele.style.display = 'block';
        }
      };

      window.addEventListener('resize', resizeHandler);
    }
  }
};
exports.default = _default;