"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'configActivity1',
  props: {},
  data: function data() {
    return {
      code: undefined,
      detail: undefined,
      show: false,
      result_item: undefined,
      // 中奖结果
      questionShowLoading: false,
      questionPopShow: false,
      buttonPopupShow: false,
      buttonPopupShowType: 0,
      questionDetail: undefined,
      questionAnswer: [],
      questionStep: 0,
      questionIsAnswer: false,
      answered_num: 0
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var code = this.$route.query.code;

    if (code) {
      this.code = code;
      console.log(code);
    }

    this.fetchConfigActivityDetail();
    this.fetchActivityLogs();
  },
  methods: {
    buttonPopupShowAction: function buttonPopupShowAction(type) {
      this.buttonPopupShowType = type;
      this.buttonPopupShow = true;
    },
    fetchConfigActivityDetail: function fetchConfigActivityDetail() {
      var _this = this;

      _core.mallInteractor.fetchConfigActivityDetail({
        code: this.code
      }).then(function (data) {
        _this.detail = data;

        if (_this.detail.activity_status == 1) {
          _vant.Dialog.alert({
            title: '温馨提醒',
            message: '活动暂未开始'
          }).then(function () {// on close
          });
        }
      });
    },
    questionPopClick: function questionPopClick() {
      var _this2 = this;

      if (this.questionShowLoading == true) {
        return;
      }

      this.questionShowLoading = true;

      _core.mallInteractor.fetchConfigActivityQuestionList({
        code: this.code
      }).then(function (data) {
        // this.result_item = data.result_item;
        _this2.questionDetail = data;
        _this2.questionPopShow = true;
        _this2.questionShowLoading = false;

        if (_this2.questionDetail.list.length > 0) {
          _this2.questionIsAnswer = _this2.questionDetail.list[0].doctor_answer == null ? false : true;
        }
      }).catch(function (e) {
        _this2.questionShowLoading = false;
      });
    },
    preAction: function preAction() {
      this.questionStep--;
    },
    questionStepNext: function questionStepNext() {
      if (this.questionStep + 1 < this.questionDetail.list.length) {
        this.questionStep++;
      } else {
        (0, _vant.Toast)('没有下一题了');
      }
    },
    questionPopClosedAction: function questionPopClosedAction() {
      this.questionAnswer = [];
      this.questionStep = 0;
    },
    imageRadioChange: function imageRadioChange(index, v) {
      this.questionAnswer[index] = v;

      if (this.questionStep < this.questionDetail.list.length - 1) {
        this.questionStep++;
      }
    },
    radioChange: function radioChange() {
      var _this3 = this;

      if (this.questionStep < this.questionDetail.list.length - 1) {
        setTimeout(function () {
          _this3.questionStep++;
        }, 500);
      }
    },
    fetchConfigActivityQuestionSave: function fetchConfigActivityQuestionSave() {
      var _this4 = this;

      if (this.questionAnswer.length != this.questionDetail.list.length) {
        (0, _vant.Toast)('题目未完成');
        return;
      }

      var doctor_answer_array = [];

      for (var index = 0; index < this.questionAnswer.length; index++) {
        var element = this.questionAnswer[index];
        doctor_answer_array.push({
          question_id: this.questionDetail.list[index].id,
          doctor_answer: element
        });
      }

      var dict = {
        code: this.code,
        doctor_answer_array: doctor_answer_array
      };

      _core.mallInteractor.fetchConfigActivityQuestionSave(dict).then(function (data) {
        _this4.answered_num = data.answered_num;
        _this4.questionStep++;
      }).catch(function (e) {});
    },
    questionSaveAfterAction: function questionSaveAfterAction() {
      this.questionPopClosedAction();
      this.questionPopClick();
    },
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      _core.mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    fetchConfigActivityButtonReceive: function fetchConfigActivityButtonReceive() {
      var _this5 = this;

      _core.mallInteractor.fetchConfigActivityButtonReceive({
        code: this.code
      }).then(function (data) {
        if (data.redirect == 1) {
          _this5.jumpToRewardList();
        } else {
          (0, _vant.Toast)(data.reward_msg);
        }
      });
    },
    jumpToRewardList: function jumpToRewardList() {
      this.$router.push({
        path: '/configActivityRewardList',
        query: {
          code: this.code
        }
      });
    }
  }
};
exports.default = _default;