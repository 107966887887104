//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'AllSettings',
  props: {},
  data: function data() {
    return {
      list: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivitySettledDetail();
  },
  methods: {
    fetchActivitySettledDetail: function fetchActivitySettledDetail() {
      var _this = this;

      mallInteractor.fetchActivitySettledDetail().then(function (data) {
        _this.list = data.list;
      });
    },
    receiveAction: function receiveAction(item) {
      var _this2 = this;

      if (item.is_received == 0 && item.status == 1) {
        console.log(item);
        mallInteractor.fetchActivitySettledReceive({
          amount: item.amount
        }).then(function (data) {
          Toast('领取成功');
          setTimeout(function () {
            _this2.fetchActivitySettledDetail();
          }, 1000);
        });
      }
    }
  }
};