"use strict";

var _interopRequireWildcard = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.object.keys");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

require("@/styles/index.scss");

var _vant = _interopRequireDefault(require("vant"));

require("vant/lib/index.css");

require("@/icons");

var _App = _interopRequireDefault(require("@/App.vue"));

require("@/permission");

require("@/pwa/registerServiceWorker");

var _router = _interopRequireDefault(require("@/router"));

var _store = _interopRequireDefault(require("@/store"));

var _report = _interopRequireDefault(require("@/utils/report"));

var _utils = require("@/utils");

var _bus = _interopRequireDefault(require("@/utils/bus"));

var _filters = _interopRequireDefault(require("@/filters"));

var _lang = _interopRequireWildcard(require("@/lang"));

var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));

var _vueLotteryDraw = _interopRequireDefault(require("vue-lottery-draw"));

// 引入样式
// import 'vue-easytable/libs/themes-base/index.css'
// import {VTable} from 'vue-easytable'
_vue.default.use(_vueLotteryDraw.default); // // 注册到全局
// Vue.component(VTable.name, VTable)


(0, _lang.VueVantLocales)();
(0, _utils.initPlatform)();
_vue.default.config.productionTip = false;
var _process$env = process.env,
    NODE_ENV = _process$env.NODE_ENV,
    VUE_APP_SENTRY_ENABLED = _process$env.VUE_APP_SENTRY_ENABLED;
var PROD = NODE_ENV === 'production'; // if (PROD && VUE_APP_SENTRY_ENABLED === 'yes') {
//   const { VUE_APP_SENTRY_DSN } = process.env
//   const sentry = Report.getInstance(Vue, {
//     dsn: VUE_APP_SENTRY_DSN,
//     release: __VERSION__,
//     environment: 'Prod'
//   })
//   window.$sentry = sentry
//   Vue.config.errorHandler = (error, vm, info) => {
//     window.$sentry.log({
//       error,
//       type: 'vue errorHandler',
//       vm,
//       info
//     })
//   }
// }
// Vue.prototype.getTokenFormApp = function (appToken) {
//   console.log(appToken)
//   const { token } = store.getters
//   console.log(token)
//   if (appToken !== token) {
//     console.log('appToken !== token')
//     store.dispatch('user/saveToken', appToken)
//     return
//   } 
//   if (appToken && !token) {
//     console.log('appToken && !token')
//     store.dispatch('user/saveToken', appToken)
//     return
//   }
//   $bus.emit('getToken', t)
// },

Object.keys(_filters.default).forEach(function (filterName) {
  _vue.default.filter(filterName, _filters.default[filterName]);
});

_vue.default.use(_vant.default).use(_bus.default).use(_vueClipboard.default);

var _default = new _vue.default({
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount('#app');

exports.default = _default;

window.getTokenFormApp = function (appToken) {
  console.log(appToken);
  var token = _store.default.getters.token;
  console.log(token);

  if (appToken !== token) {
    console.log('appToken !== token');

    _store.default.dispatch('user/saveToken', appToken);

    return;
  }

  if (appToken && !token) {
    console.log('appToken && !token');

    _store.default.dispatch('user/saveToken', appToken);

    return;
  } // bus.emit('getToken', 't')

};

window.getBuildFormApp = function (build) {
  console.log(build);

  _store.default.dispatch('user/saveBuild', build); // bus.emit('getToken', 't')

};