var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { "background-color": "white" } },
      [
        _c("CommonHeader", { attrs: { title: "投诉类型" } }),
        _c(
          "van-radio-group",
          {
            staticStyle: { padding: "10px" },
            model: {
              value: _vm.query.type,
              callback: function($$v) {
                _vm.$set(_vm.query, "type", $$v)
              },
              expression: "query.type"
            }
          },
          _vm._l(_vm.tagList, function(item) {
            return _c("van-radio", { attrs: { name: item.id } }, [
              _vm._v(_vm._s(item.name))
            ])
          }),
          1
        ),
        _c("CommonHeader", { attrs: { title: "投诉说明" } }),
        _c("van-field", {
          staticStyle: { height: "100px" },
          attrs: {
            rows: "1",
            autosize: "",
            type: "textarea",
            placeholder: "请详细填写您的反馈详情，以确保投诉能够被受理"
          },
          model: {
            value: _vm.query.content,
            callback: function($$v) {
              _vm.$set(_vm.query, "content", $$v)
            },
            expression: "query.content"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "van-button",
          {
            staticStyle: { width: "80%" },
            attrs: { round: "", color: "#ff9000" },
            on: { click: _vm.fetchCommunitySetComplaint }
          },
          [_vm._v("提交")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }