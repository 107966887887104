"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AllSettings',
  props: {},
  data: function data() {
    return {
      list: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivitySettledDetail();
  },
  methods: {
    fetchActivitySettledDetail: function fetchActivitySettledDetail() {
      var _this = this;

      _core.mallInteractor.fetchActivitySettledDetail().then(function (data) {
        _this.list = data.list;
      });
    },
    receiveAction: function receiveAction(item) {
      var _this2 = this;

      if (item.is_received == 0 && item.status == 1) {
        console.log(item);

        _core.mallInteractor.fetchActivitySettledReceive({
          amount: item.amount
        }).then(function (data) {
          (0, _vant.Toast)('领取成功');
          setTimeout(function () {
            _this2.fetchActivitySettledDetail();
          }, 1000);
        });
      }
    }
  }
};
exports.default = _default;