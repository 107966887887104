"use strict";

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.map");

require("core-js/modules/es.number.constructor");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Flyin',
  props: {
    elLeft: {
      type: Number,
      default: 0
    },
    elTop: {
      type: Number,
      default: 0
    },
    cutout: {
      type: String,
      default: 'https://img.yzcdn.cn/vant/ipad.jpeg'
    },
    balls: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    }
  },
  methods: {
    beforeEnter: function beforeEnter(el) {
      el.style.transform = "translate3d(".concat(this.elLeft - 30, "px,").concat(this.elTop - 100, "px , 0)");
      el.style.opacity = 0;
    },
    afterEnter: function afterEnter(el) {
      var _this = this;

      var badgePosition = document.getElementById('buycar').getBoundingClientRect();
      el.style.transform = "translate3d(".concat(badgePosition.left + 30, "px,").concat(badgePosition.top - 30, "px,0)");
      el.style.transition = 'transform .88s cubic-bezier(0.3, -0.25, 0.7, -0.15)';
      el.style.transition = 'transform .88s linear';
      this.$emit('update:balls', this.balls.map(function (item) {
        return false;
      }));
      el.style.opacity = 1;
      el.addEventListener('transitionend', function () {
        el.style.display = 'none';

        _this.targetLocation();
      });
      el.addEventListener('webkitAnimationEnd', function () {
        el.style.display = 'none';

        _this.targetLocation();
      });
    },
    targetLocation: function targetLocation() {
      document.getElementById('buycar').classList.add('flyin-to-cart');
      setTimeout(function () {
        document.getElementById('buycar').classList.remove('flyin-to-cart');
      }, 500);
    }
  }
};
exports.default = _default;