"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MySpace',
  props: {},
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        1: '审核中',
        3: '审核拒绝'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      detail: undefined,
      tabsActive: 0,
      tabs: [{
        title: '发帖',
        name: 0
      }, {
        title: '点赞',
        name: 1
      }, {
        title: '收藏',
        name: 2
      }],
      query: {
        page: 0,
        size: 10,
        object_type: 0
      },
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      actionSheetItem: undefined,
      actionSheetShow: false,
      actions2: [{
        name: '删除'
      }, {
        name: '取消'
      }],
      actions1: [{
        name: '编辑'
      }, {
        name: '删除'
      }, {
        name: '取消'
      }],
      actions: [{
        name: '分享'
      }, {
        name: '删除'
      }, {
        name: '取消'
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    console.log('mounted');
    this.fetchCommunityDoctorInfo();
  },
  methods: {
    getActions: function getActions() {
      if (this.actionSheetItem.status == 1) {
        return this.actions2;
      } else if (this.actionSheetItem.status == 3) {
        return this.actions1;
      } else {
        return this.actions;
      }
    },
    tabsOnClick: function tabsOnClick(name, title) {
      this.query.object_type = name;
      this.fetchPostList(this.query);
    },
    clickCell: function clickCell(item) {
      this.$router.push({
        path: '/postDetail',
        query: {
          id: item.id,
          scene: 'app'
        }
      });
    },
    infoIconAction: function infoIconAction(item) {
      this.actionSheetItem = item;
      this.actionSheetShow = true;
    },
    actionSheetOnSelect: function actionSheetOnSelect(item, index) {
      var _this = this;

      console.log(item);

      if (item.name == '编辑') {
        this.actionSheetShow = false;
        this.$router.push({
          path: '/editPost',
          query: {
            post_id: this.actionSheetItem.id
          }
        });
      }

      if (item.name == '分享') {
        this.actionSheetShow = false;
        this.shareAction(this.actionSheetItem);
      }

      if (item.name == '删除') {
        _core.communityInteractor.fetchCommunityDeletePost({
          post_id: this.actionSheetItem.id
        }).then(function (data) {
          _this.onRefresh();

          _this.actionSheetShow = false;
        });
      }

      if (item.name == '取消') {
        this.actionSheetItem = undefined;
        this.actionSheetShow = false;
      }
    },
    shareAction: function shareAction(item) {
      _core.communityInteractor.fetchCommunityGetPostShareUrl({
        post_id: item.id
      }).then(function (data) {
        var dict = {
          'title': item.title,
          'info': item.strip_tag_content,
          'url': process.env.VUE_APP_URL + "/postDetail?scene=h5&id=".concat(data.id)
        };

        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          // ios
          // JS通知WKWebView
          window.webkit.messageHandlers.share.postMessage(dict);
        } else if (/(Android)/i.test(navigator.userAgent)) {
          // android
          window.a5ixlz.share(JSON.stringify(dict)); // window.a5ixlz.startActivity(dict)
        }
      });
    },
    fetchCommunityDoctorInfo: function fetchCommunityDoctorInfo() {
      var _this2 = this;

      _core.communityInteractor.fetchCommunityDoctorInfo().then(function (data) {
        _this2.detail = data;
      });
    },
    fetchToggleVote: function fetchToggleVote(item) {
      _core.communityInteractor.fetchToggleVote({
        object_type: 'post',
        object_id: item.id
      }).then(function (data) {
        item.is_voted = !item.is_voted;
        item.vote_count = item.is_voted ? item.vote_count + 1 : item.vote_count - 1;
      });
    },
    onLoad: function onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      this.query.page = this.query.page + 1;
      console.log('onLoad');
      this.fetchPostList(this.query);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false;
      console.log('onRefresh');
      console.log(this.query); // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchPostList(this.query);
    },
    fetchPostList: function fetchPostList(query) {
      var _this3 = this;

      _core.communityInteractor.fetchPostList(query).then(function (data) {
        if (_this3.query.page === 1) {
          _this3.list = data.list;
        } else {
          _this3.list = [].concat((0, _toConsumableArray2.default)(_this3.list), (0, _toConsumableArray2.default)(data.list));
        }

        if (data.total <= _this3.list.length) {
          _this3.finished = true;
        }

        console.log(_this3.list);
        _this3.loading = false;
      });
    },
    jumpToMyFollowList: function jumpToMyFollowList() {
      this.$router.push({
        path: '/communityMyFollowList'
      });
    },
    jumpToMyFansList: function jumpToMyFansList() {
      this.$router.push({
        path: '/communityMyFansList'
      });
    },
    jumpToEditPost: function jumpToEditPost() {
      this.$router.push({
        path: '/editPost'
      });
    },
    jumpToCommunityMessages: function jumpToCommunityMessages() {
      this.$router.push({
        path: '/communityMessages'
      });
    }
  }
};
exports.default = _default;