"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.number.constructor");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MallItemDetail',
  data: function data() {
    return {
      isShowBuild: false
    };
  },
  methods: {
    closeAction: function closeAction() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // ios
        // JS通知WKWebView
        window.webkit.messageHandlers.close.postMessage({});
      } else if (/(Android)/i.test(navigator.userAgent)) {
        // H5跳转APP [2喜贴券页/3协定方/4开方页/5常用方页/6已开处方页/7等级权益/8我的收益/10患者交流/11患者用药]
        window.a5ixlz.close(); // window.a5ixlz.startActivity(dict)
      }
    },
    popPageAction: function popPageAction() {
      this.$router.go(-1);
    }
  },
  computed: {},
  mounted: function mounted() {
    var build = _store.default.getters.build;

    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // ios
      this.isShowBuild = Number(build) >= 1050;
    } else if (/(Android)/i.test(navigator.userAgent)) {
      // android
      this.isShowBuild = Number(build) >= 3200000;
    }
  },
  created: function created() {}
};
exports.default = _default;