import { render, staticRenderFns } from "./fourActivity.vue?vue&type=template&id=1b9e89fe&scoped=true&"
import script from "./fourActivity.vue?vue&type=script&lang=js&"
export * from "./fourActivity.vue?vue&type=script&lang=js&"
import style0 from "./fourActivity.vue?vue&type=style&index=0&id=1b9e89fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b9e89fe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b9e89fe')) {
      api.createRecord('1b9e89fe', component.options)
    } else {
      api.reload('1b9e89fe', component.options)
    }
    module.hot.accept("./fourActivity.vue?vue&type=template&id=1b9e89fe&scoped=true&", function () {
      api.rerender('1b9e89fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/activity/fourActivity.vue"
export default component.exports