"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'FourActivity',
  props: {},
  data: function data() {
    return {
      detail: undefined,
      active: 1
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityFourKF202312();
    this.fetchActivityLogs();
  },
  methods: {
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      _core.mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    fetchActivityFourKF202312: function fetchActivityFourKF202312() {
      var _this = this;

      _core.mallInteractor.fetchActivityFourKF202312().then(function (data) {
        _this.detail = data;
      });
    },
    fetchActivityFourKF202312Receive: function fetchActivityFourKF202312Receive() {
      var _this2 = this;

      _core.mallInteractor.fetchActivityFourKF202312Receive().then(function (data) {
        _this2.fetchActivityFourKF202312();
      });
    }
  }
};
exports.default = _default;