"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _BackToTop = _interopRequireDefault(require("@/components/BackToTop"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BackToTopDemo',
  components: {
    BackToTop: _BackToTop.default
  },
  data: function data() {
    return {
      list: [],
      loading: false,
      finished: false
    };
  },
  methods: {
    onLoad: function onLoad() {
      var _this = this;

      setTimeout(function () {
        for (var i = 0; i < 10; i++) {
          _this.list.push(_this.list.length + 1);
        }

        _this.loading = false;

        if (_this.list.length >= 40) {
          _this.finished = true;
        }
      }, 1000);
    }
  }
};
exports.default = _default;