"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'activityType2RecipeList',
  props: {},
  data: function data() {
    return {
      userInfo: undefined,
      data: undefined
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var userInfo = _store.default.getters.userInfo;
    this.userInfo = userInfo;
    this.fetchActivityGFRecipes202309();
  },
  methods: {
    pushToList: function pushToList() {
      this.$router.push({
        path: '/activityType2AppRecipeList'
      });
    },
    pushToPatientUrl: function pushToPatientUrl() {
      _core.mallInteractor.fetchActivityCommonGetLockStr({
        object_id: this.userInfo.id
      }).then(function (data) {
        window.location.href = process.env.VUE_APP_PATIENT_URL + '/pages/activity/activityType2200309?doctor=' + data.lock_str;
      });
    },
    switchOnInput: function switchOnInput(item) {
      this.fetchActivityGFRecipes202309AddAccord({
        type: 1,
        recipe_id: item.recipe_id
      });
    },
    accordRecipeShare: function accordRecipeShare(item) {
      var _this = this;

      _core.mallInteractor.fetchActivityGFRecipes202309AccordRecipeShare({
        recipe_id: item.recipe_id
      }).then(function (data) {
        _this.shareAction(data.url);
      });
    },
    recipeShare: function recipeShare(item) {
      var _this2 = this;

      _core.mallInteractor.fetchActivityGFRecipes202309RecipeShare({
        id: item.recipe_id
      }).then(function (data) {
        _this2.shareAction(data.url);
      });
    },
    shareAction: function shareAction(url) {
      var dict = {
        'title': this.userInfo.name + '医生为您开据身体调理方案-喜郎中"',
        'info': '点击查看方案详情',
        'url': url
      };

      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // ios
        // JS通知WKWebView
        window.webkit.messageHandlers.share.postMessage(dict);
      } else if (/(Android)/i.test(navigator.userAgent)) {
        // android
        window.a5ixlz.share(JSON.stringify(dict));
      }
    },
    fetchActivityGFRecipes202309: function fetchActivityGFRecipes202309() {
      var _this3 = this;

      _core.mallInteractor.fetchActivityGFRecipes202309({
        doctor_id: this.userInfo.id
      }).then(function (data) {
        _this3.data = data; // this.list = data.list
      });
    },
    fetchActivityGFRecipes202309AddAccord: function fetchActivityGFRecipes202309AddAccord(query) {
      var _this4 = this;

      _core.mallInteractor.fetchActivityGFRecipes202309AddAccord(query).then(function (data) {
        _this4.fetchActivityGFRecipes202309();
      });
    }
  }
};
exports.default = _default;