"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AllSettings',
  props: {},
  data: function data() {
    return {
      hgDetail: {
        list: [{
          status: 0
        }, {
          status: 0
        }],
        count: 0
      },
      total_amount: 0,
      total_income: 0
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityReturn();
  },
  methods: {
    fetchActivityReturn: function fetchActivityReturn() {
      var _this = this;

      _core.mallInteractor.fetchActivityReturn202307().then(function (data) {
        _this.hgDetail.list[0].status = data.first_button_status;
        _this.hgDetail.list[1].status = data.second_button_status;
        _this.hgDetail.count = data.count;
        _this.total_income = data.total_income;
        _this.total_amount = data.total_amount;
      });
    },
    receiveAction: function receiveAction(item, index) {
      var _this2 = this;

      console.log(item);
      console.log(index);

      if (item.status == 1) {
        _core.mallInteractor.fetchActivityReceive202307({
          button_id: index + 1
        }).then(function (data) {
          (0, _vant.Toast)('领取成功');
          setTimeout(function () {
            _this2.fetchActivityReturn();
          }, 1000);
        });
      }
    }
  }
};
exports.default = _default;