var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "flex-flow": "wrap",
        display: "flex",
        "flex-direction": "row",
        "margin-left": "3vw"
      }
    },
    _vm._l(_vm.list, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticStyle: {
            width: "45.5vw",
            height: "100px",
            "z-index": "0",
            margin: "3vw 3vw 0 0",
            "border-radius": "10px",
            overflow: "hidden"
          },
          on: {
            click: function($event) {
              return _vm.jumpToUrl(item.url)
            }
          }
        },
        [
          _c("van-image", {
            staticStyle: { width: "100%", height: "100px" },
            attrs: { fit: "cover", src: item.image.url }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }