"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _store = _interopRequireDefault(require("@/store"));

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MallAddressEdit',
  data: function data() {
    return {
      name: undefined,
      phone: undefined,
      region: undefined,
      detail: undefined,
      showArea: false,
      is_default: false,
      areaList: undefined,
      loading: true,
      id: undefined,
      pattern: /^1[3456789]\d{9}$/,
      patternName: /^[\u4E00-\u9FA5A-Za-z]{2,20}$/,
      type: undefined
    };
  },
  methods: {
    onSubmit: function onSubmit(values) {
      var _this2 = this;

      console.log(values);
      values.is_default = values.is_default ? 1 : 0;
      values['region'] = this.region;

      if (this.id) {
        values['id'] = this.id;
      }

      if (!this.region) {
        (0, _vant.Toast)('请选择地区');
        return;
      }

      if (!this.detail) {
        (0, _vant.Toast)('请填写详细地址');
        return;
      }

      _core.mallInteractor.fetchMallCreateAddress(values).then(function (data) {
        _this2.$router.back(-1);
      });
    },
    onConfirm: function onConfirm(value) {
      console.log(value);
      this.region = value[0] + ' ' + value[1] + ' ' + value[2];
      this.showArea = false;
    },
    fetchAreaList: function fetchAreaList() {
      var _this = this;

      _core.mallInteractor.fetchMallGetAllAreas().then(function (data) {
        _this.areaList = data;
        _this.loading = false;
      });
    }
  },
  computed: {},
  mounted: function mounted() {},
  created: function created() {
    if (this.$route.query) {
      this.type = this.$route.query.type;
      this.name = this.$route.query.name;
      this.phone = this.$route.query.mobile;
      this.region = this.$route.query.region;
      this.detail = this.$route.query.detail;
      this.is_default = this.$route.query.is_default == 1 ? true : false;
      this.id = this.$route.query.id;
    }

    this.fetchAreaList();
  }
};
exports.default = _default;