var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: {
        width: "100%",
        height: "120vh",
        "background-color": "white"
      }
    },
    [
      _c("div", { staticStyle: { height: "20px" } }),
      _vm.detail
        ? _c(
            "div",
            {
              staticClass: "card",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "0px",
                "flex-direction": "column",
                "align-items": "center"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "6px 12px",
                    display: "flex",
                    "justify-content": "space-between",
                    "align-items": "center",
                    width: "100%"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("van-image", {
                        staticStyle: {
                          width: "32px",
                          height: "32px",
                          display: "flex",
                          "align-items": "center"
                        },
                        attrs: { fit: "contain", src: _vm.detail.img_url }
                      }),
                      _c("div", { staticStyle: { padding: "4px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              color: "#5D5A57",
                              "font-size": "15px"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.detail.name) +
                                " " +
                                _vm._s(_vm.detail.mobile)
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "400",
                              color: "#A7A7A7",
                              "font-size": "14px",
                              "padding-top": "5px"
                            }
                          },
                          [_vm._v(_vm._s(_vm.detail.created_at))]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        color: "#F39800",
                        "font-size": "15px"
                      }
                    },
                    [_vm._v(_vm._s(_vm.detail.status ? "已认证" : "未认证"))]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm.detail
        ? _c(
            "div",
            {
              staticClass: "card",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "0px",
                "flex-direction": "column",
                "align-items": "center"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "12px",
                    display: "flex",
                    "justify-content": "space-between",
                    "align-items": "center",
                    width: "100%"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        color: "#5D5A57",
                        "font-size": "15px"
                      }
                    },
                    [
                      _vm._v("可提现余额："),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            color: "#F39800",
                            "font-size": "15px"
                          }
                        },
                        [_vm._v(_vm._s(_vm.detail.income_amount) + "喜分")]
                      )
                    ]
                  ),
                  _c(
                    "van-button",
                    {
                      style: {
                        color:
                          _vm.detail.button_status == 0 ? "#A7A7A7" : "white",
                        background:
                          _vm.detail.button_status == 0 ? "#F1F0F0" : "#F39800",
                        border:
                          _vm.detail.button_status == 0 ? "#F1F0F0" : "#F39800"
                      },
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.fetchActivityInviteDetailCash()
                        }
                      }
                    },
                    [_vm._v("提取至我的收益")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.detail
        ? _c(
            "div",
            {
              staticClass: "card",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "0px",
                "flex-direction": "column",
                "align-items": "center"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "16px 10px",
                    display: "flex",
                    "justify-content": "space-between",
                    "align-items": "center",
                    width: "100%"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("van-image", {
                        staticStyle: { width: "15px" },
                        attrs: {
                          fit: "contain",
                          src: require("../../assets/activity/inviteDoctor/d_b_1.png")
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "400",
                            color: "#5D5A57",
                            "font-size": "14px",
                            "padding-left": "5px"
                          }
                        },
                        [_vm._v("邀请任务")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        color: "#5D5A57",
                        "font-size": "15px"
                      }
                    },
                    [
                      _vm._v("累计获取："),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            color: "#F39800",
                            "font-size": "15px"
                          }
                        },
                        [_vm._v(_vm._s(_vm.detail.total_amount) + "喜分")]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "van-cell-group",
                { staticStyle: { width: "100%" } },
                _vm._l(_vm.detail.task_list, function(item, index) {
                  return _c("van-cell", {
                    key: index,
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    color: "#5D5A57",
                                    "font-size": "15px"
                                  }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    color: "#A7A7A7",
                                    "font-size": "13px"
                                  }
                                },
                                [_vm._v(_vm._s(item.task_str))]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "align-items": "center",
                                    display: "flex",
                                    "flex-direction": "row-reverse"
                                  }
                                },
                                [
                                  item.status == 1
                                    ? _c(
                                        "van-button",
                                        {
                                          staticStyle: {
                                            color: "#F39800",
                                            background: "#FFF3E4",
                                            border: "#FFF3E4"
                                          },
                                          attrs: { size: "small" }
                                        },
                                        [_vm._v("已完成")]
                                      )
                                    : _c(
                                        "van-button",
                                        {
                                          staticStyle: {
                                            color: "#A7A7A7",
                                            background: "#F1F0F0",
                                            border: "#F1F0F0"
                                          },
                                          attrs: { size: "small" }
                                        },
                                        [_vm._v("待完成")]
                                      )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticStyle: { height: "30px" } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }