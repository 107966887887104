"use strict";

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MallItemBuySuccess',
  data: function data() {
    return {
      title: undefined
    };
  },
  methods: {
    popToRoot: function popToRoot() {
      this.$router.replace({
        path: '/mall'
      });
    }
  },
  computed: {},
  mounted: function mounted() {},
  created: function created() {
    console.log(this.$route.query);
    this.title = this.$route.query.totalAmount;
  }
};
exports.default = _default;