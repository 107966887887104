"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'share202207',
  props: {},
  data: function data() {
    return {
      hgDetail: {
        list: [{
          status: 0
        }, {
          status: 0
        }],
        accord_share_url: undefined,
        accord_set_url: undefined,
        count: 0
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchActivityXTDetail();
  },
  methods: {
    jumpTo: function jumpTo(url) {
      window.location.href = url;
    },
    fetchActivityXTDetail: function fetchActivityXTDetail() {
      var _this = this;

      _core.mallInteractor.fetchActivityXTDetail().then(function (data) {
        _this.hgDetail.list[0].status = data.first_button_status;
        _this.hgDetail.list[1].status = data.second_button_status;
        _this.hgDetail.accord_set_url = data.accord_set_url;
        _this.hgDetail.accord_share_url = data.accord_share_url;
        _this.hgDetail.count = data.count;
      });
    },
    receiveAction: function receiveAction(item, index) {
      var _this2 = this;

      console.log(item);
      console.log(index);

      if (item.status == 1) {
        _core.mallInteractor.fetchActivityXTReceive({
          button_id: index + 1
        }).then(function (data) {
          (0, _vant.Toast)('领取成功');
          setTimeout(function () {
            _this2.fetchActivityXTDetail();
          }, 1000);
        });
      }
    }
  }
};
exports.default = _default;