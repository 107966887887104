"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-hidden-integral/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _core = require("@/core");

var _vant = require("vant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CommunityDoctorInfo',
  props: {},
  data: function data() {
    return {
      detail: undefined,
      query: {
        doctor_id: undefined,
        page: 0,
        size: 10
      },
      list: [],
      total: undefined,
      loading: false,
      finished: false,
      refreshing: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    console.log(this.$route.query);
    this.query.doctor_id = this.$route.query.doctor_id;
    this.fetchCommunityDoctorInfo();
  },
  methods: {
    clickCell: function clickCell(item) {
      this.$router.push({
        path: '/postDetail',
        query: {
          id: item.id,
          scene: 'app'
        }
      });
    },
    fetchToggleFollow: function fetchToggleFollow(is_followed) {
      var that = this;

      if (is_followed) {
        _vant.Dialog.confirm({
          message: '确认不再关注此人',
          confirmButtonColor: '#ff9000'
        }).then(function () {
          // on confirm
          that.toggleFollow(is_followed);
        }).catch(function () {// on cancel
        });
      } else {
        that.toggleFollow(is_followed);
      }
    },
    toggleFollow: function toggleFollow(is_followed) {
      var _this = this;

      _core.communityInteractor.fetchToggleFollow({
        doctor_id: this.detail.doctor_id
      }).then(function (data) {
        _this.detail.is_followed = !is_followed;
      });
    },
    fetchCommunityDoctorInfo: function fetchCommunityDoctorInfo() {
      var _this2 = this;

      _core.communityInteractor.fetchCommunityDoctorInfo({
        doctor_id: this.query.doctor_id
      }).then(function (data) {
        _this2.detail = data;
      });
    },
    fetchToggleVote: function fetchToggleVote(item) {
      _core.communityInteractor.fetchToggleVote({
        object_type: 'post',
        object_id: item.id
      }).then(function (data) {
        item.is_voted = !item.is_voted;
        item.vote_count = item.is_voted ? item.vote_count + 1 : item.vote_count - 1;
      });
    },
    onLoad: function onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      this.query.page = this.query.page + 1;
      console.log('onLoad');
      this.fetchPostList(this.query);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false;
      console.log('onRefresh');
      console.log(this.query); // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.fetchPostList(this.query);
    },
    fetchPostList: function fetchPostList(query) {
      var _this3 = this;

      _core.communityInteractor.fetchPostList(query).then(function (data) {
        if (_this3.query.page === 1) {
          _this3.list = data.list;
        } else {
          _this3.list = [].concat((0, _toConsumableArray2.default)(_this3.list), (0, _toConsumableArray2.default)(data.list));
        }

        if (data.total <= _this3.list.length) {
          _this3.finished = true;
        }

        _this3.total = data.total;
        console.log(_this3.list);
        _this3.loading = false;
      });
    },
    jumpToMyFollowList: function jumpToMyFollowList() {
      this.$router.push({
        path: '/communityMyFollowList',
        query: {
          doctor_id: this.query.doctor_id
        }
      });
    },
    jumpToMyFansList: function jumpToMyFansList() {
      this.$router.push({
        path: '/communityMyFansList',
        query: {
          doctor_id: this.query.doctor_id
        }
      });
    }
  }
};
exports.default = _default;