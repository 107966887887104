var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "body",
      staticStyle: { width: "100%", "background-color": "#EF2D2C" }
    },
    [
      _c(
        "div",
        { staticStyle: { "text-align": "center", padding: "50px 0" } },
        [
          _c("van-image", {
            staticStyle: { width: "90%", "z-index": "0" },
            attrs: {
              fit: "fill",
              src: require("../../assets/activity/inviteDoctor202402/banner.png")
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/inviteDoctor202402/i_d_h.png")
                }
              }),
              _c("div", { staticClass: "image-value" }, [_vm._v("我的奖励")])
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                padding: "50px 16px 20px 16px",
                width: "100%",
                "text-align": "center"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "16px",
                    "font-weight": "bold",
                    "padding-bottom": "10px"
                  }
                },
                [
                  _vm._v(
                    "有效邀请医生数：" + _vm._s(_vm.detail.valid_invite) + "人"
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    padding: "10px 0",
                    "justify-content": "space-between"
                  }
                },
                [
                  _vm.detail
                    ? _c("div", { staticStyle: { "text-align": "center" } }, [
                        _vm.detail.one_reward_status == 0
                          ? _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _c("van-image", {
                                  staticStyle: { width: "80%" },
                                  attrs: {
                                    fit: "contain",
                                    src: require("../../assets/activity/inviteDoctor202402/i_d_0.png")
                                  }
                                }),
                                _vm._m(0),
                                _vm._m(1)
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.detail.one_reward_status == 1
                          ? _c(
                              "div",
                              {
                                staticStyle: { position: "relative" },
                                on: {
                                  click: function($event) {
                                    return _vm.fetchActivityInviteDoctorCash202402(
                                      1
                                    )
                                  }
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: { width: "80%" },
                                  attrs: {
                                    fit: "contain",
                                    src: require("../../assets/activity/inviteDoctor202402/i_d_1.png")
                                  }
                                }),
                                _vm._m(2),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      bottom: "24px",
                                      width: "100%",
                                      color: "#ED2231",
                                      "font-size": "14px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("立即领取")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.detail.one_reward_status == 2
                          ? _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _c("van-image", {
                                  staticStyle: { width: "80%" },
                                  attrs: {
                                    fit: "contain",
                                    src: require("../../assets/activity/inviteDoctor202402/i_d_2.png")
                                  }
                                }),
                                _vm._m(3),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      bottom: "16%",
                                      width: "100%",
                                      color: "rgba(255, 255, 255, 0.32)",
                                      "font-size": "14px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("已领取")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.detail
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        [
                          _c("van-image", {
                            staticStyle: { width: "85%" },
                            attrs: {
                              fit: "contain",
                              src: require("../../assets/activity/inviteDoctor202402/i_t_m.png")
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detail
                    ? _c("div", { staticStyle: { "text-align": "center" } }, [
                        _vm.detail.two_reward_status == 0
                          ? _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _c("van-image", {
                                  staticStyle: { width: "80%" },
                                  attrs: {
                                    fit: "contain",
                                    src: require("../../assets/activity/inviteDoctor202402/i_d_0.png")
                                  }
                                }),
                                _vm._m(4),
                                _vm._m(5)
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.detail.two_reward_status == 1
                          ? _c(
                              "div",
                              {
                                staticStyle: { position: "relative" },
                                on: {
                                  click: function($event) {
                                    return _vm.fetchActivityInviteDoctorCash202402(
                                      2
                                    )
                                  }
                                }
                              },
                              [
                                _c("van-image", {
                                  staticStyle: { width: "80%" },
                                  attrs: {
                                    fit: "contain",
                                    src: require("../../assets/activity/inviteDoctor202402/i_d_1.png")
                                  }
                                }),
                                _vm._m(6),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      bottom: "24px",
                                      width: "100%",
                                      color: "#ED2231",
                                      "font-size": "14px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("立即领取")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.detail.two_reward_status == 2
                          ? _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _c("van-image", {
                                  staticStyle: { width: "80%" },
                                  attrs: {
                                    fit: "contain",
                                    src: require("../../assets/activity/inviteDoctor202402/i_d_2.png")
                                  }
                                }),
                                _vm._m(7),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      bottom: "16%",
                                      width: "100%",
                                      color: "rgba(255, 255, 255, 0.32)",
                                      "font-size": "14px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v("已领取")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ]
              ),
              _vm._m(8)
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/inviteDoctor202402/i_d_h.png")
                }
              }),
              _c("div", { staticClass: "image-value" }, [_vm._v("奖励规则")])
            ],
            1
          ),
          _vm._m(9)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/inviteDoctor202402/i_d_h.png")
                }
              }),
              _c("div", { staticClass: "image-value" }, [_vm._v("邀请记录")])
            ],
            1
          ),
          _vm.detail
            ? _c(
                "div",
                {
                  staticStyle: {
                    padding: "50px 16px 20px 16px",
                    width: "100%",
                    "text-align": "center"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "bold",
                        "padding-bottom": "10px"
                      }
                    },
                    [
                      _vm._v(
                        "累计邀请医生数：" +
                          _vm._s(_vm.detail.invite_list.length) +
                          "人"
                      )
                    ]
                  ),
                  _c(
                    "table",
                    {
                      staticClass: "tg",
                      staticStyle: { width: "100%", margin: "10px 0" }
                    },
                    [
                      _vm._m(10),
                      _c(
                        "tbody",
                        _vm._l(_vm.detail.invite_list, function(item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "tg-zs29" }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _c("td", { staticClass: "tg-zs29" }, [
                              _vm._v(_vm._s(item.mobile))
                            ]),
                            _c(
                              "td",
                              {
                                class:
                                  item.auth_status == 1 ? "tg-zs31" : "tg-zs30"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.auth_status == 1 ? "已认证" : "未认证"
                                  )
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                class:
                                  item.prescription_status == 1
                                    ? "tg-zs31"
                                    : "tg-zs30"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.prescription_status == 1
                                      ? "已开方"
                                      : "未开方"
                                  )
                                )
                              ]
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  ),
                  _vm.detail.invite_list.length == 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "padding-bottom": "10px"
                          }
                        },
                        [_vm._v("暂无数据")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#EF2132",
                        "font-size": "12px",
                        padding: "0px 0",
                        "text-align": "left"
                      }
                    },
                    [_vm._v(" *此页面数据仅本人可见 ")]
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: { display: "flex", "justify-content": "center" }
        },
        [
          _c(
            "div",
            { staticClass: "image-title" },
            [
              _c("van-image", {
                staticStyle: { width: "55%" },
                attrs: {
                  fit: "contain",
                  src: require("../../assets/activity/inviteDoctor202402/i_d_h.png")
                }
              }),
              _c("div", { staticClass: "image-value" }, [_vm._v("常见问题")])
            ],
            1
          ),
          _vm._m(11)
        ]
      ),
      _vm._m(12),
      _c(
        "footer",
        {
          staticStyle: {
            width: "100%",
            position: "fixed",
            bottom: "0",
            left: "0",
            height: "70px",
            "justify-content": "center",
            "background-color": "white",
            "align-items": "center",
            display: "flex"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "background-color": "#EF2D2C",
                color: "white",
                "line-height": "44px",
                width: "80%",
                "font-size": "16px",
                "border-radius": "22px",
                "text-align": "center"
              },
              on: {
                click: function($event) {
                  return _vm.shareAction()
                }
              }
            },
            [_vm._v("立即邀请医生入驻")]
          )
        ]
      ),
      _c("div", { staticStyle: { height: "70px" } })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { position: "absolute", top: "20px", width: "100%" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "rgba(255, 228, 195, 0.25)"
            }
          },
          [_vm._v("暂未达成")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { position: "absolute", bottom: "25px", width: "100%" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "rgba(255, 230, 205, 1)",
              "font-weight": "bold",
              "font-size": "20px"
            }
          },
          [_vm._v("200")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "rgba(255, 230, 205, 1)",
              "font-size": "12px"
            }
          },
          [_vm._v("喜分")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { position: "absolute", top: "18px", width: "100%" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "#F33434",
              "font-size": "12px",
              "font-weight": "bold"
            }
          },
          [_vm._v("喜分奖励")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "#F33434",
              "font-weight": "bold",
              "font-size": "26px"
            }
          },
          [_vm._v("200")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { position: "absolute", top: "18px", width: "100%" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "#F33434",
              "font-size": "12px",
              "font-weight": "bold"
            }
          },
          [_vm._v("喜分奖励")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "#F33434",
              "font-weight": "bold",
              "font-size": "26px"
            }
          },
          [_vm._v("200")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { position: "absolute", top: "20px", width: "100%" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "rgba(255, 228, 195, 0.25)"
            }
          },
          [_vm._v("暂未达成")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { position: "absolute", bottom: "25px", width: "100%" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "rgba(255, 230, 205, 1)",
              "font-weight": "bold",
              "font-size": "20px"
            }
          },
          [_vm._v("800")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "rgba(255, 230, 205, 1)",
              "font-size": "12px"
            }
          },
          [_vm._v("喜分")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { position: "absolute", top: "18px", width: "100%" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "#F33434",
              "font-size": "12px",
              "font-weight": "bold"
            }
          },
          [_vm._v("喜分奖励")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "#F33434",
              "font-weight": "bold",
              "font-size": "26px"
            }
          },
          [_vm._v("800")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { position: "absolute", top: "18px", width: "100%" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "#F33434",
              "font-size": "12px",
              "font-weight": "bold"
            }
          },
          [_vm._v("喜分奖励")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              "text-align": "center",
              color: "#F33434",
              "font-weight": "bold",
              "font-size": "26px"
            }
          },
          [_vm._v("800")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-size": "14px",
          "padding-bottom": "10px",
          display: "flex",
          "justify-content": "space-around"
        }
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              "background-color": "rgba(237, 34, 47, 0.1)",
              color: "#ED222F",
              width: "80px",
              "line-height": "30px",
              "border-radius": "15px"
            }
          },
          [_vm._v("邀请1人")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              "background-color": "rgba(237, 34, 47, 0.1)",
              color: "#ED222F",
              width: "80px",
              "line-height": "30px",
              "border-radius": "15px"
            }
          },
          [_vm._v("邀请5人")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { padding: "50px 16px 20px 16px", width: "100%" } },
      [
        _c("div", { staticClass: "text-header" }, [
          _vm._v(" 完成任务，最高可领1000喜分奖励 ")
        ]),
        _c("div", { staticClass: "text-div" }, [
          _vm._v(" 任务一：邀请1名同行有效看病处方，即可获得200喜分奖励 ")
        ]),
        _c("br"),
        _c("div", { staticClass: "text-div" }, [
          _vm._v(" 任务二：累计邀请5名同行有效看病处方，即可获得800喜分奖励")
        ]),
        _c(
          "div",
          {
            staticStyle: {
              color: "#EF2132",
              "font-size": "14px",
              padding: "10px 0"
            }
          },
          [_vm._v(" *本活动自2024年2月1日起有效。 ")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "tg-uc1r" }, [_vm._v("邀请医生")]),
        _c("th", { staticClass: "tg-uc1r" }, [_vm._v("手机号码")]),
        _c("th", { staticClass: "tg-uc1r" }, [_vm._v("认证状态")]),
        _c("th", { staticClass: "tg-uc1r" }, [_vm._v("开方状态")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { padding: "50px 16px 20px 16px", width: "100%" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              color: "#EF2D2C",
              "font-size": "16px",
              "font-weight": "bold",
              "padding-bottom": "10px"
            }
          },
          [_vm._v(" Q：如何算作有效看病处方? ")]
        ),
        _c("div", { staticClass: "text-div" }, [
          _vm._v(
            " A：患者成功支付完成订单，且不发生退单行为则算作有效看病处方。 "
          )
        ]),
        _c("br"),
        _c(
          "div",
          {
            staticStyle: {
              color: "#EF2D2C",
              "font-size": "16px",
              "font-weight": "bold",
              "padding-bottom": "10px"
            }
          },
          [_vm._v(" Q：如何领取奖励？ ")]
        ),
        _c("div", { staticClass: "text-div" }, [
          _vm._v(" A：被邀请医生完成有效看病处方"),
          _c(
            "span",
            { staticStyle: { "font-weight": "bold", color: "#ED222F" } },
            [_vm._v("三天后")]
          ),
          _vm._v(
            "，您可在活动页点击【领取】按钮获得奖励，可在【我的收益】中查看已获得的奖励。若有退款行为，则不计入奖励计算。 "
          )
        ]),
        _c("br"),
        _c(
          "div",
          {
            staticStyle: {
              color: "#EF2D2C",
              "font-size": "16px",
              "font-weight": "bold",
              "padding-bottom": "10px"
            }
          },
          [_vm._v(" Q：开具单品订单能参与活动吗? ")]
        ),
        _c("div", { staticClass: "text-div" }, [
          _vm._v(
            " A：不能，只有开具颗粒、膏方、饮片及饮片膏剂型处方才会计入活动，成药、单品处方均不计入此项活动。 "
          )
        ]),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "text-align": "center",
          "font-size": "12px",
          color: "white",
          "padding-bottom": "40px"
        }
      },
      [
        _vm._v("如有疑问，请联系客服："),
        _c("span", { staticStyle: { color: "white" } }, [_vm._v("4000918999")]),
        _c("br"),
        _c("br"),
        _vm._v("最终解释权归喜郎中所有")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }