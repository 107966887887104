//
//
//
//
//
//
//
//
import { mallInteractor } from '@/core';
import { Dialog, Toast } from 'vant';
export default {
  name: 'AppAllSlideList',
  props: {},
  data: function data() {
    return {
      list: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchAppAllSlideList();
    this.fetchActivityLogs();
  },
  methods: {
    fetchActivityLogs: function fetchActivityLogs() {
      //统计
      mallInteractor.fetchActivityLogs(this.$route.query).then(function (data) {});
    },
    fetchAppAllSlideList: function fetchAppAllSlideList() {
      var _this = this;

      mallInteractor.fetchAppAllSlideList({
        display_client: '2'
      }).then(function (data) {
        _this.list = data.slides;
      });
    },
    jumpToUrl: function jumpToUrl(url) {
      window.location.href = url;
    }
  }
};