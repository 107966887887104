var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg" },
    [
      _vm.goodsInfo && _vm.slideList.length != 0
        ? _c(
            "van-swipe",
            {
              staticClass: "my-swipe",
              attrs: { autoplay: 3000, "indicator-color": "white" }
            },
            _vm._l(_vm.slideList, function(item, index) {
              return _c(
                "van-swipe-item",
                { key: index },
                [_c("van-image", { attrs: { src: item.url } })],
                1
              )
            }),
            1
          )
        : _c(
            "van-swipe",
            {
              staticClass: "my-swipe",
              attrs: { autoplay: 3000, "indicator-color": "white" }
            },
            [
              _vm.goodsInfo && _vm.goodsInfo.cover_image_file
                ? _c(
                    "van-swipe-item",
                    [
                      _c("van-image", {
                        attrs: { src: _vm.goodsInfo.cover_image_file.url }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
      _c(
        "van-cell-group",
        [
          _vm.goodsInfo
            ? _c("van-cell", {
                attrs: {
                  title: _vm.goodsInfo.name,
                  value: _vm.goodsInfoPrice(),
                  label: _vm.goodsInfo.title,
                  "value-class": "value-class"
                }
              })
            : _vm._e(),
          _c("van-cell", { attrs: { title: "快递费", value: "包邮" } }),
          _c("van-cell", { attrs: { title: "商品详情" } }),
          _vm.goodsInfo
            ? _c("div", {
                staticClass: "des",
                staticStyle: { margin: "6px" },
                domProps: { innerHTML: _vm._s(_vm.goodsInfo.description) }
              })
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticStyle: { height: "60px" } }),
      _c(
        "div",
        {
          staticClass: "footer",
          staticStyle: {
            height: "60px",
            "text-align": "center",
            padding: "10px 0",
            "background-color": "transparent"
          }
        },
        [
          _c(
            "van-button",
            {
              staticClass: "payButton",
              attrs: { round: "" },
              on: { click: _vm.payButtonAction }
            },
            [_vm._v("立即兑换")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }