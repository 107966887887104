var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text": "没有更多了",
                offset: "150"
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "cell" },
                [
                  _c(
                    "van-cell-group",
                    [
                      _c("van-cell", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "custom-title" },
                                        [_vm._v(_vm._s(item.bank_card.bank))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          style: _vm._f("statusFilter")(
                                            item.status
                                          )
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("statusLeftFilter")(
                                                item.status
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c("div", {}, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "flex-end"
                                        }
                                      },
                                      [
                                        _vm._v("到账："),
                                        _c(
                                          "div",
                                          { staticStyle: { color: "#f99000" } },
                                          [_vm._v(_vm._s(item.actual_amount))]
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c("div", {}, [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "14px" } },
                                      [_vm._v("兑换：" + _vm._s(item.amount))]
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [_vm._v(_vm._s(item.created_at))]
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }